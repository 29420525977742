import React, { FC, useEffect, useRef } from 'react'
import {
  useMediumMinimumSizePreference,
  useProjectState
} from '@hooks/useProject'
import dayjs from 'dayjs'
import {
  ActionButton,
  OverlayTrigger,
  ProgressCircle,
  StatusLight,
  Tooltip
} from 'ui'
import {
  SpIconCloud,
  SpIconCloudDisconnected,
  SpIconCloudError
} from 'workflow-icons'
import { useAuth } from '@hooks/useAuth'

const DocumentSnapshotStatusIndicator: FC = () => {
  const isFeatured = useProjectState('isFeatured')
  const documentChannelStatus = useProjectState('documentChannelStatus')
  const documentSnapshotSuccess = useProjectState('documentSnapshotSuccess')
  const documentSnapshotTimestamp = useProjectState('documentSnapshotTimestamp')
  const ownerUserUuid = useProjectState('ownerUserUuid')
  const size = useMediumMinimumSizePreference()

  const { localUser, status } = useAuth()

  const isProjectOwner = localUser?.uuid === ownerUserUuid

  const getConnStatus = (): {
    icon: JSX.Element
    tooltipHeader: string
    status: 'info' | 'notice' | 'positive' | 'negative'
  } => {
    if (ownerUserUuid !== null && localUser !== null && isFeatured) {
      return {
        icon: <SpIconCloudError slot="icon" />,
        tooltipHeader: 'Duplicate project to save document',
        status: 'notice'
      }
    }

    if (ownerUserUuid !== null && localUser !== null && !isProjectOwner) {
      return {
        icon: <SpIconCloudError slot="icon" />,
        tooltipHeader: `Viewing a community project. Changes can not be saved.`,
        status: 'notice'
      }
    }
    switch (documentChannelStatus) {
      case null:
        return {
          icon: <ProgressCircle size="s" indeterminate label="Connecting" />,
          tooltipHeader: 'Connecting',
          status: 'info'
        }
      case true:
        return !documentSnapshotSuccess
          ? {
              icon: <SpIconCloudError slot="icon" />,
              tooltipHeader: 'Error saving document',
              status: 'notice'
            }
          : {
              icon: <SpIconCloud slot="icon" />,
              tooltipHeader: 'Connected',
              status: 'positive'
            }
      case false:
        return {
          icon: <SpIconCloudDisconnected slot="icon" />,
          tooltipHeader: 'Disconnected',
          status: 'negative'
        }
    }
  }

  const connectionState = getConnStatus()

  if (status === 'CHECKING_STATUS' || status === 'ERROR_CHECKING_STATUS') {
    return null
  }

  return (
    <>
      <OverlayTrigger offset={0} placement="bottom">
        <ActionButton
          slot="trigger"
          aria-label="Connection status"
          quiet
          size={size}>
          {connectionState.icon}
        </ActionButton>

        <Tooltip slot="hover-content">
          <p
            style={{
              margin: 0
            }}>
            {connectionState.tooltipHeader}
          </p>
          {isProjectOwner &&
            !isFeatured &&
            `Last saved at ${dayjs(documentSnapshotTimestamp).format('h:mma')}`}
        </Tooltip>
      </OverlayTrigger>
      <StatusLight
        size={size}
        hidden={connectionState.status === 'info'}
        variant={connectionState.status}
      />
    </>
  )
}

export default DocumentSnapshotStatusIndicator
