import { FC } from 'react'
import { DialogBase, Dialog, Button, ButtonGroup } from 'ui'
import { useFirefly } from '@hooks/useFirefly'
import { uploadReferenceImg } from './FireflyPanel'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

const FireflyUploadImagesRightsDialog: FC = () => {
  const size = useMediumMinimumSizePreference()

  const {
    showUploadingImageRightsDialog,
    setShowUploadingImageRightsDialog,
    setHasViewedUploadingImageRightsDialog
  } = useFirefly()

  function closeDialog() {
    setShowUploadingImageRightsDialog(false)
    setHasViewedUploadingImageRightsDialog(true)
  }

  function handleContinue() {
    closeDialog()
    uploadReferenceImg()
  }

  if (!showUploadingImageRightsDialog) return null
  return (
    <DialogBase underlay open={showUploadingImageRightsDialog}>
      <Dialog size={size} noDivider style={{ width: size === 'm' ? 480 : 530 }}>
        <h2 slot="heading">About uploading images</h2>
        <p>
          Scene to image helps users apply a particular style to their prompts.
          To use this service, you must have the rights to use any third-party
          images, and your upload history will be stored as thumbnails.
        </p>

        <ButtonGroup slot="button" size={size}>
          <Button onClick={closeDialog} variant="secondary" treatment="outline">
            Cancel
          </Button>
          <Button onClick={handleContinue} variant="cta">
            Continue
          </Button>
        </ButtonGroup>
      </Dialog>
    </DialogBase>
  )
}

export default FireflyUploadImagesRightsDialog
