import React from 'react'
import LocalVideo from './LocalVideo'
import RemoteVideos from './RemoteVideos'
import { useChime } from '@hooks/useChime'

const ChimeMeeting = () => {
  const { meetingId } = useChime()
  return (
    <>
      <LocalVideo />
      <RemoteVideos />
    </>
  )
}

export default ChimeMeeting
