import React, { FC, PropsWithChildren } from 'react'
import cn from 'classnames'
import styles from '@styles/components/SideNavigationItem.module.scss'

interface Props extends PropsWithChildren {
  onClick: () => void
  selected?: boolean
}

const SideNavigationItem: FC<Props> = ({ children, onClick, selected }) => {
  return (
    <p
      className={cn(styles['side-nav-item'], {
        [styles['selected']]: selected
      })}
      onClick={onClick}>
      {children}
    </p>
  )
}

export default SideNavigationItem
