import React, { ComponentProps, FC } from 'react'
import styles from '@styles/components/ColorPickerWithTextfield.module.scss'
import { ColorPicker, FieldLabel, Textfield } from 'ui'

interface Props extends ComponentProps<typeof ColorPicker> {
  label: string
  showHexField?: boolean
  size?: 's'
}

const COLOR_TEXTFIELD_PATTERN = '[#]{0,1}[A-Fa-f0-9]{6}'
const HEX_COLOR_REGEX = new RegExp(COLOR_TEXTFIELD_PATTERN)

const ColorPickerWithTextfield: FC<Props> = ({
  label,
  size = 's',
  showHexField = true,
  ...props
}) => {
  function onTextfieldChange(e: Event) {
    const color = e?.target?.value
    if (!HEX_COLOR_REGEX.test(color)) return

    props?.onChange?.({ target: { color } })
  }

  return (
    <div className={styles['container']}>
      <FieldLabel size={size} for={label}>
        {label}
      </FieldLabel>
      {showHexField && (
        <Textfield
          pattern={COLOR_TEXTFIELD_PATTERN}
          id={label}
          size={size}
          type="text"
          value={props?.color}
          onChange={onTextfieldChange}
        />
      )}
      <ColorPicker size={size} {...props} />
    </div>
  )
}

export default ColorPickerWithTextfield
