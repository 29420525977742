import { useEffect } from 'react'
import { configureSusiLight } from './SusiLight.config'

type IMSToken = string
export interface SusiLightWrapperProps {
  redirectUri?: string
  onSusiRedirect?: (e: CustomEvent<{ detail: URL | string }>) => any
  onSusiToken: (e: CustomEvent<{ detail: IMSToken }>) => any
  onSusiError: (e: CustomEvent<unknown>) => any
  onSusiLoad: (e: CustomEvent<{ detail: { ready: boolean } }>) => any
}

export const SusiLightWrapper = ({
  redirectUri,
  onSusiRedirect,
  onSusiToken,
  onSusiError,
  onSusiLoad
}: SusiLightWrapperProps) => {
  useEffect(() => {
    import('@sentry/wrapper')
  }, [])

  return (
    <susi-sentry
      type="large-buttons"
      id="susi-sentry"
      ref={element => {
        if (!element) return
        const { popup, stage, config, authParams } =
          configureSusiLight(redirectUri)

        element.config = config
        element.authParams = authParams
        element.stage = stage
        element.popup = popup

        element.addEventListener('redirect', onSusiRedirect as EventListener)
        element.addEventListener('on-token', onSusiToken as EventListener)
        element.addEventListener('on-error', onSusiError as EventListener)
        element.addEventListener('on-load', onSusiLoad as EventListener)
      }}></susi-sentry>
  )
}
