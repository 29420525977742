import CompatibilityWarning from '@components/compatibilityWarning/CompatibilityWarning'
import styles from '@styles/components/Compatibility.module.scss'
import { FC } from 'react'
import { Heading } from 'pages/compatibility'

const MobileWarning: FC = () => {
  return (
    <div className={styles['container']}>
      <div className={styles['content']}>
        <CompatibilityWarning
          containerStyle={{
            marginLeft: 8,
            marginRight: 8
          }}
          image="/compatibility_illustration.png"
          altText="The Project Neo studio is not available on mobile devices. Please try Project Neo on a desktop."
          heading={<Heading />}
          description={
            <p
              style={{
                fontSize: '1rem',
                margin: 0
              }}>
              The Project Neo studio is not available on touch-only devices that
              do not have a mouse or trackpad.
            </p>
          }
        />
      </div>
    </div>
  )
}

export default MobileWarning
