import { useProjectState } from '@hooks/useProject'
import { usePropertyBounds } from '@hooks/usePropertyBounds'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { EngineCommitChange, PropertyPanelPath } from '@services/engine/types'
import { PropertyPayload, SceneState } from '@store/slices/sceneSlice'
import { FC, useState } from 'react'
import { NumberField, Slider } from 'ui'
import styles from '@styles/components/BoundedPropertySlider.module.scss'

// linear bijection from a closed interval [a, b] onto another closed interval [c, d]
function closedIntervalOntoClosedInterval(
  a: number,
  b: number,
  c: number,
  d: number,
  x: number
) {
  return b - a === a ? 0 : ((d - c) / (b - a)) * x + (c * b - a * d) / (b - a)
}

type UnitFormatProps = {
  disabled?: boolean
  ariaLabel?: string
  label: string
  valueKey: keyof SceneState
  propertyPath: PropertyPanelPath
  format: 'unit'
  unitType?: 'degree'
  minPercentage?: undefined
  maxPercentage?: undefined
  step?: number
}

type PercentageFormatProps = {
  disabled?: boolean
  ariaLabel?: string
  label: string
  valueKey: keyof SceneState
  propertyPath: PropertyPanelPath
  format: 'percentage'
  unitType?: undefined
  minPercentage: number
  maxPercentage: number
  step?: number
}

type CommonProps = {
  disabled?: boolean
  ariaLabel?: string
  label: string
  valueKey: keyof SceneState
  propertyPath: PropertyPanelPath
  step?: number
  enableUpperboundOverride?: boolean
}

type Props = CommonProps & (UnitFormatProps | PercentageFormatProps)

export const BoundedPropertySlider: FC<Props> = ({
  disabled,
  ariaLabel,
  label,
  minPercentage,
  maxPercentage,
  valueKey,
  propertyPath,
  format = 'unit',
  unitType,
  step = 0.001,
  enableUpperboundOverride = false
}) => {
  const sizePreference = useProjectState('sizePreference')
  const { setPropertyState } = useSceneActions()
  const propertyValue = useSceneState(valueKey) as number
  const { softMin, softMax, hardMin, hardMax } = usePropertyBounds(
    propertyPath
  ) as { softMin: number; softMax: number; hardMin: number; hardMax: number }

  const [prevSoftMax, setPrevSoftMax] = useState<number>(softMax)
  const [adjustedUpperBound, setAdjustedUpperBound] = useState<number>(
    getAdjustedUpperBound()
  )

  const [adjustedMaxPercentage, setAdjustedMaxPercentage] = useState<number>(
    maxPercentage as number
  )

  if (areBoundsDefined() && prevSoftMax !== softMax) {
    setAdjustedUpperBound(getAdjustedUpperBound())
    setPrevSoftMax(softMax)
  }

  let currentPercentage = 0

  if (format === 'percentage' && areBoundsDefined()) {
    currentPercentage = closedIntervalOntoClosedInterval(
      softMin,
      adjustedUpperBound,
      minPercentage!,
      adjustedMaxPercentage,
      propertyValue
    )
  }

  function getAdjustedUpperBound(): number {
    return enableUpperboundOverride
      ? Math.max(propertyValue, Math.min(softMax, hardMax))
      : softMax
  }

  function areBoundsDefined() {
    return (
      typeof softMin !== 'undefined' &&
      typeof adjustedUpperBound !== 'undefined'
    )
  }

  function handleSliderInteractionForPercentage(
    e: any,
    commit?: EngineCommitChange
  ) {
    const lowerBound = Math.max(softMin, hardMin)

    let value = Math.max(
      (e as unknown as { target: { value: number } }).target.value * 100,
      minPercentage as number
    )

    if (!areBoundsDefined()) return
    currentPercentage = value
    // Converts percentage to value for engine
    value = closedIntervalOntoClosedInterval(
      minPercentage!,
      adjustedMaxPercentage,
      lowerBound,
      adjustedUpperBound,
      value
    )

    setPropertyState({
      key: valueKey,
      value,
      commit
    } as PropertyPayload)
  }

  function handleSliderInteractionForUnit(e: any, commit?: EngineCommitChange) {
    let value = (e as unknown as { target: { value: number } }).target.value
    const lowerBound = Math.max(softMin, hardMin)

    if (!areBoundsDefined()) return
    value = Math.max(value, lowerBound)
    value = Math.min(value, adjustedUpperBound)

    setPropertyState({
      key: valueKey,
      value,
      commit
    } as PropertyPayload)
  }

  function getStyle(cssClassname: string) {
    return enableUpperboundOverride ? styles[cssClassname] : undefined
  }

  function handleOnNumberFieldChangeForPercentage(e: any) {
    const percentage = Math.max(
      (e as unknown as { target: { value: number } }).target.value * 100,
      minPercentage as number
    )

    const valueForEngine =
      (adjustedUpperBound * (percentage * maxPercentage!)) /
      (100 * adjustedMaxPercentage)

    if (percentage > adjustedMaxPercentage) {
      setAdjustedMaxPercentage(percentage)
      setAdjustedUpperBound(valueForEngine)
    }

    setPropertyState({
      key: valueKey,
      value: valueForEngine
    } as PropertyPayload)
  }

  function handleOnNumberFieldChangeForUnit(e: any) {
    const lowerBound = Math.max(softMin, hardMin)

    let value = Math.max(
      (e as unknown as { target: { value: number } }).target.value,
      lowerBound
    )

    if (value > adjustedUpperBound) {
      setAdjustedUpperBound(value)
    } else {
      value = Math.min(value, adjustedUpperBound)
    }

    setPropertyState({
      key: valueKey,
      value
    } as PropertyPayload)
  }

  const intlFormatOptionsForPercentage = `{
            "style": "percent",
            "maximumFractionDigits": "3",
            "trailingZeroDisplay": "stripIfInteger"
        }`

  const intlFormatOptionsForUnit = `{
          "style": "unit",
          "unit": "${unitType ?? ''}",
          "unitDisplay": "narrow",
          "maximumFractionDigits": "3",
          "trailingZeroDisplay": "stripIfInteger"
      }`

  if (format === 'unit') {
    return (
      <div className={getStyle('container')}>
        <Slider
          className={getStyle('slider')}
          //@ts-expect-error
          disabled={disabled}
          size={sizePreference}
          label={label}
          aria-label={ariaLabel}
          variant="filled"
          value={propertyValue}
          min={softMin}
          max={adjustedUpperBound}
          step={step}
          editable={!enableUpperboundOverride}
          onInput={e => handleSliderInteractionForUnit(e)}
          onMouseDown={e =>
            handleSliderInteractionForUnit(e, EngineCommitChange.BEGIN_COMMIT)
          }
          onMouseUp={e =>
            handleSliderInteractionForUnit(e, EngineCommitChange.END_COMMIT)
          }
          hideStepper
          labelVisibility="text"
          format-options={intlFormatOptionsForUnit}
        />
        {enableUpperboundOverride && (
          <NumberField
            className={getStyle('number-field')}
            hideStepper
            size={sizePreference}
            min={0}
            step={step}
            value={propertyValue}
            aria-label={ariaLabel}
            onChange={handleOnNumberFieldChangeForUnit}
            format-options={intlFormatOptionsForUnit}
          />
        )}
      </div>
    )
  } else if (format === 'percentage') {
    return (
      <div className={getStyle('container')}>
        <Slider
          className={getStyle('slider')}
          //@ts-expect-error
          disabled={disabled}
          size={sizePreference}
          label={label}
          aria-label={ariaLabel}
          variant="filled"
          value={currentPercentage! / 100}
          min={minPercentage! / 100}
          max={adjustedMaxPercentage / 100}
          step={step}
          editable={!enableUpperboundOverride}
          onInput={e => handleSliderInteractionForPercentage(e)}
          onMouseDown={e =>
            handleSliderInteractionForPercentage(
              e,
              EngineCommitChange.BEGIN_COMMIT
            )
          }
          onMouseUp={e =>
            handleSliderInteractionForPercentage(
              e,
              EngineCommitChange.END_COMMIT
            )
          }
          hideStepper
          labelVisibility="text"
          format-options={intlFormatOptionsForPercentage}
        />
        {enableUpperboundOverride && (
          <NumberField
            className={getStyle('number-field')}
            hideStepper
            size={sizePreference}
            min={0}
            step={step}
            value={currentPercentage! / 100}
            aria-label={ariaLabel}
            onChange={handleOnNumberFieldChangeForPercentage}
            format-options={intlFormatOptionsForPercentage}
          />
        )}
      </div>
    )
  }

  return <></>
}
