import React, { FC, ReactNode } from 'react'
import styles from '@styles/components/PanelSectionHeader.module.scss'
import cn from 'classnames'

interface Props {
  children?: ReactNode
  className?: string
  sectionHeader: ReactNode
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const PanelSectionHeader: FC<Props> = ({
  children,
  className,
  sectionHeader,
  onClick
}) => {
  return (
    <div className={cn(styles['container'], className)} onClick={onClick}>
      <h6>{sectionHeader}</h6>
      {children}
    </div>
  )
}

export default PanelSectionHeader
