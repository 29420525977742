import { RootStore } from '@store/store'
import { setAdobeAnalyticsInitialized } from '@concerns/monitoring/store/monitoringSlice'

class AdobeAnalyticsWrapper {
  private analytics: any = null

  constructor() {
    this.initialize = this.initialize.bind(this)
    this.sendEvent = this.sendEvent.bind(this)
  }

  public async initialize() {
    // Don't import if window is not ready
    if (process.env.NEXT_PUBLIC_CLIENT_APP_ENVIRONMENT === 'prd' && window) {
      // Requires async import because package uses cookies and window object.
      const { createInstance } = await import('@adobe/alloy')

      this.analytics = await createInstance({
        name: process.env.NEXT_PUBLIC_CLIENT_ADOBE_ANALYTICS_NAME
      })

      await this.analytics('configure', {
        edgeConfigId:
          process.env.NEXT_PUBLIC_CLIENT_ADOBE_ANALYTICS_EDGE_CONFIG_ID,
        orgId: process.env.NEXT_PUBLIC_CLIENT_ADOBE_ANALYTICS_ORG_ID
      })

      RootStore.dispatch(setAdobeAnalyticsInitialized())
    }
  }

  public sendEvent(payload: any) {
    this.analytics?.('sendEvent', payload)
  }
}

const AdobeAnalytics = new AdobeAnalyticsWrapper()
export default AdobeAnalytics
