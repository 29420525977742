import React, { FC } from 'react'
import CommunityCard from '@components/communityPanel/CommunityCard'
import styles from '@styles/components/CommunityPanel.module.scss'

const communityCards = [
  {
    image: '/placeholder_comingsoon.jpg',
    title: 'Coming Soon'
  }
]

const CommunityPanel: FC = () => {
  return (
    <div className={styles['container']}>
      <h3 className={styles['header']}>Community</h3>
      <div className={styles['flex-container']}>
        {communityCards.map((card, index) => (
          <CommunityCard key={index} image={card.image} title={card.title} />
        ))}
      </div>
    </div>
  )
}

export default CommunityPanel
