import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  from,
  HttpLink,
  fromPromise,
  toPromise
} from '@apollo/client'
import AdobeIMS from '@services/auth/IMS'

let client: ApolloClient<any>

export function createAuthLink() {
  const authLink = new ApolloLink((operation, forward) => {
    return fromPromise(
      AdobeIMS.getAccessTokenAsync().then(tokenInfo => {
        // Set X-Api-Key because it's always required
        operation.setContext(({ headers = {} }) => ({
          headers: { ...headers, 'X-Api-Key': process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID }
        }))

        // Only add the authorization header if the access token is valid
        if (AdobeIMS.isAccessTokenValid()) {
          operation.setContext(({ headers = {} }) => ({
            headers: { ...headers, authorization: `Bearer ${tokenInfo?.token}` }
          }))
        }

        return toPromise(forward(operation))
      })
    )
  })

  return authLink
}

export function createHttpLink() {
  const httpLink = from([
    createAuthLink(),
    new HttpLink({
      uri: process.env.NEXT_PUBLIC_SERVICE_CORE_GRAPHQL_ENDPOINT
    })
  ])

  return httpLink
}

if (global.window) {
  client = new ApolloClient({
    link: createHttpLink(),
    cache: new InMemoryCache()
  })
} else {
  client = new ApolloClient({
    cache: new InMemoryCache()
  })
}

export default client
