import React, { FC, useState } from 'react'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { ActionButton, Icon } from 'ui'
import CreateProjectButton from '@components/project/CreateProjectButton'
import JoinDiscordButton, {
  DiscordLink
} from '@components/navigation/JoinDiscordButton'
import AppLogo from '@components/appLogo/AppLogo'
import CloseIcon from '/public/s2_icon_close.svg'
import DiscordLogoIcon from '/public/discord-mark-blue.svg'
import HamburgerMenuIcon from '/public/s2_icon_menu_hamburger.svg'
import styles from '@styles/components/CommunityAppBar.module.scss'
import {
  CommunityAppBarRouteSwitcher,
  CommunityAppBarUserControls
} from './CommunityAppBarComponents'
import { useIsMobileLayout } from '@hooks/useMobileLayout'
import { UploadProjectButton } from '@components/project/UploadProjectButton'
import { useFlags } from 'launchdarkly-react-client-sdk'

export interface CommunityAppBarProps {
  isAuthenticated: boolean
}

const CommunityAppBar: FC<CommunityAppBarProps> = ({ isAuthenticated }) => {
  const router = useRouter()
  const flags = useFlags()
  const isMobileLayout = useIsMobileLayout()

  const [navWidth, setNavWidth] = useState<number>(0)
  const openNav = () => setNavWidth(260)
  const closeNav = () => setNavWidth(0)

  return (
    <div className={styles['container']}>
      <nav className={styles['nav']}>
        <div className={styles['nav-content']}>
          <div className={styles['hamburger-menu']}>
            <ActionButton
              aria-label="Menu"
              quiet
              onClick={navWidth === 0 ? openNav : closeNav}>
              <Icon slot="icon" className="icon-m">
                {navWidth === 0 ? <HamburgerMenuIcon /> : <CloseIcon />}
              </Icon>
            </ActionButton>
          </div>
          <AppLogo onClick={() => router.push('/')} logo="projectneo" />
          <div
            className={cn(styles['nav-links'], {
              [styles['hide']]: navWidth > 0
            })}>
            <CommunityAppBarRouteSwitcher
              isAuthenticated={isAuthenticated}
              router={router}
              vertical={false}
            />
          </div>
        </div>

        <div
          className={cn(styles['nav-action-buttons'], {
            [styles['hide']]: navWidth > 0
          })}>
          {!isMobileLayout && (
            <ActionButton
              className={styles['discord-button']}
              aria-label="Join Discord"
              href={DiscordLink}
              target="_blank"
              quiet>
              <Icon slot="icon" className="icon-m">
                <DiscordLogoIcon />
              </Icon>
            </ActionButton>
          )}
          {flags['debug-tf-fx-project-json'] && <UploadProjectButton />}
          {!isMobileLayout && isAuthenticated && <CreateProjectButton />}
          <CommunityAppBarUserControls />
        </div>

        <div
          className={cn(styles['side-nav-overlay'], {
            [styles['side-nav-overlay__show']]: navWidth > 0
          })}
          onClick={closeNav}
        />

        <div className={styles['side-nav']} style={{ width: navWidth }}>
          <div className={styles['side-nav-content']}>
            <div>
              <div className={styles['side-nav-links']}>
                <CommunityAppBarRouteSwitcher
                  isAuthenticated={isAuthenticated}
                  router={router}
                  vertical={true}
                />
              </div>
            </div>
            <div className={styles['side-nav-action-buttons']}>
              {isAuthenticated && (
                <CreateProjectButton label="Create a new project" />
              )}
              <JoinDiscordButton />
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default CommunityAppBar
