import React, { FC, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  DialogBase,
  ActionButton,
  Icon,
  FieldLabel,
  Picker,
  MenuItem,
  RadioGroup,
  Radio,
  Switch
} from 'ui'
import styles from '@styles/components/SettingsDialog.module.scss'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import {
  Color,
  ColorKey,
  ProjectState,
  Size,
  ElementSize
} from '@store/slices/projectSlice'
import CrossIcon from '/public/s2_icon_cross_2.svg'
import SideNavigationItem from '@components/sideNavigationItem/SideNavigationItem'
import PanelAccordion from '@components/panel/PanelAccordion'
import GroupAutoFocus from '@components/propertiesPanel/GroupAutoFocus'
import ObjectSnappingSwitch from '@components/propertiesPanel/ObjectSnappingSwitch'
import GroupGizmos3d from '@components/propertiesPanel/GroupGizmos3d'
import GroupInputDevice from '@components/propertiesPanel/GroupInputDevice'
import ShareLink from '@components/project/ShareLink'
import { useAuth } from '@hooks/useAuth'
import { useUpdateUserMutation } from '@store/graphql/__generated__/schema'

type NavigationItem = {
  label: string
  value: ProjectState['selectedSettingsItem']
  featureFlagKey?: string
}

const NavigationItems: NavigationItem[] = [
  { label: 'General', value: 'general' },
  { label: 'Input', value: 'input' },
  {
    label: 'Files',
    value: 'files',
    featureFlagKey: 'base-pf-ui-project-private-default'
  },
  { label: 'About', value: 'about' }
]

const SettingsDialog: FC = () => {
  const flags = useFlags()
  const { localUser, setProjectPrivateDefault } = useAuth()
  const showSettingsDialog = useProjectState('showSettingsDialog')
  const selectedSettingsItem = useProjectState('selectedSettingsItem')
  const colorPreference = useProjectState('colorPreference')
  const sizePreference = useProjectState('sizePreference')
  const {
    setColorPreference,
    setSizePreference,
    setSelectedSettingsItem,
    setShowSettingsDialog
  } = useProjectActions()

  const [generalBehaviorsCollapsed, setGeneralBehaviorsCollapsed] =
    useState(false)
  const [generalInterfaceCollapsed, setGeneralInterfaceCollapsed] =
    useState(false)
  const [inputOptionsCollapsed, setInputOptionsCollapsed] = useState(false)
  const [projectPublicStatusCollapsed, setProjectPublicStatusCollapsed] =
    useState(false)
  const [aboutCollapsed, setAboutCollapsed] = useState(false)

  const [updateUser] = useUpdateUserMutation({
    onCompleted(data) {
      setProjectPrivateDefault(
        Boolean(data.updateUser?.preferences?.projectsPrivateDefault)
      )
    }
  })

  const filteredNavigationItems = NavigationItems.filter(trigger => {
    if (trigger.featureFlagKey) {
      return flags[trigger.featureFlagKey]
    }

    return true
  })

  const handleSwitchClick = () => {
    const newValue = !localUser?.preferences?.projectsPrivateDefault
    setProjectPrivateDefault(newValue)
    updateUser({
      variables: {
        preferences: {
          projectsPrivateDefault: newValue
        }
      }
    })
  }

  return (
    <DialogBase underlay open={showSettingsDialog}>
      <div className={styles['dialog']}>
        <ActionButton
          className={styles['close-btn']}
          onClick={() => setShowSettingsDialog(false)}>
          <Icon slot="icon">
            <CrossIcon />
          </Icon>
        </ActionButton>

        <div className={styles['header']}>
          <h2>App settings</h2>
        </div>

        <div className={styles['side-navigation']}>
          {filteredNavigationItems.map(({ label, value }) => (
            <SideNavigationItem
              key={value}
              selected={value === selectedSettingsItem}
              onClick={() => setSelectedSettingsItem(value)}>
              {label}
            </SideNavigationItem>
          ))}
        </div>

        <div className={styles['main-content']}>
          {selectedSettingsItem === 'general' && (
            <>
              {(flags['base-pf-ui-color-theme'] ||
                flags['base-pf-ui-size-preference']) && (
                <PanelAccordion
                  label="Interface"
                  collapsed={generalInterfaceCollapsed}
                  onChange={setGeneralInterfaceCollapsed}>
                  <div className="flex gap-m">
                    <div className="flex flex-1 flex-col">
                      {flags['base-pf-ui-color-theme'] && (
                        <>
                          <FieldLabel size="m" for="color-theme">
                            Color theme
                          </FieldLabel>
                          <Picker
                            id="color-theme"
                            value={colorPreference}
                            onchange={e => {
                              setColorPreference(
                                (
                                  e as unknown as {
                                    target: { value: ColorKey }
                                  }
                                ).target.value
                              )
                            }}>
                            <MenuItem value={Color.light}>Light</MenuItem>
                            <MenuItem value={Color.dark}>Dark</MenuItem>
                          </Picker>
                        </>
                      )}
                    </div>
                    <div className="flex flex-1 flex-col">
                      {flags['base-pf-ui-size-preference'] && (
                        <>
                          <FieldLabel size="m" for="scale">
                            Scale
                          </FieldLabel>

                          <RadioGroup
                            id="scale"
                            selected={sizePreference}
                            change={e =>
                              setSizePreference(
                                (
                                  e as unknown as {
                                    target: { selected: ElementSize }
                                  }
                                ).target.selected
                              )
                            }>
                            <Radio value={Size.small}>Small</Radio>
                            <Radio value={Size.medium}>Medium</Radio>
                            <Radio value={Size.large}>Large</Radio>
                          </RadioGroup>
                        </>
                      )}
                    </div>
                  </div>
                </PanelAccordion>
              )}
              <PanelAccordion
                label="Behaviors"
                collapsed={generalBehaviorsCollapsed}
                onChange={setGeneralBehaviorsCollapsed}>
                {flags['base-tf-fx-snapping'] && <ObjectSnappingSwitch />}
                {flags['base-tf-fx-camera-autofocus'] && <GroupAutoFocus />}
              </PanelAccordion>
            </>
          )}

          {selectedSettingsItem === 'input' && (
            <PanelAccordion
              label="Input options"
              collapsed={inputOptionsCollapsed}
              onChange={setInputOptionsCollapsed}>
              <GroupInputDevice />
              <GroupGizmos3d />
            </PanelAccordion>
          )}

          {selectedSettingsItem === 'files' && (
            <>
              <PanelAccordion
                label="Sharing status for new files"
                collapsed={projectPublicStatusCollapsed}
                onChange={setProjectPublicStatusCollapsed}>
                <FieldLabel size="m">History view</FieldLabel>
                {/* TODO hook up state and action */}
                <Switch
                  size="m"
                  checked={
                    localUser?.preferences?.projectsPrivateDefault ?? false
                  }
                  onClick={handleSwitchClick}>
                  Enable live history viewing for all of your designs
                </Switch>

                <div>
                  <p className={styles['description']}>
                    Create with the community, allowing others to view, Like and
                    appreciate a timeline of your process step by step. History
                    View allows everyone to learn more from each other in
                    real-time. Your files remain yours, but everyone can view
                    your shape creation process on the Community Page.
                  </p>

                  <ShareLink className={styles['description']} />
                </div>
              </PanelAccordion>
            </>
          )}

          {selectedSettingsItem === 'about' && (
            <>
              <PanelAccordion
                label="About Project Neo"
                collapsed={aboutCollapsed}
                onChange={setAboutCollapsed}>
                <p className={styles['description']}>
                  Supercharging your design work for logos, infographics, brand
                  icons and illustrative artwork with the power of building
                  shapes as volume vectors, instead of just flat vector files.
                </p>
              </PanelAccordion>
            </>
          )}
        </div>
      </div>
    </DialogBase>
  )
}

export default SettingsDialog
