import { FC } from 'react'
import { DialogBase, Button, ActionButton, Icon } from 'ui'
import styles from '@styles/components/FireflyOnboardingDialog.module.scss'
import {
  LocalStorageBooleanValue,
  LocalStorageKey
} from 'constants/localStorage'

import { useFirefly } from '@hooks/useFirefly'
import CrossIcon from '/public/s2_icon_cross.svg'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

const FireflyOnboardingDialog: FC = () => {
  const size = useMediumMinimumSizePreference()

  const {
    showOnboardingDialog,
    setShowOnboardingDialog,
    setShowLimitedFreeTimeToast
  } = useFirefly()

  function handleClose() {
    setShowOnboardingDialog(false)
    localStorage.setItem(
      LocalStorageKey.fireflyViewedOnboardingDialog,
      LocalStorageBooleanValue.TRUE
    )
    setShowLimitedFreeTimeToast(true)
  }

  if (!showOnboardingDialog) return null
  return (
    <DialogBase underlay open={showOnboardingDialog}>
      <div className={styles['dialog']}>
        <ActionButton
          quiet
          className={styles['close-button']}
          onClick={handleClose}>
          <Icon slot="icon">
            <CrossIcon />
          </Icon>
        </ActionButton>
        <img src="/firefly_onboarding.png" alt="onboarding firefly" />
        <div className={styles['content']}>
          <h2>Project Neo’s Scene to image puts you in control</h2>
          <p>
            Transform your 3D scene into where ever your imagination takes you.
            Scene to image takes your 3D scene and gives you control over the
            generative output by referencing your own creation for structure and
            depth to produce an image.
          </p>

          <Button onClick={handleClose} variant="cta" size={size}>
            Try Scene to image
          </Button>
        </div>
      </div>
    </DialogBase>
  )
}

export default FireflyOnboardingDialog
