import { SceneNavigatorEventPayload } from '@services/engine/types'
import { setPropertyState } from '@store/slices/sceneSlice'
import { put } from 'redux-saga/effects'

export default function* setSceneElements(
  dataEvent: SceneNavigatorEventPayload
) {
  if (dataEvent.status === 'NO_ELEMENT_SELECTED') {
    yield put(
      setPropertyState({
        key: 'elements',
        value: []
      })
    )
    yield put(
      setPropertyState({
        key: 'nodeDepth',
        value: 0
      })
    )
    yield put(
      setPropertyState({
        key: 'elementParentName',
        value: ''
      })
    )
  } else {
    yield put(
      setPropertyState({
        key: 'elements',
        value: dataEvent.data.info.elements
      })
    )
    yield put(
      setPropertyState({
        key: 'nodeDepth',
        value: dataEvent.data.info.depth
      })
    )
    yield put(
      setPropertyState({
        key: 'elementParentName',
        value: dataEvent.data.info.name
      })
    )
  }
}
