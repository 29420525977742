import React, {
  FC,
  InputHTMLAttributes,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import cn from 'classnames'
import styles from '@styles/components/OrbitSlider.module.scss'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

const thumbWidth = 20
const margin = 22.5

interface Initial {
  client: number
  value: number
}

interface Props
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'min' | 'max' | 'value' | 'step'
  > {
  min?: number
  max?: number
  step?: number
  value?: number
  onStart?: (newValue: number) => void
  onUpdate?: (newValue: number) => void
  onEnd?: () => void

  // more low level callbacks
  onStartDrag?: (initial: number) => void
  onDrag?: (initial: number, current: number) => void
  onEndDrag?: (initial: number, current: number) => void

  vertical?: boolean
  periodic?: boolean
}

const OrbitSlider: FC<Props> = ({
  min = 0,
  max = 100,
  step = 1,
  value = min,
  readOnly,
  onChange,

  onStart,
  onUpdate,
  onEnd,

  onStartDrag,
  onDrag,
  onEndDrag,

  vertical = false,
  periodic = false,
  ...rest
}) => {
  const size = useMediumMinimumSizePreference()
  const [initial, setInitial] = useState<null | Initial>(null)
  const ref = useRef<HTMLInputElement>(null)

  const onValueChanged = useMemo(() => {
    const slider = ref.current
    if (!slider) return (value: number) => {}
    const ticksContainer = slider.parentElement?.querySelector(
      `.${styles['ticks']}`
    ) as HTMLElement
    const ticksContainerWidth = ticksContainer.offsetWidth
    const adjustment = thumbWidth / 2 - ticksContainerWidth / 2
    const stepsPerPixel =
      (max - min) / (slider.clientWidth - thumbWidth + margin)
    return (value: number) => {
      const translation = (value - min) / stepsPerPixel + adjustment
      ticksContainer.style.transform = `translateX(${translation}px)`
    }
  }, [ref.current, min, max])

  const onDragMemo = useMemo(() => {
    const slider = ref.current
    if (!slider) return (initial: Initial, event: MouseEvent) => {}

    let adjust: (value: number) => number
    if (periodic) {
      const range = max - min
      adjust = (value: number) =>
        ((((value - min) % range) + range) % range) + min
    } else adjust = (value: number) => Math.max(min, Math.min(max, value))
    const stepsPerPixel =
      (max - min) / (slider.clientWidth - thumbWidth + margin)
    if (vertical)
      return (initial: Initial, event: MouseEvent) => {
        if (onUpdate) {
          onUpdate(
            adjust(
              initial.value - (event.clientY - initial.client) * stepsPerPixel
            )
          )
        }
      }
    else
      return (initial: Initial, event: MouseEvent) => {
        if (onUpdate) {
          onUpdate(
            adjust(
              initial.value + (event.clientX - initial.client) * stepsPerPixel
            )
          )
        }
      }
  }, [ref.current, periodic, vertical, min, max, onUpdate])

  useEffect(() => {
    if (!initial) return
    const onMouseMove = (event: MouseEvent) => {
      if (onDrag) {
        const newPos = vertical ? event.clientY : event.clientX
        onDrag(initial?.client, newPos)
      }
      onDragMemo(initial, event)
    }
    const onDragEnd = () => {
      if (onEndDrag) {
        const newPos = vertical ? event.clientY : event.clientX
        onEndDrag(initial?.client, newPos)
      }

      setInitial(null)

      if (onEnd) {
        onEnd()
      }
    }
    document.addEventListener('mousemove', onMouseMove)
    const resetEvents = ['mouseup', 'blur', 'mouseleave']
    resetEvents.forEach(event => document.addEventListener(event, onDragEnd))

    if (onStartDrag) onStartDrag(initial.client)
    if (onStart) onStart(initial.value)
    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      resetEvents.forEach(event =>
        document.removeEventListener(event, onDragEnd)
      )
    }
  }, [initial, onDragMemo, onStart, onEnd])

  useEffect(() => {
    onValueChanged(value)
  }, [value, onValueChanged])

  const renderTicks = () => {
    const ticks: ReactElement[] = []
    const tickCounts = [
      3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1
    ]

    let i = 0
    tickCounts.forEach((count, index) => {
      const className = index % 2 === 0 ? styles.smallTick : styles.largeTick
      for (let j = 0; j < count; j++) {
        ticks.push(<div key={`${className}-${i}`} className={className} />)
        i++
      }
    })

    return ticks
  }

  return (
    <div className={cn(styles['container'], styles[`size-${size}`])}>
      <input
        ref={ref}
        className={styles['slider']}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onMouseDown={(event: React.MouseEvent<HTMLInputElement>) =>
          event.buttons === 1 &&
          !initial &&
          setInitial({
            client: vertical ? event.clientY : event.clientX,
            value
          })
        }
        readOnly={readOnly === undefined ? !onChange : readOnly}
        onChange={onChange}
        {...rest}
      />
      <div className={styles['ticks']}>{renderTicks()}</div>
    </div>
  )
}

export default OrbitSlider
