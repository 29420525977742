import { useEffect, useState } from 'react'

type Image = {
  id: string
  url: string
}

type Style = {
  id: string
  title: {
    defaultMessage: string
  }
  images: Image[]
}

type StylesReference = {
  featured: {
    images: Image[]
  }
  styles: Style[]
}

export const useStyleReferences = () => {
  const [styles, setStyles] = useState<StylesReference | null>(null)
  useEffect(() => {
    getStyles()
  }, [])

  async function getStyles() {
    if (styles) return

    const res: any = await fetch(
      `${process.env.NEXT_PUBLIC_CLIENT_FIREFLY_ASSET_ENDPOINT}/image-style-zeros/v3/content.json`,
      {
        method: 'GET'
      }
    ).then(r => r.json())

    setStyles(res)
  }

  return styles
}
