import React, { FC } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ActionButton, Divider, Icon, Overlay, Popover } from 'ui'
import styles from '@styles/components/EnvironmentActionMenu.module.scss'
import { ElementSize } from '@store/slices/projectSlice'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import LightIcon from '/public/s2_icon_light_sun.svg'
import PanelSectionHeader from '@components/panel/PanelSectionHeader'
import Lights from './Lights'
import Floor from './Floor'

const EnvironmentActionMenu: FC = () => {
  const flags = useFlags()

  const sizePreference = useProjectState('sizePreference')
  const showEnvironmentMenuPopover = useProjectState(
    'showEnvironmentMenuPopover'
  )

  const { setShowEnvironmentMenuPopover } = useProjectActions()

  const size: ElementSize = sizePreference === 'l' ? 'm' : 's'

  return (
    <>
      <ActionButton
        size={size}
        id="environment-action-menu-popover-trigger"
        quiet>
        <Icon slot="icon">
          <LightIcon />
        </Icon>
      </ActionButton>

      <Overlay
        placement="bottom"
        offset={6}
        trigger="environment-action-menu-popover-trigger@click"
        spOpened={() => setShowEnvironmentMenuPopover(true)}
        spClosed={() => setShowEnvironmentMenuPopover(false)}
        open={showEnvironmentMenuPopover}>
        <Popover className={styles['popover']} data-input-size={size}>
          <h2>Environment</h2>

          <PanelSectionHeader sectionHeader="Light" />
          <Lights size={size} />

          {flags['base-tf-fx-ground'] && (
            <>
              <Divider size="s" />
              <PanelSectionHeader sectionHeader="Floor" />
              <Floor size={size} />
            </>
          )}
        </Popover>
      </Overlay>
    </>
  )
}

export default EnvironmentActionMenu
