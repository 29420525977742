import React, { FC } from 'react'
import { useRouter } from 'next/router'
import { ActionButton, Icon, Tooltip } from 'ui'
import ChevronIcon from '/public/s2_icon_chevron_left.svg'
import { useProjectState } from '@hooks/useProject'
import { ElementSize } from '@store/slices/projectSlice'

const BackButton: FC = () => {
  const router = useRouter()

  const sizePreference = useProjectState('sizePreference')
  const buttonSize: ElementSize = sizePreference === 'l' ? 'm' : 's'

  return (
    <ActionButton
      size={buttonSize}
      quiet
      onClick={() => router.push('/my-projects')}>
      <Icon slot="icon">
        <ChevronIcon />
      </Icon>

      <Tooltip selfManaged placement="bottom">
        Go back to your files
      </Tooltip>
    </ActionButton>
  )
}

export default BackButton
