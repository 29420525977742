import {
  DocumentEventObserver,
  MouseCaptureInfo
} from '@services/engine/documentEventObserver'

import { useEffect, useState } from 'react'
import _uniqueId from 'lodash/uniqueId'

const CursorOverlay = () => {
  const [shouldBlockUI, setShouldBlockUI] = useState<boolean>(false)
  const [activeCursor, setActiveCursor] = useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    const observer = DocumentEventObserver.instance()
    if (!observer) return
    const mouseCaptureInfoListenerID = _uniqueId(
      'CursorOverlayMouseCaptureInfoListener-'
    )
    const mouseStateCaptureListener = (info: MouseCaptureInfo | null) => {
      setShouldBlockUI(!!info)
      setActiveCursor(info?.cursor)
    }

    // mount
    observer.addMouseCaptureInfoListener(
      mouseCaptureInfoListenerID,
      mouseStateCaptureListener
    )

    return () => {
      // unmount
      observer.removeMouseCaptureInfoListener(mouseCaptureInfoListenerID)
    }
  }, [])

  return shouldBlockUI ? (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1000,
        cursor: activeCursor
      }}
    />
  ) : null
}

export default CursorOverlay
