import React from 'react'
import { ActionMenu, Icon, MenuItem, OverlayTrigger, Tooltip } from 'ui'
import BetaAppIcon from '/public/s2_icon_beta_app.svg'
import CommentTextIcon from '/public/s2_icon_comment_text.svg'
import DiscordIcon from '/public/icon_discord.svg'
import { SpIconAnnotate } from 'workflow-icons'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

const Feedback = () => {
  const size = useMediumMinimumSizePreference()

  function onMenuClick({ target: { value } }: any) {
    switch (value) {
      case 'feedback-suggestions-and-feature-requests':
        window.open('https://forms.office.com/r/xaniKRQiud', '_blank')
        break
      case 'join-discord':
        window.open('https://discord.gg/QqkgvBG7hR', '_blank')
        break
      case 'release-notes':
        window.open(
          'https://new.express.adobe.com/webpage/tbB7j4GW82ilL',
          '_blank'
        )
        break
      default:
        return
    }
  }

  return (
    <OverlayTrigger placement="bottom" offset={10}>
      <ActionMenu
        size={size}
        slot="trigger"
        placement="bottom-end"
        quiet
        onchange={onMenuClick}>
        <Icon slot="icon">
          <BetaAppIcon />
        </Icon>

        <MenuItem value="feedback-suggestions-and-feature-requests">
          <Icon slot="icon">
            <CommentTextIcon />
          </Icon>
          Give feedback
        </MenuItem>
        <MenuItem value="join-discord">
          <Icon slot="icon">
            <DiscordIcon />
          </Icon>
          Join the Discord
        </MenuItem>
        <MenuItem value="release-notes">
          <SpIconAnnotate slot="icon" />
          Release Notes
        </MenuItem>
      </ActionMenu>
      <Tooltip slot="hover-content">Feedback</Tooltip>
    </OverlayTrigger>
  )
}

export default Feedback
