import React, { FC, memo } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { EngineCommitChange, ScenePrimType } from '@services/engine/types'
import { FieldLabel, NumberField, Slider } from 'ui'
import { PropertyInputSlider } from '@components/slider/FirstAndSubsequentInputSlider'
import { useProjectState } from '@hooks/useProject'
import { PercentPropertySlider } from '@components/slider/PercentPropertySlider'
import { BoundedPropertySlider } from '@components/slider/BoundedPropertySlider'

const SoftBoxInputs: FC = () => {
  const { setPropertyState } = useSceneActions()
  const address = useSceneState('address')
  const primitiveRadial0 = useSceneState('primitiveRadial0')
  const primitiveRadial1 = useSceneState('primitiveRadial1')
  const primitiveRadial2 = useSceneState('primitiveRadial2')
  const primitiveRadial3 = useSceneState('primitiveRadial3')

  const sizePreference = useProjectState('sizePreference')

  return (
    <div>
      <FieldLabel>Corners</FieldLabel>
      <div className="flex gap-s">
        <NumberField
          hideStepper
          size={sizePreference}
          min={0}
          step={0.001}
          aria-label="radial 0"
          value={primitiveRadial0}
          onChange={e => {
            setPropertyState({
              address,
              key: 'primitiveRadial0',
              value: e.target.value
            })
          }}
        />
        <NumberField
          hideStepper
          size={sizePreference}
          min={0}
          step={0.001}
          aria-label="radial 1"
          value={primitiveRadial1}
          onChange={e => {
            setPropertyState({
              address,
              key: 'primitiveRadial1',
              value: e.target.value
            })
          }}
        />
        <NumberField
          hideStepper
          size={sizePreference}
          min={0}
          step={0.001}
          aria-label="radial 2"
          value={primitiveRadial2}
          onChange={e => {
            setPropertyState({
              address,
              key: 'primitiveRadial2',
              value: e.target.value
            })
          }}
        />
        <NumberField
          hideStepper
          size={sizePreference}
          min={0}
          step={0.001}
          aria-label="radial 3"
          value={primitiveRadial3}
          onChange={e => {
            setPropertyState({
              address,
              key: 'primitiveRadial3',
              value: e.target.value
            })
          }}
        />
      </div>
    </div>
  )
}

const HorseshoeInputs: FC = () => {
  const { setPropertyState } = useSceneActions()
  const address = useSceneState('address')
  const primitiveHorseshoeRadius = useSceneState('primitiveHorseshoeRadius')
  const primitiveHorseshoeWidth = useSceneState('primitiveHorseshoeWidth')
  const primitiveHorseshoeLength = useSceneState('primitiveHorseshoeLength')
  const primitiveHorseshoeThickness = useSceneState(
    'primitiveHorseshoeThickness'
  )
  const primitiveHorseshoeCorner = useSceneState('primitiveHorseshoeCorner')

  const sizePreference = useProjectState('sizePreference')

  return (
    <>
      <BoundedPropertySlider
        ariaLabel="Angle"
        label="Angle"
        valueKey="primitiveHorseshoeAngle"
        propertyPath="treeNode.angle"
        format="unit"
        unitType="degree"
      />
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={0}
        max={1}
        step={0.001}
        aria-label="Radius"
        label="Radius"
        value={primitiveHorseshoeRadius}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeRadius',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeRadius',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeRadius',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={0}
        max={1}
        step={0.001}
        aria-label="Width"
        label="Width"
        value={primitiveHorseshoeWidth}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeWidth',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeWidth',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeWidth',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={0}
        max={1}
        step={0.001}
        aria-label="Length"
        label="Length"
        value={primitiveHorseshoeLength}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeLength',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeLength',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeLength',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={0}
        max={1}
        step={0.001}
        aria-label="Thickness"
        label="Thickness"
        value={primitiveHorseshoeThickness}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeThickness',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeThickness',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeThickness',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={0}
        max={1}
        step={0.001}
        aria-label="Corners"
        label="Corners"
        value={primitiveHorseshoeCorner}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeCorner',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeCorner',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveHorseshoeCorner',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
    </>
  )
}

const StarInputs: FC = () => {
  const { setPropertyState } = useSceneActions()

  const primitiveStarPoints = useSceneState('primitiveStarPoints')
  const address = useSceneState('address')

  const sizePreference = useProjectState('sizePreference')

  return (
    <>
      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={3}
        max={12}
        step={1}
        aria-label="Points"
        label="Points"
        value={primitiveStarPoints}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveStarPoints',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveStarPoints',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            address,
            key: 'primitiveStarPoints',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
      <PercentPropertySlider
        ariaLabel="Angle"
        label="Angle"
        valueKey="primitiveStarAngle"
        propertyPath="treeNode.angle"
      />
      <PercentPropertySlider
        aria-label="Corners"
        label="Corners"
        valueKey="primitiveStarCorners"
        propertyPath="treeNode.roundingRadius"
      />
    </>
  )
}

const FlatShape: FC = props => {
  const primitiveType = useSceneState('primitiveType')
  const showRadialInputs = primitiveType === ScenePrimType.SOFT_BOX
  const showStarInputs = primitiveType === ScenePrimType.STAR
  const showHorseshoeInputs = primitiveType === ScenePrimType.HORSESHOE
  const showTriangleInputs = primitiveType === ScenePrimType.TRIANGLE

  return (
    <>
      <PercentPropertySlider
        ariaLabel="Hole"
        label="Hole"
        valueKey="primitiveHole"
        propertyPath="treeNode.standard.thickness"
      />
      {showRadialInputs && <SoftBoxInputs {...props} />}
      {showStarInputs && <StarInputs {...props} />}
      {showHorseshoeInputs && <HorseshoeInputs {...props} />}
      {showTriangleInputs && (
        <BoundedPropertySlider
          ariaLabel="Vertex"
          label="Vertex"
          valueKey="triangleVertex"
          propertyPath="treeNode.dartOffset"
          format="percentage"
          minPercentage={-100}
          maxPercentage={100}
        />
      )}
    </>
  )
}

export default memo(FlatShape)
