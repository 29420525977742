import React, { FC, ReactNode } from 'react'
import cn from 'classnames'
import styles from '@styles/components/InlineAlert.module.scss'
import { ClassNameProps } from 'types/reactProps'

interface Props extends ClassNameProps {
  children?: ReactNode
  variant?: 'neutral'
  title?: string
}

const InlineAlert: FC<Props> = ({
  children,
  className,
  title,
  variant = 'neutral'
}) => {
  return (
    <div className={cn(styles['container'], styles[variant], className)}>
      {title && <h6>{title}</h6>}
      <p>{children}</p>
    </div>
  )
}

export default InlineAlert
