import React, { FC } from 'react'
import Rail from '@components/rail/Rail'
import styles from '@styles/components/LeftRail.module.scss'
import cn from 'classnames'
import { useSceneState } from '@hooks/useScene'
import Toolbar from '@components/propertiesPanel/Toolbar'
import CursorOverlay from '@components/cursorOverlay/CursorOverlay'
import { ClassNameProps } from 'types/reactProps'

const LeftRail: FC<ClassNameProps> = ({ className }) => {
  const engineState = useSceneState('engineState')

  return (
    <Rail className={cn(styles['container'], className)}>
      {engineState !== 'INITIALIZE' && <Toolbar />}
      <CursorOverlay />
    </Rail>
  )
}

export default LeftRail