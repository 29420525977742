export enum LocalStorageBooleanValue {
  TRUE = 'true',
  FALSE = 'false'
}

export const LocalStorageKey = {
  appPreferenceColor: 'app_preference_color',
  appPreferenceSize: 'app_preference_size',
  engineHideTouchDeviceWarningDialog: 'hide_touch_device_warning_dialog',
  engineHideWindowsGPUConfigInstructionsDialog:
    'hide_windows_GPU_config_instruction_dialog',
  fireflyAgreedToToS: 'firefly_agree_to_ToS',
  fireflyViewedOnboardingDialog: 'firefly_viewed_onboarding_dialog',
  privateBetaHasViewedSurveyMessage: 'has_viewed_private_beta_survey_message',
  projectHideShareCommunityDialog: 'project_hide_share_community_dialog'
} as const
