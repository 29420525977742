import React, {
  FC,
  HtmlHTMLAttributes,
  PropsWithChildren,
  ReactNode
} from 'react'
import cn from 'classnames'
import styles from '@styles/components/LibraryThumbnail.module.scss'
import { FieldLabel } from 'ui'

interface Props extends PropsWithChildren, HtmlHTMLAttributes<HTMLDivElement> {
  label?: string
  selected?: boolean
  actionMenu?: ReactNode
}

const LibraryThumbnail: FC<Props> = ({
  actionMenu,
  children,
  label,
  selected,
  ...containerProps
}) => {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['container']}>
        {actionMenu && (
          <div className={styles['action-menu']}>{actionMenu}</div>
        )}

        <div
          {...containerProps}
          className={cn(
            styles['thumbnail'],
            { [styles['selected']]: selected },
            containerProps.className
          )}>
          {children}
        </div>
      </div>
      {label && <FieldLabel className={styles['label']}>{label}</FieldLabel>}
    </div>
  )
}

export default LibraryThumbnail
