import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import AddObject from './AddObject'
import StylesPicker from './StylesPicker'
import FireflyShowGeneratePromptButton from '@components/fireflyPanel/FireflyShowGeneratePromptButton'
import { useFirefly } from '@hooks/useFirefly'

const SceneContextBar = () => {
  const flags = useFlags()
  const { isSceneRealisticOrVectorArt } = useFirefly()

  return (
    <>
      <AddObject />
      <StylesPicker />
      {flags['tf-ui-firefly-generate-prompt-btn-no-object-selected'] &&
        isSceneRealisticOrVectorArt && <FireflyShowGeneratePromptButton />}
    </>
  )
}

export default SceneContextBar
