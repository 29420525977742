import React, { ComponentProps, FC } from 'react'
import { Link } from 'ui'

export const CommunityShareLink =
  'https://new.express.adobe.com/webpage/tbB7j4GW82ilL#community-sharing'

type Props = ComponentProps<typeof Link>

const ShareLink: FC<Props> = props => {
  return (
    <Link
      variant="secondary"
      onClick={() => window.open(CommunityShareLink, '_blank')}
      {...props}>
      Learn More
    </Link>
  )
}

export default ShareLink
