import React, { FC, ReactNode } from 'react'
import styles from '@styles/components/PanelContainer.module.scss'
import cn from 'classnames'

interface Props {
  children: ReactNode
  className?: string
}

const PanelContainer: FC<Props> = ({ children, className }) => {
  return (
    <div className={cn(styles['panel-container'], className)}>{children}</div>
  )
}

export default PanelContainer
