import {
  BoundsType,
  BoundsVariant,
  PropertyPanelPath
} from '@services/engine/types'
import { useSceneState } from './useScene'

export const usePropertyBounds = (
  propertyPath: PropertyPanelPath
): Record<BoundsType, BoundsVariant | undefined> => {
  const propertyList = useSceneState('propertyList')
  const property = propertyList[propertyPath]
  const softMin = property?.softMin
  const softMax = property?.softMax
  const hardMin = property?.hardMin
  const hardMax = property?.hardMax
  return { softMin, softMax, hardMin, hardMax }
}
