import {
  BindingsContainer,
  BoolPropertyBindings,
  MutableBoolPropertyBindings,
  NumberPropertyBindings,
  MutableNumberPropertyBindings
} from './bindings'

import { useMutableProperty, TMutableProperty, useProperty } from './property'

interface MouseCaptureObject {
  enableMouseCapture(enable: boolean): void
}

declare class UIControllerBindings {
  get2DGizmosActiveProperty(): MutableBoolPropertyBindings
  getShapeEditionModeProperty(): MutableBoolPropertyBindings
  getSnappingActiveProperty(): MutableBoolPropertyBindings

  getInputDeviceSettingProperty(): MutableNumberPropertyBindings
  getDeducedInputDeviceProperty(): NumberPropertyBindings

  setMouseCaptureObject(object: MouseCaptureObject): void

  shouldHideOverlayPanels(): boolean
  getShouldShowMinimalUIProperty(): BoolPropertyBindings
}

export enum InputDeviceSetting {
  MOUSE,
  TRACKPAD,
  AUTOMATIC
}

export enum InputDevice {
  MOUSE,
  TRACKPAD
}

const uiBindingsContainer = new BindingsContainer<UIControllerBindings>(
  'UIControllerBindings'
)

export interface TUISettings {
  shapeEditionMode: () => TMutableProperty<boolean>
  gizmo2DActive: () => TMutableProperty<boolean>
  snappingActive: () => TMutableProperty<boolean>

  inputDeviceSetting: () => TMutableProperty<InputDeviceSetting>
  deducedInputDeviceSetting: () => InputDeviceSetting

  setMouseCaptureObject: (f: MouseCaptureObject) => void
  shouldHideOverlayPanels: () => boolean

  shouldShowMinimalInterface: () => boolean
}

type TUISettingsFunction = () => TUISettings

export const useUISettings: TUISettingsFunction = () => {
  return {
    shapeEditionMode: () => {
      return useMutableProperty<boolean>(() => {
        return uiBindingsContainer.get()?.getShapeEditionModeProperty()
      })
    },

    gizmo2DActive: () => {
      return useMutableProperty<boolean>(() => {
        return uiBindingsContainer.get()?.get2DGizmosActiveProperty()
      })
    },

    snappingActive: () => {
      return useMutableProperty<boolean>(() => {
        return uiBindingsContainer.get()?.getSnappingActiveProperty()
      })
    },

    inputDeviceSetting: () => {
      return useMutableProperty<InputDeviceSetting>(() => {
        return uiBindingsContainer.get()?.getInputDeviceSettingProperty()
      })
    },

    deducedInputDeviceSetting: () => {
      const value = useProperty<InputDeviceSetting>(() => {
        return uiBindingsContainer.get()?.getDeducedInputDeviceProperty()
      })

      return value ?? InputDeviceSetting.MOUSE
    },

    setMouseCaptureObject: (object: MouseCaptureObject) => {
      uiBindingsContainer.get()?.setMouseCaptureObject(object)
    },

    shouldHideOverlayPanels: function (): boolean {
      return uiBindingsContainer.get()?.shouldHideOverlayPanels() ?? false
    },

    shouldShowMinimalInterface: () => {
      const shouldShow = useProperty<boolean>(() => {
        return uiBindingsContainer.get()?.getShouldShowMinimalUIProperty()
      })

      return shouldShow ?? false
    }
  }
}
