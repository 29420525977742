import React, { FC, ReactNode } from 'react'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import { ProjectState } from '@store/slices/projectSlice'
import { LibraryViewHeader } from './constants'
import PanelHeader from '@components/panel/PanelHeader'
import PanelCard from '@components/panel/PanelCard'
import UserLibraryMaterialItems from './UserLibraryMaterialItems'
import LibraryThreeDShapesItems from './LibraryThreeDShapesItems'
import UserLibraryBackgroundItems from './UserLibraryBackgroundItems'

const LibraryItems: Partial<
  Record<ProjectState['selectedLibraryView'], ReactNode>
> = {
  'neo-three-d-shapes': <LibraryThreeDShapesItems />,
  'user-background': <UserLibraryBackgroundItems />,
  'user-material': <UserLibraryMaterialItems />
}

const LibraryFullAssetView: FC = () => {
  const selectedLibraryView = useProjectState('selectedLibraryView')
  const { setSelectedLibraryView } = useProjectActions()

  return (
    <>
      <PanelHeader
        onBackClick={() => setSelectedLibraryView('tabs-panel')}
        textAlign="center">
        {LibraryViewHeader[selectedLibraryView]}
      </PanelHeader>

      <div style={{ padding: 12 }}>
        <PanelCard>{LibraryItems[selectedLibraryView]}</PanelCard>
      </div>
    </>
  )
}

export default LibraryFullAssetView
