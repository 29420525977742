import React from 'react'

import styles from '@styles/components/HelpDialog.module.scss'
import HelpCircleIcon from '/public/s2_icon_help_circle.svg'

import { ActionButton, DialogWrapper, Icon, OverlayTrigger, Tooltip } from 'ui'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

const content = [
  {
    header: 'Camera Controls',
    list: [
      { action: 'Frame selection', shortcut: 'Ctrl + F' },
      {
        action: 'Orbit',
        shortcut: 'Secondary mouse button or 1 + Primary Mouse Button'
      },
      {
        action: 'Pan',
        shortcut: 'Space + Primary Mouse Button or 2 + Primary Mouse Button'
      },
      {
        action: 'Zoom',
        shortcut: 'Mouse scroll wheel or 3 + Primary Mouse Button or +/-'
      }
    ]
  },
  {
    header: 'Shape Manipulation',
    list: [
      {
        action: 'Corner size',
        shortcut: 'Option + Primary Mouse Button + Drag (on box edge)'
      },
      {
        action: 'Cycle between handles & box',
        shortcut: 'Double click (on object)'
      },
      {
        action: 'Uniform scaling',
        shortcut: 'Primary Mouse Button + Drag (on box face)'
      }
      // {
      //   action: 'Snap to 0.1 units',
      //   shortcut: 'Cmd + Primary Mouse Button + Drag'
      // },
      // {
      //   action: '45 degree rotation',
      //   shortcut: 'Shift + Primary Mouse Button + Drag'
      // }
    ]
  },
  {
    header: 'Shortcuts',
    list: [
      // {
      //   action: 'Copy shape', shortcut: 'Cmd + C',
      // },
      // {
      //   action: 'Cut shape', shortcut: 'Cmd + X',
      // },
      {
        action: 'Deselect',
        shortcut: 'Esc'
      },
      {
        action: 'Duplicate shape',
        shortcut: 'Cmd + D'
      },
      // {
      //   action: 'Group selected items',
      //   shortcut: 'Cmd + G'
      // },
      {
        action: 'Hide/Unhide shape',
        shortcut: 'Cmd + Shift + H'
      },
      // {
      //   action: 'Lock shape', shortcut: 'Cmd + L',
      // },
      {
        action: 'Move in X, Y and Z axis',
        shortcut: 'Option + Primary Mouse Button + Drag'
      },
      {
        action: 'Move in XY, YZ and ZX planes',
        shortcut: 'Ctrl + Primary Mouse Button + Drag'
      },
      {
        action: 'Move object',
        shortcut: 'Primary Mouse Button + Drag'
      },
      {
        action: 'Move shape down in hierarchy',
        shortcut: 'Cmd + ['
      },
      {
        action: 'Move shape up in hierarchy',
        shortcut: 'Cmd + ]'
      },
      {
        action: 'Move shape to bottom in hierarchy',
        shortcut: 'Ctrl + Shift + ['
      },
      {
        action: 'Move shape to top in hierarchy',
        shortcut: 'Ctrl + Shift + ]'
      },

      {
        action: 'Multi-select object',
        shortcut: 'Shift + Primary Mouse Button'
      },
      // {
      //   action: 'Paste shape', shortcut: 'Cmd + V',
      // },
      {
        action: 'Redo',
        shortcut: 'Cmd + Shift + Z'
      },
      {
        action: 'Remove an object from selection',
        shortcut: 'Shift + Primary Mouse Button'
      },
      {
        action: 'Select all',
        shortcut: 'Cmd + A'
      },
      {
        action: 'Select object',
        shortcut: 'Primary Mouse Button'
      },
      {
        action: 'Select first child',
        shortcut: 'Option + Shift + ]'
      },
      {
        action: 'Select parent group',
        shortcut: 'Option + Shift + ['
      },
      {
        action: 'Select next shape',
        shortcut: 'Option + ]'
      },
      {
        action: 'Select previous shape',
        shortcut: 'Option + ['
      },
      {
        action: 'Show/hide debug panel',
        shortcut: 'Ctrl + Space'
      },
      {
        action: 'Undo',
        shortcut: 'Cmd + Z'
      }
      // {
      //   action: 'Ungroup selected items',
      //   shortcut: 'Cmd + Shift + G'
      // }
      // {
      //   action: 'Unlock shape', shortcut: 'Cmd + Shift + L',
      // },
    ]
  },
  {
    header: 'Collaboration Shortcuts',
    list: [
      {
        action: 'Close input menu',
        shortcut: 'Esc'
      },
      {
        action: 'Emoji picker (click emoji to choose)',
        shortcut: '/'
      },
      {
        action: 'Submit text input',
        shortcut: '/ + / + Enter'
      },
      {
        action: 'Text input',
        shortcut: '/ + / + /'
      }
    ]
  }
]

const HelpDialog = () => {
  const size = useMediumMinimumSizePreference()

  return (
    <OverlayTrigger type="modal">
      <DialogWrapper
        slot="click-content"
        headline="Inputs and Controls"
        dismissable
        underlay>
        {content.map((section, sectionIndex) => (
          <div key={sectionIndex} className={styles['help-dialog-content']}>
            <h3 className={styles['header']}>{section.header}</h3>
            {section.list.map((item, itemIndex) => (
              <div key={itemIndex} className={styles['flex-container']}>
                <div className={styles['flex-action']}>{item.action}</div>
                <div className={styles['flex-shortcut']}>{item.shortcut}</div>
              </div>
            ))}
          </div>
        ))}
        <div>
          <h3 className={styles['header']}>Recommended Browsers</h3>
          <div className={styles['help-dialog-content']}>
            <div className={styles['flex-container']}>
              <div className={styles['flex-action']}>Intel-based Macs</div>
              <div className={styles['flex-shortcut']}>
                Apple Safari is the only supported browser
              </div>
            </div>
            <div className={styles['flex-container']}>
              <div className={styles['flex-action']}>Apple Silicon Macs</div>
              <div className={styles['flex-shortcut']}>
                Google Chrome, Microsoft Edge and Opera
              </div>
            </div>
            <div className={styles['flex-container']}>
              <div className={styles['flex-action']}>For all Macs</div>
              <div className={styles['flex-shortcut']}>
                Google Chrome & Apple Safari
              </div>
            </div>
            <div className={styles['flex-container']}>
              <div className={styles['flex-action']}>For Windows Devices</div>
              <div className={styles['flex-shortcut']}>
                Google Chrome & Microsoft Edge
              </div>
            </div>
          </div>
        </div>
        <a
          href="https://new.express.adobe.com/webpage/tbB7j4GW82ilL"
          target="_blank">
          <h2
            style={{
              textDecoration: 'underline'
            }}>
            Release Notes
          </h2>
        </a>
      </DialogWrapper>
      <ActionButton slot="trigger" quiet aria-label="Help" size={size}>
        <Icon slot="icon">
          <HelpCircleIcon />
        </Icon>
      </ActionButton>
      <Tooltip slot="hover-content" placement="bottom">
        Help
      </Tooltip>
    </OverlayTrigger>
  )
}

export default HelpDialog
