import { PayloadAction } from '@reduxjs/toolkit'
import Context from '@store/middleware/context'
import {
  setEngineState,
  HistoryState,
  setIsLoadingScene,
  setHistoryMode,
  setHistorySliderPosition,
  setShowHistoryDialog
} from '@store/slices/historySlice'
import {
  put,
  fork,
  take,
  all,
  call,
  takeEvery,
  select
} from 'redux-saga/effects'
import createEventChannel from '../createEventChannel'
import Engine from '@services/engine/engine'
import {
  DeviceStatusEventPayload,
  ModelLoadedStatusEventPayload
} from '@services/engine/types'
import { PayloadType, RootState } from '@store/store'
import {
  setPropertyState,
  setEngineState as sceneSliceSetEngineState
} from '@store/slices/sceneSlice'

function* initializeEngine() {
  if (Context.Engine) {
    // unmount engine before initializing
    yield Context.Engine.unmount()
    yield Context.Engine.mount()
    return
  }
  Context.Engine = new Engine()
  // no need for Launch Darkly features in community page yet
  const features = '{}'
  yield fork(Context.Engine.initialize, features)
}

function* handleSetEngineState({
  payload
}: PayloadAction<HistoryState['engineState']>) {
  switch (payload) {
    case 'INITIALIZE':
      yield call(clearEngineCache)
      yield call(initializeEngine)
      yield put(setEngineState('INITIALIZED'))
      break
    case 'INITIALIZED':
      yield handleInitializedEngineAndLoadDocument()
      break
    case 'INITIALIZED_AND_DOCUMENT_LOADED':
      yield put(
        setPropertyState({
          key: 'frameEnabled',
          value: false
        })
      )
      yield put(setIsLoadingScene(false))
      yield put(setHistoryMode(1)) // enable history mode by default

      break
    case 'UNMOUNT':
      yield unmountEngine()
      // TODO just reset the state
      yield put(setHistoryMode(0)) // disable history mode when engine is unmounted
      yield put(setHistorySliderPosition(1)) // reset history slider position
      break
    case 'UNMOUNTED':
      yield put(setIsLoadingScene(true))
      break
    default:
      break
  }
}

function* handleSceneSliceSetEngineState({
  payload
}: PayloadAction<PayloadType<typeof sceneSliceSetEngineState>>) {
  const { engineState }: HistoryState = yield select(
    (state: RootState) => state.history
  )

  // Syncs engineState of historySlice and sceneSlice when engine unmounts from studio page
  // or when there is an error emitted from EngineModule.ts
  if (
    (payload === 'UNMOUNTED' || payload === 'ERROR') &&
    engineState !== 'UNMOUNTED'
  ) {
    yield put(setEngineState(payload))
  }

  if (payload === 'ERROR') {
    yield put(setShowHistoryDialog({ isOpen: false }))
  }
}

async function clearEngineCache() {
  if (!caches) return

  const keyList = await caches.keys()

  const EngineCacheKeys = ['engine/build/neo.js', 'engine/build/neo.wasm']

  await Promise.all(
    keyList.map(async key => {
      const cache = await caches.open(key)
      const cacheKeys = await cache.keys()

      return Promise.all(
        cacheKeys
          .filter(entry => EngineCacheKeys.some(key => entry.url.includes(key)))
          .map(entry => cache.delete(entry))
      )
    })
  )
}

function* handleInitializedEngineAndLoadDocument() {
  if (Context.Engine?.loadDocument) {
    const { projectUuid } = yield select((state: RootState) => state.project)
    if (!projectUuid) return
    yield fork(
      Context.Engine.loadDocument,
      `${process.env.NEXT_PUBLIC_SERVICE_CORE_API_ENDPOINT}/projects/${projectUuid}/document`
    )
  }
  yield fork(handleStatusChannel)
  yield fork(handleDeviceStatusChannel)
}

function* unmountEngine() {
  if (Context.Engine) {
    yield Context.Engine.unmount()
    yield [
      Context.Engine.EngineStatusChannel,
      Context.Engine.EngineDataChannel,
      Context.Engine.EngineDebugChannel,
      Context.Engine.EngineFrameDataChannel
    ].forEach(channel => channel.removeAllListeners())
  }
  yield put(setEngineState('UNMOUNTED'))
}

function* handleStatusChannel(): Generator<any, void, any> {
  if (!Context.Engine?.EngineStatusChannel) {
    return
  }
  const statusChannel = yield call(
    createEventChannel,
    Context.Engine.EngineStatusChannel,
    'status'
  )
  while (true) {
    try {
      const statusEvent: ModelLoadedStatusEventPayload = yield take(
        statusChannel
      )
      const modelLoaded = statusEvent?.status === 1
      if (modelLoaded) {
        yield put(setEngineState('INITIALIZED_AND_DOCUMENT_LOADED'))
      }
    } catch (err) {
      console.error(err)
    }
  }
}

function* handleDeviceStatusChannel(): Generator<any, void, any> {
  if (!Context.Engine?.EngineStatusChannel) {
    return
  }
  const channel = yield call(
    createEventChannel,
    Context.Engine?.EngineStatusChannel,
    'device-status'
  )
  while (true) {
    try {
      const deviceStatus: DeviceStatusEventPayload = yield take(channel)

      if (deviceStatus === DeviceStatusEventPayload.SLOW_INTEL_GPU) {
        // TODO handle slow intel gpu
      }
    } catch (err) {
      console.error(err)
    }
  }
}

function* handleSetHistoryMode({
  payload
}: PayloadAction<HistoryState['historyMode']>) {
  // enabled by default when engine is initialized from community page
  yield Context.Engine?.setHistoryMode(payload)
}

function* handleHistorySliderPosition({
  payload
}: PayloadAction<HistoryState['historySliderPosition']>) {
  yield Context.Engine?.setHistorySliderPos(payload)
}

export default function* historyEngineSaga() {
  yield all([
    takeEvery(setEngineState.type, handleSetEngineState),
    takeEvery(sceneSliceSetEngineState.type, handleSceneSliceSetEngineState),
    takeEvery(setHistoryMode.type, handleSetHistoryMode),
    takeEvery(setHistorySliderPosition.type, handleHistorySliderPosition)
  ])
}
