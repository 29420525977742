import { ComponentProps, FC } from 'react'
import { BoundedPropertySlider } from './BoundedPropertySlider'

type Props = Omit<
  ComponentProps<typeof BoundedPropertySlider>,
  'minPercentage' | 'maxPercentage' | 'format'
>

export const PercentPropertySlider: FC<Props> = ({
  disabled,
  ariaLabel,
  label,
  valueKey,
  propertyPath,
  enableUpperboundOverride
}) => {
  return (
    <BoundedPropertySlider
      disabled={disabled}
      ariaLabel={ariaLabel}
      label={label}
      valueKey={valueKey}
      propertyPath={propertyPath}
      format="percentage"
      minPercentage={0}
      maxPercentage={100}
      enableUpperboundOverride={enableUpperboundOverride}
    />
  )
}
