import React, { FC, useState } from 'react'
import {
  ActionButton,
  Icon,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuItem,
  Overlay,
  Popover,
  Switch
} from 'ui'
import styles from '@styles/components/InputDeviceMenu.module.scss'
import MouseButtonMiddleIcon from '/public/temp-icon-input-mouse.svg'
import TouchOneFingerSwipeIcon from '/public/temp-icon-input-trackpad.svg'
import {
  InputDeviceSetting,
  useUISettings
} from '@services/engine/useUISettings'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

const InputDeviceMenu: FC = () => {
  const size = useMediumMinimumSizePreference()

  const { deducedInputDeviceSetting, inputDeviceSetting } = useUISettings()
  const [getInputDevice, setInputDevice] = inputDeviceSetting()
  const currentInputDeviceSetting = getInputDevice() ?? InputDeviceSetting.MOUSE

  const deducedInputDevice = deducedInputDeviceSetting()

  const getDeducedInputDeviceLabel = () => {
    return deducedInputDevice == InputDeviceSetting.TRACKPAD
      ? 'Trackpad'
      : 'Mouse'
  }

  const automaticInputDevice = getDeducedInputDeviceLabel()

  const [isOpen, setIsOpen] = useState(false)

  function handleAutomaticToggle() {
    if (InputDeviceSetting.AUTOMATIC === currentInputDeviceSetting) {
      setInputDevice(
        automaticInputDevice === 'Mouse'
          ? InputDeviceSetting.MOUSE
          : InputDeviceSetting.TRACKPAD
      )
    } else {
      setInputDevice(InputDeviceSetting.AUTOMATIC)
    }
  }

  function renderTriggerIcon() {
    if (InputDeviceSetting.MOUSE === currentInputDeviceSetting) {
      return <MouseButtonMiddleIcon />
    }

    if (InputDeviceSetting.TRACKPAD === currentInputDeviceSetting) {
      return <TouchOneFingerSwipeIcon />
    }

    return automaticInputDevice === 'Mouse' ? (
      <MouseButtonMiddleIcon />
    ) : (
      <TouchOneFingerSwipeIcon />
    )
  }

  return (
    <>
      <ActionButton
        size={size}
        aria-expanded={isOpen}
        aria-haspopup={isOpen}
        aria-controls="menu"
        selected={isOpen}
        id="input-device-menu-trigger"
        quiet
        holdAffordance>
        <Icon slot="icon">{renderTriggerIcon()}</Icon>
      </ActionButton>

      <Overlay
        spOpened={() => setIsOpen(true)}
        spClosed={() => setIsOpen(false)}
        placement="bottom"
        offset={10}
        trigger="input-device-menu-trigger@click">
        <Popover className={styles['popover']}>
          <Menu size={size} className={styles['menu']}>
            <MenuGroup selects="single" size={size}>
              <span slot="header">Input device</span>
              <MenuItem
                disabled={
                  InputDeviceSetting.AUTOMATIC === currentInputDeviceSetting
                }
                onClick={() => setInputDevice(InputDeviceSetting.MOUSE)}
                selected={
                  InputDeviceSetting.MOUSE === currentInputDeviceSetting ||
                  (automaticInputDevice === 'Mouse' &&
                    InputDeviceSetting.AUTOMATIC === currentInputDeviceSetting)
                }>
                <Icon slot="icon">
                  <MouseButtonMiddleIcon />
                </Icon>
                Mouse
              </MenuItem>
              <MenuItem
                disabled={
                  InputDeviceSetting.AUTOMATIC === currentInputDeviceSetting
                }
                onClick={() => setInputDevice(InputDeviceSetting.TRACKPAD)}
                selected={
                  InputDeviceSetting.TRACKPAD === currentInputDeviceSetting ||
                  (automaticInputDevice === 'Trackpad' &&
                    InputDeviceSetting.AUTOMATIC === currentInputDeviceSetting)
                }>
                <Icon slot="icon">
                  <TouchOneFingerSwipeIcon />
                </Icon>
                Trackpad
              </MenuItem>
            </MenuGroup>

            <MenuDivider />

            <MenuGroup size={size}>
              <MenuItem onClick={handleAutomaticToggle}>
                Automatic ({automaticInputDevice})
                <Switch
                  size={size}
                  style={{ pointerEvents: 'none' }} // Allow menu item to control click behavior when toggling switch
                  slot="value"
                  checked={
                    InputDeviceSetting.AUTOMATIC === currentInputDeviceSetting
                  }></Switch>
              </MenuItem>
            </MenuGroup>
          </Menu>
        </Popover>
      </Overlay>
    </>
  )
}

export default InputDeviceMenu
