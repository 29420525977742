import React, { FC } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { ActionButton, Icon, Tooltip } from 'ui'
import { GroupExpanded } from '@services/engine/types'

import ExplodeSceneIcon from '/public/s2_icon_explode_scene.svg'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

const GroupExpand: FC = () => {
  const size = useMediumMinimumSizePreference()
  const groupExpanded = useSceneState('groupExpanded')
  const { setPropertyState } = useSceneActions()

  return (
    <ActionButton
      size={size}
      quiet
      toggles
      onclick={() => {
        setPropertyState({
          key: 'groupExpanded',
          value:
            groupExpanded === GroupExpanded.NORMAL
              ? GroupExpanded.EXPANDED
              : GroupExpanded.NORMAL
        })
        document.getElementById('canvas')?.focus()
      }}>
      <Icon slot="icon" className="icon-m">
        <ExplodeSceneIcon />
      </Icon>
      <Tooltip selfManaged placement="bottom">
        {groupExpanded ? 'Implode selected group' : 'Explode selected group'}
      </Tooltip>
    </ActionButton>
  )
}

export default GroupExpand
