import React, { FC, memo } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import {
  ActionButton,
  Checkbox,
  FieldLabel,
  Icon,
  OverlayTrigger,
  Tooltip
} from 'ui'
import { MaterialType } from '@services/engine/types'
import MaterialPicker from '@components/propertiesPanel/MaterialPicker'
import SaveToIcon from '/public/temp-icon-add-to-lib.svg'
import { useProjectState, useProjectActions } from '@hooks/useProject'
import PanelAccordion from '@components/panel/PanelAccordion'
import { Section } from './ObjectProperties'
import { VariableColorPicker } from '@components/variableModeColorPicker/VariableModeColorPicker'
import { PercentPropertySlider } from '@components/slider/PercentPropertySlider'

const Material: FC = () => {
  const threeDMaterialCollapsed = useProjectState('threeDMaterialCollapsed')
  const sizePreference = useProjectState('sizePreference')
  const { setPanelAccordionItem } = useProjectActions()

  const materialType = useSceneState('materialType')
  const address = useSceneState('address')
  const materialColor = useSceneState('materialColor')
  const materialEColorLig = useSceneState('materialEColorLig')
  const materialEColorTop = useSceneState('materialEColorTop')
  const materialEColorSha = useSceneState('materialEColorSha')
  const materialIColor = useSceneState('materialIColor')
  const materialIEmissiveColor = useSceneState('materialIEmissiveColor')
  const materialKeepStylesInSync = useSceneState('materialKeepStylesInSync')
  const materialReflective = useSceneState('materialReflective')

  const { setPropertyState } = useSceneActions()

  const { setShowSaveMaterialDialog } = useProjectActions()

  const isPTypeSelected = materialType === MaterialType.P
  const isETypeSelected = materialType === MaterialType.E
  const isPixelTypeSelected = materialType === MaterialType.PIXEL
  const isIllustrativeTypeSelected = materialType === MaterialType.I

  return (
    <PanelAccordion
      id={Section.appearance}
      label={
        <>
          Appearance
          <div className="position-relative">
            <div className="panel-accordion-label-button">
              <OverlayTrigger placement="bottom">
                <ActionButton
                  size={sizePreference}
                  quiet
                  slot="trigger"
                  onClick={e => {
                    e.stopPropagation()
                    setShowSaveMaterialDialog(true)
                  }}>
                  <Icon slot="icon">
                    <SaveToIcon />
                  </Icon>
                </ActionButton>
                <Tooltip slot="hover-content">Save material to library</Tooltip>
              </OverlayTrigger>
            </div>
          </div>
        </>
      }
      collapsed={threeDMaterialCollapsed}
      onChange={collapsed =>
        setPanelAccordionItem({ key: 'threeDMaterialCollapsed', collapsed })
      }>
      {isPTypeSelected && (
        <MaterialPicker
          sizePreference={sizePreference}
          label="Color"
          showHexField
          address={address}
          materialColor={materialColor}
          materialEColorLig={materialEColorLig}
          materialEColorTop={materialEColorTop}
          materialEColorSha={materialEColorSha}
          materialIColor={materialIColor}
          materialIEmissiveColor={materialIEmissiveColor}
          materialKeepStylesInSync={materialKeepStylesInSync}
          materialType={materialType}
          setPropertyState={setPropertyState}
        />
      )}
      {(isETypeSelected ||
        isPixelTypeSelected ||
        isIllustrativeTypeSelected) && <VariableColorPicker />}

      {isPTypeSelected ? (
        <>
          <PercentPropertySlider
            ariaLabel="Roughness"
            label="Roughness"
            valueKey="materialRoughness"
            propertyPath="shapeMaterial.pbrRoughness"
          />
          <PercentPropertySlider
            ariaLabel="Metallic"
            label="Metallic"
            valueKey="materialMetalness"
            propertyPath="shapeMaterial.pbrMetalness"
          />
          <div className="flex align-center gap-xs">
            <Checkbox
              id="primitive-material-reflective"
              size={sizePreference}
              checked={materialReflective}
              change={e =>
                setPropertyState({
                  address,
                  key: 'materialReflective',
                  value: !materialReflective
                })
              }></Checkbox>
            <FieldLabel for="primitive-material-reflective">
              Reflective
            </FieldLabel>
          </div>
        </>
      ) : isETypeSelected || isPixelTypeSelected ? (
        <>
          <PercentPropertySlider
            disabled
            ariaLabel="Highlight Size"
            label="Highlight Size"
            valueKey="materialESpecularSize"
            propertyPath="shapeMaterial.exprSpecularSize"
          />
          <PercentPropertySlider
            ariaLabel="Highlight Intensity"
            label="Highlight Intensity"
            valueKey="materialESpecularIntensity"
            propertyPath="shapeMaterial.exprSpecularIntensity"
          />
        </>
      ) : (
        <>
          <PercentPropertySlider
            ariaLabel="Stroke Size"
            label="Stroke Size"
            valueKey="materialIStrokeSize"
            propertyPath="shapeMaterial.illustrativeStrokeSize"
          />
          <PercentPropertySlider
            ariaLabel="Highlight Intensity"
            label="Highlight Intensity"
            valueKey="materialIHighlightIntensity"
            propertyPath="shapeMaterial.illustrativeSpecularIntensity"
          />
          <PercentPropertySlider
            ariaLabel="Paint Stroke Intensity"
            label="Paint Stroke Intensity"
            valueKey="materialIStrokeIntensity"
            propertyPath="shapeMaterial.illustrativeNormalIntensity"
          />
          <PercentPropertySlider
            ariaLabel="Color Variation"
            label="Stroke Color Variation"
            valueKey="materialIColorVarIntensity"
            propertyPath="shapeMaterial.illustrativeColorVariation"
          />
          <PercentPropertySlider
            ariaLabel="Scale X"
            label="Scale X"
            valueKey="materialIScaleX"
            propertyPath="shapeMaterial.illustrativeScaleX"
          />
          <PercentPropertySlider
            ariaLabel="Scale Y"
            label="Scale Y"
            valueKey="materialIScaleY"
            propertyPath="shapeMaterial.illustrativeScaleY"
          />
          <PercentPropertySlider
            ariaLabel="Angle"
            label="Angle"
            valueKey="materialIAngle"
            propertyPath="shapeMaterial.illustrativeAngle"
          />
        </>
      )}
    </PanelAccordion>
  )
}

export default memo(Material)
