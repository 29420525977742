import React, { FC, PropsWithChildren } from 'react'
import cn from 'classnames'
import { ClassNameProps } from 'types/reactProps'
import styles from '@styles/components/PanelContent.module.scss'

type Props = PropsWithChildren<ClassNameProps>

const PanelContent: FC<Props> = ({ children, className }) => {
  return <div className={cn(styles['container'], className)}>{children}</div>
}

export default PanelContent
