import React, { FC } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Tab, TabPanel, Tabs } from 'ui'
import { ProjectState } from '@store/slices/projectSlice'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import { LibraryViewHeader } from './constants'
import PanelHeader from '@components/panel/PanelHeader'
import PanelAccordion from '@components/panel/PanelAccordion'
import LibraryThreeDShapesItems from './LibraryThreeDShapesItems'
import UserLibraryMaterialItems from './UserLibraryMaterialItems'
import UserLibraryBackgroundItems from './UserLibraryBackgroundItems'
import { useSceneState } from '@hooks/useScene'
import { EngineSelectedSceneNode } from '@services/engine/types'

const ValidTabs: Readonly<ProjectState['selectedLibraryTab'][]> = [
  'neo-library',
  'user-library'
]

const LibraryTabsPanel: FC = () => {
  const flags = useFlags()

  const selectedSceneNode = useSceneState('selectedSceneNode')

  const libraryThreeDShapeCollapsed = useProjectState(
    'libraryThreeDShapeCollapsed'
  )
  const libraryUserMaterialCollapsed = useProjectState(
    'libraryUserMaterialCollapsed'
  )
  const libraryUserBackgroundCollapsed = useProjectState(
    'libraryUserBackgroundCollapsed'
  )
  const selectedLibraryTab = useProjectState('selectedLibraryTab')

  const {
    setPanelAccordionItem,
    setSelectedLibraryTab,
    setSelectedLibraryView
  } = useProjectActions()

  function handleTabChange(event: any) {
    const selected = event.target?.selected
    const isValidTab = ValidTabs.some(tab => tab === selected)
    if (isValidTab) setSelectedLibraryTab(selected)
  }

  return (
    <>
      <PanelHeader textAlign="center">
        {LibraryViewHeader['tabs-panel']}
      </PanelHeader>

      <div className="tabs-container">
        <Tabs
          quiet
          compact
          className="tabs"
          selected={selectedLibraryTab}
          size="m"
          change={handleTabChange}>
          {flags['base-pf-ui-library-assets'] && (
            <Tab label="Neo assets" value="neo-library"></Tab>
          )}
          <Tab label="My assets" value="user-library"></Tab>

          {/* Rendering content in TabPanel is slow but are required to render selected tab indicator. */}
          {flags['base-pf-ui-library-assets'] && (
            <TabPanel value="neo-library"></TabPanel>
          )}
          <TabPanel value="user-library"></TabPanel>
        </Tabs>
      </div>

      <div className="tab-panel">
        <div className="tab-panel-content">
          {flags['base-pf-ui-library-assets'] &&
            selectedLibraryTab === 'neo-library' && (
              <>
                <PanelAccordion
                  label={LibraryViewHeader['neo-three-d-shapes']}
                  collapsed={libraryThreeDShapeCollapsed}
                  onChange={collapsed =>
                    setPanelAccordionItem({
                      key: 'libraryThreeDShapeCollapsed',
                      collapsed
                    })
                  }>
                  <LibraryThreeDShapesItems
                    totalItems={9}
                    onViewAll={() =>
                      setSelectedLibraryView('neo-three-d-shapes')
                    }
                  />
                </PanelAccordion>
              </>
            )}
          {selectedLibraryTab === 'user-library' && (
            <>
              <PanelAccordion
                label={LibraryViewHeader['user-material']}
                collapsed={libraryUserMaterialCollapsed}
                onChange={collapsed =>
                  setPanelAccordionItem({
                    key: 'libraryUserMaterialCollapsed',
                    collapsed
                  })
                }>
                <UserLibraryMaterialItems
                  totalItems={9}
                  onViewAll={() => setSelectedLibraryView('user-material')}
                  disabled={selectedSceneNode !== EngineSelectedSceneNode.SHAPE}
                />
              </PanelAccordion>

              <PanelAccordion
                label={LibraryViewHeader['user-background']}
                collapsed={libraryUserBackgroundCollapsed}
                onChange={collapsed =>
                  setPanelAccordionItem({
                    key: 'libraryUserBackgroundCollapsed',
                    collapsed
                  })
                }>
                <UserLibraryBackgroundItems
                  totalItems={9}
                  onViewAll={() => setSelectedLibraryView('user-background')}
                  disabled={selectedSceneNode !== EngineSelectedSceneNode.NONE}
                />
              </PanelAccordion>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default LibraryTabsPanel
