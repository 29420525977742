import { RootState } from '@store/store'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { firefly } from '@store/slices/fireflySlice'
import { EngineMode } from '@services/engine/types'
import { useSceneState } from './useScene'

export const useFirefly = () => {
  const state = useSelector((state: RootState) => state.firefly)
  const sceneMode = useSceneState('mode')
  const dispatch = useDispatch()
  const boundedActions = bindActionCreators(firefly.actions, dispatch)

  return {
    ...state,
    ...boundedActions,
    selectedGeneratedImage: state.generatedImages.find(
      ({ selected }) => selected
    )?.url,
    isSceneRealisticOrVectorArt:
      sceneMode === EngineMode.NORMAL || sceneMode === EngineMode.OUTLINE
  }
}
