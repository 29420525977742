import React, { FC } from 'react'
import styles from '@styles/components/VideoAvatar.module.scss'

interface Props {
  src?: string
}

const VideoAvatar: FC<Props> = ({ src }) => {
  return <img className={styles.img} src={src} />
}

export default VideoAvatar
