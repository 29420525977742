import React, { memo } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ActionButton, Icon, Overlay, Popover } from 'ui'
import styles from '@styles/components/StylesActionMenu.module.scss'
import { ElementSize } from '@store/slices/projectSlice'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import { useSceneState, useSceneActions } from '@hooks/useScene'
import { EngineMode } from '@services/engine/types'
import SelectionCardItem from '@components/selectionCardItem/SelectionCardItem'
import StyleIcon from '/public/s2_icon_style.svg'
import StylesInputs from './StylesInputs'
import PanelSectionHeader from '@components/panel/PanelSectionHeader'
import PanelCard from '@components/panel/PanelCard'

export const EngineModeLabelMap = {
  [EngineMode.NORMAL]: 'Realistic',
  [EngineMode.OUTLINE]: 'Vector Art',
  [EngineMode.PIXEL]: 'Pixelated',
  [EngineMode.ILLUSTRATIVE]: 'Illustrative'
} as const

export const EngineModeImgMap = {
  [EngineMode.NORMAL]: '/styles_realistic.png',
  [EngineMode.OUTLINE]: '/styles_expressive.png',
  [EngineMode.PIXEL]: '/styles_pixelart.png',
  [EngineMode.ILLUSTRATIVE]: '/styles_illustrative.png'
} as const

export const Styles = [
  EngineMode.NORMAL,
  EngineMode.OUTLINE,
  EngineMode.PIXEL,
  EngineMode.ILLUSTRATIVE
] as const

const StylesActionMenu = () => {
  const sizePreference = useProjectState('sizePreference')
  const showStylesMenuPopover = useProjectState('showStylesMenuPopover')

  const { setShowStylesMenuPopover } = useProjectActions()

  const size: ElementSize = sizePreference === 'l' ? 'm' : 's'

  const mode = useSceneState('mode')

  const { setPropertyState } = useSceneActions()

  const showInputs =
    mode === EngineMode.PIXEL ||
    mode === EngineMode.OUTLINE ||
    mode === EngineMode.ILLUSTRATIVE

  const flags = useFlags()

  const enabledStyles = Styles.filter(style => {
    if (
      style === EngineMode.ILLUSTRATIVE &&
      !flags['base-tf-fx-illustrative-style']
    ) {
      return null
    } else {
      return style
    }
  })

  return (
    <>
      <ActionButton size={size} id="styles-action-menu-popover-trigger" quiet>
        <Icon slot="icon">
          <StyleIcon />
        </Icon>
        Styles
      </ActionButton>

      <Overlay
        placement="bottom-start"
        offset={6}
        trigger="styles-action-menu-popover-trigger@click"
        spOpened={() => setShowStylesMenuPopover(true)}
        spClosed={() => setShowStylesMenuPopover(false)}
        open={showStylesMenuPopover}>
        <Popover className={styles['popover']} data-input-size={size}>
          <h2>Styles</h2>

          <div className={styles['styles-option']}>
            {enabledStyles.map(value => (
              <SelectionCardItem
                key={value}
                label={EngineModeLabelMap[value]}
                imgFullSize
                imgSrc={EngineModeImgMap[value]}
                onClick={() =>
                  setPropertyState({
                    key: 'mode',
                    value
                  })
                }
                selected={value === mode}
                showCheckMark={false}
              />
            ))}
          </div>

          {showInputs && (
            <>
              <PanelCard>
                <PanelSectionHeader
                  className={styles['inputs-header']}
                  sectionHeader={EngineModeLabelMap[mode]}
                />
                <StylesInputs size={size} />
              </PanelCard>
            </>
          )}
        </Popover>
      </Overlay>
    </>
  )
}

export default memo(StylesActionMenu)
