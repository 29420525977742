import React, { FC, ReactNode } from 'react'
import styles from '@styles/components/DialogForm.module.scss'
import cn from 'classnames'

interface Props {
  children?: ReactNode
  className?: string
}

const DialogForm: FC<Props> = ({ children, className }) => {
  return <div className={cn(styles.container, className)}>{children}</div>
}

export default DialogForm
