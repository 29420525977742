import React from 'react'
import { useInput } from '@hooks/useInput'
import ReactPlayer from 'react-player'
import { useSync } from '@hooks/useSync'
import { Textfield } from 'ui'
import styles from '@styles/components/PlayerPanel.module.scss'

const PlayerPanel = () => {
  const { sendLocalUserPlayerChangeEvent, playerUrl, playerState } = useSync()
  const { value, onKeyDown, onChange } = useInput('', onInputSubmit)

  function onInputSubmit(value: string) {
    sendLocalUserPlayerChangeEvent({
      url: value,
      state: 'pause'
    })
  }

  return (
    <div className={styles['container']}>
      <h3 className={styles['header']}>CoPlayer</h3>
      <div className={styles['flex-container']}>
        <Textfield
          className={styles['textfield']}
          value={value}
          onKeyDown={onKeyDown}
          onChange={onChange}
        />
        <ReactPlayer
          width="100%"
          playing={playerState === 'play'}
          controls={true}
          onPlay={() => sendLocalUserPlayerChangeEvent({ state: 'play' })}
          onPause={() => sendLocalUserPlayerChangeEvent({ state: 'pause' })}
          url={playerUrl}
        />
      </div>
    </div>
  )
}

export default PlayerPanel
