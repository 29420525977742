import React, { FC } from 'react'
import { useSceneActions } from '@hooks/useScene'

import { EngineUndoRedo } from '@services/engine/types'

import { SpIconUndo, SpIconRedo } from 'workflow-icons'
import { ActionGroup, ActionButton, OverlayTrigger, Tooltip } from 'ui'

import { getCmdModifier } from '@services/engine/utils'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

const UndoRedo: FC = () => {
  const { undoRedo } = useSceneActions()
  const size = useMediumMinimumSizePreference()

  return (
    <ActionGroup quiet size={size}>
      <OverlayTrigger offset={0} placement="bottom">
        <ActionButton
          slot="trigger"
          onClick={() => {
            undoRedo(EngineUndoRedo.UNDO)
            document.getElementById('canvas')?.focus()
          }}
          value={EngineUndoRedo.UNDO.toString()}
          aria-label="Undo">
          <SpIconUndo slot="icon" />
        </ActionButton>
        <Tooltip slot="hover-content">Undo ({getCmdModifier()} + Z)</Tooltip>
      </OverlayTrigger>
      <OverlayTrigger offset={0} placement="bottom">
        <ActionButton
          slot="trigger"
          onClick={() => {
            undoRedo(EngineUndoRedo.REDO)
            document.getElementById('canvas')?.focus()
          }}
          value={EngineUndoRedo.REDO.toString()}
          aria-label="Redo">
          <SpIconRedo slot="icon" />
        </ActionButton>
        <Tooltip slot="hover-content">
          Redo ({getCmdModifier()} + Shift + Z)
        </Tooltip>
      </OverlayTrigger>
    </ActionGroup>
  )
}

export default UndoRedo
