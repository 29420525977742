import React, { useState } from 'react'
import { Button, ButtonGroup, Dialog, DialogBase, FieldLabel } from 'ui'
import Textfield from '@components/textfield/Textfield'
import { useUserLibrary } from '@hooks/useUserLibrary'
import { useSceneState } from '@hooks/useScene'
import { useProjectActions, useProjectState } from '@hooks/useProject'

const SaveMaterialDialog = () => {
  const [inputValue, setInputValue] = useState('')
  const { createUserLibraryAsset } = useUserLibrary()

  const materialColor = useSceneState('materialColor')
  const materialEColorLig = useSceneState('materialEColorLig')
  const materialEColorSha = useSceneState('materialEColorSha')
  const materialEColorTop = useSceneState('materialEColorTop')
  const materialESpecularIntensity = useSceneState('materialESpecularIntensity')
  const materialESpecularSize = useSceneState('materialESpecularSize')
  const materialKeepStylesInSync = useSceneState('materialKeepStylesInSync')
  const materialMetalness = useSceneState('materialMetalness')
  const materialReflective = useSceneState('materialReflective')
  const materialRoughness = useSceneState('materialRoughness')
  const materialIColor = useSceneState('materialIColor')
  const materialIEmissiveColor = useSceneState('materialIEmissiveColor')
  const materialIStrokeSize = useSceneState('materialIStrokeSize')
  const materialIHighlightIntensity = useSceneState(
    'materialIHighlightIntensity'
  )
  const materialIStrokeIntensity = useSceneState('materialIStrokeIntensity')
  const materialIColorVarIntensity = useSceneState('materialIColorVarIntensity')
  const materialIScaleX = useSceneState('materialIScaleX')
  const materialIScaleY = useSceneState('materialIScaleY')
  const materialIAngle = useSceneState('materialIAngle')
  const materialType = useSceneState('materialType')

  const showSaveMaterialDialog = useProjectState('showSaveMaterialDialog')
  const { setShowSaveMaterialDialog } = useProjectActions()

  function saveMaterialAndExit() {
    if (inputValue) {
      createUserLibraryAsset({
        type: 'material',
        name: inputValue,
        document: {
          materialColor,
          materialEColorLig,
          materialEColorSha,
          materialEColorTop,
          materialESpecularIntensity,
          materialESpecularSize,
          materialKeepStylesInSync,
          materialMetalness,
          materialReflective,
          materialRoughness,
          materialIColor,
          materialIEmissiveColor,
          materialIStrokeSize,
          materialIHighlightIntensity,
          materialIStrokeIntensity,
          materialIColorVarIntensity,
          materialIScaleX,
          materialIScaleY,
          materialIAngle,
          materialType
        }
      })
    }

    closeDialog()
  }

  function closeDialog() {
    setShowSaveMaterialDialog(false)
    setInputValue('')
    document.getElementById('canvas')?.focus()
  }

  return (
    <DialogBase underlay open={showSaveMaterialDialog}>
      <Dialog size="s" noDivider>
        <h2 slot="heading">Save custom material</h2>
        <FieldLabel size="m">Material name</FieldLabel>
        <Textfield
          autoFocus
          value={inputValue}
          onInput={e => setInputValue(e.currentTarget.value)}
          onChange={saveMaterialAndExit}
        />

        <ButtonGroup slot="button">
          <Button onClick={closeDialog} variant="secondary">
            Cancel
          </Button>
          <Button onClick={saveMaterialAndExit} variant="primary">
            Save
          </Button>
        </ButtonGroup>
      </Dialog>
    </DialogBase>
  )
}

export default SaveMaterialDialog
