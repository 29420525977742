import { FC, useState } from 'react'
import { Button, Overlay, Popover } from 'ui'
import styles from '@styles/components/StudioAppBarAdminMenu.module.scss'
import { useProjectState } from '@hooks/useProject'
import { useRouter } from 'next/router'
import ProjectDeleteDialog from '@components/project/ProjectDeleteDialog'

export const StudioAppBarAdminMenu: FC = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const projectUuid = useProjectState('projectUuid')
  const { push } = useRouter()

  return (
    <>
      <Button id="admin-menu" quiet className={styles['action-btn']}>
        Admin
      </Button>
      <Overlay placement="bottom-end" offset={16} trigger="admin-menu@click">
        <Popover className={styles['popover']}>
          <div
            className={styles['btn']}
            onClick={() => setShowDeleteDialog(true)}>
            Delete project
          </div>
        </Popover>
      </Overlay>
      {projectUuid && (
        <ProjectDeleteDialog
          show={showDeleteDialog}
          setShow={setShowDeleteDialog}
          textCopy="Are you sure you want to delete this project?"
          projectUuid={projectUuid}
          onDelete={() => push('/discover')}
        />
      )}
    </>
  )
}
