import React, { FC } from 'react'
import styles from '@styles/components/FireflyImage.module.scss'

interface FireflyImageProps {
  alt: string
  imageUrl: string
  onClick: () => void
}

const FireflyImage: FC<FireflyImageProps> = ({ alt, imageUrl, onClick }) => {
  return (
    <div className={styles['container']}>
      <img
        className={styles['image']}
        src={imageUrl}
        alt={alt}
        onClick={onClick}
      />
    </div>
  )
}

export default FireflyImage
