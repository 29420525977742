import React, { FC, PropsWithChildren, ReactNode } from 'react'
import cn from 'classnames'
import { ClassNameProps } from 'types/reactProps'
import styles from '@styles/components/PanelAccordion.module.scss'
import PanelCard from './PanelCard'
import PanelSectionHeader from './PanelSectionHeader'
import ChevronIcon from '/public/s2_icon_chevron_right.svg'
import { ActionButton, Icon, OverlayTrigger, Tooltip } from 'ui'
import { SpIconInfoOutline } from 'workflow-icons'

type Props = PropsWithChildren<ClassNameProps> & {
  id?: string
  label: ReactNode
  collapsed: boolean
  onChange: (collapsed: boolean) => void
  tooltip?: string
}

const PanelAccordion: FC<Props> = ({
  id,
  children,
  className,
  collapsed,
  label,
  onChange,
  tooltip
}) => {
  return (
    <PanelCard
      id={id}
      className={cn(
        styles['container'],
        { [styles['container-collapsed']]: collapsed },
        className
      )}>
      <PanelSectionHeader
        className={styles['label']}
        sectionHeader={label}
        onClick={() => onChange(!collapsed)}>
        {tooltip && (
          <OverlayTrigger placement="top">
            <ActionButton quiet size="s" slot="trigger">
              <SpIconInfoOutline slot="icon" />
            </ActionButton>
            <Tooltip slot="hover-content" delayed>
              {tooltip}
            </Tooltip>
          </OverlayTrigger>
        )}
        <Icon
          className={cn(styles['icon'], {
            [styles['icon-collapsed']]: collapsed
          })}>
          <ChevronIcon />
        </Icon>
      </PanelSectionHeader>
      <div
        className={cn(styles['content-wrapper'], {
          [styles['collapsed-content-wrapper']]: collapsed
        })}>
        <div className={styles['content']}>
          <div>{children}</div>
        </div>
      </div>
    </PanelCard>
  )
}

export default PanelAccordion
