import {
  CreateProjectMutation,
  CreateProjectMutationVariables,
  useCreateProjectMutation
} from '@store/graphql/__generated__/schema'
import { useProjectsPrivateDefault } from './useProjectsPrivateDefault'
import { MutationHookOptions } from '@apollo/client'
import { colors, uniqueNamesGenerator } from 'unique-names-generator'

type MutationOptions = MutationHookOptions<
  CreateProjectMutation,
  Omit<CreateProjectMutationVariables, 'public'>
>

type MutationHookReturnType = ReturnType<typeof useCreateProjectMutation>

export const createNewProjectName = () => {
  return uniqueNamesGenerator({
    dictionaries: [colors]
  })
}

export const useCreateProject: (
  args?: MutationOptions
) => MutationHookReturnType = args => {
  const projectsPrivateDefault = useProjectsPrivateDefault()

  if (!args?.variables?.name || !args?.variables?.tags) {
    throw new Error(
      'Both name and tags must be defined in the useCreateProject variables object'
    )
  }

  return useCreateProjectMutation({
    ...args,
    variables: {
      name: args?.variables?.name,
      tags: args?.variables?.tags,
      public: projectsPrivateDefault
    }
  })
}
