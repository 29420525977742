import React, { FC, PropsWithChildren } from 'react'
import cn from 'classnames'
import { ClassNameProps } from 'types/reactProps'
import styles from '@styles/components/PanelCard.module.scss'

type Props = PropsWithChildren<ClassNameProps> & {
  id?: string
}

const PanelCard: FC<Props> = ({ children, className, id }) => {
  return (
    <div id={id} className={cn(styles['container'], className)}>
      {children}
    </div>
  )
}

export default PanelCard
