import ColorPickerWithTextfield from '@components/colorPickerWithTextfield/ColorPickerWithTextfield'
import { useProjectState } from '@hooks/useProject'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { EngineCommitChange } from '@services/engine/types'
import { EngineMaterialColorKey } from './VariableModeColorPicker'
import { FC } from 'react'
import { useVectorOrPixelMode } from '@hooks/useVectorOrPixelMode'

const colorTypeValues = [
  'Body color',
  'Highlight color',
  'Shadow color'
] as const

type ColorType = (typeof colorTypeValues)[number]

type VectorColorKeys = Extract<
  EngineMaterialColorKey,
  'materialEColorLig' | 'materialEColorSha' | 'materialEColorTop'
>

type IllustrativeColorKeys = Extract<
  EngineMaterialColorKey,
  'materialIColor' | 'materialIEmissiveColor'
>

const vectorColorMap: Record<ColorType, VectorColorKeys> = {
  'Body color': 'materialEColorLig',
  'Highlight color': 'materialEColorTop',
  'Shadow color': 'materialEColorSha'
}

const illustrativeColorMap: Record<
  Extract<ColorType, 'Body color' | 'Shadow color'>,
  IllustrativeColorKeys
> = {
  'Body color': 'materialIColor',
  'Shadow color': 'materialIEmissiveColor'
}

export const SplitColorPicker: FC = () => {
  const sizePreference = useProjectState('sizePreference')
  const { setPropertyState } = useSceneActions()

  const materialEColorTop = useSceneState('materialEColorTop')
  const materialEColorLig = useSceneState('materialEColorLig')
  const materialEColorSha = useSceneState('materialEColorSha')
  const materialIColor = useSceneState('materialIColor')
  const materialIEmissiveColor = useSceneState('materialIEmissiveColor')
  const isExpressiveOrPixelMode = useVectorOrPixelMode()

  const colorMap = isExpressiveOrPixelMode
    ? vectorColorMap
    : illustrativeColorMap

  function updateColors(
    color: string,
    colorKey: EngineMaterialColorKey,
    commit?: EngineCommitChange
  ) {
    setPropertyState({
      key: colorKey,
      value: color,
      commit
    })
  }

  function getCurrentBodyColor() {
    return isExpressiveOrPixelMode ? materialEColorLig : materialIColor
  }

  function getCurrentHighlightColor() {
    return materialEColorTop
  }

  function getCurrentShadowColor() {
    return isExpressiveOrPixelMode ? materialEColorSha : materialIEmissiveColor
  }

  return (
    <>
      <ColorPickerWithTextfield
        size={sizePreference}
        label="Body color"
        color={getCurrentBodyColor()}
        onMouseDown={e =>
          updateColors(
            e.target!.color,
            colorMap['Body color'],
            EngineCommitChange.BEGIN_COMMIT
          )
        }
        onInput={e => updateColors(e.target!.color, colorMap['Body color'])}
        onChange={e =>
          updateColors(
            e.target!.color,
            colorMap['Body color'],
            EngineCommitChange.END_COMMIT
          )
        }
      />
      {isExpressiveOrPixelMode && (
        <ColorPickerWithTextfield
          size={sizePreference}
          label="Highlight color"
          color={getCurrentHighlightColor()}
          onMouseDown={e =>
            updateColors(
              e.target!.color,
              colorMap['Highlight color'],
              EngineCommitChange.BEGIN_COMMIT
            )
          }
          onInput={e =>
            updateColors(e.target!.color, colorMap['Highlight color'])
          }
          onChange={e =>
            updateColors(
              e.target!.color,
              colorMap['Highlight color'],
              EngineCommitChange.END_COMMIT
            )
          }
        />
      )}
      <ColorPickerWithTextfield
        size={sizePreference}
        label="Shadow color"
        color={getCurrentShadowColor()}
        onMouseDown={e =>
          updateColors(
            e.target!.color,
            colorMap['Shadow color'],
            EngineCommitChange.BEGIN_COMMIT
          )
        }
        onInput={e => updateColors(e.target!.color, colorMap['Shadow color'])}
        onChange={e =>
          updateColors(
            e.target!.color,
            colorMap['Shadow color'],
            EngineCommitChange.END_COMMIT
          )
        }
      />
    </>
  )
}
