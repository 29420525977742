import {
  BindingsContainer,
  MutableBoolPropertyBindings,
  TPropertyBindings,
  NumberPropertyBindings,
  MutableNumberPropertyBindings,
  TConverter,
  CameraPoseBindings,
  getWasmModule
} from './bindings'

import { useProperty, useMutableProperty, TMutableProperty } from './property'
import { CameraPosition, CCameraPosition, EngineCamera } from './types'

interface CameraPosePropertyBindings
  extends TPropertyBindings<CameraPoseBindings> {}

declare class CameraControllerBindings {
  frameSelection(): void
  frameAll(): void

  getAutoFocusEnabledProperty(): MutableBoolPropertyBindings
  getCameraOrbitSnappingProperty(): MutableBoolPropertyBindings
  getCameraOrbitSnappingThresholdProperty(): MutableNumberPropertyBindings
  getCameraTypeProperty(): NumberPropertyBindings
  getCameraPoseProperty(): CameraPosePropertyBindings
  cameraAnimating(): boolean

  setOrbitPhi(phi: number): void
  setOrbitTheta(theta: number): void

  orbit(phiDirection: number, thetaDirection: number): boolean

  startOrbitDrag(x: number, y: number, dpi: number, scale: number): void
  orbitDrag(x: number, y: number, temporarySnapping: boolean): void
  endOrbitDrag(x: number, y: number, temporarySnapping: boolean): void
}

class CameraPoseConverter
  implements TConverter<CameraPosition, CameraPoseBindings>
{
  TBoundFromT(position: CameraPosition): CameraPoseBindings {
    let p = new CameraPoseBindings()

    const module = getWasmModule()

    const target = new module['Vec3Bindings'](
      position.target.x,
      position.target.y,
      position.target.z
    )
    p.setTarget(target)
    target.delete()

    p.setRadius(position.view.r)
    p.setPhi(position.view.phi)
    p.setTheta(position.view.theta)

    return p
  }

  TFromTBound(poseBindings: CameraPoseBindings): CameraPosition {
    let p = new CCameraPosition()

    const target = poseBindings.getTarget()
    p.target.x = target.x()
    p.target.y = target.y()
    p.target.z = target.z()
    target.delete()

    p.view.r = poseBindings.getRadius()
    p.view.phi = poseBindings.getPhi()
    p.view.theta = poseBindings.getTheta()

    poseBindings.delete()

    return p
  }
}

const cameraBindingsContainer = new BindingsContainer<CameraControllerBindings>(
  'CameraControllerBindings'
)

export enum SnappedAngleDirection {
  previous = 0,
  stationary = 1,
  next = 2
}

export interface TUseCamera {
  // getters
  cameraType: () => EngineCamera
  cameraPosition: () => CameraPosition
  cameraAnimating: () => boolean

  // setters
  setOrbitPhi: (phi: number) => void
  setOrbitTheta: (theta: number) => void

  // properties
  autoFocusEnabled: () => TMutableProperty<boolean>
  cameraOrbitSnapping: () => TMutableProperty<boolean>
  cameraOrbitSnappingThreshold: () => TMutableProperty<number>

  // actions
  frameSelection: () => void
  frameAll: () => void
  orbit: (
    phiDirection: SnappedAngleDirection,
    thetaDirection: SnappedAngleDirection
  ) => boolean

  // Drag sequence
  startOrbitDrag: (x: number, y: number, dpi: number, scale: number) => void
  orbitDrag: (x: number, y: number, temporarySnapping: boolean) => void
  endOrbitDrag: (x: number, y: number, temporarySnapping: boolean) => void
}

type TUseCameraFunction = () => TUseCamera

export const useCamera: TUseCameraFunction = () => {
  return {
    autoFocusEnabled: () => {
      return useMutableProperty<boolean>(() => {
        return cameraBindingsContainer.get()?.getAutoFocusEnabledProperty()
      })
    },

    cameraOrbitSnapping: () => {
      return useMutableProperty<boolean>(() => {
        return cameraBindingsContainer.get()?.getCameraOrbitSnappingProperty()
      })
    },

    cameraOrbitSnappingThreshold: () => {
      return useMutableProperty<number>(() => {
        return cameraBindingsContainer
          .get()
          ?.getCameraOrbitSnappingThresholdProperty()
      })
    },

    cameraType: function (): EngineCamera {
      const prop = useProperty<EngineCamera>(() => {
        return cameraBindingsContainer.get()?.getCameraTypeProperty()
      })

      return prop ?? EngineCamera.PERSPECTIVE
    },

    cameraPosition: function (): CameraPosition {
      const converter = new CameraPoseConverter()
      const prop = useProperty<CameraPosition, CameraPoseBindings>(() => {
        return cameraBindingsContainer.get()?.getCameraPoseProperty()
      }, converter)

      return prop ?? new CCameraPosition()
    },
    cameraAnimating: () => {
      return cameraBindingsContainer.get()?.cameraAnimating() ?? false
    },
    setOrbitPhi: (phi: number) => {
      cameraBindingsContainer.get()?.setOrbitPhi(phi)
    },
    setOrbitTheta: (theta: number) => {
      cameraBindingsContainer.get()?.setOrbitTheta(theta)
    },
    frameSelection: () => {
      cameraBindingsContainer.get()?.frameSelection()
    },
    frameAll: () => {
      cameraBindingsContainer.get()?.frameAll()
    },
    orbit: (
      phiDirection: SnappedAngleDirection,
      thetaDirection: SnappedAngleDirection
    ) => {
      return (
        cameraBindingsContainer.get()?.orbit(phiDirection, thetaDirection) ??
        false
      )
    },
    startOrbitDrag: (x: number, y: number, dpi: number, scale: number) => {
      cameraBindingsContainer.get()?.startOrbitDrag(x, y, dpi, scale)
    },
    orbitDrag: (x: number, y: number, temporarySnapping: boolean) => {
      cameraBindingsContainer.get()?.orbitDrag(x, y, temporarySnapping)
    },
    endOrbitDrag: (x: number, y: number, temporarySnapping: boolean) => {
      cameraBindingsContainer.get()?.endOrbitDrag(x, y, temporarySnapping)
    }
  }
}
