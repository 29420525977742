import { ThemeProvider } from 'styled-components'
import { lightTheme } from 'amazon-chime-sdk-component-library-react'
import ChimeMeeting from './ChimeMeeting'
import { useChime } from '@hooks/useChime'
import styles from '@styles/components/ChimePanel.module.scss'

import { StatusLight } from 'ui'
import PanelHeader from '@components/panel/PanelHeader'
import PanelContainer from '@components/panel/PanelContainer'

const ChimePanel = () => {
  const { meetingId } = useChime()
  return (
    <PanelContainer>
      <PanelHeader>Video Chat</PanelHeader>
      {meetingId ? (
        <StatusLight className={styles['status-light']} variant="positive">
          Meeting in progress
        </StatusLight>
      ) : (
        <StatusLight className={styles['status-light']} variant="info">
          Meeting not started
        </StatusLight>
      )}

      <div className={styles['meeting-container']}>
        <ThemeProvider theme={lightTheme}>
          <ChimeMeeting />
        </ThemeProvider>
      </div>
    </PanelContainer>
  )
}

export default ChimePanel
