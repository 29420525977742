import React, { FC } from 'react'
import { DocumentEventObserver } from '@services/engine/documentEventObserver'
import {
  PrimitiveIds,
  ToolActivationMode,
  ToolUI,
  useTools
} from '@services/engine/useTools'
import { useUISettings } from '@services/engine/useUISettings'
import LibraryThumbnail from './LibraryThumbnail'
import LibraryThumbnailGrid from './LibraryThumbnailGrid'
import { Link } from 'ui'

interface Props {
  totalItems?: number
  onViewAll?: () => void
}

// TODO get map of primitive thumbnail images
// const PrimitiveThumbnail: Record<(typeof PrimitiveIds)[number], string> = {
//   addPrimitive_Box: '',
//   addPrimitive_Cylinder: '',
//   addPrimitive_Sphere: '',
//   addPrimitive_Cone: '',
//   addPrimitive_Poly: '',
//   addPrimitive_Egg: '',
//   addPrimitive_Torus: '',
//   addPrimitive_Horseshoe: '',
//   addPrimitive_Capsule: '',
//   addPrimitive_HollowCone: '',
//   addPrimitive_Drop: '',
//   addPrimitive_Triangle: '',
//   addPrimitive_Star: '',
//   addPrimitive_Pin: ''
// }

const LibraryThreeDShapesItems: FC<Props> = ({ onViewAll, totalItems }) => {
  const { toolUIs, permanentToolID, setPermanentToolID } = useTools()

  const { setMouseCaptureObject } = useUISettings()

  setMouseCaptureObject({
    enableMouseCapture: (enable: boolean): void => {
      DocumentEventObserver.instance()?.enableMouseCapture(enable)
    }
  })

  const primitiveTools = toolUIs()?.filter((tool: ToolUI | undefined) => {
    const id = tool?.id()
    return PrimitiveIds.some(primID => primID === id)
  })

  const showViewAllLink =
    onViewAll &&
    totalItems &&
    primitiveTools?.length &&
    primitiveTools.length > totalItems

  const permanentToolIDValue = permanentToolID()

  return (
    <>
      <LibraryThumbnailGrid>
        {primitiveTools
          .filter((_, index) => {
            if (typeof totalItems === 'number') {
              return index < totalItems
            } else {
              return true
            }
          })
          .map(tool => {
            if (!tool) return null
            const id = tool?.id()

            return (
              <LibraryThumbnail
                key={id}
                selected={id === permanentToolIDValue}
                draggable
                onDragStart={() => {
                  setPermanentToolID(tool.id(), ToolActivationMode.drag)
                  document.getElementById('canvas')?.focus()
                  return false
                }}
                onClick={() => {
                  setPermanentToolID(tool.id(), ToolActivationMode.standard)
                  document.getElementById('canvas')?.focus()
                }}>
                {/* TODO replace with large thumbnail images */}
                {tool.icon()}
              </LibraryThumbnail>
            )
          })}
      </LibraryThumbnailGrid>

      {showViewAllLink && (
        <Link
          style={{ alignSelf: 'flex-end' }}
          variant="secondary"
          onClick={onViewAll}>
          View all
        </Link>
      )}
    </>
  )
}

export default LibraryThreeDShapesItems
