import React, { FC, useEffect, useRef } from 'react'
import {
  useApplyVideoObjectFit,
  useAudioVideo
} from 'amazon-chime-sdk-component-library-react'
import styles from '@styles/components/VideoTile.module.scss'

interface Props {
  tileId?: number | null
  isVideoEnabled?: boolean
}

const VideoTile: FC<Props> = ({ isVideoEnabled, tileId }) => {
  const audioVideo = useAudioVideo()
  const videoEl = useRef<HTMLVideoElement>(null)
  useApplyVideoObjectFit(videoEl)

  useEffect(() => {
    if (
      !audioVideo ||
      !tileId ||
      !videoEl.current ||
      isVideoEnabled === false
    ) {
      return
    }

    audioVideo.bindVideoElement(tileId, videoEl.current)

    return () => {
      const tile = audioVideo.getVideoTile(tileId)
      if (tile) {
        audioVideo.unbindVideoElement(tileId)
      }
    }
  }, [audioVideo, tileId, isVideoEnabled])

  return <video ref={videoEl} className={styles.video} />
}

export default VideoTile
