import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import cn from 'classnames'
import { ActionMenu, Divider, Icon, MenuDivider, MenuItem } from 'ui'
import styles from '@styles/components/ContextBar.module.scss'
import { useSceneState } from '@hooks/useScene'
import {
  useMediumMinimumSizePreference,
  useProjectActions,
  useProjectState
} from '@hooks/useProject'
import { EngineSelectedSceneNode } from '@services/engine/types'
import CursorOverlay from '@components/cursorOverlay/CursorOverlay'
import SceneContextBar from './SceneContextBar'
import ObjectContextBar from './ObjectContextBar'
import PropertiesIcon from '/public/s2_icon_properties.svg'
import VisibilityIcon from '/public/s2_icon_visibility.svg'
import RevertIcon from '/public/s2_icon_revert.svg'

interface Props {
  show: boolean
}

const ContextBar: FC<Props> = ({ show }) => {
  const flags = useFlags()

  const showSceneNavigator = useProjectState('showSceneNavigator')
  const showOrbitControls = useProjectState('showOrbitControls')
  const isDraggingContextBar = useProjectState('isDraggingContextBar')
  const size = useMediumMinimumSizePreference()
  const { setShowContextBar, setOpenedPanel, setIsDraggingContextBar } =
    useProjectActions()

  const selectedSceneNode = useSceneState('selectedSceneNode')

  const showObjectContextBar =
    selectedSceneNode === EngineSelectedSceneNode.SHAPE ||
    selectedSceneNode === EngineSelectedSceneNode.GROUP ||
    selectedSceneNode === EngineSelectedSceneNode.MULTI_SELECTED

  const showSceneContextBar =
    selectedSceneNode === EngineSelectedSceneNode.PROJECT ||
    selectedSceneNode === EngineSelectedSceneNode.NONE

  const contextBarRef = useRef<HTMLDivElement>(null)

  const [isDocked, setIsDocked] = useState(true)

  const [customPosition, setCustomPosition] = useState<{
    x: number
    y: number
  } | null>(null)

  const customPositionRef = useRef({ x: 0, y: 0 })

  const lastPointerPositionRef = useRef({ x: 0, y: 0 })

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!contextBarRef.current) return

      const containerRef = contextBarRef.current.parentElement
      if (!containerRef) return

      document.body.style.cursor = 'grabbing'

      const containerRect = containerRef.getBoundingClientRect()
      const contextBarRect = contextBarRef.current.getBoundingClientRect()

      const diffX = e.clientX - lastPointerPositionRef.current.x
      const diffY = e.clientY - lastPointerPositionRef.current.y

      let contextBarPositionX = customPositionRef.current.x + diffX
      let contextBarPositionY = customPositionRef.current.y + diffY

      contextBarPositionX = Math.max(
        showOrbitControls ? 50 : 8,
        Math.min(
          contextBarPositionX,
          containerRect.right -
            contextBarRect.width -
            containerRect.left -
            (showSceneNavigator ? 90 : 8)
        )
      )

      contextBarPositionY = Math.max(
        showOrbitControls ? 54 : 8,
        Math.min(
          contextBarPositionY,
          containerRect.height - contextBarRect.height - 8
        )
      )

      lastPointerPositionRef.current = {
        x: e.clientX,
        y: e.clientY
      }

      customPositionRef.current = {
        x: contextBarPositionX,
        y: contextBarPositionY
      }

      setCustomPosition({
        x: contextBarPositionX,
        y: contextBarPositionY
      })
    },
    [showOrbitControls, showSceneNavigator]
  )

  const handleMouseUp = useCallback(() => {
    document.body.style.userSelect = 'auto'
    document.body.style.cursor = 'auto'
    setIsDraggingContextBar(false)
  }, [])

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!contextBarRef.current) return

    const containerRef = contextBarRef.current.parentElement
    if (!containerRef) return

    lastPointerPositionRef.current = {
      x: e.clientX,
      y: e.clientY
    }

    document.body.style.userSelect = 'none'
    setIsDraggingContextBar(true)

    if (!isDocked) {
      document.addEventListener('mouseup', handleMouseUp, { once: true })
      document.addEventListener('mousemove', handleMouseMove)
      return
    }

    const contextBarRect = contextBarRef.current.getBoundingClientRect()
    const { offsetTop, offsetLeft } = contextBarRef.current

    const contextBarPositionX = isDocked
      ? offsetLeft - contextBarRect.width / 2
      : offsetLeft
    const contextBarPositionY = offsetTop

    customPositionRef.current = {
      x: contextBarPositionX,
      y: contextBarPositionY
    }

    setCustomPosition({
      x: contextBarPositionX,
      y: contextBarPositionY
    })
    setIsDocked(false)

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp, { once: true })
  }

  useEffect(() => {
    if (!isDraggingContextBar) {
      document.removeEventListener('mousemove', handleMouseMove)
    }

    return () => {}
  }, [isDraggingContextBar])

  if (!show) return null
  return (
    <div
      ref={contextBarRef}
      className={cn(styles['wrapper'], {
        [styles['docked']]: isDocked
      })}
      style={
        !isDocked && customPosition
          ? { top: customPosition.y, left: customPosition.x }
          : undefined
      }>
      <div className={styles.container}>
        <div
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          className={cn(styles['grab-handle'], {
            [styles['grabbing']]: isDraggingContextBar
          })}
        />

        <div
          className={cn(styles['content'], {
            'no-pointer-events': isDraggingContextBar
          })}>
          {showSceneContextBar && <SceneContextBar />}
          {showObjectContextBar && <ObjectContextBar />}
        </div>

        <Divider size="s" vertical className={styles['divider']} />

        <ActionMenu size={size} quiet className={styles['more-menu']}>
          <MenuItem onClick={() => setOpenedPanel('properties')}>
            <Icon slot="icon">
              <PropertiesIcon />
            </Icon>
            More properties
          </MenuItem>
          <MenuDivider size="s" />
          <MenuItem onClick={() => setShowContextBar(false)}>
            <Icon slot="icon">
              <VisibilityIcon />
            </Icon>
            Hide contextual bar
          </MenuItem>
          <MenuItem onClick={() => setIsDocked(true)}>
            <Icon slot="icon">
              <RevertIcon />
            </Icon>
            Reset bar position
          </MenuItem>
        </ActionMenu>
      </div>

      <CursorOverlay />
    </div>
  )
}

export default memo(
  ContextBar,
  (prevProps, nextProps) => prevProps.show === nextProps.show
)
