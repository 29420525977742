import {
  MeetingManager,
  useVideoInputs
} from 'amazon-chime-sdk-component-library-react'
import { ActionButton, Menu, MenuItem, OverlayTrigger, Popover } from 'ui'

import { SpIconSettings } from 'workflow-icons'

const DeviceSelector = ({
  meetingManager
}: {
  meetingManager: MeetingManager
}) => {
  const { devices, selectedDevice } = useVideoInputs()

  const handleDeviceSelect = ({ target: { value: deviceId } }: any) => {
    meetingManager.selectVideoInputDevice(deviceId)
    meetingManager.startVideoInputDevice(deviceId)
  }

  return (
    <OverlayTrigger>
      <ActionButton
        slot="trigger"
        style={{ paddingTop: 3, borderRadius: 0 }}
        quiet
        size="s">
        <SpIconSettings size="m" />
      </ActionButton>
      <Popover slot="click-content" style={{ position: 'relative' }}>
        <Menu
          selects="single"
          onchange={handleDeviceSelect}
          value={selectedDevice as string}>
          {devices.map(({ deviceId, label }) => (
            <MenuItem
              value={deviceId}
              key={deviceId}
              selected={deviceId === selectedDevice}>
              {label}
            </MenuItem>
          ))}
        </Menu>
      </Popover>
    </OverlayTrigger>
  )
}

export default DeviceSelector
