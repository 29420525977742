import { useEffect, useState } from 'react'

export const useIsMobileLayout = () => {
  const [isMobileLayout, setIsMobileLayout] = useState<boolean>(
    window.matchMedia('(max-width: 650px)').matches
  )

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(max-width: 650px)')

    const handleMediaQueryChange = (event: MediaQueryListEvent) =>
      setIsMobileLayout(event.matches)

    mediaQueryList.addEventListener('change', handleMediaQueryChange)
    return () => {
      mediaQueryList.removeEventListener('change', handleMediaQueryChange)
    }
  }, [])

  return isMobileLayout
}
