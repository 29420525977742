import { ITokenInformation } from '@identity/imslib/adobe-id/custom-types/CustomTypes'
import AdobeIMS from '@services/auth/IMS'

interface ChimeFetchProps {
  path: string
  method: 'POST' | 'GET' | 'DELETE'
  body?: {}
}

async function chimeRequest({ path, method, body }: ChimeFetchProps) {
  const accessToken: ITokenInformation | null =
    await AdobeIMS.getAccessTokenAsync()

  return fetch(
    `${process.env.NEXT_PUBLIC_SERVICE_CORE_API_ENDPOINT}/meetings${path}`,
    {
      method,
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${accessToken?.token!}`,
        'X-Api-Key': process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID
      },
      ...(body && { body: JSON.stringify(body) })
    }
  ).then(r => r.json())
}

export async function createMeeting(projectUuid: string) {
  const { data } = await chimeRequest({
    path: `/`,
    method: 'POST',
    body: {
      project_uuid: projectUuid
    }
  })
  return data.Meeting
}

export async function getMeeting(meetingId: string) {
  const { data } = await chimeRequest({
    path: `/${meetingId}`,
    method: 'GET'
  })
  return data.Meeting
}

export async function addAttendee(meetingId: string, userUuid: string) {
  const { data } = await chimeRequest({
    path: `/${meetingId}/attendees`,
    method: 'POST',
    body: {
      user_uuid: userUuid
    }
  })
  return data.Attendee
}

export async function removeAttendee(meetingId: string, userUuid: string) {
  await chimeRequest({
    path: `/${meetingId}/attendees`,
    method: 'DELETE',
    body: {
      attendee_id: userUuid
    }
  })
}
