import React, { FC, PropsWithChildren } from 'react'
import { ActionButton, Icon } from 'ui'
import cn from 'classnames'
import styles from '@styles/components/PanelHeader.module.scss'
import Typography from '@components/base/Typography/Typography'
import { ClassNameProps } from 'types/reactProps'
import ArrowIcon from '/public/s2_icon_arrow.svg'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

interface Props extends ClassNameProps, PropsWithChildren {
  onBackClick?: () => void
  textAlign?: React.CSSProperties['textAlign']
}

const PanelHeader: FC<Props> = ({
  children,
  className,
  onBackClick,
  textAlign = 'left'
}) => {
  const size = useMediumMinimumSizePreference()

  return (
    <div className={styles['container']}>
      {onBackClick && (
        <ActionButton
          className={styles['back-button']}
          quiet
          size="s"
          onClick={onBackClick}>
          <Icon slot="icon">
            <ArrowIcon />
          </Icon>
        </ActionButton>
      )}
      <Typography
        variant={size === 'm' ? 'h4' : 'h3'}
        className={cn(
          styles['text'],
          {
            'text-align-left': textAlign === 'left',
            'text-align-center': textAlign === 'center',
            'text-align-right': textAlign === 'right'
          },
          className
        )}>
        {children}
      </Typography>
    </div>
  )
}

export default PanelHeader
