import React from 'react'
import { OverlayTrigger, Tooltip, ActionButton, Icon } from 'ui'
import { useSceneActions } from '@hooks/useScene'
import { getCmdModifier } from '@services/engine/utils'
import DuplicateIcon from '/public/s2_icon_duplicate.svg'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

const ObjectDuplicateButton = () => {
  const size = useMediumMinimumSizePreference()

  const { duplicatePrimitive } = useSceneActions()

  const modifier = getCmdModifier()

  return (
    <OverlayTrigger placement="top" offset={0}>
      <ActionButton
        size={size}
        quiet
        slot="trigger"
        onClick={() => {
          duplicatePrimitive()
          document.getElementById('canvas')?.focus()
        }}>
        <Icon slot="icon">
          <DuplicateIcon />
        </Icon>
      </ActionButton>
      <Tooltip slot="hover-content">Duplicate object ({modifier} + D)</Tooltip>
    </OverlayTrigger>
  )
}

export default ObjectDuplicateButton
