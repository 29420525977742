export interface SusiLightElement
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement & SusiLightConfig
  > {
  type: string
  id: string
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'susi-sentry': SusiLightElement
    }
  }
}

export interface SusiLightConfig {
  popup: boolean
  stage: boolean
  config: {
    consentProfile:
      | 'adobe-id-sign-up'
      | 'purchase'
      | 'quote_request'
      | 'trial'
      | 'free'
  }
  authParams: {
    client_id: string
    dt: boolean
    locale: 'en-us' | string
    redirect_uri: string
    response_type: 'token' | string
    scope: string
  }
}

export const configureSusiLight = (redirectUri?: string): SusiLightConfig => ({
  popup: true,
  stage: process.env.NEXT_PUBLIC_ADOBE_IMS_ENV !== 'prd',
  config: {
    consentProfile: 'adobe-id-sign-up'
  },
  authParams: {
    client_id: process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID!,
    dt: false,
    locale: 'en-us',
    redirect_uri:
      redirectUri || (process.env.NEXT_PUBLIC_CLIENT_WEB_HOST as string),
    response_type: 'token',
    scope:
      'AdobeID,openid,pps.read,pps.search' +
      (process.env.NEXT_PUBLIC_ADOBE_IMS_ENV === 'prd' ? '' : `,firefly_api`)
  }
})
