import { useRef } from 'react'

export const useDoubleClick = (onDoubleClick: () => void) => {
  const doubleClickCountRef = useRef(0)
  const doubleClickTimeoutRef = useRef<NodeJS.Timeout>()

  function onClick() {
    doubleClickCountRef.current++

    if (doubleClickCountRef.current === 2) {
      console.log('onDoubleClick')
      onDoubleClick()
      doubleClickCountRef.current = 0
      clearTimeout(doubleClickTimeoutRef.current)
    }

    doubleClickTimeoutRef.current = setTimeout(() => {
      doubleClickCountRef.current = 0
    }, 225)
  }

  return onClick
}
