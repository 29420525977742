import * as Sentry from '@sentry/nextjs'

export function isMacOSX(): boolean {
  return navigator.userAgent.indexOf('Mac OS X') != -1
}

export function getCmdModifier(): string {
  return isMacOSX() ? 'Cmd' : 'Ctrl'
}

export function getAltModifier(): string {
  return isMacOSX() ? 'Option' : 'Alt'
}

export function parseValidEngineJson(data: string) {
  try {
    const json = JSON.parse(data)

    if (typeof json !== 'object' || json === null) {
      throw new Error('JSON must be an object')
    }

    if (Array.isArray(json)) {
      throw new Error('JSON must not be an array')
    }

    if (Object.keys(json).length === 0) {
      throw new Error('JSON object must not be empty')
    }

    return json
  } catch (error) {
    if (error instanceof SyntaxError) {
      throw new Error(`Invalid JSON syntax: ${error.message}`)
    }
    throw error
  }
}

export function traceInvalidJsonError(
  filename: string,
  fn: string,
  data: string,
  customErr?: string
): void {
  const err = new Error(
    customErr ||
      'Caught invalid JSON before emitting engine data (changes not emitted)'
  )

  const trace = {
    filename,
    fn,
    invalid_data: data
  }

  // Send to Sentry
  Sentry.captureException(JSON.stringify(trace))
}

export function degToRad(angle: number): number {
  return (angle * Math.PI) / 180
}

export function radToDeg(angle: number): number {
  return (angle * 180) / Math.PI
}
