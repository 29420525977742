import React, { ComponentProps, FC, ReactNode, useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicTheme = dynamic(
  async () => await import('@swc-react/theme').then(({ Theme }) => Theme),
  {
    ssr: false,
    loading: () => <></>
  }
)

interface Props extends ComponentProps<typeof DynamicTheme> {
  children: ReactNode
}

export const Theme: FC<Props> = ({
  color = 'light',
  theme = 'express',
  scale = 'medium',
  ...props
}) => {
  useEffect(() => {
    import('@spectrum-web-components/theme/src/express/themes.js')
    import('@spectrum-web-components/theme/express/scale-medium.js')
    import('@spectrum-web-components/theme/src/themes.js')
  }, [])

  return <DynamicTheme {...props} scale={scale} color={color} theme={theme} />
}
