import { useLayoutEffect, useState } from 'react'

function detectOS() {
  const userAgent = window.navigator.userAgent
  const platform = window.navigator.platform
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']
  const isMobile =
    /BlackBerry|Windows Phone|iPhone|iPod|Android/i.test(userAgent) &&
    window.innerWidth < 700

  if (isMobile) return 'mobile'
  if (macosPlatforms.indexOf(platform) !== -1) return 'MacOS'
  if (windowsPlatforms.indexOf(platform) !== -1) return 'Windows'
  if (iosPlatforms.indexOf(platform) !== -1) return 'iOS'
  if (/Android/.test(userAgent)) return 'Android'
  if (/Linux/.test(platform)) return 'Linux'
  return 'unknown'
}

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false)

  useLayoutEffect(() => {
    setIsMobile(detectOS() === 'mobile')
  }, [])

  return isMobile
}
