import React from 'react'
import { ActionButton, Icon, Tooltip } from 'ui'
import styles from '@styles/components/Toolbar.module.scss'
import { useUISettings } from '@services/engine/useUISettings'
import DirectSelectIcon from '/public/s2_icon_direct_select.svg'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

const EditShape = () => {
  const { shapeEditionMode } = useUISettings()
  const [getShapeEditionMode, setShapeEditionMode] = shapeEditionMode()
  const isShapeEditionMode = getShapeEditionMode() ?? false

  const size = useMediumMinimumSizePreference()

  return (
    <ActionButton
      size={size}
      selected={isShapeEditionMode}
      onClick={() => {
        setShapeEditionMode(!isShapeEditionMode)
      }}
      aria-label="Toggle Edit Shape">
      <Tooltip selfManaged placement="top" className={styles.tooltip}>
        <div className={styles['tooltip-content']}>V to Select, A to Edit</div>
      </Tooltip>
      <Icon slot="icon">
        <DirectSelectIcon />
      </Icon>
      Edit shape
    </ActionButton>
  )
}

export default EditShape
