type HTMLELementsWithIds =
  | 'studioAppBarDownloadButton'
  | 'shareExportDownloadButton'
  | 'shareExportDialogButton'
  | 'copyLinkButton'
  | 'sendToIllustratorButton'
  | 'duplicateRemixButton'

export const HTMLElementIdsForTracking: Readonly<
  Record<HTMLELementsWithIds, string>
> = {
  // The download button in the app bar (not in the dialog)
  studioAppBarDownloadButton: 'download-export-action-button-trigger',
  // The button in the footer of the download dialog
  shareExportDownloadButton: 'share-export-download-button',
  // the share button to open the dialog in the studio app bar
  shareExportDialogButton: 'share-export-dialog-button',
  // the copy link button in the studio
  copyLinkButton: 'copy-link-button',
  // the send to illustrator as vectors button
  sendToIllustratorButton: 'send-to-illustrator-button',
  // the duplicate button in the remix dialog
  duplicateRemixButton: 'duplicate-remix-button'
}
