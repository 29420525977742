import React from 'react'
import Typography from '@components/base/Typography/Typography'
import { Dialog, DialogBase, ProgressBar } from 'ui'
import styles from '@styles/components/ExportingDialog.module.scss'

const ExportingDialog = () => {
  return (
    <DialogBase underlay open>
      <Dialog size="s" noDivider className={styles['dialog']}>
        <Typography variant="h4">Exporting...</Typography>
        <div className={styles['progress-bar-container']}>
          <ProgressBar indeterminate style={{ width: '100%' }} />
        </div>
      </Dialog>
    </DialogBase>
  )
}

export default ExportingDialog
