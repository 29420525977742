import React, { FC } from 'react'
import { ActionButton, ActionMenu, Icon, MenuItem, Picker } from 'ui'
import cn from 'classnames'
import styles from '@styles/components/FireflyPreview.module.scss'
import DownloadIcon from '/public/s2_icon_download.svg'
import AiStyleIcon from '/public/s2_icon_ai_generative_layer.svg'
import AiGenerateIcon from '/public/s2_icon_ai_generate.svg'
// import ThumbUpIcon from '/public/s2_icon_thumb_up.svg'
// import ThumbDownIcon from '/public/s2_icon_thumb_down.svg'
// import FlagIcon from '/public/s2_icon_flag.svg'
import SparklesIcon from '/public/s2_icon_sparkles.svg'

import { saveAs } from 'file-saver'
import FireflyImageSkeleton from '@components/fireflyPanel/FireflyImageSkeleton'
import { useFirefly } from '@hooks/useFirefly'
import { SpIconMore } from 'workflow-icons'
import { AspectRatio } from '@store/slices/fireflySlice'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

const MenuItems = [
  {
    icon: <AiGenerateIcon />,
    label: 'Generate similar',
    key: 'generate-similar'
  },
  {
    icon: <AiStyleIcon />,
    label: 'Use style as reference',
    key: 'style-reference'
  }
  // {
  //   icon: <ThumbUpIcon />,
  //   label: 'Rate up',
  //   key: 'rate-up'
  // },
  // {
  //   icon: <ThumbDownIcon />,
  //   label: 'Rate down',
  //   key: 'rate-down'
  // },
  // {
  //   icon: <FlagIcon />,
  //   label: 'Report',
  //   key: 'report'
  // }
] as const

const FireflyPreview: FC = () => {
  const size = useMediumMinimumSizePreference()

  const {
    aspectRatio,
    imageGenerationStatus,
    generateSimilarImages,
    selectedGeneratedImage,
    setPropertyState,
    addDownloadURL,
    downloadURLs
  } = useFirefly()

  async function downloadImage() {
    if (!selectedGeneratedImage) return

    let downloadURL = downloadURLs.find(
      ({ originalURL }) => originalURL === selectedGeneratedImage
    )?.downloadURL

    if (!downloadURL) {
      // Can not use image URL from firefly api to download image because using a different origin will open a new tab instead of downloading (https://html.spec.whatwg.org/multipage/links.html#downloading-resources)
      const res = await fetch(selectedGeneratedImage)
      const blob = await res.blob()
      downloadURL = URL.createObjectURL(blob)

      // Add downloadURL for reference if user decides to download again and to revoke URL when it's not used anymore
      addDownloadURL({ originalURL: selectedGeneratedImage, downloadURL })
    }

    saveAs(downloadURL, 'project_neo_adobe_firefly_generated_image.png')
  }

  function onMenuClick({
    target: { value }
  }: {
    target: { value: (typeof MenuItems)[number]['key'] }
  }) {
    switch (value) {
      case 'generate-similar':
        return (
          selectedGeneratedImage &&
          generateSimilarImages({ imgUrl: selectedGeneratedImage })
        )
      case 'style-reference':
        return useGeneratedImgAsStyleRef()
      default:
        break
    }
  }

  function useGeneratedImgAsStyleRef() {
    selectedGeneratedImage &&
      setPropertyState({
        key: 'styleImageReference',
        value: { type: 'generated-output', url: selectedGeneratedImage }
      })
  }

  return (
    <div className={styles['container']}>
      <Picker
        size={size}
        className={cn(
          'picker-min-width',
          'w-auto',
          styles['aspect-ratio-picker']
        )}
        label="Aspect ratio"
        quiet
        value={aspectRatio}
        onchange={e => {
          const value = (e as unknown as { target: { value: AspectRatio } })
            .target.value

          setPropertyState({
            key: 'aspectRatio',
            value
          })
        }}>
        <MenuItem key={AspectRatio.LANDSCAPE} value={AspectRatio.LANDSCAPE}>
          Landscape 4:3
        </MenuItem>
        <MenuItem key={AspectRatio.PORTRAIT} value={AspectRatio.PORTRAIT}>
          Portrait 3:4
        </MenuItem>
        <MenuItem key={AspectRatio.SQUARE} value={AspectRatio.SQUARE}>
          Square 1:1
        </MenuItem>
        <MenuItem key={AspectRatio.WIDESCREEN} value={AspectRatio.WIDESCREEN}>
          Widescreen 16:9
        </MenuItem>
      </Picker>

      {imageGenerationStatus === 'generating-new-images' ? (
        <FireflyImageSkeleton />
      ) : selectedGeneratedImage ? (
        <div className={styles['content']}>
          {selectedGeneratedImage && (
            <>
              <div className={styles['image-wrapper']}>
                <img
                  className={styles['image']}
                  src={selectedGeneratedImage}
                  alt="Project Neo Firefly Image"
                />
              </div>
              <div className={styles['action-button-wrapper']}>
                <ActionButton size={size} onClick={downloadImage}>
                  <Icon className="icon-m" slot="icon">
                    <DownloadIcon />
                  </Icon>
                  Download
                </ActionButton>

                <ActionMenu
                  size={size}
                  onchange={onMenuClick}
                  placement="bottom">
                  <SpIconMore slot="icon" />
                  {MenuItems.map(({ key, icon, label }) => (
                    <MenuItem key={key} value={key}>
                      <Icon className="icon-m" slot="icon">
                        {icon}
                      </Icon>
                      {label}
                    </MenuItem>
                  ))}
                </ActionMenu>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className={styles['empty']}>
          <SparklesIcon />
          <p>Your generated image will appear here</p>
        </div>
      )}
    </div>
  )
}

export default FireflyPreview
