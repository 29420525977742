import React, { ReactNode } from 'react'
import { ActionButton, Dialog, OverlayTrigger, Tooltip } from 'ui'
import Avatar, { Size, getSize } from '@components/avatar/Avatar'
import styles from '@styles/components/AvatarGroup.module.scss'

interface AvatarData<T extends { [key: string]: any }> {
  key: string
  name: string
  src: string
  data: T
  borderColor: string
  tooltip: string
  readOnly?: boolean
}

interface Props<T extends { [key: string]: any }> {
  avatars: AvatarData<T>[]
  hiddenAvatarsDialogContent: (
    hiddenAvatars: AvatarData<T>[],
    closeDialog: () => void
  ) => ReactNode
  maxVisible?: number
  size?: Size
  onAvatarClick: (avatar: AvatarData<T>['data']) => void
}

const AvatarGroup = <T extends { [key: string]: any }>({
  avatars,
  hiddenAvatarsDialogContent,
  maxVisible = 5,
  size = 'S',
  onAvatarClick
}: Props<T>) => {
  const visibleAvatars = avatars.slice(0, maxVisible)
  const remainingAvatars = avatars.length - maxVisible
  const remainingVisibleAvatars = avatars.slice(maxVisible)
  const avatarSize = getSize(size)

  return (
    <div className="flex align-center">
      {visibleAvatars.map((avatar, index) => (
        <div
          className={styles['visible-avatar-container']}
          key={avatar.key}
          style={{
            marginLeft: index === 0 ? 0 : avatarSize / 4
            // zIndex: maxVisible - index, // Uncomment this line to show the first avatar on top - Spectrum Design AvatarGroup
            // position: 'relative', // Uncomment this line to show the first avatar on top - Spectrum Design AvatarGroup
          }}>
          <OverlayTrigger offset={0} placement="bottom">
            <ActionButton
              slot="trigger"
              quiet
              onClick={() => !avatar.readOnly && onAvatarClick(avatar.data)}
              style={{
                padding: 0
              }}>
              <Avatar
                src={avatar.src}
                alt={avatar.name}
                size={size}
                withBorder
                borderColor={avatar.borderColor}
              />
            </ActionButton>
            <Tooltip slot="hover-content">{avatar.tooltip}</Tooltip>
          </OverlayTrigger>
        </div>
      ))}
      {remainingAvatars > 0 && (
        <OverlayTrigger offset={0}>
          <ActionButton slot="trigger" quiet>
            <div
              className={styles['remaining-avatar-container']}
              style={{
                marginLeft: avatarSize / 4,
                width: avatarSize,
                height: avatarSize
              }}>
              <span
                className={styles['remaining-avatar-container__avatar-count']}>
                +{remainingAvatars}
              </span>
            </div>
          </ActionButton>

          <Dialog>
            {hiddenAvatarsDialogContent(remainingVisibleAvatars, close)}
          </Dialog>
        </OverlayTrigger>
      )}
      {/* Uncomment for remaining avatars text - From Spectrum Design AvatarGroup */}
      {/* {remainingAvatars > 0 && (
        <span style={{ marginLeft: 8, fontSize: '12px' }}>
        {remainingAvatars} more {remainingAvatars > 1 ? 'members' : 'member'}
        </span>
      )} */}
    </div>
  )
}

export default AvatarGroup
