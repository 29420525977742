import React, { FC } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { ActionButton, Icon, Tooltip } from 'ui'
import VisibilityIcon from '/public/s2_icon_visibility.svg'
import VisibilityOffIcon from '/public/s2_icon_visibility_off.svg'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

interface Props {
  className?: string
}

const ObjectVisibilityToggle: FC<Props> = ({ className }) => {
  const elements = useSceneState('elements')
  const { togglePrimitiveVisibility } = useSceneActions()

  const size = useMediumMinimumSizePreference()

  const selectedElements = elements.filter(({ selected }) => selected)

  function toggleVisibility() {
    selectedElements.forEach(({ uuid }) => {
      togglePrimitiveVisibility({ uuid })
    })
    document.getElementById('canvas')?.focus()
  }

  if (!selectedElements.length) return null
  return (
    <ActionButton
      size={size}
      quiet
      className={className}
      onclick={toggleVisibility}>
      <Icon slot="icon">
        {selectedElements[selectedElements.length - 1].visible ? (
          <VisibilityIcon />
        ) : (
          <VisibilityOffIcon />
        )}
      </Icon>
      <Tooltip selfManaged placement="top" offset={0}>
        Toggle visibility
      </Tooltip>
    </ActionButton>
  )
}

export default ObjectVisibilityToggle
