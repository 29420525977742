import React, { FC, useState } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { Radio, RadioGroup } from 'ui'
import DialogForm from '@components/base/Dialog/DialogForm'
import Typography from '@components/base/Typography/Typography'
import ShareExportFooter from './ShareExportFooter'
import VideoPreviewButton from './VideoPreviewButton'
import VideoFrameSize from '@components/share/VideoFrameSize'
import VideoAnimationSettings, {
  AnimationSettingsType,
  AnimationTypeValue,
  AnimationTypes,
  QualityOptions
} from './VideoAnimationSettings'

import {
  CodecType,
  MediaIO,
  VideoAnimationParameters,
  makeBoomerangVideoAnimationParameters,
  makeLightingVideoAnimationParameters,
  makeTurnTableVideoAnimationParameters,
  makeVideoEncodingParameters
} from '@services/engine/MediaIO'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

const VideoExportForm: FC<{ projectName: string }> = ({ projectName }) => {
  const minSizePreference = useMediumMinimumSizePreference()

  const [frameSizeError, setFrameSizeError] = useState<string>('')

  const [animationSettingsError, setAnimationSettingsError] =
    useState<string>('')

  const { prepareMediaIO, previewVideoEncoding } = MediaIO

  /* Initialize media hook */
  prepareMediaIO()

  const frameSize = useSceneState('frameSize')
  const canvasAnimationStartedForRecording = useSceneState(
    'canvasAnimationStartedForRecording'
  )
  /* Define state */
  const { setPropertyState } = useSceneActions()

  const [quality, setQuality] = useState(1.0)

  const [codecType, setCodecType] = useState<CodecType>('avc') // for now setting codec type not exposed

  const [animationType, setAnimationType] =
    useState<AnimationTypeValue>('turntable')

  const [animationSettings, setAnimationSettings] = useState<
    AnimationSettingsType<number>
  >({
    turntable: {
      duration: 5,
      numberOfTurns: 1
    },
    boomerang: {
      duration: 8,
      numberOfBackAndForth: 1,
      horizontalAngleAmplitude: 90
    },
    light: {
      duration: 5,
      numberOfTurns: 1
    }
  })

  /* Load parameters into encoder */
  function makeVideoAnimationParameters(): VideoAnimationParameters | null {
    var params: VideoAnimationParameters
    switch (animationType) {
      case 'turntable': {
        const p = makeTurnTableVideoAnimationParameters()
        const { duration, numberOfTurns } = animationSettings.turntable
        p.duration = duration
        p.numberOfTurns = numberOfTurns
        params = p
        break
      }

      case 'boomerang': {
        const p = makeBoomerangVideoAnimationParameters()
        const { duration, numberOfBackAndForth, horizontalAngleAmplitude } =
          animationSettings.boomerang
        p.duration = duration
        p.numberOfBackAndForth = numberOfBackAndForth
        p.horizontalAngleAmplitude = horizontalAngleAmplitude
        params = p
        break
      }

      case 'light': {
        const p = makeLightingVideoAnimationParameters()
        const { duration, numberOfTurns } = animationSettings.light
        p.duration = duration
        p.numberOfTurns = numberOfTurns
        params = p
        break
      }

      default:
        return null
    }

    return params
  }

  /* Define handlers */
  function handleAnimationTypeChange(e: any) {
    const selected = e.target?.selected
    if (selected) setAnimationType(selected)
  }

  function handleQualityChange(e: any) {
    const selected = e.target?.selected
    if (selected) setQuality(selected)
  }

  /* Preview */
  function handlePreview() {
    const animationParams = makeVideoAnimationParameters()
    if (!animationParams) {
      return
    }
    previewVideoEncoding(animationParams)
    animationParams.delete()
  }

  /* Encode and download */
  function handleExport() {
    if (!canvasAnimationStartedForRecording) {
      const animParams = makeVideoAnimationParameters()
      if (!animParams) {
        return
      }

      const encodingParams = makeVideoEncodingParameters()

      encodingParams.codecType = codecType
      encodingParams.width = frameSize.w
      encodingParams.height = frameSize.h
      encodingParams.quality = quality
      encodingParams.framesPerSecond = 30
      encodingParams.fileName = projectName
      encodingParams.shouldUpscale = false

      for (const q of QualityOptions) {
        if (q.value === quality) {
          encodingParams.shouldUpscale = q.upscale
          break
        }
      }

      setPropertyState({
        key: 'videoEncodingParameters',
        value: encodingParams
      })

      setPropertyState({
        key: 'videoAnimationParameters',
        value: animParams
      })

      setPropertyState({
        key: 'canvasAnimationStartedForRecording',
        value: true
      })
    }
  }

  return (
    <>
      <DialogForm>
        <div className="flex flex-row align-center justify-between">
          <Typography variant="h4">Video</Typography>
          <VideoPreviewButton onPreviewClick={handlePreview} />
        </div>
        <RadioGroup
          horizontal
          selected={animationType}
          change={handleAnimationTypeChange}>
          {AnimationTypes.map(({ label, value }) => (
            <Radio key={value} value={value} size={minSizePreference}>
              {label}
            </Radio>
          ))}
        </RadioGroup>
        <Typography variant="h4">Quality</Typography>
        <RadioGroup horizontal selected={quality} change={handleQualityChange}>
          {QualityOptions.map(({ label, value }) => (
            <Radio key={value} value={value} size={minSizePreference}>
              {label}
            </Radio>
          ))}
        </RadioGroup>
        <VideoAnimationSettings
          animationType={animationType}
          animationSettings={animationSettings}
          setAnimationSettings={setAnimationSettings}
          error={animationSettingsError}
          setError={setAnimationSettingsError}
        />
        <VideoFrameSize error={frameSizeError} setError={setFrameSizeError} />
      </DialogForm>
      <ShareExportFooter
        onExportClick={handleExport}
        exportDisabled={
          Boolean(frameSizeError) || Boolean(animationSettingsError)
        }
        buttonText="Render & Download"
      />
    </>
  )
}

export default VideoExportForm
