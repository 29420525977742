import React, { FC, ReactNode } from 'react'
import styles from '@styles/components/FireflyThumbnail.module.scss'
import cn from 'classnames'

import CloseIcon from '/public/s2_icon_close.svg'
import CheckmarkIcon from '/public/s2_icon_checkmark.svg'
import DeleteIcon from '/public/s2_icon_delete.svg'

interface Props {
  children?: ReactNode
  className?: string
  image?: string
  label?: string
  selected?: boolean
  isDeletable?: boolean
  onClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void
  onClose?: () => void
}

const FireflyThumbnail: FC<Props> = ({
  children,
  className,
  image,
  label,
  selected,
  isDeletable,
  onClick,
  onClose
}) => {
  return (
    <div className={cn(styles['container'], className)}>
      <div
        className={cn(styles['thumbnail-container'], {
          [styles['selected']]: selected
        })}>
        {image && (
          <img
            src={image}
            alt={label}
            onClick={onClick}
            className={styles['thumbnail']}
          />
        )}
        {children}
      </div>
      {label && <span className={styles['label']}>{label}</span>}

      {(isDeletable || onClose) && (
        <div
          className={styles['close-icon']}
          onClick={e => {
            e.stopPropagation()
            onClose?.()
          }}>
          {isDeletable ? <DeleteIcon /> : <CloseIcon />}
        </div>
      )}

      {selected && (
        <div className={styles['checkmark-icon']}>
          <CheckmarkIcon />
        </div>
      )}
    </div>
  )
}

export default FireflyThumbnail
