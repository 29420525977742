import {
  BindingsContainer,
  IDeletable,
  ArrayBindings,
  SignalPropertyBindings
} from './bindings'
import { useProperty } from './property'

declare class UuidBindings implements IDeletable {
  get(index: number): number

  delete(): void
}
declare class SelectionControllerBindings {
  currentSelection(): ArrayBindings<UuidBindings>
  selectionChangedProperty(): SignalPropertyBindings

  canGroup(): boolean
  group(): boolean

  canUngroup(): boolean
  ungroup(): boolean
}

const selectionBindingsContainer =
  new BindingsContainer<SelectionControllerBindings>(
    'SelectionControllerBindings'
  )

export class Uuid {
  public constructor(bindings: UuidBindings) {
    this._0 = bindings.get(0)
    this._1 = bindings.get(1)
    this._2 = bindings.get(2)
    this._3 = bindings.get(3)
  }

  toString(): string {
    return (
      '[' + this._0 + ', ' + this._1 + ', ' + this._2 + ', ' + this._3 + ']'
    )
  }

  private _0: number
  private _1: number
  private _2: number
  private _3: number
}

export class Selection {
  public count() {
    return this._uuids.length
  }

  public at(index: number): Uuid {
    return this._uuids[index]
  }

  public add(uuid: Uuid) {
    this._uuids.push(uuid)
  }
  private _uuids = Array<Uuid>()
}

export interface TUseSelection {
  currentSelection(): Selection

  canGroup(): boolean
  group(): boolean

  canUngroup(): boolean
  ungroup(): boolean
}

type TUseSelectionFunction = () => TUseSelection

function getCurrentSelection(): Selection {
  const selBindings = selectionBindingsContainer.get()?.currentSelection()
  if (!selBindings) {
    return new Selection()
  }

  const selection = new Selection()

  const n = selBindings.size()
  for (let i = 0; i < n; ++i) {
    const uuidBindings: UuidBindings = selBindings.get(i)
    const uuid = new Uuid(uuidBindings)
    selection.add(uuid)

    uuidBindings.delete()
  }

  selBindings.delete()

  return selection
}

export const useSelection: TUseSelectionFunction = () => {
  return {
    currentSelection: () => {
      const value = useProperty<undefined>(() => {
        return selectionBindingsContainer.get()?.selectionChangedProperty()
      })

      return getCurrentSelection()
    },

    canGroup: () => {
      return selectionBindingsContainer.get()?.canGroup() ?? false
    },

    group: () => {
      return selectionBindingsContainer.get()?.group() ?? false
    },

    canUngroup: () => {
      return selectionBindingsContainer.get()?.canUngroup() ?? false
    },

    ungroup: () => {
      return selectionBindingsContainer.get()?.ungroup() ?? false
    }
  }
}
