import { ProjectState } from '@store/slices/projectSlice'

export const LibraryViewHeader: Record<
  ProjectState['selectedLibraryView'],
  string
> = {
  'tabs-panel': 'Library',
  'neo-three-d-shapes': '3D shapes',
  'user-background': 'Background',
  'user-material': 'Material'
}
