import { useEffect, useState } from 'react'
import { useTools } from '@services/engine/useTools'
import { useUISettings } from '@services/engine/useUISettings'
import {
  DocumentEventObserver,
  MouseCaptureInfo
} from '@services/engine/documentEventObserver'

export const useIsExportingVideo = () => {
  const { shouldHideOverlayPanels } = useUISettings()

  const { currentToolID } = useTools()
  const toolID = currentToolID()

  const [isExportingVideo, setIsExportingVideo] = useState(
    shouldHideOverlayPanels()
  )

  useEffect(() => {
    setIsExportingVideo(shouldHideOverlayPanels())

    const mouseStateCaptureListener = (info: MouseCaptureInfo | null) => {
      // force refresh on click down, when Exporting the mouse
      setIsExportingVideo(shouldHideOverlayPanels())
    }

    const mouseCaptureInfoListenerID = 'StudioLayoutMouseCaptureInfoListener'

    // mount
    DocumentEventObserver.instance()?.addMouseCaptureInfoListener(
      mouseCaptureInfoListenerID,
      mouseStateCaptureListener
    )

    return () => {
      // unmount
      DocumentEventObserver.instance()?.removeMouseCaptureInfoListener(
        mouseCaptureInfoListenerID
      )
    }
  }, [
    toolID /* making sure to reevaluate show viewport overlay panels status when tool changes */
  ])

  return isExportingVideo
}
