import React, { FC } from 'react'
import styles from '@styles/components/Textfield.module.scss'
import cn from 'classnames'

type Props = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'size' | 'crossOrigin'
> & {
  crossOrigin?: '' | 'anonymous' | 'use-credentials'
  onChange?: () => void
  onEscape?: (e?: React.KeyboardEvent<HTMLInputElement>) => void
  size?: 's' | 'm' | 'l'
}

const Textfield: FC<Props> = ({
  onChange,
  onKeyUp,
  onEscape,
  size = 'm',
  ...props
}) => {
  function handleBlur(e: React.FocusEvent<HTMLInputElement, Element>) {
    props?.onBlur?.(e)
    onChange?.()
  }

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    onKeyUp?.(e)

    console.log('e.key', e.key)

    if (e.key === 'Enter') {
      onChange?.()
    }

    if (e.key === 'Escape') {
      onEscape?.(e)
    }
  }

  return (
    <input
      {...props}
      onBlur={handleBlur}
      onKeyUp={handleKeyUp}
      className={cn(styles['input'], styles[`size-${size}`], props.className)}
    />
  )
}

export default Textfield
