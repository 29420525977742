import React, { FC } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import cn from 'classnames'
import { Icon, Popover } from 'ui'
import styles from '@styles/components/AddObjectMenu.module.scss'
import { ToolUI, useToolEvents, useTools } from '@services/engine/useTools'
import { useUISettings } from '@services/engine/useUISettings'
import { DocumentEventObserver } from '@services/engine/documentEventObserver'
import LinkOutIcon from '/public/s2_icon_link_out.svg'
import CursorOverlay from '@components/cursorOverlay/CursorOverlay'
import { useProjectActions } from '@hooks/useProject'
import { StudioPanel } from '@store/slices/projectSlice'

interface Props {
  closePopover?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const AddObjectMenu: FC<Props> = ({ closePopover }) => {
  const flags = useFlags()

  const primitiveIdsToShow = flags['base-pf-ui-library-assets']
    ? [
        'addPrimitive_Box',
        'addPrimitive_Cylinder',
        'addPrimitive_Sphere',
        'addPrimitive_Cone',
        'addPrimitive_Poly',
        'addPrimitive_Egg',
        'addPrimitive_Torus',
        'addPrimitive_Horseshoe'
      ]
    : [
        'addPrimitive_Box',
        'addPrimitive_Cylinder',
        'addPrimitive_Sphere',
        'addPrimitive_Cone',
        'addPrimitive_Poly',
        'addPrimitive_Egg',
        'addPrimitive_Torus',
        'addPrimitive_Horseshoe',
        'addPrimitive_Capsule',
        'addPrimitive_HollowCone',
        'addPrimitive_Drop',
        'addPrimitive_Triangle',
        'addPrimitive_Star',
        'addPrimitive_Pin'
      ]

  const { toolUIs } = useTools()

  const { setMouseCaptureObject } = useUISettings()

  setMouseCaptureObject({
    enableMouseCapture: (enable: boolean): void => {
      DocumentEventObserver.instance()?.enableMouseCapture(enable)
    }
  })

  const { setSelectedLibraryTab, setSelectedLibraryView, setOpenedPanel } =
    useProjectActions()

  function handleMoreShapesClick(
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    closePopover?.(e)
    setSelectedLibraryView('neo-three-d-shapes')
    setSelectedLibraryTab('neo-library')
    setOpenedPanel(StudioPanel.Library)
  }

  const { handleOnToolClick, handleOnToolDragStart } = useToolEvents()

  return (
    <>
      <Popover className={styles['three-d-shape-menu-popover']}>
        {toolUIs()
          ?.filter((tool: ToolUI | undefined) => {
            const id = tool?.id()
            return primitiveIdsToShow.some(pId => pId === id)
          })
          .map(tool => {
            if (!tool) return null
            const id = tool?.id()
            return (
              <div
                className={styles['menu-item']}
                key={id}
                draggable
                onDragStart={() => handleOnToolDragStart(tool)}
                onDragEnd={e => closePopover?.(e)}
                onClick={e => {
                  closePopover?.(e)
                  handleOnToolClick(tool)
                }}>
                {tool.icon()}
                {tool.tooltipLabel()}
              </div>
            )
          })}
        {flags['base-pf-ui-library-assets'] && (
          <div
            className={cn(styles['menu-item'], styles['more-shapes'])}
            onClick={handleMoreShapesClick}>
            More shapes
            <Icon>
              <LinkOutIcon />
            </Icon>
          </div>
        )}

        <CursorOverlay />
      </Popover>
    </>
  )
}

export default AddObjectMenu
