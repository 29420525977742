import React, { FC, useState } from 'react'
import { saveAs } from 'file-saver'
import { Radio, RadioGroup } from 'ui'
import { useFlags } from 'launchdarkly-react-client-sdk'
import ShareExportFooter from './ShareExportFooter'
import { useSceneActions } from '@hooks/useScene'
import Typography from '@components/base/Typography/Typography'

const PNGQualityOptions = [
  { label: 'High', value: '1' },
  { label: 'Medium', value: '0.7' },
  { label: 'Low', value: '0.5' }
] as const

const BMPQualityOptions = [
  { label: 'Mega HD (16k/8640p)', value: '8640' },
  { label: 'Full Ultra HD (8k/4320p)', value: '4320' },
  { label: 'Ultra HD (4k/2160p)', value: '2160' },
  { label: 'Full HD (2k/1080p)', value: '1080' }
] as const

const FormatOptionsMap = {
  png: PNGQualityOptions,
  jpg: PNGQualityOptions,
  bmp: BMPQualityOptions
} as const

interface Props {
  hidden?: boolean
  format: 'png' | 'jpg' | 'bmp'
}

const ImageExportFormLegacyAPI: FC<Props> = ({ hidden, format }) => {
  const { exportCapture } = useSceneActions()

  const [pngSelectedQuality, setPngSelectedQuality] = useState('1')
  const [jpegSelectedQuality, setJpegSelectedQuality] = useState('1')
  const [bmpSelectedQuality, setBmpSelectedQuality] = useState('8640')

  const selectedQuality =
    format === 'png'
      ? pngSelectedQuality
      : format === 'jpg'
      ? jpegSelectedQuality
      : bmpSelectedQuality

  const setQualityFormatMap = {
    png: setPngSelectedQuality,
    jpg: setJpegSelectedQuality,
    bmp: setBmpSelectedQuality
  }

  const flags = useFlags()
  const bmpEnabled = flags['base-tf-ui-export-big']

  function handleQualityChange(e: any) {
    const selected = e.target?.selected
    if (selected) setQualityFormatMap[format](selected)
  }

  function handleExport() {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement
    if (!canvas) return

    const quality = Number(selectedQuality)

    switch (format) {
      case 'bmp':
        return exportCapture({
          format: 'bmp',
          height: quality,
          workflow: 'download'
        })
      case 'png':
        return saveAs(canvas.toDataURL('image/png', quality), 'neo_export.png')
      case 'jpg':
        return saveAs(canvas.toDataURL('image/jpeg', quality), 'neo_export.jpg')
      default:
        return
    }
  }

  if (hidden) return null
  return (
    <>
      <Typography variant="h4">Quality</Typography>
      <RadioGroup selected={selectedQuality} change={handleQualityChange}>
        {FormatOptionsMap[format].map(({ label, value }) => (
          <Radio key={value} value={value}>
            {label}
          </Radio>
        ))}
      </RadioGroup>
      <ShareExportFooter onExportClick={handleExport} buttonText="Download" />
    </>
  )
}

export default ImageExportFormLegacyAPI
