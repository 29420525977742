import React, { FC } from 'react'
import { Icon, OverlayTrigger, Tooltip } from 'ui'
import { ElementSize } from '@store/slices/projectSlice'
import Badge from '@components/badge/Badge'
import VisibilityIcon from '/public/s2_icon_visibility.svg'

interface Props {
  size?: ElementSize
}

const ViewOnlyBadge: FC<Props> = ({ size = 'm' }) => {
  return (
    <OverlayTrigger offset={0} placement="bottom">
      <Badge slot="trigger" size={size} fill="subtle" variant="notice">
        <Icon size={size}>
          <VisibilityIcon />
        </Icon>
        View only
      </Badge>

      <Tooltip slot="hover-content">
        You are viewing a file shared in the Community. Changes will not be
        saved.
      </Tooltip>
    </OverlayTrigger>
  )
}

export default ViewOnlyBadge
