import React, { FC, useState } from 'react'
import { ActionMenu, Icon, MenuItem, Textfield } from 'ui'
import cn from 'classnames'
import styles from '@styles/components/ChatPanel.module.scss'
import { useSync } from '@hooks/useSync'
import SmileIcon from 'public/smile-icon.svg'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

const Emojis = ['😍', '😱', '🤯'] as const

const ChatInput: FC = () => {
  const size = useMediumMinimumSizePreference()

  const [messageValue, setMessageValue] = useState<string>('')

  const { sendLocalUserSubmitMessageValue } = useSync()

  const handleMessageSubmit = e => {
    if (e.key === 'Enter' && e.target.value !== '') {
      sendLocalUserSubmitMessageValue({ submit_message: e.target.value })
      setMessageValue('')
    }
  }

  return (
    <div className={cn(styles['chat-input'])}>
      <Textfield
        size={size}
        className={cn(styles['chat-input__textfield'])}
        value={messageValue}
        onInput={e => setMessageValue(e.target?.value)}
        onKeyUp={handleMessageSubmit}
      />
      <div className={cn(styles['chat-input__emoji-input'])}>
        <ActionMenu size={size}>
          <Icon slot="icon">
            <SmileIcon />
          </Icon>
          {Emojis.map(emoji => (
            <MenuItem
              key={emoji}
              onClick={() =>
                sendLocalUserSubmitMessageValue({
                  submit_message: emoji
                })
              }>
              {emoji}
            </MenuItem>
          ))}
        </ActionMenu>
      </div>
    </div>
  )
}

export default ChatInput
