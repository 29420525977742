import React, { useEffect, useRef, useState } from 'react'
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { ActionButton, Icon, Tooltip, Truncated } from 'ui'
import styles from './SceneLayersPanel.module.scss'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { usePrevious } from '@hooks/usePrevious'
import {
  ElementIconId,
  EngineStackReorderingMode,
  SceneNavigatorElement
} from '@services/engine/types'
import { useSelection } from '@services/engine/useSelection'
import Panel from '@components/panel/Panel'
import PanelHeader from '@components/panel/PanelHeader'
import SortableElement from './SortableElement'
import DeleteIcon from '/public/s2_icon_delete.svg'
import DuplicateIcon from '/public/s2_icon_duplicate.svg'
import ChevronIcon from '/public/s2_icon_chevron.svg'
import GroupIcon from '/public/s2_icon_group.svg'
import UngroupIcon from '/public/s2_icon_ungroup.svg'
import { useMaxMediumSizePreference } from '@hooks/useProject'

type NormalizedElement = SceneNavigatorElement & { id: string; color: string }

const SceneLayersPanel = () => {
  const listRef = useRef<HTMLUListElement>(null)

  const size = useMaxMediumSizePreference()

  const elementParentName = useSceneState('elementParentName')
  const elements = useSceneState('elements')
  const nodeDepth = useSceneState('nodeDepth')

  const { canGroup, group, canUngroup, ungroup } = useSelection()

  const groupButtonEnabled = canGroup()
  const ungroupButtonEnabled = canUngroup()

  const {
    reorderStackElements,
    selectElement,
    selectElementParentChild,
    setPropertyState,
    togglePrimitiveVisibility,
    deletePrimitive,
    duplicatePrimitive
  } = useSceneActions()

  const isInsideGroup = nodeDepth > 0

  const [isDragging, setIsDragging] = useState(false)

  const normalizedElements = elements
    .map(
      element =>
        ({
          ...element,
          id: element.uuid,
          color: '#' + element.color.toString(16).padStart(6, '0')
        } as NormalizedElement)
    )
    .reverse()

  const prevElements = usePrevious(normalizedElements)

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5
      }
    })
  )

  useEffect(() => {
    scrollToView(normalizedElements, prevElements)
  }, [normalizedElements])

  function scrollToView(
    currElements: NormalizedElement[],
    prevElements?: NormalizedElement[]
  ) {
    if (!prevElements || !listRef.current) return

    const newItemAdded = currElements.length > prevElements.length
    if (newItemAdded) {
      listRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }

    const prevSelectedElements = prevElements.filter(e => e.selected)
    const currSelectedElements = currElements.filter(e => e.selected)
    const newlySelectedElements = currSelectedElements.reduce((curr, e) => {
      if (!prevSelectedElements.some(({ id }) => id === e.id)) {
        curr.push(e)
      }
      return curr
    }, [] as NormalizedElement[])

    if (newlySelectedElements.length) {
      const element: HTMLElement | null = listRef.current.querySelector(
        `#sortable-item-${newlySelectedElements[0]?.id}`
      )

      if (element && !isScrolledIntoView(listRef.current, element)) {
        listRef.current.scrollTo({
          top: element.offsetTop - 50,
          behavior: 'smooth'
        })
      }
    } else if (prevElements.length && currSelectedElements.length) {
      const newReorderedElements = currSelectedElements.filter(e => {
        const oldIndex = prevElements.findIndex(({ id }) => id === e.id)
        const newIndex = currElements.findIndex(({ id }) => id === e.id)
        return oldIndex !== newIndex
      })

      if (newReorderedElements.length) {
        const element: HTMLElement | null = listRef.current.querySelector(
          `#sortable-item-${newlySelectedElements[0]?.id}`
        )

        if (element && !isScrolledIntoView(listRef.current, element)) {
          listRef.current.scrollTo({
            top: element.offsetTop - 50,
            behavior: 'smooth'
          })
        }
      }
    }
  }

  function handleDragStart({ active }: DragStartEvent) {
    const isActiveDragElementSelected = normalizedElements.find(
      ({ id }) => id === active.id
    )?.selected

    if (!isActiveDragElementSelected) {
      selectElement({ uuid: active.id as string, multiSelect: false })
    }
    setIsDragging(true)
  }

  function handleReorder(to: number) {
    const srcIndices = normalizedElements
      .filter(e => e.selected)
      .map(({ id }) => elements.findIndex(element => element.uuid === id))
      .reverse()

    const destIndex = elements.findIndex(
      element => element.uuid === normalizedElements[to].id
    )

    const mode = srcIndices.some(index => index > destIndex)
      ? EngineStackReorderingMode.BEFORE
      : EngineStackReorderingMode.AFTER

    return reorderStackElements({
      srcIndices,
      destIndex,
      mode
    })
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    setIsDragging(false)

    if (!over || active.id === over.id) return

    const newIndex = normalizedElements.findIndex(el => el.id === over.id)
    if (newIndex === -1) return

    handleReorder(newIndex)
  }

  function handleDragCancel() {
    setIsDragging(false)
  }

  function handleSelect(id: string, multiSelect: boolean) {
    const isSelected = normalizedElements.find(e => e.id === id)?.selected
    if (isSelected && multiSelect) return

    selectElement({ uuid: id, multiSelect })
    document.getElementById('canvas')?.focus()
  }

  function handleOpenGroupElements(id: string) {
    const isGroup =
      normalizedElements.find(e => e.id === id)?.iconID === ElementIconId.GROUP
    if (!isGroup) return

    selectElement({ uuid: id, multiSelect: false })
    setTimeout(() => {
      selectElementParentChild(1)
    }, 0)
  }

  function handleSelectParent() {
    selectElementParentChild(-1)
  }

  function handleMoveSelectedObjects(direction: 'up-one' | 'down-one') {
    const targetIds = normalizedElements
      .filter(({ selected }) => selected)
      .map(({ id }) => id)

    const currentIndexes = targetIds
      .map(targetId =>
        normalizedElements.findIndex(({ id }) => id === targetId)
      )
      .filter(idIndex => idIndex !== -1)
      .sort((a, b) => a - b)
    if (!currentIndexes.length) return

    let to: number

    if (direction === 'down-one') {
      const lastObjectCurrentIndex = currentIndexes[currentIndexes.length - 1]

      if (lastObjectCurrentIndex === normalizedElements.length - 1) {
        // Target index out of bounds
        return
      }
      to = lastObjectCurrentIndex + 1
    } else {
      const firstObjectCurrentIndex = currentIndexes[0]

      if (firstObjectCurrentIndex === 0) {
        // Target index out of bounds
        return
      }
      to = firstObjectCurrentIndex - 1
    }

    handleReorder(to)
  }

  return (
    <Panel className={styles['panel']}>
      <PanelHeader
        className={styles['header']}
        onBackClick={isInsideGroup ? handleSelectParent : undefined}>
        {isInsideGroup ? (
          <Truncated style={{ marginBottom: -4 }}>
            {elementParentName || 'Group'}
          </Truncated>
        ) : (
          'Layers'
        )}
      </PanelHeader>

      <div className={styles['controls']}>
        <div className={styles['left-controls']}>
          <ActionButton quiet size={size} onClick={() => duplicatePrimitive()}>
            <Icon slot="icon">
              <DuplicateIcon />
            </Icon>

            <Tooltip selfManaged placement="top" offset={0}>
              Duplicate
            </Tooltip>
          </ActionButton>
          <ActionButton quiet size={size} onClick={() => deletePrimitive()}>
            <Icon slot="icon">
              <DeleteIcon />
            </Icon>

            <Tooltip selfManaged placement="top" offset={0}>
              Delete
            </Tooltip>
          </ActionButton>

          {groupButtonEnabled && (
            <ActionButton quiet size={size} onClick={() => group()}>
              <Icon slot="icon">
                <GroupIcon />
              </Icon>

              <Tooltip selfManaged placement="top" offset={0}>
                Group objects
              </Tooltip>
            </ActionButton>
          )}

          {ungroupButtonEnabled && (
            <ActionButton quiet size={size} onClick={() => ungroup()}>
              <Icon slot="icon">
                <UngroupIcon />
              </Icon>

              <Tooltip selfManaged placement="top" offset={0}>
                Ungroup objects
              </Tooltip>
            </ActionButton>
          )}
        </div>

        <div />
        <ActionButton
          quiet
          size={size}
          onClick={() => handleMoveSelectedObjects('up-one')}>
          <Icon
            slot="icon"
            className={styles['chevron-icon']}
            style={{ transform: 'rotate(180deg)' }}>
            <ChevronIcon />
          </Icon>
        </ActionButton>
        <ActionButton
          quiet
          size={size}
          onClick={() => handleMoveSelectedObjects('down-one')}>
          <Icon slot="icon" className={styles['chevron-icon']}>
            <ChevronIcon />
          </Icon>
        </ActionButton>
      </div>

      {normalizedElements.length ? (
        <div className={styles['content']}>
          <ul className={styles['list']} ref={listRef}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragCancel={handleDragCancel}
              onDragEnd={handleDragEnd}>
              <SortableContext
                items={normalizedElements}
                strategy={verticalListSortingStrategy}>
                {normalizedElements.map(e => (
                  <SortableElement
                    key={e.id}
                    {...e}
                    isDragging={isDragging}
                    onRename={value =>
                      setPropertyState({
                        key: 'elementName',
                        value
                      })
                    }
                    onShowGroupElements={() => handleOpenGroupElements(e.id)}
                    onSelect={multiSelect => handleSelect(e.id, multiSelect)}
                    toggleVisibility={() =>
                      togglePrimitiveVisibility({ uuid: e.id })
                    }
                  />
                ))}
              </SortableContext>
              <DragOverlay dropAnimation={null}>
                {isDragging ? (
                  <div
                    style={{
                      height: 40,
                      width: 318
                    }}
                  />
                ) : null}
              </DragOverlay>
            </DndContext>
          </ul>
        </div>
      ) : null}
    </Panel>
  )
}

export default SceneLayersPanel

function isScrolledIntoView(container: HTMLElement, element: HTMLElement) {
  const containerTop = container.scrollTop
  const containerBottom = containerTop + container.clientHeight

  const elementTop = element.offsetTop
  const elementBottom = elementTop + element.clientHeight

  return elementTop >= containerTop && elementBottom <= containerBottom
}
