import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EngineData } from '@services/engine/types'
import { ExtendedTuple } from 'types/helper'
import { PropertyPayload } from './sceneSlice'

export type UserUuid = string

export interface PresenceUserDataFields {
  is_anonymous: string
  adobe_user_id: string
  adobe_user_email: string
  adobe_user_avatar_url: string
  adobe_user_display_name: string
  preferences: { color: [number, number, number] }
  uuid: UserUuid
}

export type PhoenixPresence = {
  metas: []
  user: PresenceUserDataFields
}

export type PhoenixPresenceList = PhoenixPresence[]

export interface LocalUserMouseMoveEvent {
  x: number
  y: number
}

export interface Message {
  user: PresenceUserDataFields
  message: string
  timestamp: number
}

export interface LocalUserInputMessage {
  input_message: string
}

export interface LocalUserSubmitMessage {
  submit_message: string
}

export interface LocalUserEmojiMessage {
  emoji_message: string
}

export interface RemoteUserMouseMoveEvent {
  remote_user_uuid: UserUuid
  x: number
  y: number
}

export interface RemoteSceneChangeEvent {
  remote_user_uuid: UserUuid
  dataEvent: EngineData
}

export interface RemotePropertyChangeEvent {
  remote_user_uuid: UserUuid
  address: string
  property: PropertyPayload
}

export interface RemoteUserLocationChangeEvent {
  remote_user_uuid: UserUuid
  location: ExtendedTuple<number, 8>
}

export interface RemoteUserInputMessageEvent {
  remote_user_uuid: UserUuid
  input_message: string
}

export interface RemoteUserSubmitMessageEvent {
  remote_user_uuid: string
  submit_message: string
}

export interface RemoteUserEmojiMessageEvent {
  remote_user_uuid: UserUuid
  emoji_message: string
}

export interface PlayerChangeEvent {
  remote_user_uuid: UserUuid
  url?: string
  state?: string
}

export interface SyncState {
  remoteUsers: PhoenixPresenceList
  localUserTextMessageValue: LocalUserInputMessage | null
  localUserEmojiMessageValue: LocalUserInputMessage | null
  showLocalUserTextInput: boolean
  showLocalUserEmojiPicker: boolean
  remoteUserTextMessages: Record<UserUuid, string>
  remoteUserEmojiMessages: Record<UserUuid, string>
  localUserFollowingRemoteUser: PresenceUserDataFields | null
  remoteUsersFollowingLocalUser: PhoenixPresenceList
  messages: Message[]
  playerUrl: string
  playerState: string
}

const initialState: SyncState = {
  remoteUsers: [],
  localUserTextMessageValue: null,
  localUserEmojiMessageValue: null,
  showLocalUserEmojiPicker: false,
  showLocalUserTextInput: false,
  remoteUserTextMessages: {},
  remoteUserEmojiMessages: {},
  localUserFollowingRemoteUser: null,
  remoteUsersFollowingLocalUser: [],
  messages: [],
  playerUrl: '',
  playerState: 'pause'
}

export const sync = createSlice({
  name: 'sync',
  initialState,
  reducers: {
    startSync: () => {},
    sendLocalUserMousePosition: (
      _,
      { payload }: PayloadAction<LocalUserMouseMoveEvent>
    ) => {},
    sendLocalUserInputMessageValue: (
      _,
      { payload }: PayloadAction<LocalUserInputMessage>
    ) => {},
    sendLocalUserSubmitMessageValue: (
      _,
      { payload }: PayloadAction<LocalUserSubmitMessage>
    ) => {},
    sendLocalUserEmojiMessageValue: (
      _,
      { payload }: PayloadAction<LocalUserEmojiMessage>
    ) => {},
    sendLocalUserPlayerChangeEvent: (
      state,
      { payload }: PayloadAction<PlayerChangeEvent>
    ) => {
      if (payload.url) {
        state.playerUrl = payload.url
      }
      if (payload.state) {
        state.playerState = payload.state
      }
    },
    receivedRemoteSceneChangeEvent: (
      _,
      { payload }: PayloadAction<RemoteSceneChangeEvent>
    ) => {},
    receivedRemotePropertyChangeEvent: (
      _,
      { payload }: PayloadAction<RemoteSceneChangeEvent>
    ) => {},
    receivedRemoteUserInputMessageEvent: (
      state,
      { payload }: PayloadAction<RemoteUserInputMessageEvent>
    ) => {
      state.remoteUserTextMessages[payload.remote_user_uuid] =
        payload.input_message
    },
    receivedRemoteUserSubmitMessageEvent: (
      state,
      { payload }: PayloadAction<RemoteUserSubmitMessageEvent>
    ) => {
      const message = {
        user: state.remoteUsers.find(
          u => u.user.uuid === payload.remote_user_uuid
        )?.user!,
        message: payload.submit_message,
        // @@TODO: Should probably send timestamps from the server
        // Messages are ephemeral so it probably doesn't matter too much, but still.
        timestamp: Date.now()
      }
      state.messages = [...state.messages, message]
    },
    receivedRemoteUserEmojiMessageEvent: (
      state,
      { payload }: PayloadAction<RemoteUserEmojiMessageEvent>
    ) => {
      state.remoteUserEmojiMessages[payload.remote_user_uuid] =
        payload.emoji_message
    },
    receivedRemoteUserPlayerChangeEvent: (
      state,
      { payload }: PayloadAction<PlayerChangeEvent>
    ) => {
      if (payload.url) {
        state.playerUrl = payload.url
      }
      if (payload.state) {
        state.playerState = payload.state
      }
    },
    receivedRemoteUserMouseMoveEvent: (
      _,
      { payload }: PayloadAction<RemoteUserMouseMoveEvent>
    ) => {},
    receivedRemoteUserLocationChangeEvent: (
      _,
      { payload }: PayloadAction<RemoteUserLocationChangeEvent>
    ) => {},
    setRemoteUsers: (
      state,
      { payload }: PayloadAction<PhoenixPresenceList>
    ) => {
      state.remoteUsers = payload
    },
    setShowLocalUserEmojiPicker: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showLocalUserEmojiPicker = payload
    },
    setShowLocalUserTextInput: (state, { payload }: PayloadAction<boolean>) => {
      state.showLocalUserTextInput = payload
    },
    setLocalUserFollowingRemoteUser: (
      state,
      { payload }: PayloadAction<SyncState['localUserFollowingRemoteUser']>
    ) => {
      state.localUserFollowingRemoteUser = payload
    },
    setRemoteUsersFollowingLocalUser: (
      state,
      { payload }: PayloadAction<SyncState['remoteUsersFollowingLocalUser']>
    ) => {
      state.remoteUsersFollowingLocalUser = payload
    },
    startRemoteUsersFollowSession: () => {},
    endRemoteUsersFollowSession: () => {},
    clearLocalMessages: state => {
      state.messages = []
    }
  }
})

export const {
  startSync,
  setRemoteUsers,
  receivedRemoteSceneChangeEvent,
  receivedRemotePropertyChangeEvent,
  receivedRemoteUserInputMessageEvent,
  receivedRemoteUserSubmitMessageEvent,
  receivedRemoteUserEmojiMessageEvent,
  receivedRemoteUserMouseMoveEvent,
  receivedRemoteUserLocationChangeEvent,
  receivedRemoteUserPlayerChangeEvent,
  sendLocalUserMousePosition,
  sendLocalUserInputMessageValue,
  sendLocalUserSubmitMessageValue,
  sendLocalUserEmojiMessageValue,
  sendLocalUserPlayerChangeEvent,
  setShowLocalUserTextInput,
  setShowLocalUserEmojiPicker,
  setLocalUserFollowingRemoteUser,
  setRemoteUsersFollowingLocalUser,
  startRemoteUsersFollowSession,
  endRemoteUsersFollowSession,
  clearLocalMessages
} = sync.actions

export default sync.reducer
