import { call, put, select, take, takeEvery } from 'redux-saga/effects'
import { userLoaded } from '@store/slices/authSlice'
import {
  loadProject,
  projectLoaded,
  resetState,
  saveProject,
  setColorPreference,
  setIsPublic,
  setIsSharedProjectToastOpen,
  setSizePreference,
  toggleProjectPublicStatus
} from '@store/slices/projectSlice'
import { PayloadType, RootState } from '@store/store'
import { PayloadAction } from '@reduxjs/toolkit'
import { setEngineState } from '@store/slices/sceneSlice'
import ApolloClient from '@store/graphql/client'
import { createNewProjectName } from '@hooks/useCreateProject'
import {
  CreateProjectDocument,
  CreateProjectMutationResult,
  UpdateProjectDocument,
  UpdateProjectMutationResult
} from '@store/graphql/__generated__/schema'
import {
  handleDocumentSnapshotUpdate,
  isDocumentUpdatable
} from '../document/documentSaga'
import { LocalStorageKey } from 'constants/localStorage'

function* handleLoadProject({
  payload: { requireAuth }
}: PayloadAction<PayloadType<typeof loadProject>>) {
  if (requireAuth) {
    yield call(ensureUserLoaded)
  }

  yield put(projectLoaded())
}

function* ensureUserLoaded() {
  // Sometimes, the user loads before loadProject() gets called
  const { localUser } = yield select((state: RootState) => state.auth)
  if (!localUser) yield take(userLoaded.type)
}

function* handleSetEngineState({
  payload
}: PayloadAction<PayloadType<typeof setEngineState>>) {
  if (payload !== 'UNMOUNTED') return

  yield put(resetState())
}

function* handleSaveProject() {
  const { auth, project }: RootState = yield select((state: RootState) => state)

  const { localUser } = auth
  const { isFeatured, ownerUserUuid } = project

  if (isDocumentUpdatable(localUser, isFeatured, ownerUserUuid)) {
    yield handleDocumentSnapshotUpdate()
  }
}

function* handleSetColorPreference({
  payload
}: PayloadAction<PayloadType<typeof setColorPreference>>) {
  yield localStorage.setItem(LocalStorageKey.appPreferenceColor, payload)
}

function* handleSetSizePreference({
  payload
}: PayloadAction<PayloadType<typeof setSizePreference>>) {
  yield localStorage.setItem(LocalStorageKey.appPreferenceSize, payload)
}

function* handleToggleProjectPublicStatus() {
  const { isPublic, projectUuid }: RootState['project'] = yield select(
    (state: RootState) => state.project
  )
  if (!projectUuid) return

  const newPublicStatus = !isPublic

  yield put(setIsPublic(newPublicStatus))
  yield call(setProjectPublicStatus, projectUuid, newPublicStatus)
}

async function setProjectPublicStatus(uuid: string, isPublic: boolean) {
  return await ApolloClient.mutate<UpdateProjectMutationResult['data']>({
    mutation: UpdateProjectDocument,
    variables: {
      uuid,
      public: isPublic
    }
  })
}

export default function* projectSaga() {
  yield takeEvery(loadProject.type, handleLoadProject)
  yield takeEvery(setEngineState.type, handleSetEngineState)
  yield takeEvery(saveProject.type, handleSaveProject)
  yield takeEvery(setColorPreference.type, handleSetColorPreference)
  yield takeEvery(setSizePreference.type, handleSetSizePreference)
  yield takeEvery(
    toggleProjectPublicStatus.type,
    handleToggleProjectPublicStatus
  )
}
