import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useState
} from 'react'
import { FieldLabel } from 'ui'
import Typography from '@components/base/Typography/Typography'
import VideoFormNumberField, {
  Validator
} from '@components/propertiesPanel/VideoFormNumberField'
import styles from '@styles/components/VideoExportDialog.module.scss'
import { MediaIO } from '@services/engine/MediaIO'

export type AnimationTypeValue = 'turntable' | 'boomerang' | 'light'

export const AnimationTypes = [
  { label: 'Turntable', value: 'turntable' },
  { label: 'Boomerang', value: 'boomerang' },
  { label: 'Shadow play', value: 'light' }
] as const

export const QualityOptions = [
  { label: 'High', value: 1.0, upscale: true },
  { label: 'Medium', value: 0.5, upscale: false },
  { label: 'Low', value: 0.3, upscale: false }
] as const
export interface AnimationSettingsType<T> {
  turntable: {
    duration: T
    numberOfTurns: T
  }
  boomerang: {
    duration: T
    numberOfBackAndForth: T
    horizontalAngleAmplitude: T
  }
  light: {
    duration: T
    numberOfTurns: T
  }
}

export type AnimationValidationError = AnimationSettingsType<string>

interface VideoAnimationSettingsProps {
  animationType: AnimationTypeValue
  animationSettings: AnimationSettingsType<number>
  setAnimationSettings: Dispatch<SetStateAction<AnimationSettingsType<number>>>
  error: string
  setError: Dispatch<SetStateAction<string>>
}

const VideoAnimationSettings: FC<VideoAnimationSettingsProps> = ({
  animationType,
  animationSettings,
  setAnimationSettings,
  error,
  setError
}) => {
  const [validationError, setValidationError] =
    useState<AnimationValidationError>({
      turntable: {
        duration: '',
        numberOfTurns: ''
      },
      boomerang: {
        duration: '',
        horizontalAngleAmplitude: '',
        numberOfBackAndForth: ''
      },
      light: {
        duration: '',
        numberOfTurns: ''
      }
    })

  function shouldDisplayError() {
    const keys = validationError[animationType]
    const errors = Object.values(keys)
    return errors.some(err => err !== '')
  }

  const fields = {
    duration: {
      label: 'Duration (s)',
      id: 'video-animation-duration'
    },

    ...((animationType === 'turntable' || animationType === 'light') &&
      typeof animationSettings[animationType].numberOfTurns === 'number' && {
        numberOfTurns: {
          label: 'Rotations',
          id: 'video-animation-rotation'
        }
      }),

    ...(animationType === 'boomerang' &&
      typeof animationSettings[animationType].numberOfBackAndForth ===
        'number' && {
        numberOfBackAndForth: {
          label: 'Loops',
          id: 'video-animation-loop'
        }
      }),

    ...(animationType === 'boomerang' &&
      typeof animationSettings[animationType].horizontalAngleAmplitude ===
        'number' && {
        horizontalAngleAmplitude: {
          label: 'Angle',
          id: 'video-animation-angle'
        }
      })
  }

  const {
    getRotationValidators,
    getBackAndForthValidators,
    getAngleValidators,
    getDurationValidators
  } = MediaIO

  function getValidators(value: number): AnimationSettingsType<Validator[]> {
    return {
      turntable: {
        duration: getDurationValidators(value),
        numberOfTurns: getRotationValidators(value)
      },
      boomerang: {
        duration: getDurationValidators(value),
        numberOfBackAndForth: getBackAndForthValidators(value),
        horizontalAngleAmplitude: getAngleValidators(value)
      },
      light: {
        duration: getDurationValidators(value),
        numberOfTurns: getRotationValidators(value)
      }
    }
  }

  const validators = useCallback(
    (value: number, type: string) => {
      return getValidators(value)[animationType][type]
    },
    [animationSettings]
  )

  function updateAnimationSettings(key: string, value: number) {
    setAnimationSettings((prevState: any) => ({
      ...prevState,
      [animationType]: {
        ...prevState[animationType],
        [key]: value
      }
    }))
  }

  function handleOnError(key: string, error: string) {
    setValidationError({
      ...validationError,
      [animationType]: {
        ...validationError[animationType],
        [key]: error
      }
    })

    setError(error)
  }

  return (
    <div className="flex flex-col">
      <Typography variant="h4">Animation settings</Typography>
      <div className="flex">
        {Object.keys(fields).map(key => {
          const field = fields[key]
          return (
            <div
              key={`${animationType}-${key}-container`}
              style={{ marginRight: '0.6em' }}
              className="flex flex-col">
              <FieldLabel key={`${animationType}-${key}-label`} for={field.id}>
                {field.label}
              </FieldLabel>
              <VideoFormNumberField
                key={`${animationType}-${key}-input`}
                id={field.id}
                aria-label={field.id}
                positiveOnly
                step={1}
                value={animationSettings[animationType][key]}
                setValue={(value: number) =>
                  updateAnimationSettings(key, value)
                }
                validators={validators(
                  animationSettings[animationType][key],
                  key
                )}
                isError={Boolean(error)}
                onError={(error: string) => {
                  handleOnError(key, error)
                }}
                minWidth={75}
              />
            </div>
          )
        })}
      </div>
      {shouldDisplayError() && error && <p className={styles.error}>{error}</p>}
    </div>
  )
}

export default VideoAnimationSettings
