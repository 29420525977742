import { FC, ReactNode } from 'react'
import styles from '@styles/components/Compatibility.module.scss'

interface ImageCardProps {
  image: string
  altText: string
  imageContainerStyle?: React.CSSProperties
  imageStyle?: React.CSSProperties
}

const ImageCard: FC<ImageCardProps> = ({
  image,
  imageContainerStyle,
  imageStyle,
  altText
}) => {
  return (
    <div className={styles['image-card-container']} style={imageContainerStyle}>
      <img
        src={image}
        alt={altText}
        className={styles['image']}
        style={imageStyle}
      />
    </div>
  )
}

interface TextCardProps {
  heading: ReactNode
  description: ReactNode
  textContainerStyle?: React.CSSProperties
}

const TextCard: FC<TextCardProps> = ({
  heading,
  description,
  textContainerStyle
}) => {
  return (
    <div className={styles['text-card-container']} style={textContainerStyle}>
      {heading}
      {description}
    </div>
  )
}

interface CompatibilityWarningProps {
  containerStyle?: React.CSSProperties
  image: string
  altText: string
  heading: ReactNode
  description: ReactNode
}

const CompatibilityWarning: FC<CompatibilityWarningProps> = ({
  image,
  altText,
  heading,
  description,
  containerStyle
}) => {
  return (
    <div
      className={styles['compatibility-warning-container']}
      style={containerStyle}>
      <ImageCard image={image} altText={altText} />
      <TextCard heading={heading} description={description} />
    </div>
  )
}

export default CompatibilityWarning
