import { FC, useState } from 'react'
import { DialogBase, Dialog, Button, Checkbox, FieldGroup } from 'ui'
import { useProject } from '@hooks/useProject'
import {
  LocalStorageBooleanValue,
  LocalStorageKey
} from 'constants/localStorage'

const TouchDeviceWarningDialog: FC = () => {
  const { showTouchDeviceWarningDialog, setShowTouchDeviceWarningDialog } =
    useProject()

  const [checked, setChecked] = useState(false)

  function handleClose() {
    setShowTouchDeviceWarningDialog(false)
    localStorage.setItem(
      LocalStorageKey.engineHideTouchDeviceWarningDialog,
      checked ? LocalStorageBooleanValue.TRUE : LocalStorageBooleanValue.FALSE
    )
  }

  return (
    <DialogBase underlay open={showTouchDeviceWarningDialog}>
      <Dialog size="s">
        <h2 slot="heading">Touch-only devices</h2>
        <p>
          Project Neo Studio does not support touch-only devices yet, please use
          mouse and keyboard.
        </p>
        <FieldGroup>
          <Checkbox
            size="m"
            checked={checked}
            change={() => setChecked(!checked)}>
            Don't show this message again
          </Checkbox>
        </FieldGroup>
        <Button onClick={handleClose} variant="primary" slot="button">
          Close
        </Button>
      </Dialog>
    </DialogBase>
  )
}

export default TouchDeviceWarningDialog
