import React, { FC, useMemo, useState } from 'react'
import {
  ActionButton,
  ActionGroup,
  Tooltip,
  Divider,
  Icon,
  Overlay,
  OverlayCloseEvent
} from 'ui'
import styles from '@styles/components/Toolbar.module.scss'
import {
  PrimitiveIds,
  ToolUI,
  useToolEvents,
  useTools
} from '@services/engine/useTools'
import { useUISettings } from '@services/engine/useUISettings'
import { DocumentEventObserver } from '@services/engine/documentEventObserver'
import CubeIcon from '/public/s2_icon_cube.svg'
import AddObjectMenu from './AddObjectMenu'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

const Toolbar: FC = () => {
  const size = useMediumMinimumSizePreference()

  const { toolUIs, permanentToolID, isToolDraggable } = useTools()

  const { setMouseCaptureObject } = useUISettings()

  setMouseCaptureObject({
    enableMouseCapture: (enable: boolean): void => {
      DocumentEventObserver.instance()?.enableMouseCapture(enable)
    }
  })

  const permanentToolIDValue = permanentToolID()

  const [showAddObjectPopover, setShowAddObjectPopover] = useState(false)

  const isThreeDShapeToolActive = PrimitiveIds.some(
    primId => primId === permanentToolIDValue
  )

  const tools = toolUIs()

  const editAndSelectTools = useMemo(
    () =>
      tools?.filter((tool: ToolUI | undefined) => {
        const id = tool?.id()
        return id === 'select' || id === 'editShape'
      }),
    [tools]
  )

  const navigationTools = useMemo(
    () =>
      tools?.filter((tool: ToolUI | undefined) => {
        const id = tool?.id()
        switch (id) {
          case 'pan':
          case 'zoom':
          case 'orbit':
            return true
          default:
            return false
        }
      }),
    [tools]
  )

  const textTool = useMemo(
    () =>
      tools?.filter((tool: ToolUI | undefined) => {
        const id = tool?.id()
        return id === 'addPrimitive_Text'
      })[0],
    [tools]
  )

  const { handleOnToolClick, handleOnToolDragStart } = useToolEvents()

  function closePopover(e?: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    setShowAddObjectPopover(false)
    e?.currentTarget.dispatchEvent(OverlayCloseEvent)
  }

  return (
    <>
      <ActionGroup
        selects="single"
        emphasized
        selected={permanentToolIDValue}
        size={size}
        vertical
        quiet>
        {editAndSelectTools.map((tool: ToolUI | undefined, index) => {
          if (!tool) return null

          return (
            <ActionButton
              size={size}
              value={tool.id()}
              key={tool.id()}
              draggable={isToolDraggable(tool.id())}
              onClick={() => handleOnToolClick(tool)}
              ondragstart={() => handleOnToolDragStart(tool)}>
              {tool.icon()}
              <Tooltip selfManaged placement="right" className={styles.tooltip}>
                <div className={styles['tooltip-content']}>
                  {tool.tooltipLabel()}
                </div>
              </Tooltip>
            </ActionButton>
          )
        })}
      </ActionGroup>

      <ActionButton
        size={size}
        holdAffordance
        onClick={() => setShowAddObjectPopover(true)}
        quiet
        emphasized
        id="three-d-shape-menu-trigger"
        selected={showAddObjectPopover || isThreeDShapeToolActive}>
        <Icon slot="icon">
          <CubeIcon />
        </Icon>
      </ActionButton>

      <Overlay
        trigger="three-d-shape-menu-trigger@click"
        offset={8}
        placement="right-start"
        open={showAddObjectPopover}
        spOpened={() => setShowAddObjectPopover(true)}
        spClosed={() => setShowAddObjectPopover(false)}>
        <AddObjectMenu closePopover={closePopover} />
      </Overlay>

      {textTool && (
        <ActionButton
          size="m"
          quiet
          draggable
          onClick={() => {
            handleOnToolClick(textTool)
          }}
          onDragStart={() => handleOnToolDragStart(textTool)}>
          {textTool.icon()}
          <Tooltip selfManaged placement="right">
            {textTool.tooltipLabel()}
          </Tooltip>
        </ActionButton>
      )}

      <Divider size="m" style={{ width: 20 }} />

      <ActionGroup
        selects="single"
        emphasized
        selected={permanentToolIDValue}
        size={size}
        vertical
        quiet>
        {navigationTools.map((tool: ToolUI | undefined) => {
          if (!tool) return null

          return (
            <ActionButton
              value={tool.id()}
              key={tool.id()}
              draggable={isToolDraggable(tool.id())}
              onClick={() => handleOnToolClick(tool)}
              ondragstart={() => handleOnToolDragStart(tool)}>
              {tool.icon()}
              <Tooltip selfManaged placement="right" className={styles.tooltip}>
                <div className={styles['tooltip-content']}>
                  {tool.tooltipLabel()}
                </div>
              </Tooltip>
            </ActionButton>
          )
        })}
      </ActionGroup>
    </>
  )
}

export default Toolbar
