import { BindingsContainer, IDeletable } from './bindings'

import { StringPropertyBindings, ArrayBindings } from './bindings'
import { useProperty } from './property'

import { Icon } from 'ui'

import SelectIcon from '/public/s2_icon_select.svg'
import DirectSelectIcon from '/public/s2_icon_direct_select.svg'
import HandIcon from '/public/s2_icon_hand.svg'
import SearchIcon from '/public/s2_icon_search.svg'
import OrbitIcon from '/public/s2_icon_orbit.svg'

import S2CubeIcon from '/public/s2_icon_cube.svg'
import S2CylinderIcon from '/public/s2_icon_cylinder.svg'
import S2SphereIcon from '/public/s2_icon_sphere.svg'
import S2CapsuleIcon from '/public/s2_icon_capsule.svg'
import S2DropIcon from '/public/s2_icon_3d_drop.svg'
import S2ShoehornIcon from '/public/s2_icon_3d_u_shape.svg'
import S2WedgeIcon from '/public/s2_icon_3d_wedge.svg'
import S2PolygonIcon from '/public/s2_icon_3d_polygon.svg'
import S2TorusIcon from '/public/s2_icon_torus.svg'
import S2ConeIcon from '/public/s2_icon_cone.svg'
import S2StarIcon from '/public/s2_icon_3d_star.svg'
import EggIcon from '/public/icon_volume_egg.svg'
import PinIcon from '/public/icon_volume_pin.svg'
import TextIcon from '/public/s2_icon_text.svg'
import styles from '@styles/components/Toolbar.module.scss'

import MouseButtonLeftIcon from '/public/icon_mouse_button_left.svg'
import MouseButtonMiddleIcon from '/public/icon_mouse_button_middle.svg'
import MouseButtonRightIcon from '/public/icon_mouse_button_right.svg'
import { ReactNode } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

type ToolID = string

const selectToolID = 'select'

export interface ToolUI {
  id(): ToolID
  icon(): any
  tooltipLabel(): ReactNode
}

class SelectToolUI implements ToolUI {
  id(): ToolID {
    return selectToolID
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <SelectIcon />
      </Icon>
    )
  }

  tooltipLabel(): string {
    return 'Select (V)'
  }
}

class EditShapeToolUI implements ToolUI {
  id(): ToolID {
    return 'editShape'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <DirectSelectIcon />
      </Icon>
    )
  }

  tooltipLabel(): string {
    return 'Edit Shape (A)'
  }
}

class PanToolUI implements ToolUI {
  id(): ToolID {
    return 'pan'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <HandIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return (
      <>
        <div>Pan (H)</div>
        <div>or</div>
        <div className={styles['space']}>Space</div>
        <div>+</div>
        <MouseButtonLeftIcon />
      </>
    )
  }
}

class ZoomToolUI implements ToolUI {
  id(): ToolID {
    return 'zoom'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <SearchIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return (
      <>
        <div>Zoom (Z)</div>
        <div>or</div>
        <div>Scroll&nbsp;</div>
        <MouseButtonMiddleIcon />
      </>
    )
  }
}

class OrbitToolUI implements ToolUI {
  id(): ToolID {
    return 'orbit'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <OrbitIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return (
      <>
        <div>Turn around (O)</div>
        <div>or</div>
        <div>Drag&nbsp;</div>
        <MouseButtonRightIcon />
      </>
    )
  }
}

class AddBoxToolUI implements ToolUI {
  id(): ToolID {
    return 'addPrimitive_Box'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <S2CubeIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return <div>Cube</div>
  }
}

class AddCylinderToolUI implements ToolUI {
  id(): ToolID {
    return 'addPrimitive_Cylinder'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <S2CylinderIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return <div>Cylinder</div>
  }
}

class AddSphereToolUI implements ToolUI {
  id(): ToolID {
    return 'addPrimitive_Sphere'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <S2SphereIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return <div>Sphere</div>
  }
}

class AddCapsuleToolUI implements ToolUI {
  id(): ToolID {
    return 'addPrimitive_Capsule'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <S2CapsuleIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return <div>Capsule</div>
  }
}

class AddHollowConeToolUI implements ToolUI {
  id(): ToolID {
    return 'addPrimitive_HollowCone'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <S2ConeIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return <div>Hollow cone</div>
  }
}

class AddDropToolUI implements ToolUI {
  id(): ToolID {
    return 'addPrimitive_Drop'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <S2DropIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return <div>Drop</div>
  }
}

class AddTriangleToolUI implements ToolUI {
  id(): ToolID {
    return 'addPrimitive_Triangle'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <S2WedgeIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return <div>Triangle</div>
  }
}

class AddPolygonToolUI implements ToolUI {
  id(): ToolID {
    return 'addPrimitive_Poly'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <S2PolygonIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return <div>Polygon</div>
  }
}

class AddShoeHornToolUI implements ToolUI {
  id(): ToolID {
    return 'addPrimitive_Horseshoe'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <S2ShoehornIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return <div>Shoe horn</div>
  }
}

class TorusToolUI implements ToolUI {
  id(): ToolID {
    return 'addPrimitive_Torus'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <S2TorusIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return <div>Torus</div>
  }
}

class ConeToolUI implements ToolUI {
  id(): ToolID {
    return 'addPrimitive_Cone'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <S2ConeIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return <div>Cone</div>
  }
}

class StarToolUI implements ToolUI {
  id(): ToolID {
    return 'addPrimitive_Star'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <S2StarIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return <div>Star</div>
  }
}

class EggToolUI implements ToolUI {
  id(): ToolID {
    return 'addPrimitive_Egg'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <EggIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return <div>Egg</div>
  }
}

class PinToolUI implements ToolUI {
  id(): ToolID {
    return 'addPrimitive_Pin'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <PinIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return <div>Pin</div>
  }
}

class TextToolUI implements ToolUI {
  id(): ToolID {
    return 'addPrimitive_Text'
  }

  icon(): any {
    return (
      <Icon slot="icon">
        <TextIcon />
      </Icon>
    )
  }

  tooltipLabel(): ReactNode {
    return <>Add text</>
  }
}

export enum ToolActivationMode {
  standard = 0, // click on a tool icon, keyboard
  drag = 1
}

declare class ToolsControllerBindings {
  getCurrentToolID(): ToolID
  isToolTransient(id: ToolID): boolean
  isToolDraggable(id: ToolID): boolean

  getPermanentToolID(): ToolID
  setPermanentToolID(id: ToolID, mode: ToolActivationMode): void

  // for index.html only so could omitted from this interface
  // left here for sanity
  activateAddPermanentTool(primitivePresetAsInt: number): void

  getCurrentToolIDProperty(): StringPropertyBindings

  getToolIDs(): ArrayBindings<ToolID>
}

const toolsBindingsContainer = new BindingsContainer<ToolsControllerBindings>(
  'ToolsControllerBindings'
)

export interface TUseTools {
  permanentToolID: () => ToolID
  setPermanentToolID: (id: ToolID, mode: ToolActivationMode) => void

  currentToolID: () => ToolID

  isToolTransient: (id: ToolID) => boolean
  isToolDraggable: (id: ToolID) => boolean

  toolUIs: () => Array<ToolUI | undefined /* undefined for separator */>
}

type TUseToolsFunction = () => TUseTools

export const useTools: TUseToolsFunction = () => {
  const flags = useFlags()

  return {
    permanentToolID: () => {
      return toolsBindingsContainer.get()?.getPermanentToolID() ?? selectToolID
    },
    toolUIs: () => {
      const toolIDsArray = toolsBindingsContainer.get()?.getToolIDs()
      if (!toolIDsArray) {
        return []
      }

      const toolIDs = new Set<ToolID>()

      const toolsCount = toolIDsArray.size()
      for (var i = 0; i < toolsCount; ++i) {
        const id = toolIDsArray.get(i)
        toolIDs.add(id)
      }

      const uis = [
        new SelectToolUI(),
        new EditShapeToolUI(),
        new PanToolUI(),
        new ZoomToolUI(),
        new OrbitToolUI(),

        undefined,

        new AddBoxToolUI(),
        new AddCylinderToolUI(),
        new AddSphereToolUI(),
        new AddCapsuleToolUI(),
        new AddHollowConeToolUI(),
        new AddDropToolUI(),
        new AddTriangleToolUI(),
        new AddPolygonToolUI(),
        new AddShoeHornToolUI(),
        new TorusToolUI(),
        new ConeToolUI(),
        new StarToolUI(),
        flags['base-tf-toolbar-egg-primitive'] && new EggToolUI(),
        flags['base-tf-toolbar-pin-primitive'] && new PinToolUI(),
        flags['base-pf-ui-text-object'] && new TextToolUI()
      ]

      const filteredUIs = new Array<ToolUI | undefined>()

      uis.forEach(ui => {
        if (typeof ui === 'undefined') {
          filteredUIs.push(undefined)
        } else if (ui) {
          const id = ui.id()

          if (toolIDs.has(id)) {
            filteredUIs.push(ui)
          } else {
            // UI not mapped to any of the
            // tools provided by the engine
            console.log('tool ID=' + id + ' not found in engine')
          }
        }
      })

      toolIDsArray.delete()

      return filteredUIs
    },
    setPermanentToolID: (toolID: ToolID, mode: ToolActivationMode) => {
      toolsBindingsContainer.get()?.setPermanentToolID(toolID, mode)
    },
    currentToolID: function (): ToolID {
      const value = useProperty<ToolID>(() => {
        return toolsBindingsContainer.get()?.getCurrentToolIDProperty()
      })

      return value ?? selectToolID
    },

    isToolTransient: function (toolID: ToolID): boolean {
      return toolsBindingsContainer.get()?.isToolTransient(toolID) ?? false
    },

    isToolDraggable: function (toolID: ToolID): boolean {
      return toolsBindingsContainer.get()?.isToolDraggable(toolID) ?? false
    }
  }
}

export const PrimitiveIds = [
  'addPrimitive_Box',
  'addPrimitive_Cylinder',
  'addPrimitive_Sphere',
  'addPrimitive_Cone',
  'addPrimitive_Poly',
  'addPrimitive_Egg',
  'addPrimitive_Torus',
  'addPrimitive_Horseshoe',
  'addPrimitive_Capsule',
  'addPrimitive_HollowCone',
  'addPrimitive_Drop',
  'addPrimitive_Triangle',
  'addPrimitive_Star',
  'addPrimitive_Pin'
] as const

export function useToolEvents() {
  const { setPermanentToolID } = useTools()

  const handleOnToolClick = (tool: ToolUI) => {
    setPermanentToolID(tool.id(), ToolActivationMode.standard)
    document.getElementById('canvas')?.focus()
  }

  const handleOnToolDragStart = (tool: ToolUI) => {
    setPermanentToolID(tool.id(), ToolActivationMode.drag)
    document.getElementById('canvas')?.focus()
    return false
  }

  return { handleOnToolClick, handleOnToolDragStart }
}
