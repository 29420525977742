import React from 'react'
import Typography from '@components/base/Typography/Typography'
import { Button, Dialog, DialogBase, ProgressBar } from 'ui'
import styles from '@styles/components/ExportingDialog.module.scss'
import { useProject } from '@hooks/useProject'
import { useRouter } from 'next/router'

const RefreshDialog = () => {
  const { showRefreshDialog, setShowRefreshModal } = useProject()
  const { reload } = useRouter()

  if (!showRefreshDialog) {
    return null
  }

  return (
    <DialogBase underlay open>
      <Dialog size="s">
        <h2 slot="heading">Disconnected</h2>
        <p>
          You were disconnected. Your changes are not being saved. Please
          refresh the page to attempt reconnection.
        </p>
        <Button
          slot="button"
          onClick={() => setShowRefreshModal(false)}
          variant="primary">
          Ok
        </Button>
      </Dialog>
    </DialogBase>
  )
}

export default RefreshDialog
