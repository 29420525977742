import { ITokenInformation } from '@identity/imslib/adobe-id/custom-types/CustomTypes'
import AdobeIMS from '@services/auth/IMS'

export function fromStorageUrl(url: string) {
  let path = url.replace(/\/+/g, '/')
  if (path.slice(-1) === '/') {
    path = path.slice(0, -1)
  }

  return new URL(
    `${process.env.NEXT_PUBLIC_SERVICE_CORE_STORAGE}${path}`
  ).toString()
}

// TODO: abstract to support any URL
export async function getPresignedImageUrl(projectUuid: string) {
  if (!process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID) return

  const accessToken: ITokenInformation | null | void =
    await AdobeIMS.getAccessTokenAsync()

  return fetch(
    `${process.env.NEXT_PUBLIC_SERVICE_CORE_API_ENDPOINT}/projects/${projectUuid}/presigned-image-url`,
    {
      headers: {
        authorization: `Bearer ${accessToken?.token}`,
        'X-Api-Key': process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID
      }
    }
  )
}

export async function getPresignedSVGUrl(projectUuid: string) {
  if (!process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID) return

  const accessToken: ITokenInformation | null | void =
    await AdobeIMS.getAccessTokenAsync()

  return fetch(
    `${process.env.NEXT_PUBLIC_SERVICE_CORE_API_ENDPOINT}/projects/${projectUuid}/presigned-svg-url`,
    {
      headers: {
        authorization: `Bearer ${accessToken?.token}`,
        'X-Api-Key': process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID
      }
    }
  )
}
