import React, { FC } from 'react'
import styles from '@styles/components/OrbitControls.module.scss'

import { ActionButton, Tooltip, Icon } from 'ui'

import { getCmdModifier } from '@services/engine/utils'
import { SnappedAngleDirection, useCamera } from '@services/engine/useCamera'

import IconLeftArrowOrbit from '/public/s2_icon_left_arrow_orbit.svg'
import LockCameraOrientationIcon from '/public/temp-icon-orbit-snap.svg'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

export const LeftOrbitButton: FC = () => {
  const size = useMediumMinimumSizePreference()

  const modifier = getCmdModifier()

  const { orbit } = useCamera()

  return (
    <ActionButton
      size={size}
      className={styles['navigation-btn-rounded-left']}
      quiet
      onClick={() => {
        orbit(SnappedAngleDirection.next, SnappedAngleDirection.stationary)
        document.getElementById('canvas')?.focus()
      }}>
      <div className={styles['icon_container_rotated_0_deg']}>
        <div className={styles['navigation-btn__icon']}>
          <IconLeftArrowOrbit />
        </div>
      </div>
      <Tooltip selfManaged placement="bottom">
        Turn Left ({modifier} + Left Arrow)
      </Tooltip>
    </ActionButton>
  )
}

export const RightOrbitButton: FC = () => {
  const size = useMediumMinimumSizePreference()
  const modifier = getCmdModifier()

  const { orbit } = useCamera()

  return (
    <ActionButton
      size={size}
      className={styles['navigation-btn-rounded-right']}
      quiet
      onClick={() => {
        orbit(SnappedAngleDirection.previous, SnappedAngleDirection.stationary)
        document.getElementById('canvas')?.focus()
      }}>
      <div className={styles['icon_container_rotated_180_deg']}>
        <div className={styles['navigation-btn__icon']}>
          <IconLeftArrowOrbit />
        </div>
      </div>

      <Tooltip selfManaged placement="bottom">
        Turn Right ({modifier} + Right Arrow)
      </Tooltip>
    </ActionButton>
  )
}

export const DownOrbitButton: FC = () => {
  const size = useMediumMinimumSizePreference()
  const modifier = getCmdModifier()

  const { orbit } = useCamera()

  return (
    <ActionButton
      size={size}
      className={styles['navigation-btn-rounded-up']}
      quiet
      onClick={() => {
        orbit(SnappedAngleDirection.stationary, SnappedAngleDirection.next)
        document.getElementById('canvas')?.focus()
      }}>
      <div className={styles['icon_container_rotated_180_deg']}>
        <div className={styles['navigation-btn__icon']}>
          <IconLeftArrowOrbit />
        </div>
      </div>

      <Tooltip selfManaged placement="bottom">
        Tilt up ({modifier} + Up Arrow)
      </Tooltip>
    </ActionButton>
  )
}

export const UpOrbitButton: FC = () => {
  const size = useMediumMinimumSizePreference()
  const modifier = getCmdModifier()

  const { orbit } = useCamera()

  return (
    <ActionButton
      size={size}
      className={styles['navigation-btn-rounded-down']}
      quiet
      onClick={() => {
        orbit(SnappedAngleDirection.stationary, SnappedAngleDirection.previous)
        document.getElementById('canvas')?.focus()
      }}>
      <div className={styles['icon_container_rotated_0_deg']}>
        <div className={styles['navigation-btn__icon']}>
          <IconLeftArrowOrbit />
        </div>
      </div>
      <Tooltip selfManaged placement="bottom">
        Tilt down ({modifier} + Down Arrow)
      </Tooltip>
    </ActionButton>
  )
}

export const ToggleOrbitConstraintsButton: FC = () => {
  const size = useMediumMinimumSizePreference()

  const { cameraOrbitSnapping } = useCamera()
  const [isCameraOrbitSnappingEnabled, setCameraOrbitSnappingEnabled] =
    cameraOrbitSnapping()

  const cameraOrbitSnappingCurrentlyEnabled =
    isCameraOrbitSnappingEnabled() ?? false

  return (
    <div className={styles['lock-camera-button-container']}>
      <ActionButton
        size={size}
        className={styles['action-btn']}
        toggles
        selected={cameraOrbitSnappingCurrentlyEnabled}
        onClick={() => {
          setCameraOrbitSnappingEnabled(!cameraOrbitSnappingCurrentlyEnabled)
        }}>
        <Icon slot="icon">
          <LockCameraOrientationIcon />
        </Icon>

        <Tooltip selfManaged placement="bottom">
          Snap Camera Orientation
        </Tooltip>
      </ActionButton>
    </div>
  )
}
