import { Task } from 'redux-saga'
import { ActionPattern, cancel, fork, take } from 'redux-saga/effects'

type ActionWithPayload = {
  type: string
  payload: any
}

export function* takeEveryAndCancel(
  task: any,
  actionToListenFor: ActionWithPayload,
  actionToCancelUpon: ActionWithPayload
) {
  while (true) {
    yield take(
      ((action: ActionWithPayload) =>
        action.type === actionToListenFor.type &&
        action.payload === actionToListenFor.payload) as ActionPattern
    )

    const forkedTask: Task<any> = yield fork(task)

    yield take(
      ((action: ActionWithPayload) =>
        action.type === actionToCancelUpon.type &&
        action.payload === actionToCancelUpon.payload) as ActionPattern
    )

    yield cancel(forkedTask)
  }
}
