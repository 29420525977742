import { FC } from 'react'
import { MenuItem, Picker } from 'ui'
import { useProjectState } from '@hooks/useProject'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { Font, fontMap, fonts } from '@services/engine/types'

type Props = {
  style?: React.CSSProperties
}

export const FontPicker: FC<Props> = ({ style }) => {
  const sizePreference = useProjectState('sizePreference')
  const textPrimitiveProperties = useSceneState('textPrimitiveProperties')
  const { setPropertyState } = useSceneActions()

  return (
    <Picker
      aria-label="font-family"
      size={sizePreference}
      style={style}
      value={textPrimitiveProperties.font}
      onchange={e => {
        document.getElementById('canvas')?.focus()
        const newFont = (e.target as any).value as Font

        setPropertyState({
          key: 'textPrimitiveProperties',
          value: {
            ...textPrimitiveProperties,
            font: newFont,
            weight: fontMap[newFont][0] // When changing fonts, set the weight to the first in the "weight array". The "weight array" is purposely setup to have "Regular" as the first weight, with the exception being when font = AdobeHandwriting
          }
        })
      }}
      label={textPrimitiveProperties.font}>
      {fonts.map(f => (
        <MenuItem key={f} value={f}>
          {f}
        </MenuItem>
      ))}
    </Picker>
  )
}
