import React, { FC } from 'react'
import { useUISettings } from '@services/engine/useUISettings'
import { Switch } from 'ui'

const GroupGizmos3d: FC = () => {
  const { gizmo2DActive } = useUISettings()

  const [getGizmo2DActive, set2DGizmo2DActive] = gizmo2DActive()

  const isGizmo2dActive = getGizmo2DActive()

  return (
    <Switch
      checked={!isGizmo2dActive}
      onclick={() => {
        set2DGizmo2DActive(!isGizmo2dActive)
      }}>
      Legacy 3D Gizmos
    </Switch>
  )
}

export default GroupGizmos3d
