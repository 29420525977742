import { FC } from 'react'
import { DialogBase, Dialog, Button } from 'ui'
import { useFirefly } from '@hooks/useFirefly'

const FireflyEmptySceneDialog: FC = () => {
  const { showEmptySceneDialog, setShowEmptySceneDialog } = useFirefly()

  function handleClose() {
    setShowEmptySceneDialog(false)
  }

  if (!showEmptySceneDialog) return null
  return (
    <DialogBase underlay open={showEmptySceneDialog}>
      <Dialog size="s" noDivider style={{ width: 480 }}>
        <h2 slot="heading">Your scene is empty</h2>
        <p>
          Scene to image takes your 3D scene created in Project Neo and uses the
          depth and objects you create to control the generative output.
        </p>

        <p>
          <strong>
            You need to create a scene before using Scene to image.
          </strong>
        </p>

        <Button onClick={handleClose} variant="cta" slot="button">
          OK
        </Button>
      </Dialog>
    </DialogBase>
  )
}

export default FireflyEmptySceneDialog
