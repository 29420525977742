import assert from 'assert'
import Context from '@store/middleware/context'

export interface KeyboardEventListener {
  onKeyDown(e: KeyboardEvent): boolean
  onKeyUp(e: KeyboardEvent): boolean
}

export interface MouseEventListener {
  onMouseDown(e: MouseEvent): boolean
  onMouseMoved(e: MouseEvent): boolean
  onMouseUp(e: MouseEvent): boolean
}

export class MouseCaptureInfo {
  source: any = null
  clientX = 0
  clientY = 0
  cursor = undefined

  localX(e: HTMLElement): number {
    const r = e.getBoundingClientRect()
    return this.clientX - r.left
  }

  localY(e: HTMLElement): number {
    const r = e.getBoundingClientRect()
    return this.clientY - r.top
  }
}

var _singleton: DocumentEventObserver | null = null

type MouseCaptureInfoListener = (info: MouseCaptureInfo | null) => void

export class DocumentEventObserver {
  public static instance(): DocumentEventObserver | null {
    return _singleton
  }

  public static initialize(): void {
    // assert(_singleton === null)
    _singleton = new DocumentEventObserver()
    _singleton.observe()
  }

  public static terminate(): void {
    _singleton?.unobserve()
    _singleton = null
  }

  private activeCursor(): string | undefined {
    return Context.Engine?.getCurrentCursor()
  }

  public enableMouseCapture(enable: boolean): void {
    if (enable) {
      const info = new MouseCaptureInfo()
      info.clientX = this._lastKnownClientPosX
      info.clientY = this._lastKnownClientPosY
      info.cursor = this.activeCursor()

      this._startCapture(info)
    } else {
      this.stopCapture()
    }
  }

  private _startCapture(info: MouseCaptureInfo): void {
    this._mouseCaptureInfo = info

    this._mouseCaptureInfoListeners.forEach(
      (listener: MouseCaptureInfoListener) => {
        listener(info)
      }
    )
  }

  public startCapture(e: MouseEvent): void {
    if (this._mouseCaptureInfo === null) {
      const info = new MouseCaptureInfo()
      info.source = e.target
      info.clientX = e.clientX
      info.clientY = e.clientY
      info.cursor = this.activeCursor()

      this._startCapture(info)
    }
  }

  public stopCapture(): void {
    if (this._mouseCaptureInfo !== null) {
      this._mouseCaptureInfo = null
      this._mouseCaptureInfoListeners.forEach(
        (listener: MouseCaptureInfoListener) => {
          listener(null)
        }
      )
    }
  }

  public mouseCaptureInfo(): MouseCaptureInfo | null {
    return this._mouseCaptureInfo
  }

  private observe(): void {
    if (!document) {
      return
    }

    document.addEventListener('keydown', this._keydownListener, true)
    document.addEventListener('keyup', this._keyupListener, true)

    document.addEventListener('mousedown', this._mousedownListener, true)
    document.addEventListener('mousemove', this._mousemoveListener, true)
    document.addEventListener('mouseup', this._mouseupListener, true)
  }

  public unobserve(): void {
    if (!document) {
      return
    }

    document.removeEventListener('keydown', this._keydownListener)
    document.removeEventListener('keyup', this._keyupListener)

    document.removeEventListener('mousedown', this._mousedownListener)
    document.removeEventListener('mousemove', this._mousemoveListener)
    document.removeEventListener('mouseup', this._mousemoveListener)
  }

  private addKeyboardEventListener(
    id: string,
    listener: KeyboardEventListener
  ): void {
    this._keyboardEventListeners.set(id, listener)
  }

  public addMouseEventListener(id: string, listener: MouseEventListener): void {
    this._mouseEventListeners.set(id, listener)
  }

  public addMouseCaptureInfoListener(
    id: string,
    listener: MouseCaptureInfoListener
  ): void {
    this._mouseCaptureInfoListeners.set(id, listener)
  }

  public removeKeyboardEventListener(id: string): void {
    this._keyboardEventListeners.delete(id)
  }

  public removeMouseEventListener(id: string): void {
    this._mouseEventListeners.delete(id)
  }

  public removeMouseCaptureInfoListener(id: string): void {
    this._mouseCaptureInfoListeners.delete(id)
  }

  private constructor() {
    const self = this

    this._keydownListener = (e: KeyboardEvent) => {
      self.iterateKeyboardEventListeners((listener: KeyboardEventListener) => {
        return listener.onKeyDown(e)
      })
    }

    this._keyupListener = (e: KeyboardEvent) => {
      self.iterateKeyboardEventListeners((listener: KeyboardEventListener) => {
        return listener.onKeyUp(e)
      })
    }

    this._mousedownListener = (e: MouseEvent) => {
      this._updateLastMousePosition(e)

      this.iterateMouseEventListeners(
        (listener: MouseEventListener): boolean => {
          return listener.onMouseDown(e)
        }
      )
    }

    this._mousemoveListener = (e: MouseEvent) => {
      this._updateLastMousePosition(e)

      this.iterateMouseEventListeners(
        (listener: MouseEventListener): boolean => {
          return listener.onMouseMoved(e)
        }
      )
    }

    this._mouseupListener = (e: MouseEvent) => {
      this._updateLastMousePosition(e)

      this.iterateMouseEventListeners(
        (listener: MouseEventListener): boolean => {
          return listener.onMouseUp(e)
        }
      )
    }
  }

  private static iterateListeners<TListener>(
    listeners: Map<string, TListener>,
    iterator: (listener: TListener) => boolean
  ): boolean {
    for (const [key, value] of listeners) {
      if (iterator(value)) {
        return true
      }
    }

    return false
  }

  private iterateKeyboardEventListeners(
    iterator: (listener: KeyboardEventListener) => boolean
  ): boolean {
    return DocumentEventObserver.iterateListeners(
      this._keyboardEventListeners,
      iterator
    )
  }

  private iterateMouseEventListeners(
    iterator: (listener: MouseEventListener) => boolean
  ): boolean {
    return DocumentEventObserver.iterateListeners(
      this._mouseEventListeners,
      iterator
    )
  }

  private _updateLastMousePosition(e: MouseEvent): void {
    this._lastKnownClientPosX = e.clientX
    this._lastKnownClientPosY = e.clientY

    if (this._mouseCaptureInfo) {
      this._mouseCaptureInfo.clientX = this._lastKnownClientPosX
      this._mouseCaptureInfo.clientY = this._lastKnownClientPosY
    }
  }

  private _keyboardEventListeners = new Map<string, KeyboardEventListener>()
  private _mouseEventListeners = new Map<string, MouseEventListener>()

  private _keydownListener: (e: KeyboardEvent) => void
  private _keyupListener: (e: KeyboardEvent) => void

  private _mousedownListener: (e: MouseEvent) => void
  private _mousemoveListener: (e: MouseEvent) => void
  private _mouseupListener: (e: MouseEvent) => void

  private _mouseCaptureInfoListeners = new Map<
    string,
    MouseCaptureInfoListener
  >()

  private _mouseCaptureInfo: MouseCaptureInfo | null = null
  private _lastKnownClientPosX = 0
  private _lastKnownClientPosY = 0
}
