import React, { FC, memo } from 'react'
import { EngineCommitChange } from '@services/engine/types'
import { PropertyInputSlider } from '@components/slider/FirstAndSubsequentInputSlider'
import { Switch } from 'ui'

import { useSceneActions, useSceneState } from '@hooks/useScene'
import { ElementSize } from '@store/slices/projectSlice'

interface Props {
  size: ElementSize
}

const Floor: FC<Props> = ({ size }) => {
  const floorEnabled = useSceneState('floorEnabled')
  const floorHeight = useSceneState('floorHeight')

  const { setPropertyState } = useSceneActions()

  const handleEnableFloor = () => {
    setPropertyState({
      key: 'floorEnabled',
      value: !floorEnabled
    })
  }

  return (
    <>
      <Switch size={size} checked={floorEnabled} onClick={handleEnableFloor}>
        Shadow
      </Switch>

      {floorEnabled && (
        <>
          <PropertyInputSlider
            size={size}
            variant="filled"
            min={-1}
            max={1}
            step={0.001}
            aria-label="Floor Height"
            label="Floor Height"
            value={floorHeight}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'floorHeight',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'floorHeight',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'floorHeight',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
            editable
            hideStepper
          />
        </>
      )}
    </>
  )
}

export default memo(
  Floor,
  (prevProps, nextProps) => prevProps.size === nextProps.size
)
