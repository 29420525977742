export enum BaseTfStudioFinePointerCompatibility {
  COMPATIBLE = 'compatible',
  INCOMPATIBLE = 'incompatible',
  NOT_FULLY_SUPPORTED = 'not-fully-supported'
}

export enum BasePfProjectsPrivateDefaultFlagValue {
  PUBLIC = 'public-by-default',
  PRIVATE = 'private-by-default',
  USE_PAID_SUBSCRIPTION_STATUS = 'use-paid-subscription-status'
}
