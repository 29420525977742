import React, { FC } from 'react'
import cn from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Button, ButtonGroup, DialogBase } from 'ui'
import styles from '@styles/components/FireflyToSDialog.module.scss'
import { useFirefly } from '@hooks/useFirefly'
import {
  LocalStorageBooleanValue,
  LocalStorageKey
} from 'constants/localStorage'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

const FireflyToSDialog: FC = () => {
  const flags = useFlags()

  const size = useMediumMinimumSizePreference()

  const { generateImages, showToSPopup, setShowToSPopup } = useFirefly()

  const show = showToSPopup && flags['base-pf-ui-firefly-tos-dialog']

  function handleAgreeToS() {
    setShowToSPopup(false)
    localStorage.setItem(
      LocalStorageKey.fireflyAgreedToToS,
      LocalStorageBooleanValue.TRUE
    )
    generateImages()
  }

  return (
    <DialogBase underlay open={show}>
      <div
        className={cn(styles['dialog'], {
          [styles['no-pointer-events']]: !show
        })}>
        <img src="/firefly_tos_banner.png" alt="tos banner" />

        <h2>Generative AI in Adobe apps</h2>

        <p>You can create in new ways with generative AI technology.</p>

        <p>
          By clicking “Agree,” you agree to our{' '}
          <a
            href="https://www.adobe.com/legal/licenses-terms/adobe-gen-ai-user-guidelines.html"
            target="_blank">
            User Guidelines
          </a>
          .
        </p>

        <ButtonGroup>
          <Button
            size={size}
            treatment="outline"
            variant="secondary"
            onClick={() => setShowToSPopup(false)}>
            Cancel
          </Button>
          <Button size={size} variant="cta" onClick={handleAgreeToS}>
            Agree
          </Button>
        </ButtonGroup>
      </div>
    </DialogBase>
  )
}

export default FireflyToSDialog
