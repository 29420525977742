import React, { FC, PropsWithChildren } from 'react'
import { ClassNameProps } from 'types/reactProps'
import cn from 'classnames'
import styles from '@styles/components/Panel.module.scss'

type Props = PropsWithChildren<ClassNameProps>

const Panel: FC<Props> = ({ children, className }) => {
  return <div className={cn(styles['container'], className)}>{children}</div>
}

export default Panel
