import React, { useState } from 'react'

import { Switch } from 'ui'
import PanelAccordion from '@components/panel/PanelAccordion'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import { PropertyInputSlider } from '@components/slider/FirstAndSubsequentInputSlider'
import Context from '@store/middleware/context'

const History = () => {
  const sceneHistoryCollapsed = useProjectState('sceneHistoryCollapsed')
  const sizePreference = useProjectState('sizePreference')
  const { setPanelAccordionItem } = useProjectActions()

  const [historySliderPosition, setHistorySliderPosition] = useState(1)
  const [isHistoryModeEnabled, setHistoryMode] = useState(false)
  Context.Engine?.setHistoryMode(isHistoryModeEnabled ? 1 : 0)

  return (
    <PanelAccordion
      label="History"
      collapsed={sceneHistoryCollapsed}
      onChange={collapsed =>
        setPanelAccordionItem({ key: 'sceneHistoryCollapsed', collapsed })
      }>
      <Switch
        size={sizePreference}
        style={{ marginRight: -16 }}
        checked={isHistoryModeEnabled}
        onClick={() => {
          setHistoryMode(!isHistoryModeEnabled)
          Context.Engine?.setHistoryMode(isHistoryModeEnabled ? 1 : 0)
          document.getElementById('canvas')?.focus()
        }}>
        Enable history
      </Switch>

      <PropertyInputSlider
        size={sizePreference}
        variant="filled"
        min={0}
        max={1}
        step={0.01}
        aria-label="History"
        label="History"
        value={historySliderPosition}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setHistorySliderPosition(value)
          Context.Engine?.setHistorySliderPos(value)
        }}
        editable
        hideStepper
      />
    </PanelAccordion>
  )
}

export default History
