import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicOverlay = dynamic(
  async () => await import('@swc-react/overlay').then(({ Overlay }) => Overlay),
  {
    ssr: false,
    loading: () => <></>
  }
)

export const Overlay: typeof DynamicOverlay = props => {
  useEffect(() => {
    import('@spectrum-web-components/overlay')
  }, [])

  return <DynamicOverlay {...props} />
}

export const OverlayCloseEvent = new Event('close', {
  bubbles: true,
  composed: true
})
