import React, { FC, useEffect, useState } from 'react'
import VideoTile from './VideoTile'
import VideoContainer from './VideoContainer'
import VideoAvatar from './VideoAvatar'
import {
  useAttendeeAudioStatus,
  useAudioVideo
} from 'amazon-chime-sdk-component-library-react'
import styles from '@styles/components/RemoteVideo.module.scss'
import { SpIconMovieCamera, SpIconVoiceOver } from 'workflow-icons'

interface Props {
  attendeeId: string
  tileId?: number
  name?: string
  avatar?: string
  color?: string
}

const RemoteVideo: FC<Props> = ({
  attendeeId,
  avatar,
  color,
  name,
  tileId
}) => {
  const { muted } = useAttendeeAudioStatus(attendeeId)
  const audioVideo = useAudioVideo()
  const [volume, setVolume] = useState<null | number>(null)

  useEffect(() => {
    if (!audioVideo || !attendeeId) {
      return
    }

    const callback = (
      _: string,
      volume: number | null,
      __: boolean | null,
      ___: number | null
    ) => {
      setVolume(volume)
    }

    audioVideo.realtimeSubscribeToVolumeIndicator(attendeeId, callback)

    return () =>
      audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId, callback)
  }, [attendeeId, color])

  return (
    <div className={styles['container']}>
      <VideoTile tileId={tileId} />
      <div
        className={styles['volume-indicator']}
        style={{
          background: !tileId ? 'black' : 'transparent',
          borderColor: volume ? color : 'transparent'
        }}>
        {!tileId && <VideoAvatar src={avatar} />}
        <div className={styles['name-container']}>
          <div className={styles['name']}>{name}</div>
        </div>
        {(!tileId || muted) && (
          <div
            className={styles['status']}
            style={{
              backgroundColor: tileId
                ? 'rgba(0, 0, 0, 0.5)'
                : 'rgba(255, 255, 255, 0.2)'
            }}>
            {!tileId && (
              <SpIconMovieCamera
                className={styles['icon-toggle-off']}
                size="s"
              />
            )}
            {muted && (
              <SpIconVoiceOver className={styles['icon-toggle-off']} size="s" />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default RemoteVideo
