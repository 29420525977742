import React, { FC } from 'react'
import { ActionMenu, MenuItem } from 'ui'

import styles from '@styles/components/ViewsCard.module.scss'
import Typography from '@components/base/Typography/Typography'

interface Props {
  imageUrl: string
  header: string
}

const ViewsCard: FC<Props> = ({ imageUrl, header }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['views-card']}>
        <img
          src={imageUrl}
          alt={header}
          className={styles['views-card-image']}
        />
      </div>
      <div className={styles['header-container']}>
        <Typography variant="h4">{header}</Typography>
        <ActionMenu quiet>
          <MenuItem>Item #1</MenuItem>
          <MenuItem>Item #2</MenuItem>
        </ActionMenu>
      </div>
    </div>
  )
}

export default ViewsCard
