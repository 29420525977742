import { useMediumMinimumSizePreference } from '@hooks/useProject'
import {
  ComponentProps,
  Dispatch,
  SetStateAction,
  useEffect,
  useState
} from 'react'
import { NumberField } from 'ui'

export type Validator = (() => ValidationResult) | ValidationResult

type ValidationResult = {
  isValid: boolean
  error: string
}

export type ValidationError = {
  error: string
  setError: Dispatch<SetStateAction<string>> | ((arg: string) => void)
}

const getNumberTypeValidator =
  (name: string, value: number): Validator =>
  () => ({
    isValid: typeof value === 'number' && !isNaN(value),
    error: `${name} entered must be a number`
  })

export function isValidInput(validators: Validator[]): ValidationResult {
  let errorToReturn: string = ''

  const isValid = validators.every(validator => {
    const result: ValidationResult =
      typeof validator === 'function' ? validator() : validator

    if (!result.isValid) {
      errorToReturn = result.error
    }

    return result.isValid
  })

  return {
    isValid,
    error: errorToReturn
  }
}

const isEmptyInput = (input: number) => {
  return isNaN(input) && input.toString() === 'NaN'
}

export function convertNumberStringToNumber(str: string): number {
  return Number(str.replaceAll(',', ''))
}

type Props = ComponentProps<typeof NumberField> & {
  validators?: Validator[]
  value: number
  setValue: (value: number) => void
  id: string
  onChange?: (value: number) => void
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void
  name?: string
  error?: string
  isError?: boolean
  onError?: (error: string) => void
  validateOnInput?: boolean
  validateUseEffectDeps?: any[]
}

export default function VideoFormNumberField(props: Props) {
  const size = useMediumMinimumSizePreference()

  function validate(value: number) {
    let allValidators: Validator[] = []

    if (props.validators) {
      allValidators = [
        getNumberTypeValidator(props.name ?? 'Value', value),
        ...props.validators
      ]
    }

    const validationResult: ValidationResult = isValidInput(allValidators)

    if (!validationResult.isValid) {
      props.onError && props.onError(validationResult.error)
    } else {
      props.onError && props.onError('')
    }
  }

  function handleOnBlur() {
    if (props.onChange && !isEmptyInput(props.value)) {
      props.onChange(props.value)
    }

    validate(props.value)

    const numberFieldEl = document.getElementById(props.id)
    if (numberFieldEl) {
      numberFieldEl.removeAttribute('focused')
      numberFieldEl.removeAttribute('keyboard-focused')
    }
  }

  function handleOnInput(e: React.FormEvent<HTMLInputElement>) {
    validate(e.target.value)

    if (isEmptyInput(e.target.value)) {
      return
    }

    props.setValue(e.target.value)
  }

  function handleOnKeyUp(e: any) {
    const value = convertNumberStringToNumber(e.target.value + '')
    console.log('handleOnKeyUp', value, e.code)
    validate(e.target.value)

    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.target?.blur()
      e.currentTarget?.blur()
      handleOnBlur()
      return
    }

    if (e.code === 'Tab') {
      e.target?.blur()
      e.currentTarget?.blur()
      handleOnBlur()
    }
  }

  useEffect(() => {
    if (Array.isArray(props.validateUseEffectDeps)) {
      validate(props.value)
    }
  }, props.validateUseEffectDeps ?? [])

  return (
    <NumberField
      {...props}
      size={size}
      hideStepper
      id={props.id}
      onInput={handleOnInput}
      onBlur={handleOnBlur}
      onKeyUp={handleOnKeyUp}
    />
  )
}

export function useNumberField() {
  const [error, setError] = useState<string>('')
  return { error, setError }
}
