import React, { FC } from 'react'
import Panel from '@components/panel/Panel'
import CursorOverlay from '@components/cursorOverlay/CursorOverlay'
import { useProjectState } from '@hooks/useProject'
import LibraryTabsPanel from './LibraryTabsPanel'
import LibraryFullAssetView from './LibraryFullAssetView'

const LibraryPanel: FC = () => {
  const selectedLibraryView = useProjectState('selectedLibraryView')

  return (
    <Panel>
      {selectedLibraryView === 'tabs-panel' ? (
        <LibraryTabsPanel />
      ) : (
        <LibraryFullAssetView />
      )}
      <CursorOverlay />
    </Panel>
  )
}

export default LibraryPanel
