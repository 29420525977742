import React, { FC, ReactNode } from 'react'
import cn from 'classnames'
import { Icon, Link, Overlay, Popover } from 'ui'
import styles from '@styles/components/Badge.module.scss'
import { ElementSize } from '@store/slices/projectSlice'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  icon?: ReactNode
  size?: ElementSize
  variant?: 'positive' | 'notice'
  fill?: 'subtle'
}

const Badge: FC<Props> = ({
  children,
  className,
  fill = 'subtle',
  icon,
  size = 'm',
  variant = 'positive',
  ...props
}) => {
  return (
    <div
      {...props}
      className={cn(styles['container'], className)}
      data-fill={fill}
      data-size={size}
      data-variant={variant}>
      {icon && <Icon size={size}>{icon}</Icon>}
      {children}
    </div>
  )
}

export default Badge
