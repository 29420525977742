import React from 'react'
import dynamic from 'next/dynamic'

export const MenuDivider = dynamic(
  async () => await import('./MenuDividerComponent'),
  {
    ssr: false,
    loading: () => <></>
  }
)
