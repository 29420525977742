import React, { FC } from 'react'
import styles from '@styles/components/CommunityPanel.module.scss'

interface CommunityCardProps {
  image: string
  title?: string
}

const CommunityCard: FC<CommunityCardProps> = ({ image, title }) => {
  return (
    <div className={styles['community-card']}>
      <img className={styles['community-card-image']} src={image} alt={title} />
    </div>
  )
}

export default CommunityCard
