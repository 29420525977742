import React, { useState } from 'react'
import {
  Button,
  ButtonGroup,
  Checkbox,
  DialogBase,
  Icon,
  OverlayCloseEvent
} from 'ui'
import styles from '@styles/components/ProjectShareDialog.module.scss'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import {
  LocalStorageBooleanValue,
  LocalStorageKey
} from '@constants/localStorage'
import GlobeGridIcon from '/public/s2_icon_globe_grid_2.svg'
import ShareLink from '@components/project/ShareLink'

const ProjectShareDialog = () => {
  const isShareProjectDialogOpen = useProjectState('isShareProjectDialogOpen')
  const {
    setIsSharedProjectToastOpen,
    setIsShareProjectDialogOpen,
    toggleProjectPublicStatus
  } = useProjectActions()

  const [
    doNotShowShareProjectDialogAgain,
    setDoNotShowShareProjectDialogAgain
  ] = useState(false)

  function handleConfirmShareProjectDialog(
    e: React.MouseEvent<typeof Button, MouseEvent>
  ) {
    closeShareProjectDialog(e)
    toggleProjectPublicStatus()
    localStorage.setItem(
      LocalStorageKey.projectHideShareCommunityDialog,
      doNotShowShareProjectDialogAgain
        ? LocalStorageBooleanValue.TRUE
        : LocalStorageBooleanValue.FALSE
    )

    setIsSharedProjectToastOpen(true)
  }

  function closeShareProjectDialog(
    e: React.MouseEvent<typeof Button, MouseEvent>
  ) {
    e.currentTarget.dispatchEvent(OverlayCloseEvent)
    setIsShareProjectDialogOpen(false)
  }

  return (
    <>
      <DialogBase underlay open={isShareProjectDialogOpen}>
        <div className={styles['share-project-dialog']}>
          <h2>Share in Community</h2>
          <p>
            When you share a design, others can view it and snapshots of its
            history in <strong>Community.</strong> This helps everyone see
            what’s possible with the app and learn how to use it better. To make
            it private, click and choose <strong>Remove from Community.</strong>
          </p>

          <ShareLink />

          <div className={styles['footer']}>
            <Checkbox
              size="s"
              checked={doNotShowShareProjectDialogAgain}
              onClick={() =>
                setDoNotShowShareProjectDialogAgain(
                  !doNotShowShareProjectDialogAgain
                )
              }>
              Don't show again
            </Checkbox>
            <ButtonGroup slot="button">
              <Button
                variant="secondary"
                treatment="outline"
                onClick={closeShareProjectDialog}>
                Cancel
              </Button>
              <Button
                variant="accent"
                treatment="fill"
                onClick={handleConfirmShareProjectDialog}>
                <Icon slot="icon">
                  <GlobeGridIcon />
                </Icon>
                Share in Community
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </DialogBase>
    </>
  )
}

export default ProjectShareDialog
