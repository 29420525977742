import { useEffect } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useProjectActions } from '@hooks/useProject'
import { Color, ColorKey, Size, ElementSize } from '@store/slices/projectSlice'
import { LocalStorageKey } from '@constants/localStorage'

const FifteenInchViewportWidth = 1500

export const useInitializeUIPreference = () => {
  const flags = useFlags()

  const colorThemeFlag = flags['base-pf-ui-color-theme']

  const sizeFlag = flags['base-pf-ui-size-preference']

  const { setColorPreference, setSizePreference } = useProjectActions()

  useEffect(() => {
    setThemePreference()
  }, [colorThemeFlag])

  useEffect(() => {
    setSizePreferencePreference()
  }, [sizeFlag])

  function setThemePreference() {
    if (!colorThemeFlag) {
      return setColorPreference('light')
    }

    const localStoragePreference = localStorage.getItem(
      LocalStorageKey.appPreferenceColor
    )
    const hasPreference = Object.entries(Color).some(
      ([_, val]) => val === localStoragePreference
    )
    if (hasPreference) {
      return setColorPreference(localStoragePreference as ColorKey)
    }

    const { matches: prefersDark } = window.matchMedia(
      '(prefers-color-scheme: dark)'
    )

    const preference = prefersDark ? 'dark' : 'light'
    setColorPreference(preference)
  }

  function setSizePreferencePreference() {
    if (!sizeFlag) {
      return setSizePreference('s')
    }

    const localStoragePreference = localStorage.getItem(
      LocalStorageKey.appPreferenceSize
    )
    const hasPreference = Object.entries(Size).some(
      ([_, val]) => val === localStoragePreference
    )

    if (hasPreference) {
      return setSizePreference(localStoragePreference as ElementSize)
    }

    const isBigScreen = window.innerWidth > FifteenInchViewportWidth
    setSizePreference(isBigScreen ? Size.medium : Size.small)
  }
}
