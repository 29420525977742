import React, { FC } from 'react'
import { ActionButton, Divider, Link, Overlay, Popover } from 'ui'
import styles from '@styles/components/StudioAppBarUserProfile.module.scss'
import { useAuth } from '@hooks/useAuth'
import { Legal } from '@components/legal/Legal'

const ManageAccountLink =
  process.env.NEXT_PUBLIC_CLIENT_APP_ENVIRONMENT === 'dev'
    ? 'https://stage.account.adobe.com'
    : 'https://account.adobe.com'

const StudioAppBarUserProfile: FC = () => {
  const { logout, localUser } = useAuth()

  const userName = localUser?.adobeUserDisplayName || 'User'
  const userAvatar = localUser?.adobeUserAvatarUrl || undefined

  return (
    <>
      <ActionButton id="user-profile" quiet className={styles['action-btn']}>
        <img src={userAvatar} />
      </ActionButton>
      <Overlay placement="bottom-end" offset={16} trigger="user-profile@click">
        <Popover className={styles['popover']}>
          <div className={styles['main']}>
            <img height={75} width={75} src={userAvatar} />
            <div className={styles['main-content']}>
              <h4>{userName}</h4>
              <p>{localUser?.adobeUserEmail}</p>
              <Link href={ManageAccountLink} target="_blank">
                Manage account
              </Link>
            </div>
          </div>

          <div className={styles['divider']}>
            <Divider />
          </div>
          <div className={styles['legal-container']}>
            <Legal direction="column" />
          </div>
          <div
            className={styles['sign-out']}
            role="button"
            onClick={() => logout()}>
            Sign out
          </div>
        </Popover>
      </Overlay>
    </>
  )
}

export default StudioAppBarUserProfile
