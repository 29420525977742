import React from 'react'
import { Button, ButtonGroup, Dialog, DialogBase, Link } from 'ui'
import { useRouter } from 'next/router'
import { DiscordLink } from '@components/navigation/JoinDiscordButton'
import { useSceneState } from '@hooks/useScene'

const ErrorDialog = () => {
  const engineState = useSceneState('engineState')
  const { push, reload } = useRouter()

  if (engineState !== 'ERROR') {
    return null
  }

  return (
    <DialogBase underlay open>
      <Dialog size="s">
        <h2 slot="heading">Oops, something went wrong</h2>
        <p>
          Please try and refresh the page. If error persists, contact{' '}
          <Link href={DiscordLink} target="_blank">
            support
          </Link>
          .
        </p>

        <ButtonGroup slot="button">
          <Button onClick={() => push('/my-projects')} variant="secondary">
            Go Back
          </Button>
          <Button onClick={() => reload()} variant="primary">
            Refresh page
          </Button>
        </ButtonGroup>
      </Dialog>
    </DialogBase>
  )
}

export default ErrorDialog
