import { RootState } from '@store/store'
import { useDispatch, useSelector } from 'react-redux'
import { project, ProjectState } from '@store/slices/projectSlice'
import { bindActionCreators } from '@reduxjs/toolkit'
import { isDocumentUpdatable } from '@store/middleware/document/documentSaga'

export const useProject = () => {
  const state = useSelector((state: RootState) => state.project)
  const dispatch = useDispatch()
  const boundedActions = bindActionCreators(project.actions, dispatch)

  return {
    ...state,
    ...boundedActions
  }
}

export const useIsDocumentUpdatable = () => {
  const localUser = useSelector((state: RootState) => state.auth.localUser)
  const isFeatured = useSelector((state: RootState) => state.project.isFeatured)
  const ownerUserUuid = useSelector(
    (state: RootState) => state.project.ownerUserUuid
  )

  return isDocumentUpdatable(localUser, isFeatured, ownerUserUuid)
}

export const useIsProjectViewOnly = (): boolean => {
  const isPublic = useSelector((state: RootState) => state.project.isPublic)
  const isFeatured = useSelector((state: RootState) => state.project.isFeatured)
  const projectOwnerUserUuid = useSelector(
    (state: RootState) => state.project.ownerUserUuid
  )
  const localUser = useSelector((state: RootState) => state.auth.localUser)

  const isProjectOwner = localUser?.uuid === projectOwnerUserUuid
  return !!(isPublic && !isProjectOwner && isFeatured === false)
}

export function useMediumMinimumSizePreference() {
  const state = useSelector((state: RootState) =>
    state.project.sizePreference === 's' ? 'm' : state.project.sizePreference
  )
  return state
}

export function useMaxMediumSizePreference() {
  const state = useSelector((state: RootState) =>
    state.project.sizePreference === 'l' ? 'm' : state.project.sizePreference
  )
  return state
}

export const useProjectActions = () => {
  const dispatch = useDispatch()
  const boundedActions = bindActionCreators(project.actions, dispatch)
  return boundedActions
}

export type SceneStateProperties = ReturnType<typeof useProjectActions> &
  RootState['scene']

export function useProjectState<T extends keyof RootState['project']>(
  key: T
): RootState['project'][T] {
  const state = useSelector((state: RootState) => state.project[key])
  return state
}
