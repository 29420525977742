import { EventEmitter } from 'events'
import {
  PrimitiveModifierProperty,
  EnginePrimitive,
  EngineData,
  EngineCamera,
  EngineUndoRedo,
  EngineBackground,
  GroupExpanded,
  EngineSelectParentChild,
  EngineSelectSibling,
  EngineBlendType,
  EngineRepeatType,
  PrimitiveParameter,
  PrimitiveDimensionType,
  PrimitiveDimensionParam,
  MaterialParam,
  PrimitiveSymmetryParam,
  PrimitiveSymmetryChecked,
  EngineMode,
  MaterialColorParam,
  RemoteUserLocation,
  RemoteUserStatus,
  LocalUserLocation,
  EngineCommitOrigin,
  EngineDataOrigin,
  EngineCameraProperty,
  SceneNavigatorEventPayload,
  CameraFromTo,
  Cartesian,
  EngineStackReorderingOpts,
  EngineExportCapturePayload,
  EngineCaptureImagePayload,
  FrameCallbackData,
  FrameEventPayload,
  DeviceStatusEventPayload,
  ModelLoadedStatusEventPayload,
  SelectedObjectUI,
  PropertyPanelId,
  PropertyPanelSetter,
  Vec3d,
  PropertyPanelVariant,
  PropertyPanelPath,
  propertyPanelPathVariantDict,
  IntGuard,
  BoolGuard,
  EnumBaseGuard,
  FloatGuard,
  StringGuard,
  Vec3dGuard,
  Vec2d,
  Vec2dGuard,
  PropertyHasChangedData,
  IVec2Guard,
  IVec2,
  PropertyListHasChangedData,
  SceneContentData,
  PropertyPanelSetStatus,
  IVec3,
  IVec3Guard,
  PropertySoftBoundsHaveChangedData,
  EngineCommitChange,
  fontWeightMap
} from './types'
import { CursorRegistry, Cursor } from '@services/engine/cursorRegistry'

import {
  DocumentEventObserver,
  KeyboardEventListener,
  MouseEventListener
} from './documentEventObserver'

import { isMacOSX, parseValidEngineJson, traceInvalidJsonError } from './utils'
import AdobeIMS from '@services/auth/IMS'

import { ITokenInformation } from '@identity/imslib/adobe-id/custom-types/CustomTypes'
import { captureException } from '@sentry/nextjs'
import { SnapshotListHasChangedData } from './types'

export const CANVAS_ID = 'canvas'

export let cursorRegistry = new CursorRegistry()

export const CANVAS_CONTAINER_ID = 'canvas-container'

function isTextFieldActive(): boolean {
  const elt = document.activeElement
  if (!elt) {
    return false
  }

  const tagName = elt.tagName

  const isDraggingSortableItem = !!elt.getAttribute('aria-pressed')

  return (
    isDraggingSortableItem ||
    tagName === 'INPUT' ||
    tagName === 'SP-SLIDER' ||
    tagName === 'UE-SLIDER' ||
    tagName === 'SP-NUMBER-FIELD' ||
    tagName === 'UE-NUMBER-FIELD' ||
    tagName === 'SP-TEXTFIELD' ||
    tagName === 'UE-TEXTFIELD' ||
    tagName === 'UE-COLOR-PICKER'
  )
}

export const defaultPropertyPanelId: PropertyPanelId = ''

export default class Engine {
  public EngineStatusChannel: EventEmitter = new EventEmitter()
  public EngineDataChannel: EventEmitter = new EventEmitter()
  public EngineLightingChannel: EventEmitter = new EventEmitter()
  public EnginePropertyHasChangedChannel: EventEmitter = new EventEmitter()
  public EnginePropertyListHasChangedChannel: EventEmitter = new EventEmitter()
  public EnginePropertySoftBoundsHaveChangedChannel: EventEmitter =
    new EventEmitter()
  public EngineSceneContentChannel: EventEmitter = new EventEmitter()
  public EngineDebugChannel: EventEmitter = new EventEmitter()
  public EngineFrameDataChannel: EventEmitter = new EventEmitter()
  public EngineSnapshotListHasChangedChannel = new EventEmitter()
  public EngineSnapshotRenamedChannel = new EventEmitter()
  private EngineInstance: any
  private EngineFeatures: string = ''
  public initialized: boolean = false
  public targetCanvas?: HTMLCanvasElement
  private animationFrameRef?: number
  private globalKeyboardListener: KeyboardEventListener
  private globalMouseListener: MouseEventListener
  private allowedKeyboardKeys: string[] = ['/', 'Escape']
  public engineLightingChannelKey: string = 'lightingChannelData'
  public enginePropertyHasChangedChannelKey: string = 'propertyHasChangedData'
  public enginePropertyListHasChangedChannelKey: string =
    'propertyListHasChangedData'
  public enginePropertySoftBoundsHaveChangedChannelKey: string =
    'propertySoftBoundsHaveChangedData'
  public engineSceneContentChannelKey: string = 'nodeData'
  public engineSnapshotListHasChangedChannelKey: string =
    'snapshotListHasChangedData'
  public engineSnapshotRenamedChannelKey: string = 'snapshotRenamedData'

  constructor() {
    this.initialize = this.initialize.bind(this)
    this.initializeCanvas = this.initializeCanvas.bind(this)
    this.initializeEngine = this.initializeEngine.bind(this)
    this.isAllowedKeyboardKey = this.isAllowedKeyboardKey.bind(this)
    this.animate = this.animate.bind(this)
    this.appendCanvas = this.appendCanvas.bind(this)
    this.unmount = this.unmount.bind(this)
    this.mount = this.mount.bind(this)
    this.updateFeatures = this.updateFeatures.bind(this)
    this.loadDocument = this.loadDocument.bind(this)
    this.getDocument = this.getDocument.bind(this)
    this.getLocalUserLocation = this.getLocalUserLocation.bind(this)
    this.setLocalUserLocation = this.setLocalUserLocation.bind(this)
    this.getRemoteUserCursorPosition =
      this.getRemoteUserCursorPosition.bind(this)
    this.get2DPosition = this.get2DPosition.bind(this)
    this.setRemoteUserStatus = this.setRemoteUserStatus.bind(this)
    this.setRemoteUserLocation = this.setRemoteUserLocation.bind(this)
    this.toggleUsersAvatarVisibility =
      this.toggleUsersAvatarVisibility.bind(this)
    this.setMode = this.setMode.bind(this)
    this.setStyleParam = this.setStyleParam.bind(this)
    this.setStyleParamColor = this.setStyleParamColor.bind(this)
    this.setStyleParamColorExplicit = this.setStyleParamColorExplicit.bind(this)
    this.setStyleParamExplicit = this.setStyleParamExplicit.bind(this)
    this.addPrimitive = this.addPrimitive.bind(this)
    this.duplicatePrimitive = this.duplicatePrimitive.bind(this)
    this.deletePrimitive = this.deletePrimitive.bind(this)
    this.setPrimitiveModifierType = this.setPrimitiveModifierType.bind(this)
    this.setPrimitiveModifierValue = this.setPrimitiveModifierValue.bind(this)
    this.setPrimitiveModifierDistance =
      this.setPrimitiveModifierDistance.bind(this)
    this.setPrimitiveLocation = this.setPrimitiveLocation.bind(this)
    this.setPrimitiveSize = this.setPrimitiveSize.bind(this)
    this.setPrimitiveScaleCorners = this.setPrimitiveScaleCorners.bind(this)
    this.setPrimitiveHoleAmount = this.setPrimitiveHoleAmount.bind(this)
    this.setPrimitiveParameter = this.setPrimitiveParameter.bind(this)
    this.setPrimitiveDimensionType = this.setPrimitiveDimensionType.bind(this)
    this.setPrimitiveDimensionParam = this.setPrimitiveDimensionParam.bind(this)
    this.setPrimitiveShellAmount = this.setPrimitiveShellAmount.bind(this)
    this.setPrimitiveRoundAmount = this.setPrimitiveRoundAmount.bind(this)
    this.setPrimitiveSymmetry = this.setPrimitiveSymmetry.bind(this)
    this.setPrimitiveName = this.setPrimitiveName.bind(this)
    this.setMaterialColor = this.setMaterialColor.bind(this)
    this.setMaterialParam = this.setMaterialParam.bind(this)
    this.recenterCamera = this.recenterCamera.bind(this)
    this.cameraReset = this.cameraReset.bind(this)
    this.setCameraFromTo = this.setCameraFromTo.bind(this)
    this.setCameraRadius = this.setCameraRadius.bind(this)
    this.setCameraOrbitPhi = this.setCameraOrbitPhi.bind(this)
    this.setCameraOrbitTheta = this.setCameraOrbitTheta.bind(this)
    this.setCameraType = this.setCameraType.bind(this)
    this.handleUndoRedo = this.handleUndoRedo.bind(this)
    this.setCameraProperty = this.setCameraProperty.bind(this)
    this.toggleLightStatus = this.toggleLightStatus.bind(this)
    this.setLightAngle1 = this.setLightAngle1.bind(this)
    this.setLightAngle2 = this.setLightAngle2.bind(this)
    this.setLightOcclusionDistance = this.setLightOcclusionDistance.bind(this)
    this.toggleScenePlayback = this.toggleScenePlayback.bind(this)
    this.toggleGroupOutline = this.toggleGroupOutline.bind(this)
    this.setBackgroundType = this.setBackgroundType.bind(this)
    this.setBackgroundColorA = this.setBackgroundColorA.bind(this)
    this.setBackgroundColorB = this.setBackgroundColorB.bind(this)
    this.setFloorEnabled = this.setFloorEnabled.bind(this)
    this.setFloorHeight = this.setFloorHeight.bind(this)
    this.setModelPose = this.setModelPose.bind(this)
    this.selectParentChild = this.selectParentChild.bind(this)
    this.selectSibling = this.selectSibling.bind(this)
    this.setBlendType = this.setBlendType.bind(this)
    this.setBlendAmount = this.setBlendAmount.bind(this)
    this.sendFireflyRequest = this.sendFireflyRequest.bind(this)
    this.setAutoFocusEnabled = this.setAutoFocusEnabled.bind(this)
    this.setSnapping = this.setSnapping.bind(this)
    this.isSnappingEnable = this.isSnappingEnable.bind(this)
    this.hexColorToEngineColor = this.hexColorToEngineColor.bind(this)
    this.rGBToHex = this.rGBToHex.bind(this)
    this.exportCapture = this.exportCapture.bind(this)
    this.toggleFrameEnabled = this.toggleFrameEnabled.bind(this)
    this.setFrameOpacity = this.setFrameOpacity.bind(this)
    this.setFrameSize = this.setFrameSize.bind(this)
    this.reorderStackElements = this.reorderStackElements.bind(this)
    this.togglePrimitiveVisibility = this.togglePrimitiveVisibility.bind(this)
    this.setHistoryMode = this.setHistoryMode.bind(this)
    this.setHistorySliderPos = this.setHistorySliderPos.bind(this)
    this.engineWeightToFontWeight = this.engineWeightToFontWeight.bind(this)

    // Event listeners
    const self = this

    this.globalKeyboardListener = {
      onKeyDown: (e: KeyboardEvent): boolean => {
        const textFieldActive = isTextFieldActive()

        if (!textFieldActive) {
          // dispatch first on engine, then if not handled let it go
          if (self.EngineInstance?.Command_HandleKeyDown(e)) {
            if (this.isAllowedKeyboardKey(e.key)) {
              return true
            }

            e.stopImmediatePropagation()
            e.preventDefault()
            return true
          }
        }

        return false
      },

      onKeyUp: (e: KeyboardEvent): boolean => {
        if (!isTextFieldActive()) {
          // dispatch first on engine, then if not handled let it go
          if (self.EngineInstance?.Command_HandleKeyUp(e)) {
            if (this.isAllowedKeyboardKey(e.key)) {
              return true
            }

            e.stopImmediatePropagation()
            e.preventDefault()
            return true
          }
        }

        return false
      }
    }

    this.globalMouseListener = {
      onMouseDown: (e: MouseEvent): boolean => {
        if (e.target === this.targetCanvas) {
          if (
            self.EngineInstance?.Command_HandleMouseDown(
              e.offsetX,
              e.offsetY,
              e
            )
          ) {
            DocumentEventObserver.instance()?.startCapture(e)

            e.stopImmediatePropagation()
            return true
          }
        }

        return false
      },

      onMouseMoved: (e: MouseEvent): boolean => {
        const info = DocumentEventObserver.instance()?.mouseCaptureInfo()

        if (info && this.targetCanvas) {
          const posX = info.localX(this.targetCanvas)
          const posY = info.localY(this.targetCanvas)
          self.EngineInstance?.Command_HandleMouseMoved(posX, posY, e)

          e.preventDefault()
          return true
        } else {
        }

        return false
      },

      onMouseUp: (e: MouseEvent): boolean => {
        const info = DocumentEventObserver.instance()?.mouseCaptureInfo()

        if (info && this.targetCanvas) {
          const posX = info.localX(this.targetCanvas)
          const posY = info.localY(this.targetCanvas)
          self.EngineInstance?.Command_HandleMouseUp(posX, posY, e)

          if (info.source == this.targetCanvas) {
            DocumentEventObserver.instance()?.stopCapture()
          }

          e.stopImmediatePropagation()
          return true
        }

        return false
      }
    }
  }

  public async initialize(features: string): Promise<void> {
    if (this.initialized) return
    this.targetCanvas = this.initializeCanvas()
    const { default: EngineModule } = await import('./engineModule')
    const { default: EngineModuleInstance } = await EngineModule(
      this.targetCanvas
    )
    this.EngineFeatures = features
    await this.initializeEngine(EngineModuleInstance)

    this.observeDocumentEvents()

    this.initialized = true
  }

  private static keyboardEventListenerID = 'EngineKeyboardEventListener'
  private static mouseEventListenerID = 'EngineMouseEventListener'

  private isAllowedKeyboardKey(key: string): boolean {
    return this.allowedKeyboardKeys.includes(key)
  }

  private observeDocumentEvents(): void {
    DocumentEventObserver.instance()?.addKeyboardEventListener(
      Engine.keyboardEventListenerID,
      this.globalKeyboardListener
    )

    DocumentEventObserver.instance()?.addMouseEventListener(
      Engine.mouseEventListenerID,
      this.globalMouseListener
    )
  }

  private unobserveDocumentEvents() {
    DocumentEventObserver.instance()?.removeKeyboardEventListener(
      Engine.keyboardEventListenerID
    )

    DocumentEventObserver.instance()?.removeMouseEventListener(
      Engine.mouseEventListenerID
    )
  }

  private initializeCanvas(): HTMLCanvasElement {
    const devicePixelRatio = window.devicePixelRatio || 1
    const targetCanvas = document.createElement('canvas')
    targetCanvas.id = CANVAS_ID
    targetCanvas.addEventListener('contextmenu', ev => ev.preventDefault())

    this.appendCanvas(targetCanvas)

    const xres = Math.round(targetCanvas.offsetWidth * devicePixelRatio) | 0
    const yres = Math.round(targetCanvas.offsetHeight * devicePixelRatio) | 0
    console.log(
      `React  : Setting canvas to ${xres} x ${yres} for CSS  ${targetCanvas.clientWidth} x ${targetCanvas.offsetHeight} (dpi = ${devicePixelRatio})`
    )
    targetCanvas.width = xres
    targetCanvas.height = yres

    // needed for focus() bellow to work
    targetCanvas.setAttribute('tabindex', '0')
    targetCanvas.focus()

    targetCanvas.addEventListener('click', () => {
      targetCanvas.focus()
    })

    return targetCanvas
  }

  private appendCanvas(canvas: HTMLCanvasElement) {
    const canvasContainer = document.getElementById(CANVAS_CONTAINER_ID)
    if (!canvasContainer) throw new Error('missing canvas container')
    canvasContainer.appendChild(canvas)
  }

  public unmount() {
    this.EngineInstance?.CommandCloseModel()
    this.unobserveDocumentEvents()

    if (!this.targetCanvas) return

    this.targetCanvas.parentNode?.removeChild(this.targetCanvas)

    if (typeof this.animationFrameRef === 'number') {
      window.cancelAnimationFrame(this.animationFrameRef)
    }
  }

  public mount() {
    this.observeDocumentEvents()

    if (!this.targetCanvas) return
    this.appendCanvas(this.targetCanvas)
    this.animationFrameRef = requestAnimationFrame(this.animate)
  }

  public getModule() {
    return this.EngineInstance?.Module
  }

  private initializeEngine(module: any) {
    return new Promise<void>((resolve, reject) => {
      this.EngineInstance = {
        Module: module,
        Initialize: module.cwrap('Neo_Initialize', 'number', [
          'number',
          'string',
          'number',
          'number',
          'number',
          'number',
          'number',
          'number',
          'number',
          'number',
          'number',
          'number',
          'number',
          'number',
          'number',
          'number',
          'number',
          'number'
        ]),
        DeInitialize: module.cwrap('Neo_DeInitialize', 'number', []),
        DoFrame: module.cwrap('Neo_DoFrame', 'number', ['string']),

        Command_ML_DepthToImage: module.cwrap(
          'Neo_Command_ML_DepthToImage',
          'number',
          ['string', 'string']
        ),
        CommandPngCapture: module.cwrap('Neo_CommandPngCapture', 'number', [
          'number',
          'number'
        ]),
        CommandJpgCapture: module.cwrap('Neo_CommandJpgCapture', 'number', [
          'number'
        ]),
        CommandScreenshotCapture: module.cwrap(
          'Neo_CommandScreenshotCapture',
          'number',
          []
        ),
        CommandDiffusionReferenceCapture: module.cwrap(
          'Neo_CommandDiffusionReferenceCapture',
          'number',
          []
        ),
        CommandVectorCapture: module.cwrap(
          'Neo_CommandVectorCapture',
          'number',
          ['number']
        ),
        CommandTransferMaterial: module.cwrap(
          'Neo_CommandTransferMaterial',
          'number',
          ['string', 'number']
        ),
        CommandCopyMaterial: module.cwrap('Neo_CommandCopyMaterial', 'number', [
          'string'
        ]),
        CommandPasteMaterial: module.cwrap(
          'Neo_CommandPasteMaterial',
          'number',
          ['string', 'number']
        ),
        CommandMaterial_KeepStylesInSynch: module.cwrap(
          'Neo_CommandMaterial_KeepStylesInSynch',
          'number',
          ['number']
        ),
        CommandUsers_ToggleVisibility: module.cwrap(
          'Neo_CommandUsers_ToggleVisibility',
          'number',
          []
        ),
        CommandUsers_SetRemoteStatus: module.cwrap(
          'Neo_CommandUsers_SetRemoteStatus',
          'number',
          ['string']
        ),
        CommandUsers_SetRemoteLocation: module.cwrap(
          'Neo_CommandUsers_SetRemoteLocation',
          'number',
          ['string']
        ),
        CommandGetVec2Property: module.cwrap(
          'Neo_CommandGetVec2Property',
          'number',
          ['string']
        ),
        CommandUsers_Get2DPosition: module.cwrap(
          'Neo_CommandUsers_Get2DPosition',
          'number',
          ['string']
        ),
        CommandUsers_GetLocal: module.cwrap(
          'Neo_CommandUsers_GetLocal',
          'string',
          []
        ),
        CommandUsers_SetLocalLocation: module.cwrap(
          'Neo_CommandUsers_SetLocalLocation',
          'number',
          ['string']
        ),
        CommandDebug: module.cwrap('Neo_CommandDebug', 'number', ['number']),
        CommandDebugUISetParam: module.cwrap(
          'Neo_CommandDebugUISetParam',
          'number',
          ['number', 'number']
        ),
        CommandDebugUISetColor: module.cwrap(
          'Neo_CommandDebugUISetColor',
          'number',
          ['number', 'number', 'number', 'number']
        ),
        CommandOpenModel: module.cwrap('Neo_CommandOpenModel', 'number', [
          'string'
        ]),
        CommandCloseModel: module.cwrap('Neo_CommandCloseModel', 'number', []),
        CommandSaveModel: module.cwrap('Neo_CommandSaveModel', 'number', []),
        CommandSetStyleParam: module.cwrap(
          'Neo_CommandSetStyleParam',
          'number',
          ['number', 'number', 'number']
        ),
        CommandSetStyleParamColor: module.cwrap(
          'Neo_CommandSetStyleParamColor',
          'number',
          ['number', 'number', 'number', 'number', 'number']
        ),
        CommandLightEnable: module.cwrap('Neo_CommandLightEnable', 'number', [
          'number',
          'number'
        ]),
        CommandLightTogleAll: module.cwrap(
          'Neo_CommandLightTogleAll',
          'number',
          ['number']
        ),
        CommandLightSetParam: module.cwrap(
          'Neo_CommandLightSetParam',
          'number',
          ['number', 'number', 'number', 'number']
        ),
        CommandLightSetParamColor: module.cwrap(
          'Neo_CommandLightSetParamColor',
          'number',
          ['number', 'number', 'number', 'number', 'number', 'number']
        ),
        CommandSetMode: module.cwrap('Neo_CommandSetMode', 'number', [
          'number'
        ]),
        CommandFloorEnable: module.cwrap('Neo_CommandFloorEnable', 'number', [
          'number'
        ]),
        CommandFloorHeight: module.cwrap(
          'Neo_CommandFloorSetHeight',
          'number',
          ['number', 'number', 'number']
        ),
        CommandModelSetPose: module.cwrap('Neo_CommandModelSetPose', 'number', [
          'number'
        ]),
        CommandAddPrim: module.cwrap('Neo_CommandAddPrim', 'number', [
          'number'
        ]),
        CommandDelPrim: module.cwrap('Neo_CommandDelPrim', 'number', [
          'number'
        ]),
        CommandUndoRedo: module.cwrap('Neo_CommandUndoRedo', 'number', [
          'number'
        ]),
        // shape
        CommandSetPrimLocation: module.cwrap(
          'Neo_CommandSetPrimLocation',
          'number',
          [
            'string',
            'number',
            'number',
            'number',
            'number',
            'number',
            'number',
            'number'
          ]
        ),
        CommandSetPrimSize: module.cwrap('Neo_CommandSetPrimSize', 'number', [
          'string',
          'number',
          'number',
          'number'
        ]),
        CommandSetScaleCorners: module.cwrap(
          'Neo_CommandSetScaleCorners',
          'number',
          ['number']
        ),
        CommandSetPrimParameter: module.cwrap(
          'Neo_CommandSetPrimParameter',
          'number',
          ['string', 'number', 'number', 'number']
        ),
        CommandSetPrimBlendType: module.cwrap(
          'Neo_CommandSetPrimBlendType',
          'number',
          ['string', 'number', 'number']
        ),
        CommandSetPrimDrawMaterial: module.cwrap(
          'Neo_CommandSetPrimDrawMaterial',
          'number',
          ['string', 'number', 'number']
        ),
        CommandSetPrimBlendAmount: module.cwrap(
          'Neo_CommandSetPrimBlendAmount',
          'number',
          ['string', 'number', 'number']
        ),
        CommandSetPrimHoleAmount: module.cwrap(
          'Neo_CommandSetPrimHoleAmount',
          'number',
          ['string', 'number', 'number']
        ),
        CommandSetPrimShellAmount: module.cwrap(
          'Neo_CommandSetPrimShellAmount',
          'number',
          ['string', 'number', 'number']
        ),
        CommandSetPrimRoundAmount: module.cwrap(
          'Neo_CommandSetPrimRoundAmount',
          'number',
          ['string', 'number', 'number']
        ),
        CommandSetPrimModifierType: module.cwrap(
          'Neo_CommandSetPrimModifierType',
          'number',
          ['string', 'number', 'number']
        ),
        CommandSetPrimModifierNum: module.cwrap(
          'Neo_CommandSetPrimModifierNum',
          'number',
          ['string', 'number', 'number', 'number']
        ),
        CommandSetPrimModifierDistance: module.cwrap(
          'Neo_CommandSetPrimModifierDistance',
          'number',
          ['string', 'number', 'number', 'number']
        ),
        CommandSetPrimSymmetry: module.cwrap(
          'Neo_CommandSetPrimSymmetry',
          'number',
          ['string', 'number', 'number', 'number']
        ),
        CommandSetPrimName: module.cwrap('Neo_CommandSetPrimName', 'number', [
          'string',
          'string',
          'number'
        ]),
        CommandSetPrimDimensionType: module.cwrap(
          'Neo_CommandSetPrimDimensionType',
          'number',
          ['string', 'number', 'number']
        ),
        CommandSetPrimDimensionParam: module.cwrap(
          'Neo_CommandSetPrimDimensionParam',
          'number',
          ['string', 'number', 'number', 'number']
        ),
        CommandTogglePrimVisibility: module.cwrap(
          'Neo_CommandTogglePrimVisibility',
          'number',
          ['string', 'number']
        ),
        CommandTogglePrimLocked: module.cwrap(
          'Neo_CommandTogglePrimLocked',
          'number',
          ['string', 'number']
        ),
        CommandGetMaterialColor: module.cwrap(
          'Neo_CommandGetMaterialColor',
          'number',
          ['string', 'number', 'number']
        ),
        CommandGetMaterialParam: module.cwrap(
          'Neo_CommandGetMaterialParam',
          'number',
          ['string', 'number', 'number']
        ),
        CommandSetMaterialColor: module.cwrap(
          'Neo_CommandSetMaterialColor',
          'number',
          ['string', 'number', 'number', 'number', 'number', 'number']
        ),
        CommandSetMaterialParam: module.cwrap(
          'Neo_CommandSetMaterialParam',
          'number',
          ['string', 'number', 'number', 'number']
        ),
        CommandSetMaterialColorByStyle: module.cwrap(
          'Neo_CommandSetMaterialColorByStyle',
          'number',
          ['string', 'number', 'number', 'number', 'number', 'number', 'number']
        ),
        CommandSetMaterialParamByStyle: module.cwrap(
          'Neo_CommandSetMaterialParamByStyle',
          'number',
          ['string', 'number', 'number', 'number', 'number']
        ),
        // global
        CommandSetCameraType: module.cwrap(
          'Neo_CommandSetCameraType',
          'number',
          ['number']
        ),
        CommandSetCameraParam: module.cwrap(
          'Neo_CommandSetCameraParam',
          'number',
          ['number', 'number', 'number']
        ),
        CommandCameraRecenter: module.cwrap(
          'Neo_CommandCameraRecenter',
          'number',
          ['number']
        ),
        CommandCameraReset: module.cwrap('Neo_CommandCameraReset', 'number', [
          'number'
        ]),
        CommandCamera_SetOrbitRotation: module.cwrap(
          'Neo_CommandCamera_SetOrbitRotation',
          'number',
          ['number', 'number']
        ),
        CommandCamera_SetOrbitPhiRotation: module.cwrap(
          'Neo_CommandCamera_SetOrbitRotationPhi',
          'number',
          ['number', 'number']
        ),
        CommandDuplicate: module.cwrap('Neo_CommandDuplicate', 'number', [
          'number'
        ]),
        CommandModelSelectParentChild: module.cwrap(
          'Neo_CommandModelSelectParentChild',
          'number',
          ['number']
        ),
        CommandModelSelectSiblin: module.cwrap(
          'Neo_CommandModelSelectSiblin',
          'number',
          ['number']
        ),
        CommandModelReorder: module.cwrap('Neo_CommandModelReorder', 'number', [
          'string',
          'number',
          'number'
        ]),
        CommandSetBackgroundType: module.cwrap(
          'Neo_CommandSetBackgroundType',
          'number',
          ['number']
        ),
        CommandSetBackgroundColor: module.cwrap(
          'Neo_CommandSetBackgroundColor',
          'number',
          ['number', 'number', 'number', 'number', 'number']
        ),
        CommandPlaybackTogle: module.cwrap(
          'Neo_CommandPlaybackTogle',
          'number',
          ['number']
        ),
        CommandShowObjectsTogle: module.cwrap(
          'Neo_CommandShowObjectsTogle',
          'number',
          ['number']
        ),
        CommandSetSnapping: module.cwrap('Neo_CommandSetSnapping', 'number', [
          'number'
        ]),
        CommandIsSnappingEnable: module.cwrap(
          'Neo_CommandIsSnappingEnable',
          'number',
          ['number']
        ),
        Neo_CommandSetAutoFocusEnabled: module.cwrap(
          'Neo_CommandSetAutoFocusEnabled',
          'number',
          ['number']
        ),
        Command_Frame_Toggle: module.cwrap(
          'Neo_Command_Frame_Toggle',
          'number',
          ['string', 'number', 'number']
        ),
        Command_Frame_SetOpacity: module.cwrap(
          'Neo_Command_Frame_SetOpacity',
          'number',
          ['number', 'number']
        ),
        Command_Frame_SetType: module.cwrap(
          'Neo_Command_Frame_SetType',
          'number',
          ['number']
        ),
        Command_Frame_SetSize: module.cwrap(
          'Neo_Command_Frame_SetSize',
          'number',
          ['number', 'number']
        ),
        CommandModelSelectByUUID: module.cwrap(
          'Neo_CommandModelSelectByUUID',
          'number',
          ['string', 'number']
        ),
        CommandSetCameraFromTo: module.cwrap(
          'Neo_CommandSetCameraFromTo',
          'number',
          ['number', 'number', 'number', 'number', 'number', 'number']
        ),
        CommandSetCameraRadiusPhiTheta: module.cwrap(
          'Neo_CommandSetCameraRadiusPhiTheta',
          'number',
          ['number', 'number', 'number']
        ),
        CommandSetCameraRadius: module.cwrap(
          'Neo_CommandSetCameraRadius',
          'number',
          ['number']
        ),
        CommandSetCameraPhi: module.cwrap('Neo_CommandSetCameraPhi', 'number', [
          'number'
        ]),
        CommandZoomSelection: module.cwrap(
          'Neo_CommandZoomSelection',
          'number',
          ['number']
        ),
        CommandOrbitPhi: module.cwrap('Neo_CommandOrbitPhi', 'number', [
          'number'
        ]),
        CommandOrbitTheta: module.cwrap('Neo_CommandOrbitTheta', 'number', [
          'number'
        ]),
        CommandLookAt: module.cwrap('Neo_CommandLookAt', 'number', [
          'number',
          'number',
          'number'
        ]),
        CommandSetCameraDirection: module.cwrap(
          'Neo_CommandSetCameraDirection',
          'number',
          ['number', 'number', 'number']
        ),
        CommandSetHistorySliderPos: module.cwrap(
          'Neo_CommandSetHistorySliderPos',
          'number',
          ['number']
        ),
        CommandSetHistoryMode: module.cwrap(
          'Neo_CommandSetHistoryMode',
          'number',
          ['number']
        ),
        CommandSetEnumValue: module.cwrap('Neo_CommandSetEnumValue', 'number', [
          'string',
          'number',
          'string'
        ]),
        CommandSetIntValue: module.cwrap('Neo_CommandSetIntValue', 'number', [
          'string',
          'number',
          'string'
        ]),
        CommandSetBoolValue: module.cwrap('Neo_CommandSetBoolValue', 'number', [
          'string',
          'number',
          'string'
        ]),
        CommandSetFloatValue: module.cwrap(
          'Neo_CommandSetFloatValue',
          'number',
          ['string', 'number', 'string']
        ),
        CommandSetVec3dValue: module.cwrap(
          'Neo_CommandSetVec3dValue',
          'number',
          ['string', 'number', 'number', 'number', 'string']
        ),
        CommandSetStringValue: module.cwrap(
          'Neo_CommandSetStringValue',
          'number',
          ['string', 'string', 'string']
        ),
        CommandSetIVec2Value: module.cwrap(
          'Neo_CommandSetIVec2Value',
          'number',
          ['string', 'number', 'number', 'string']
        ),
        CommandSetIVec3Value: module.cwrap(
          'Neo_CommandSetIVec3Value',
          'number',
          ['string', 'number', 'number', 'number', 'string']
        ),
        CommandBeginCommit: module.cwrap('Neo_CommandBeginCommit', null),
        CommandEndCommit: module.cwrap('Neo_CommandEndCommit', null),
        Command_AddSnapshot: module.cwrap('Neo_CommandAddSnapshot', null, [
          'string',
          'number'
        ]),
        Command_ApplySnapshot: module.cwrap(
          'Neo_CommandApplySnapshot',
          'number',
          ['number']
        ),
        Command_OverwriteSnapshot: module.cwrap(
          'Neo_CommandOverwriteSnapshot',
          'number',
          ['number']
        ),
        Command_RenameSnapshot: module.cwrap(
          'Neo_CommandRenameSnapshot',
          'number',
          ['index', 'string']
        ),
        Command_RemoveSnapshot: module.cwrap(
          'Neo_CommandRemoveSnapshot',
          'number',
          ['number']
        ),
        enableEventsQueue: module['Neo_EnableEventsQueue'],
        Command_HandleKeyDown: module['Command_Handle_Keydown'],
        Command_HandleKeyUp: module['Command_Handle_Keyup'],
        Command_HandleMouseDown: module['Command_Handle_Mousedown'],
        Command_HandleMouseMoved: module['Command_Handle_Mousemoved'],
        Command_HandleMouseUp: module['Command_Handle_Mouseup'],
        UTF8ToString: module.UTF8ToString
      }

      const callbackModelLoaded = module.addFunction(
        (status: number, message: number) => {
          const data: ModelLoadedStatusEventPayload = {
            status: status,
            sceneData: module.UTF8ToString(message)
          }

          this.EngineStatusChannel.emit('status', data)
        },
        'vii'
      )

      const callbackTexturesLoaded = module.addFunction(() => {
        this.EngineDataChannel.emit('illustrativeTexturesLoaded', true)
      }, 'v')

      const callbackPropertyPanel = module.addFunction(
        (status: number, origin: EngineDataOrigin, sceneData: number) => {
          // catch error if invalid json
          // this can be cleaned up but err'ing on the side of caution
          try {
            const parsedSceneJSON = parseValidEngineJson(
              module.UTF8ToString(sceneData)
            )

            const data: EngineData = {
              status: status,
              origin: origin,
              sceneData:
                // there's data only if selection is shape(1) || group(2) || project(3) || multiselect(4)?
                status === 1 || status === 2 || status === 3
                  ? parsedSceneJSON
                  : null
            }
            // catch error if wrong lighting data has been emitted to sagas
            try {
              if (data?.sceneData?.lighting) {
                this.EngineLightingChannel.emit(
                  this.engineLightingChannelKey,
                  data.sceneData.lighting
                )
              }
            } catch (err) {
              console.error(err)
              captureException(JSON.stringify(err))
            }

            // catch error if wrong data has been emitted to sagas
            try {
              this.EngineDataChannel.emit('data', data)
            } catch (err) {
              console.error(err)
              captureException(JSON.stringify(err))
            }
          } catch (err) {
            console.error(err)
            captureException(JSON.stringify(err))
            traceInvalidJsonError(
              'engine.ts',
              'callbackPropertyPanel',
              module.UTF8ToString(sceneData)
            )
          }
        },
        'viii'
      )

      const sceneContentHasChangedCallback = module.addFunction(
        (
          primitiveType: number,
          selectedSceneNode: number,
          styleMode: number
        ) => {
          try {
            const data: SceneContentData = {
              primitiveType: primitiveType === -1 ? null : primitiveType,
              selectedSceneNode,
              styleMode
            }

            this.EngineSceneContentChannel.emit(
              this.engineSceneContentChannelKey,
              data
            )
          } catch (err) {
            console.error(err)
          }
        },
        'viii'
      )

      const propertyListHasChangedCallback = module.addFunction(
        (sceneData: number) => {
          try {
            const data: PropertyListHasChangedData = JSON.parse(
              module.UTF8ToString(sceneData)
            )

            this.EnginePropertyListHasChangedChannel.emit(
              this.enginePropertyListHasChangedChannelKey,
              data
            )
          } catch (err) {
            console.error(err)
          }
        },
        'vi'
      )

      const snapshotListHasChangedCallback = module.addFunction(
        (snapshotData: number) => {
          try {
            const data: SnapshotListHasChangedData = JSON.parse(
              module.UTF8ToString(snapshotData)
            )

            this.EngineSnapshotListHasChangedChannel.emit(
              this.engineSnapshotListHasChangedChannelKey,
              data
            )
          } catch (err) {
            console.error(err)
          }
        },
        'vi'
      )

      const snapshotRenamedCallback = module.addFunction(
        (snapshotData: number) => {
          try {
            const data: SnapshotListHasChangedData = JSON.parse(
              module.UTF8ToString(snapshotData)
            )

            this.EngineSnapshotRenamedChannel.emit(
              this.engineSnapshotRenamedChannelKey,
              data
            )
          } catch (err) {
            console.error(err)
          }
        },
        'vi'
      )

      const propertyHasChangedCallback = module.addFunction(
        (path: number, panelId: number, val: number) => {
          try {
            const strPath = module.UTF8ToString(path)
            const strPanelId = module.UTF8ToString(panelId)
            const strVal = module.UTF8ToString(val)

            const data: PropertyHasChangedData = {
              path: strPath,
              panelId: strPanelId,
              value: strVal
            }

            this.EnginePropertyHasChangedChannel.emit(
              this.enginePropertyHasChangedChannelKey,
              data
            )
          } catch (err) {
            console.error(err)

            const strPath = module.UTF8ToString(path)
            const strPanelId = module.UTF8ToString(panelId)
            const strVal = module.UTF8ToString(val)
            captureException(
              `Path = ${strPath}, value = ${strVal}, panelId = ${strPanelId} and error is ${JSON.stringify(
                err
              )}`
            )
          }
        },
        'viii'
      )

      const propertySoftBoundsHaveChangedCallback = module.addFunction(
        (path: number, min: number, max: number) => {
          try {
            const data: PropertySoftBoundsHaveChangedData = {
              path: module.UTF8ToString(path),
              min,
              max
            }

            this.EnginePropertySoftBoundsHaveChangedChannel.emit(
              this.enginePropertySoftBoundsHaveChangedChannelKey,
              data
            )
          } catch (err) {
            console.error(err)
          }
        },
        'viff'
      )

      const callbackSceneNavigator = module.addFunction(
        (status: number, data: number) => {
          try {
            const payload: SceneNavigatorEventPayload =
              status === 0
                ? {
                    status: 'NO_ELEMENT_SELECTED'
                  }
                : {
                    data: parseValidEngineJson(module.UTF8ToString(data)),
                    status: 'AT_LEAST_ONE_ELEMENT_SELECTED'
                  }
            this.EngineDataChannel.emit('scene-navigator-data', payload)
          } catch {
            traceInvalidJsonError(
              'engine.ts',
              'callbackSceneNavigator',
              module.UTF8ToString(data)
            )
          }
        },
        'vii'
      )

      const callbackFrame = module.addFunction(
        (status: number, data: number) => {
          if (status === 0) return
          try {
            const {
              info: { enabled, size, topleft }
            }: FrameCallbackData = parseValidEngineJson(
              module.UTF8ToString(data)
            )

            const payload: FrameEventPayload = {
              enabled,
              size: { width: size[0], height: size[1] },
              position: { top: topleft[1], left: topleft[0] }
            }

            this.EngineDataChannel.emit('frame-data', payload)
          } catch {
            traceInvalidJsonError(
              'engine.ts',
              'callbackFrame',
              module.UTF8ToString(data)
            )
          }
        },
        'vii'
      )

      const callbackShowPerformance = module.addFunction(
        (fps: number, mspf: number, dummy: number) => {
          this.EngineDebugChannel.emit('debug', {
            fps: fps,
            mspf: mspf,
            dummy: dummy
          })
        },
        'viii'
      )

      const callbackSetCursor = module.addFunction(
        (cursorID: number, _str_unused_: number) => {
          const cursor: Cursor = cursorID

          this.setCursor(cursor)
        },
        'vii'
      )

      const callbackCaptureReady = module.addFunction(
        (
          status: number,
          buffer: number,
          bufferSize: number,
          depthBuffer: number,
          depthBufferSize: number,
          colorBuffer: number,
          colorBufferSize: number
        ) => {
          const captureStatus: Record<number, string | null> = {
            0: null,
            1: 'jpg',
            2: 'png',
            3: 'svg',
            4: 'screenshot',
            5: 'reference'
          }

          // TODO: handle null capture status
          if (captureStatus[status] === null) {
            return
          }
          const blobArray = new Uint8Array(
            module.HEAPU8.buffer,
            buffer,
            bufferSize
          )

          const depthBlobArray = new Uint8Array(
            module.HEAPU8.buffer,
            depthBuffer,
            depthBufferSize
          )

          const colorBlobArray = new Uint8Array(
            module.HEAPU8.buffer,
            colorBuffer,
            colorBufferSize
          )

          if (captureStatus[status] === 'jpg') {
            const objectURL = URL.createObjectURL(
              new Blob([blobArray], { type: 'image/jpg' })
            )

            this.EngineDataChannel.emit('capture_image_data', {
              objectURL,
              format: 'jpg'
            } as EngineCaptureImagePayload)
          } else if (captureStatus[status] === 'png') {
            const objectURL = URL.createObjectURL(
              new Blob([blobArray], { type: 'image/png' })
            )

            this.EngineDataChannel.emit('capture_image_data', {
              objectURL,
              format: 'png'
            } as EngineCaptureImagePayload)
          } else if (captureStatus[status] === 'svg') {
            let string = ''
            for (let i = 0; i < bufferSize; i++) {
              string += String.fromCharCode(blobArray[i])
            }
            const objectURL = URL.createObjectURL(
              new Blob([string], { type: 'image/svg+xml' })
            )

            this.EngineDataChannel.emit('capture_image_data', {
              objectURL,
              format: 'svg'
            } as EngineCaptureImagePayload)
          } else if (captureStatus[status] === 'screenshot') {
            const objectURL = URL.createObjectURL(
              new Blob([blobArray], { type: 'image/jpg' })
            )

            this.EngineDataChannel.emit('capture_image_data', {
              objectURL,
              blobArray,
              format: 'screenshot'
            } as EngineCaptureImagePayload)
          } else if (captureStatus[status] === 'reference') {
            const objectURL = URL.createObjectURL(
              new Blob([blobArray], { type: 'image/png' })
            )

            const depthObjectURL = URL.createObjectURL(
              new Blob([depthBlobArray], { type: 'image/png' })
            )

            const colorObjectURL = URL.createObjectURL(
              new Blob([colorBlobArray], { type: 'image/png' })
            )

            this.EngineDataChannel.emit('capture_reference_image_data', {
              objectURL,
              blobArray,
              depthObjectURL,
              depthBlobArray,
              colorObjectURL,
              colorBlobArray,
              format: 'reference'
            } as EngineCaptureImagePayload)
          }
        },
        'viiiiiii'
      )

      const callbackSelectedObjectUI = module.addFunction(
        (status: number, centerX: number, centerY: number) => {
          this.EngineDataChannel.emit('selected-object-ui', {
            status,
            editButtonGizmoPosition: {
              centerX,
              centerY
            }
          } as SelectedObjectUI)
        },
        'viff'
      )

      // res = 0: failed
      // res = 1: ok
      // res = 2: ok, but slow Intel GPU detected
      // res = 3: failed, wrong Neo version
      const res = this.EngineInstance?.Initialize(
        2, // Neo version
        '/engine/build/',
        callbackModelLoaded,
        callbackShowPerformance,
        callbackPropertyPanel,
        callbackSceneNavigator,
        callbackFrame,
        callbackSetCursor,
        callbackCaptureReady,
        callbackTexturesLoaded,
        callbackSelectedObjectUI,
        propertyHasChangedCallback,
        propertyListHasChangedCallback,
        sceneContentHasChangedCallback,
        propertySoftBoundsHaveChangedCallback,
        snapshotListHasChangedCallback,
        snapshotRenamedCallback,
        isMacOSX() ? 1 : 0
      )

      if (res == 0) {
        return reject()
      }

      if (res == 3) {
        console.log( 'module  version mismatch. Expected' )
        return reject()
      }

      if (res == 2) {
        // Defer execution to end of stack to emit event to allow sagas to finish subscribing to engine event channels
        setTimeout(() => {
          this.EngineStatusChannel.emit(
            'device-status',
            DeviceStatusEventPayload.SLOW_INTEL_GPU
          )
        }, 0)
      }

      this.EngineInstance?.enableEventsQueue(false)

      this.animationFrameRef = requestAnimationFrame(this.animate)

      return resolve()
    })
  }

  private openCommitBuffer: boolean = false

  public addSnapshot(name: string, index: number) {
    return this.EngineInstance?.Command_AddSnapshot(name, index)
  }

  public applySnapshot(index: number) {
    return this.EngineInstance?.Command_ApplySnapshot(index)
  }

  public overwriteSnapshot(index: number) {
    return this.EngineInstance?.Command_OverwriteSnapshot(index)
  }

  public renameSnapshot(index: number, newName: string) {
    return this.EngineInstance?.Command_RenameSnapshot(index, newName)
  }

  public removeSnapshot(index: number) {
    return this.EngineInstance?.Command_RemoveSnapshot(index)
  }

  public setPropertyPanelValue = (
    path: PropertyPanelPath,
    val: any,
    panelId: string = defaultPropertyPanelId,
    commit?: EngineCommitChange
  ): PropertyPanelSetStatus => {
    const variant: PropertyPanelVariant = propertyPanelPathVariantDict[path]

    try {
      if (!this.validatePropertyPanelVariant(val, variant)) {
        throw new Error(
          `Invalid type of value of ${JSON.stringify(
            val
          )} passed in for path ${path}`
        )
      }

      let status: PropertyPanelSetStatus = PropertyPanelSetStatus.IncorrectType

      if (commit === EngineCommitChange.BEGIN_COMMIT) {
        this.beginCommit()
        this.openCommitBuffer = true
      }

      if (variant === 'int') {
        status = this.setPropertyPanelIntValue(path, val, panelId)
      }

      if (variant === 'bool') {
        status = this.setPropertyPanelBoolValue(path, val, panelId)
      }

      if (variant === 'EnumBase') {
        status = this.setPropertyPanelEnumValue(path, val, panelId)
      }

      if (variant === 'float') {
        status = this.setPropertyPanelFloatValue(path, val, panelId)
      }

      if (variant === 'string') {
        status = this.setPropertyPanelStringValue(path, val, panelId)
      }

      if (variant === 'vec2d') {
        status = this.setPropertyPanelVec2dValue(path, val, panelId)
      }

      if (variant === 'vec3d') {
        status = this.setPropertyPanelVec3dValue(path, val, panelId)
      }

      if (variant === 'ivec2') {
        status = this.setPropertyPanelIVec2Value(path, val, panelId)
      }

      if (variant === 'ivec3') {
        status = this.setPropertyPanelIVec3Value(path, val, panelId)
      }

      if (commit === EngineCommitChange.END_COMMIT) {
        this.endCommit()
        this.openCommitBuffer = false
      }

      return status
    } catch (err) {
      captureException(JSON.stringify(err)) // send to Sentry
      console.error(err)
    }
  }

  private validatePropertyPanelVariant = (
    val: any,
    variant: PropertyPanelVariant
  ): boolean => {
    switch (variant) {
      case 'bool':
        return BoolGuard(val)
      case 'EnumBase':
        return EnumBaseGuard(val)
      case 'float':
        return FloatGuard(val)
      case 'int':
        return IntGuard(val)
      case 'string':
        return StringGuard(val)
      case 'vec2d':
        return Vec2dGuard(val)
      case 'vec3d':
        return Vec3dGuard(val)
      case 'ivec2':
        return IVec2Guard(val)
      case 'ivec3':
        return IVec3Guard(val)
      default:
        return false
    }
  }

  private setPropertyPanelBoolValue: PropertyPanelSetter = (
    path: string,
    val: boolean,
    panelId: string = defaultPropertyPanelId
  ) => {
    return this.EngineInstance?.CommandSetBoolValue(path, Number(val), panelId)
  }

  private setPropertyPanelEnumValue: PropertyPanelSetter = (
    path: string,
    val: number,
    panelId: string = defaultPropertyPanelId
  ) => {
    return this.EngineInstance?.CommandSetEnumValue(path, val, panelId)
  }

  private setPropertyPanelIntValue: PropertyPanelSetter = (
    path: string,
    val: number,
    panelId: string = defaultPropertyPanelId
  ) => {
    return this.EngineInstance?.CommandSetIntValue(path, val, panelId)
  }

  private setPropertyPanelFloatValue: PropertyPanelSetter = (
    path: string,
    val: number,
    panelId: string = defaultPropertyPanelId
  ) => {
    return this.EngineInstance?.CommandSetFloatValue(path, val, panelId)
  }

  private setPropertyPanelVec2dValue: PropertyPanelSetter = (
    path: string,
    val: Vec2d,
    panelId: string = defaultPropertyPanelId
  ) => {
    return this.EngineInstance?.CommandSetVec2dValue(
      path,
      val.x,
      val.y,
      panelId
    )
  }

  private setPropertyPanelVec3dValue: PropertyPanelSetter = (
    path: string,
    val: Vec3d,
    panelId: string = defaultPropertyPanelId
  ) => {
    return this.EngineInstance?.CommandSetVec3dValue(
      path,
      val.x,
      val.y,
      val.z,
      panelId
    )
  }

  private setPropertyPanelStringValue: PropertyPanelSetter = (
    path: string,
    val: string,
    panelId: string = defaultPropertyPanelId
  ) => {
    return this.EngineInstance?.CommandSetStringValue(path, val, panelId)
  }

  private setPropertyPanelIVec2Value: PropertyPanelSetter = (
    path: string,
    val: IVec2,
    panelId: string = defaultPropertyPanelId
  ) => {
    return this.EngineInstance?.CommandSetIVec2Value(
      path,
      val.x,
      val.y,
      panelId
    )
  }

  private setPropertyPanelIVec3Value: PropertyPanelSetter = (
    path: string,
    val: IVec3,
    panelId: string = defaultPropertyPanelId
  ) => {
    return this.EngineInstance?.CommandSetIVec3Value(
      path,
      val.x,
      val.y,
      val.z,
      panelId
    )
  }

  private beginCommit = () => this.EngineInstance?.CommandBeginCommit()
  private endCommit = () => this.EngineInstance?.CommandEndCommit()

  private animate() {
    try {
      if (!this.targetCanvas) return
      // make sure the canvas size has exactly one pixel per device pixel
      const dpr = window.devicePixelRatio || 1

      const r = this.targetCanvas?.getBoundingClientRect()
      const displayWidth = Math.round(r.right * dpr) - Math.round(r.left * dpr)
      const displayHeight = Math.round(r.bottom * dpr) - Math.round(r.top * dpr)
      this.targetCanvas.width = displayWidth
      this.targetCanvas.height = displayHeight

      this.EngineInstance?.DoFrame(this.EngineFeatures)

      this.EngineFrameDataChannel.emit(
        'location_data',
        this.getLocalUserLocation()
      )

      this.animationFrameRef = requestAnimationFrame(this.animate)
    } catch (e) {
      console.error(e)
    }
  }

  public updateFeatures(features: string) {
    this.EngineFeatures = features
  }

  public async loadDocument(uri: string): number {
    const accessToken: ITokenInformation | null =
      await AdobeIMS.getAccessTokenAsync()

    const response = await fetch(uri, {
      headers: {
        authorization: `Bearer ${accessToken?.token!}`,
        'X-Api-Key': process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID
      }
    }).then(res => res.text())

    const blobURL = URL.createObjectURL(
      new Blob([response], { type: 'application/json' })
    )

    const result = this.EngineInstance?.CommandOpenModel(blobURL)

    URL.revokeObjectURL(blobURL)

    return result
  }

  public getDocument(): string {
    const model = this.EngineInstance?.CommandSaveModel()
    return this.EngineInstance?.UTF8ToString(model)
  }

  public getLocalUserLocation(): LocalUserLocation {
    return this.EngineInstance?.CommandUsers_GetLocal()
  }

  public setLocalUserLocation(location: LocalUserLocation): number {
    const data = JSON.stringify(location)
    return this.EngineInstance?.CommandUsers_SetLocalLocation(data)
  }

  public getRemoteUserCursorPosition(uuid: string): number {
    return this.EngineInstance?.CommandUsers_Get2DPosition(uuid)
  }

  public get2DPosition(): { x: number; y: number } {
    const position = this.EngineInstance?.CommandGetVec2Property(
      'SelectionCenterCSS'
    ) as number
    return { x: (position & 0xffff) / 8, y: ((position >> 16) & 0xffff) / 8 }
  }

  public setRemoteUserStatus(status: RemoteUserStatus): number {
    const data = JSON.stringify(status)
    return this.EngineInstance?.CommandUsers_SetRemoteStatus(data)
  }

  public setRemoteUserLocation(location: RemoteUserLocation): number {
    const data = JSON.stringify(location)
    return this.EngineInstance?.CommandUsers_SetRemoteLocation(data)
  }

  public toggleUsersAvatarVisibility() {
    return this.EngineInstance?.CommandUsers_ToggleVisibility()
  }

  public setMode(mode: EngineMode) {
    return this.EngineInstance?.CommandSetMode(mode)
  }

  public setStyleParam(value: number) {
    return this.EngineInstance?.CommandSetStyleParam(0, value, 1) // this looks wrong, we should only pass 1 in the last param on the first click, not each time
  }

  public setStyleParamExplicit(v1: number, v2: number, save_for_undo: number) {
    return this.EngineInstance?.CommandSetStyleParam(v1, v2, save_for_undo)
  }

  public setStyleParamColor(
    color: string,
    commit: EngineCommitChange = EngineCommitChange.DONT_COMMIT
  ) {
    const [r, g, b] = this.hexColorToEngineColor(color)
    return this.EngineInstance?.CommandSetStyleParamColor(0, r, g, b, commit)
  }

  public setStyleParamColorExplicit(
    id: number,
    color: string,
    commit: EngineCommitChange = EngineCommitChange.DONT_COMMIT
  ) {
    const [r, g, b] = this.hexColorToEngineColor(color)
    return this.EngineInstance?.CommandSetStyleParamColor(id, r, g, b, commit)
  }

  public addPrimitive(primitive: EnginePrimitive): number {
    return this.EngineInstance?.CommandAddPrim(primitive)
  }

  public duplicatePrimitive(): number {
    return this.EngineInstance?.CommandDuplicate(-1)
  }

  public deletePrimitive(): number {
    return this.EngineInstance?.CommandDelPrim(-1)
  }

  public setPrimitiveModifierType(
    value: EngineRepeatType,
    address?: string,
    commit: EngineCommitChange = EngineCommitChange.DONT_COMMIT,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ): number {
    return this.EngineInstance?.CommandSetPrimModifierType(
      address,
      value,
      commit | origin
    )
  }

  public setPrimitiveModifierValue(
    property: PrimitiveModifierProperty,
    value: number,
    address: string | null = null,
    commit: EngineCommitChange = EngineCommitChange.DONT_COMMIT,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ): number {
    return this.EngineInstance?.CommandSetPrimModifierNum(
      address,
      property,
      value,
      commit | origin
    )
  }

  public setPrimitiveModifierDistance(
    channel: number,
    value: number,
    commit: EngineCommitChange = EngineCommitChange.DONT_COMMIT,
    address?: string,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    return this.EngineInstance?.CommandSetPrimModifierDistance(
      address,
      channel,
      value,
      commit | origin
    )
  }

  // location [positionX, positionY, positionZ, rotationX, rotationY, rotationZ]
  public setPrimitiveLocation(
    location: [number, number, number, number, number, number],
    commit: EngineCommitChange,
    address?: string,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    return this.EngineInstance?.CommandSetPrimLocation(
      address,
      ...location,
      commit | origin
    )
  }

  // size [scaleX, scaleY, scaleZ]
  public setPrimitiveSize(
    size: [number, number, number],
    commit: EngineCommitChange,
    address?: string,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    return this.EngineInstance?.CommandSetPrimSize(
      address,
      ...size,
      commit | origin
    )
  }

  public setPrimitiveScaleCorners(value: boolean) {
    return this.EngineInstance?.CommandSetScaleCorners(value ? 1 : 0)
  }

  public setPrimitiveHoleAmount(
    value: number,
    commit: EngineCommitChange = EngineCommitChange.DONT_COMMIT,
    address?: string,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    return this.EngineInstance?.CommandSetPrimHoleAmount(
      address,
      value,
      commit | origin
    )
  }

  public setPrimitiveParameter(
    parameter: PrimitiveParameter,
    value: number,
    commit: EngineCommitChange = EngineCommitChange.DONT_COMMIT,
    address?: string,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    return this.EngineInstance?.CommandSetPrimParameter(
      address,
      parameter,
      value,
      commit | origin
    )
  }

  public setPrimitiveDimensionType(value: PrimitiveDimensionType) {
    return this.EngineInstance?.CommandSetPrimDimensionType(null, value, 1)
  }

  public setPrimitiveDimensionParam(
    parameter: PrimitiveDimensionParam,
    value: number,
    commit: EngineCommitChange = EngineCommitChange.DONT_COMMIT,
    address?: string,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    return this.EngineInstance?.CommandSetPrimDimensionParam(
      address,
      parameter,
      value,
      commit | origin
    )
  }

  public setPrimitiveShellAmount(
    value: number,
    commit: EngineCommitChange = EngineCommitChange.DONT_COMMIT,
    address?: string,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    return this.EngineInstance?.CommandSetPrimShellAmount(
      address,
      value,
      commit | origin
    )
  }

  public setPrimitiveRoundAmount(
    value: number,
    commit: EngineCommitChange = EngineCommitChange.DONT_COMMIT,
    address?: string,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    return this.EngineInstance?.CommandSetPrimRoundAmount(
      address,
      value,
      commit | origin
    )
  }

  public setPrimitiveSymmetry(
    parameter: PrimitiveSymmetryParam,
    value: PrimitiveSymmetryChecked
  ) {
    return this.EngineInstance?.CommandSetPrimSymmetry(
      null,
      parameter,
      value,
      1
    )
  }

  public setPrimitiveName(
    value: string,
    commit: EngineCommitChange = EngineCommitChange.DONT_COMMIT
  ) {
    return this.EngineInstance?.CommandSetPrimName(null, value, commit)
  }

  public togglePrimitiveVisibility(uuid: string) {
    return this.EngineInstance?.CommandTogglePrimVisibility(
      uuid,
      EngineCommitChange.SINGLE_COMMIT
    )
  }

  public getMaterialProperties(address?: string) {
    if (!this.EngineInstance) return null
    const color = this.EngineInstance.CommandGetMaterialColor(
      address,
      MaterialColorParam.COLOR,
      EngineMode.NORMAL
    )
    const top = this.EngineInstance.CommandGetMaterialColor(
      address,
      MaterialColorParam.E_COLOR_TOP,
      EngineMode.OUTLINE
    )
    const lig = this.EngineInstance.CommandGetMaterialColor(
      address,
      MaterialColorParam.E_COLOR_LIG,
      EngineMode.OUTLINE
    )
    const sha = this.EngineInstance.CommandGetMaterialColor(
      address,
      MaterialColorParam.E_COLOR_SHA,
      EngineMode.OUTLINE
    )
    const i_color = this.EngineInstance.CommandGetMaterialColor(
      address,
      MaterialColorParam.I_COLOR,
      EngineMode.ILLUSTRATIVE
    )
    const i_emissive_color = this.EngineInstance.CommandGetMaterialColor(
      address,
      MaterialColorParam.I_EMISSIVE_COLOR,
      EngineMode.ILLUSTRATIVE
    )

    if ([color, top, lig, sha].some((color: number) => color === -1))
      return null
    const properties = {
      materialESpecularIntensity: this.EngineInstance.CommandGetMaterialParam(
        address,
        MaterialParam.E_SPECULAR_INTENSITY,
        EngineMode.OUTLINE
      ) as number,
      materialESpecularSize: this.EngineInstance.CommandGetMaterialParam(
        address,
        MaterialParam.E_SPECULAR_SIZE,
        EngineMode.OUTLINE
      ) as number,
      materialMetalness: this.EngineInstance.CommandGetMaterialParam(
        address,
        MaterialParam.METALNESS,
        EngineMode.NORMAL
      ) as number,
      materialReflective: this.EngineInstance.CommandGetMaterialParam(
        address,
        MaterialParam.REFLECTIVE,
        EngineMode.NORMAL
      ) as number,
      materialRoughness: this.EngineInstance.CommandGetMaterialParam(
        address,
        MaterialParam.ROUGHNESS,
        EngineMode.NORMAL
      ) as number,
      materialIStrokeSize: this.EngineInstance.CommandGetMaterialParam(
        address,
        MaterialParam.I_STROKE_SIZE,
        EngineMode.ILLUSTRATIVE
      ) as number,
      materialIHighlightIntensity: this.EngineInstance.CommandGetMaterialParam(
        address,
        MaterialParam.I_HIGHLIGHT_INTENSITY,
        EngineMode.ILLUSTRATIVE
      ) as number,
      materialIStrokeIntensity: this.EngineInstance.CommandGetMaterialParam(
        address,
        MaterialParam.I_STROKE_INTENSITY,
        EngineMode.ILLUSTRATIVE
      ) as number,
      materialIColorVarIntensity: this.EngineInstance.CommandGetMaterialParam(
        address,
        MaterialParam.I_STROKE_INTENSITY,
        EngineMode.ILLUSTRATIVE
      ) as number,
      materialIScaleX: this.EngineInstance.CommandGetMaterialParam(
        address,
        MaterialParam.I_SCALE_X,
        EngineMode.ILLUSTRATIVE
      ) as number,
      materialIScaleY: this.EngineInstance.CommandGetMaterialParam(
        address,
        MaterialParam.I_SCALE_Y,
        EngineMode.ILLUSTRATIVE
      ) as number,
      materialIAngle: this.EngineInstance.CommandGetMaterialParam(
        address,
        MaterialParam.I_ANGLE,
        EngineMode.ILLUSTRATIVE
      ) as number
    }
    if (Object.values(properties).some((value: number) => value === -1))
      return null
    return {
      ...properties,
      materialColor: this.numHexToStrHex(color),
      materialEColorTop: this.numHexToStrHex(top),
      materialEColorLig: this.numHexToStrHex(lig),
      materialEColorSha: this.numHexToStrHex(sha),
      materialIColor: this.numHexToStrHex(i_color),
      materialIEmissiveColor: this.numHexToStrHex(i_emissive_color)
    }
  }

  public setMaterialColor(
    parameter: MaterialColorParam,
    value: string,
    commit: EngineCommitChange,
    address?: string,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    return this.EngineInstance?.CommandSetMaterialColor(
      address,
      parameter,
      ...this.hexColorToEngineColor(value),
      commit | origin
    )
  }

  public setMaterialParam(
    parameter: MaterialParam,
    value: number,
    commit: EngineCommitChange,
    address?: string,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    return this.EngineInstance?.CommandSetMaterialParam(
      address,
      parameter,
      value,
      commit | origin
    )
  }

  public setMaterialColorByStyle<
    T extends EngineMode,
    K extends T extends EngineMode.NORMAL
      ? MaterialColorParam.COLOR
      : T extends EngineMode.OUTLINE | EngineMode.PIXEL
      ?
          | MaterialColorParam.E_COLOR_LIG
          | MaterialColorParam.E_COLOR_SHA
          | MaterialColorParam.E_COLOR_TOP
      : T extends EngineMode.ILLUSTRATIVE
      ? MaterialColorParam.I_COLOR | MaterialColorParam.I_EMISSIVE_COLOR
      : never
  >(
    parameter: K,
    value: string,
    style: T,
    commit: EngineCommitChange,
    address?: string,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    return this.EngineInstance?.CommandSetMaterialColorByStyle(
      address,
      parameter,
      ...this.hexColorToEngineColor(value),
      commit | origin,
      style
    )
  }

  public setMaterialParamByStyle<
    T extends EngineMode,
    K extends T extends EngineMode.NORMAL
      ?
          | MaterialParam.METALNESS
          | MaterialParam.REFLECTIVE
          | MaterialParam.ROUGHNESS
      : T extends EngineMode.OUTLINE | EngineMode.PIXEL
      ? MaterialParam.E_SPECULAR_INTENSITY | MaterialParam.E_SPECULAR_SIZE
      : T extends EngineMode.ILLUSTRATIVE
      ?
          | MaterialParam.I_STROKE_SIZE
          | MaterialParam.I_HIGHLIGHT_INTENSITY
          | MaterialParam.I_STROKE_INTENSITY
          | MaterialParam.I_COLORVAR_INTENSITY
          | MaterialParam.I_SCALE_X
          | MaterialParam.I_SCALE_Y
          | MaterialParam.I_ANGLE
      : never
  >(
    parameter: K,
    value: number,
    style: T,
    commit: EngineCommitChange = EngineCommitChange.DONT_COMMIT,
    address?: string,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    return this.EngineInstance?.CommandSetMaterialParamByStyle(
      address,
      parameter,
      value,
      commit | origin,
      style
    )
  }

  public transferMaterials() {
    return this.EngineInstance?.CommandTransferMaterial(null, 5)
  }

  public copyMaterials() {
    return this.EngineInstance?.CommandCopyMaterial(null)
  }

  public pasteMaterials() {
    return this.EngineInstance?.CommandPasteMaterial(null, 5)
  }

  public keepMaterialStylesInSynch(enabled: boolean) {
    return this.EngineInstance?.CommandMaterial_KeepStylesInSynch(
      enabled ? 1 : 0
    )
  }

  public recenterCamera() {
    return this.EngineInstance?.CommandCameraRecenter(-1)
  }

  public cameraReset() {
    return this.EngineInstance?.CommandCameraReset(-1)
  }

  public setCameraFromTo({ from, to }: CameraFromTo) {
    return this.EngineInstance?.CommandSetCameraFromTo(
      from.x,
      from.y,
      from.z,
      to.x,
      to.y,
      to.z
    )
  }

  public setCameraRadius(r: number) {
    return this.EngineInstance?.CommandSetCameraRadius(r)
  }

  public setCameraPhi(phi: number) {
    return this.EngineInstance?.CommandSetCameraPhi(phi)
  }

  public zoomSelection(factor: number) {
    return this.EngineInstance?.CommandZoomSelection(factor)
  }

  public setCameraOrbitPhi(phi: number) {
    return this.EngineInstance?.CommandOrbitPhi(phi)
  }

  public setCameraOrbitTheta(theta: number) {
    return this.EngineInstance?.CommandOrbitTheta(theta)
  }

  public setCameraDirection({ x, y, z }: Cartesian) {
    return this.EngineInstance?.CommandSetCameraDirection(x, y, z)
  }

  public setCameraType(value: EngineCamera): number {
    return this.EngineInstance?.CommandSetCameraType(value)
  }

  public handleUndoRedo(value: EngineUndoRedo) {
    if (this.openCommitBuffer) {
      this.endCommit()
    }

    this.EngineInstance?.CommandUndoRedo(value)
  }

  public setCameraProperty(
    property: EngineCameraProperty,
    value: number,
    commit: EngineCommitChange,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    this.EngineInstance?.CommandSetCameraParam(property, value, commit | origin)
  }

  public toggleLightStatus(): number {
    return this.EngineInstance?.CommandLightTogleAll(0)
  }

  public setLightAngle1(
    value: number,
    commit: EngineCommitChange,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    return this.EngineInstance?.CommandLightSetParam(
      null,
      0,
      value,
      commit | origin
    )
  }

  public setLightAngle2(
    value: number,
    commit: EngineCommitChange,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    return this.EngineInstance?.CommandLightSetParam(
      null,
      1,
      value,
      commit | origin
    )
  }

  public setLightOcclusionDistance(
    value: number,
    commit: EngineCommitChange,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    return this.EngineInstance?.CommandLightSetParam(
      null,
      2,
      value,
      commit | origin
    )
  }

  public toggleScenePlayback(): number {
    return this.EngineInstance?.CommandPlaybackTogle(0)
  }

  public toggleGroupOutline(): number {
    return this.EngineInstance?.CommandShowObjectsTogle()
  }

  public setBackgroundType(value: EngineBackground) {
    return this.EngineInstance?.CommandSetBackgroundType(value)
  }

  // TODO: refactor to a single function
  public setBackgroundColorA(
    value: string,
    commit: EngineCommitChange,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    const [r, g, b] = this.hexColorToEngineColor(value)
    return this.EngineInstance?.CommandSetBackgroundColor(
      0,
      r,
      g,
      b,
      commit | origin
    )
  }

  // TODO: refactor to a single function
  public setBackgroundColorB(
    value: string,
    commit: EngineCommitChange,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    const [r, g, b] = this.hexColorToEngineColor(value)
    return this.EngineInstance?.CommandSetBackgroundColor(
      1,
      r,
      g,
      b,
      commit | origin
    )
  }

  public setFloorEnabled(enabled: boolean) {
    return this.EngineInstance?.CommandFloorEnable(Number(enabled))
  }

  public setFloorHeight(
    value: number,
    commit: EngineCommitChange,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    return this.EngineInstance?.CommandFloorHeight(null, value, commit | origin)
  }

  public setModelPose(value: GroupExpanded) {
    return this.EngineInstance?.CommandModelSetPose(value)
  }

  public selectParentChild(value: EngineSelectParentChild) {
    return this.EngineInstance?.CommandModelSelectParentChild(value)
  }

  public selectSibling(value: EngineSelectSibling) {
    return this.EngineInstance?.CommandModelSelectSiblin(value)
  }

  public reorderStackElements({
    srcIndices,
    destIndex,
    mode
  }: EngineStackReorderingOpts) {
    // Pass the array of src indices as a string
    const srcIndicesAsString = srcIndices.join(' ')
    return this.EngineInstance?.CommandModelReorder(
      srcIndicesAsString,
      destIndex,
      mode
    )
  }

  public setBlendType(value: EngineBlendType, address?: string) {
    return this.EngineInstance?.CommandSetPrimBlendType(address, value)
  }

  public setDrawMaterial(draw: boolean, address?:string) {
    return this.EngineInstance?.CommandSetPrimDrawMaterial(address, draw ? 1 : 0)
  }

  public setAutoFocusEnabled(enabled: boolean) {
    return this.EngineInstance?.Neo_CommandSetAutoFocusEnabled(enabled ? 1 : 0)
  }

  public setSnapping(enabled: boolean) {
    return this.EngineInstance?.CommandSetSnapping(enabled ? 1 : 0)
  }

  public isSnappingEnable(enabled: boolean): number {
    return this.EngineInstance?.CommandIsSnappingEnable(0)
  }

  public setBlendAmount(
    value: number,
    commit: EngineCommitChange = EngineCommitChange.DONT_COMMIT,
    address: string | null = null,
    origin: EngineCommitOrigin = EngineCommitOrigin.LOCAL
  ) {
    return this.EngineInstance?.CommandSetPrimBlendAmount(
      address,
      value,
      commit | origin
    )
  }

  public selectElementByUUID(uuid: string, multiSelect?: boolean) {
    return this.EngineInstance?.CommandModelSelectByUUID(
      uuid,
      multiSelect ? 2 : 1
    )
  }

  public unselectElementByUUID(uuid: string) {
    return this.EngineInstance?.CommandModelSelectByUUID(uuid, 2)
  }

  public sendFireflyRequest(uri: string, prompt: string): number {
    return this.EngineInstance?.Command_ML_DepthToImage(uri, prompt)
  }

  public exportCapture(
    payload: EngineExportCapturePayload
  ): number | undefined {
    switch (payload.format) {
      case 'svg':
        return this.EngineInstance?.CommandVectorCapture(
          payload.splitFaceEnabled
        )
      case 'bmp': // TODO: remove this? --> engine doesn't support BMP. UI doesn't even show BMP
        return this.EngineInstance?.CommandPngCapture(8, false)
      case 'png':
        return this.EngineInstance?.CommandPngCapture(
          payload.compressionLevel === undefined ? 8 : payload.compressionLevel,
          payload.transparentBackground
        )
      case 'jpg':
        return this.EngineInstance?.CommandJpgCapture(
          payload.quality === undefined ? 100 : payload.quality
        )
      case 'screenshot':
        return this.EngineInstance?.CommandScreenshotCapture()
      case 'reference':
        return this.EngineInstance?.CommandDiffusionReferenceCapture()
      default:
        return
    }
  }

  public toggleFrameEnabled() {
    return this.EngineInstance?.Command_Frame_Toggle(0)
  }

  public setFrameOpacity(val: number, commit = EngineCommitChange.DONT_COMMIT) {
    return this.EngineInstance?.Command_Frame_SetOpacity(val, commit)
  }

  public setFrameSize(w: number, h: number) {
    return this.EngineInstance?.Command_Frame_SetSize(w, h)
  }

  // TODO: refactor to a color module
  public hexColorToEngineColor(hex: string): [number, number, number] {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    if (r < 0 || r > 255 || g < 0 || g > 255 || b < 0 || b > 255) {
      throw new Error('All rgb values must be between 0 and 255, inclusive')
    }

    return [r, g, b]
  }

  private numHexToStrHex(num: number) {
    return this.rGBToHex(num >> 16, (num >> 8) & 0xff, num & 0xff)
  }

  // TODO: refactor to a color module
  public rGBToHex(r: number, g: number, b: number) {
    function componentToHex(c: number) {
      let hex = c.toString(16)
      return hex.length == 1 ? '0' + hex : hex
    }

    function roundToNearestValidInteger(n: number) {
      return Math.min(Math.round(n), 255)
    }

    r = roundToNearestValidInteger(r)
    g = roundToNearestValidInteger(g)
    b = roundToNearestValidInteger(b)

    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
  }

  public getCurrentCursor(): string | undefined {
    const targetCanvas = document.getElementById('canvas') as HTMLCanvasElement
    const { style } = targetCanvas
    return style.cursor
  }

  public setCursor(cursor: Cursor) {
    const targetCanvas = document.getElementById('canvas') as HTMLCanvasElement
    if (targetCanvas) {
      const { style } = targetCanvas

      const url = cursorRegistry.urlForCursor(cursor)
      if (style && url) style.cursor = url
    }
  }

  public setHistorySliderPos(value: number) {
    return this.EngineInstance?.CommandSetHistorySliderPos(value)
  }

  public setHistoryMode(value: number) {
    return this.EngineInstance?.CommandSetHistoryMode(value)
  }

  public engineWeightToFontWeight(engineWeight: string) {
    const index = Object.values(fontWeightMap).indexOf(engineWeight)

    if (index === -1)
      throw new Error('Cannot convert engine weight to font weight')

    return Object.keys(fontWeightMap)[index]
  }
}
