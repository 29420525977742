import React, { useState } from 'react'
import cn from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import {
  useIsProjectViewOnly,
  useMediumMinimumSizePreference,
  useProjectActions,
  useProjectState
} from '@hooks/useProject'

import {
  Button,
  Icon,
  Menu,
  MenuItem,
  Overlay,
  Popover,
  Toast,
  Tooltip
} from 'ui'

import LinkIcon from '/public/s2_icon_review_link.svg'
import AdobeIcon from '/public/s2_icon_adobe_illustrator.svg'
import UserLockIcon from '/public/s2_icon_user_lock.svg'
import GlobeGridIcon from '/public/s2_icon_globe_grid.svg'

import styles from '@styles/components/ShareExportDialog.module.scss'

import ExportingDialog from './ExportingDialog'
import { EngineMode } from '@services/engine/types'
import {
  LocalStorageBooleanValue,
  LocalStorageKey
} from '@constants/localStorage'
import { HTMLElementIdsForTracking } from '@constants/htmlElementIdsForTracking'

enum ShareMenuItemValue {
  PUBLISH = 'publish',
  COPY_LINK = 'copy-link-button',
  SET_TO_PUBLIC = 'set-to-private',
  SET_TO_PRIVATE = 'set-to-public',
  SUBMIT_TO_HIGHLIGHTS = 'submit-to-highlights',
  OPEN_ILLUSTRATOR = 'open-illustrator',
  OPEN_PHOTO_SHOP = 'open-adobe-photo-shop',
  PRINT_SERVICE = 'print-on-demand-service'
}

const ShareExportDialog = () => {
  const size = useMediumMinimumSizePreference()
  const isProjectViewOnly = useIsProjectViewOnly()
  const projectUuid = useProjectState('projectUuid')
  const isPublic = useProjectState('isPublic')
  const isShareExportDialogOpen = useProjectState('isShareExportDialogOpen')
  const {
    setIsShareExportDialogOpen,
    setIsShareProjectDialogOpen,
    toggleProjectPublicStatus
  } = useProjectActions()

  const flags = useFlags()
  const captureStatus = useSceneState('captureStatus')
  const mode = useSceneState('mode')
  const frameEnabled = useSceneState('frameEnabled')
  const { exportCapture } = useSceneActions()
  const [splitFaceEnabled, setSplitFaceEnabled] = useState(false)
  const [isCopyLinkedToastOpen, setIsCopyLinkedToastOpen] = useState(false)

  const svgEnabled =
    flags['base-pf-ui-export-svg'] && flags['base-pf-ui-send-to-ai']

  const isSendToIllustratorDisabled =
    !(mode === EngineMode.OUTLINE || mode === EngineMode.PIXEL) || !frameEnabled

  function handleCopy() {
    navigator.clipboard.writeText(window.location.href)
    setIsCopyLinkedToastOpen(true)
    setTimeout(() => {
      setIsCopyLinkedToastOpen(false)
    }, 6000)
  }

  function onMenuClick({ target: { value } }: any) {
    switch (value as ShareMenuItemValue) {
      case ShareMenuItemValue.COPY_LINK:
        isPublic && handleCopy()
        break
      case ShareMenuItemValue.OPEN_ILLUSTRATOR:
        !isSendToIllustratorDisabled &&
          exportCapture({
            format: 'svg',
            splitFaceEnabled,
            workflow: 'send-to-adobe-illustrator'
          })
        break
      case ShareMenuItemValue.SET_TO_PRIVATE:
      case ShareMenuItemValue.SET_TO_PUBLIC:
        handleTogglePublicMenu()
        break
      default:
        break
    }

    setIsShareExportDialogOpen(false)
  }

  function handleDialogClosed(): void {
    setIsShareExportDialogOpen(false)
    setIsShareExportDialogOpen(false)
  }

  function handleTogglePublicMenu() {
    if (!projectUuid) return

    const showShareProjectDialog =
      !isPublic &&
      localStorage.getItem(LocalStorageKey.projectHideShareCommunityDialog) !==
        LocalStorageBooleanValue.TRUE

    if (showShareProjectDialog) {
      return setIsShareProjectDialogOpen(true)
    }

    toggleProjectPublicStatus()
  }

  const { status, format } = captureStatus

  return (
    <>
      <Button
        disabled={isProjectViewOnly}
        size={size}
        id={HTMLElementIdsForTracking.shareExportDialogButton}
        onClick={() => setIsShareExportDialogOpen(true)}>
        Share
      </Button>

      {status === 'exporting' &&
        !(format === 'screenshot' || format === 'reference') && (
          <ExportingDialog />
        )}

      <Overlay
        placement="bottom-start"
        offset={10}
        trigger={`${HTMLElementIdsForTracking.shareExportDialogButton}@click`}
        spOpened={() => setIsShareExportDialogOpen(true)}
        spClosed={handleDialogClosed}
        open={isShareExportDialogOpen}>
        <Popover
          className={cn(styles['share-menu-popover'], {
            [styles['share-menu-popover-large']]: size === 'l'
          })}>
          <h4>Share</h4>
          <Menu
            className={styles['share-menu']}
            onchange={onMenuClick}
            size={size}>
            {isPublic ? (
              <MenuItem value={ShareMenuItemValue.SET_TO_PRIVATE}>
                <Icon slot="icon">
                  <UserLockIcon />
                </Icon>
                Remove from Community
                <div slot="description">
                  Remove your design from the public gallery
                </div>
              </MenuItem>
            ) : (
              <MenuItem value={ShareMenuItemValue.SET_TO_PUBLIC}>
                <Icon slot="icon">
                  <GlobeGridIcon />
                </Icon>
                Share in Community
                <div slot="description">
                  Add your design to the public gallery
                </div>
              </MenuItem>
            )}

            <MenuItem
              id={HTMLElementIdsForTracking.copyLinkButton}
              value={ShareMenuItemValue.COPY_LINK}
              className={cn({ ['disabled-menu-item-with-tooltip']: !isPublic })}
              disabled={!isPublic}>
              <Icon slot="icon">
                <LinkIcon />
              </Icon>
              Copy link
              <div slot="description">
                Invite others to view and collaborate
              </div>
              {!isPublic && (
                <Tooltip selfManaged placement="bottom">
                  Copy link is only available for designs shared in the
                  community. Click Share in Community to share it and enable
                  Copy link.
                </Tooltip>
              )}
            </MenuItem>

            <MenuItem
              id={HTMLElementIdsForTracking.sendToIllustratorButton}
              value={ShareMenuItemValue.OPEN_ILLUSTRATOR}
              className={cn({
                ['disabled-menu-item-with-tooltip']: isSendToIllustratorDisabled
              })}
              disabled={isSendToIllustratorDisabled || !svgEnabled}>
              <Icon slot="icon">
                <AdobeIcon />
              </Icon>
              Send to Illustrator as vectors
              <div slot="description">Edit as vector paths in illustrator</div>
              {isSendToIllustratorDisabled && (
                <Tooltip selfManaged placement="bottom">
                  Send to Illustrator is only available with "Frame" enabled and
                  “Vector Art” or “Pixel Art” styles. Please select one of these
                  styles and enable "Frame" to continue.
                </Tooltip>
              )}
            </MenuItem>
          </Menu>
        </Popover>
      </Overlay>

      <Overlay
        trigger={`${HTMLElementIdsForTracking.copyLinkButton}@click`}
        spClosed={() => setIsCopyLinkedToastOpen(false)}>
        <Toast
          className={styles['toast']}
          variant="positive"
          open={isCopyLinkedToastOpen}>
          Link copied to clipboard
        </Toast>
      </Overlay>
    </>
  )
}

export default ShareExportDialog
