import React, { useState } from 'react'
import { DialogBase, Dialog, Button, Checkbox, FieldGroup } from 'ui'
import { useProject } from '@hooks/useProject'
import {
  LocalStorageBooleanValue,
  LocalStorageKey
} from 'constants/localStorage'
import styles from '@styles/components/WindowsGPUConfigInstructionDialog.module.scss'

const WindowsGPUConfigInstructionDialog = () => {
  const {
    showWindowsGPUConfigInstructionDialog,
    setShowWindowsGPUConfigInstructionDialog
  } = useProject()

  const [checked, setChecked] = useState(false)

  function handleClose() {
    setShowWindowsGPUConfigInstructionDialog(false)
    localStorage.setItem(
      LocalStorageKey.engineHideWindowsGPUConfigInstructionsDialog,
      checked ? LocalStorageBooleanValue.TRUE : LocalStorageBooleanValue.FALSE
    )
  }

  return (
    <DialogBase underlay open={showWindowsGPUConfigInstructionDialog}>
      <Dialog size="l">
        <h2 slot="heading">Oops, slow Intel GPU</h2>
        <h4>Please follow the instructions to configure your device</h4>
        <ul className={styles['list']}>
          <li>
            In the Windows search bar, look for{' '}
            <strong>Graphics Settings</strong>
          </li>
          <li>
            In the Graphics Settings window, click the browse button and look
            for the Google Chrome installation folder. Select{' '}
            <strong>chrome.exe</strong> and add it.
          </li>
          <li>{`Select Google Chrome in the list > Click Options > Select High Performance > Save`}</li>
        </ul>
        <FieldGroup>
          <Checkbox
            size="m"
            checked={checked}
            change={() => setChecked(!checked)}>
            Don't show this message again
          </Checkbox>
        </FieldGroup>
        <Button slot="button" variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Dialog>
    </DialogBase>
  )
}

export default WindowsGPUConfigInstructionDialog
