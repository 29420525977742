import React, { FC } from 'react'
import { Switch } from 'ui'
import { useCamera } from '@services/engine/useCamera'

const GroupAutoFocus: FC = () => {
  const { autoFocusEnabled } = useCamera()
  const [isAutoFocusEnabled, setAutoFocusEnabled] = autoFocusEnabled()
  const currentlyEnabled = isAutoFocusEnabled() ?? false

  return (
    <Switch
      checked={currentlyEnabled}
      onclick={() => {
        setAutoFocusEnabled(!currentlyEnabled)
      }}>
      Auto-frame
    </Switch>
  )
}

export default GroupAutoFocus
