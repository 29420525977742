import React, { FC, ReactNode } from 'react'
import styles from '@styles/components/VideoContainer.module.scss'

interface Props {
  children: ReactNode
}

const VideoContainer: FC<Props> = ({ children }) => {
  return <div className={styles.container}>{children}</div>
}

export default VideoContainer
