import React, { FC, useState } from 'react'
import { ActionButton, Icon, Overlay, OverlayCloseEvent } from 'ui'
import CubeIcon from '/public/s2_icon_cube.svg'
import AddObjectMenu from '@components/propertiesPanel/AddObjectMenu'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

const AddObject: FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const size = useMediumMinimumSizePreference()

  function closePopover(e?: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    setIsOpen(false)
    e?.currentTarget.dispatchEvent(OverlayCloseEvent)
  }

  return (
    <>
      <ActionButton
        id="context-bar-add-shape"
        holdAffordance
        selected={isOpen}
        size={size}>
        <Icon slot="icon">
          <CubeIcon />
        </Icon>
        Add shape
      </ActionButton>

      <Overlay
        open={isOpen}
        spOpened={() => setIsOpen(true)}
        spClosed={() => setIsOpen(false)}
        placement="top"
        offset={10}
        trigger="context-bar-add-shape@click">
        <AddObjectMenu closePopover={closePopover} />
      </Overlay>
    </>
  )
}

export default AddObject
