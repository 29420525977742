import React, { FC, useState } from 'react'
import { Switch } from 'ui'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import ShareExportFooter from './ShareExportFooter'
import FrameSize from '@components/propertiesPanel/FrameSize'
import Typography from '@components/base/Typography/Typography'
import {
  useMediumMinimumSizePreference,
  useProjectState
} from '@hooks/useProject'

interface Props {
  format: 'png' | 'jpg'
}

const ImageExportForm: FC<Props> = ({ format }) => {
  const frameEnabled = useSceneState('frameEnabled')
  const frameSizeLocked = useSceneState('frameSizeLocked')
  const frameUnit = useSceneState('frameUnit')
  const frameSize = useSceneState('frameSize')
  const sizePreference = useProjectState('sizePreference')

  const minSize = useMediumMinimumSizePreference()

  const { exportCapture, setPropertyState: _setPropertyState } =
    useSceneActions()

  const setPropertyState = React.useCallback(_setPropertyState, [])

  const [transparentBackground, setTransparentBackground] = useState(false)

  const [compressionLevel, setCompressionLevel] = useState(8)

  const [quality, setQuality] = useState(100)

  function handleExport() {
    if (format === 'png') {
      return exportCapture({
        format,
        transparentBackground,
        compressionLevel,
        workflow: 'download'
      })
    }

    exportCapture({
      format,
      quality,
      workflow: 'download'
    })
  }

  return (
    <>
      {format === 'png' && (
        <>
          <Switch
            size={minSize}
            checked={transparentBackground}
            onclick={() => setTransparentBackground(!transparentBackground)}>
            Transparent Background
          </Switch>
        </>
      )}

      <>
        <div className="flex flex-col" style={{ gap: '12px' }}>
          <Typography variant="h4">Size</Typography>
          <FrameSize
            sizePreference={sizePreference}
            focusCanvasOnInputChange={false}
            frameEnabled={frameEnabled}
            frameSizeLocked={frameSizeLocked}
            frameSize={frameSize}
            frameUnit={frameUnit}
            setPropertyState={setPropertyState}
          />
        </div>
        <ShareExportFooter onExportClick={handleExport} buttonText="Download" />
      </>
    </>
  )
}

export default ImageExportForm
