import React from 'react'
import PanelContainer from '@components/panel/PanelContainer'
import PanelHeader from '@components/panel/PanelHeader'
import ViewsCard from '@components/views/ViewsCard'
import { ActionButton } from 'ui'
import { SpIconShowMenu } from 'workflow-icons'

import styles from '@styles/components/ViewsPanel.module.scss'

const ViewsPanel = () => {
  return (
    <PanelContainer>
      <div className={styles['header-container']}>
        <PanelHeader>Views</PanelHeader>
        <ActionButton quiet>
          <SpIconShowMenu slot="icon" />
        </ActionButton>
      </div>
      <div className={styles['views-container']}>
        <ViewsCard imageUrl="/VIEWS_TEST_IMAGE.png" header="Untitled" />
        <ViewsCard imageUrl="/VIEWS_TEST_IMAGE.png" header="Front" />
        <ViewsCard imageUrl="/VIEWS_TEST_IMAGE.png" header="Back" />
        <ViewsCard imageUrl="/VIEWS_TEST_IMAGE.png" header="Top" />
      </div>
    </PanelContainer>
  )
}

export default ViewsPanel
