import React, { FC } from 'react'
import cn from 'classnames'
import styles from '@styles/components/SelectionCardItem.module.scss'
import CheckmarkIcon from '/public/s2_icon_checkmark.svg'
import CloseIcon from '/public/s2_icon_close.svg'
import { FieldLabel } from 'ui'

interface Props {
  imgFullSize?: boolean
  imgSrc: string
  imgStyle?: React.CSSProperties
  label: string
  onClick?: () => void
  onClose?: () => void
  selected?: boolean
  showCheckMark?: boolean
}

const SelectionCardItem: FC<Props> = ({
  imgFullSize,
  imgSrc,
  imgStyle,
  label,
  onClick,
  onClose,
  selected,
  showCheckMark = true
}) => {
  return (
    <div className={styles['wrapper']}>
      <div
        className={cn(styles['container'], { [styles['selected']]: selected })}
        onClick={onClick}
        role="button">
        <div className={styles['image-container']}>
          <img
            className={cn({ [styles['image-full-size']]: imgFullSize })}
            style={imgStyle}
            src={imgSrc}
            alt=""
          />
        </div>

        {selected && showCheckMark && (
          <div className={styles['checkmark-icon']}>
            <CheckmarkIcon />
          </div>
        )}

        {onClose && (
          <div
            className={styles['close-icon']}
            onClick={e => {
              e.stopPropagation()
              onClose()
            }}>
            <CloseIcon />
          </div>
        )}
      </div>
      <FieldLabel className={styles['label']}>{label}</FieldLabel>
    </div>
  )
}

export default SelectionCardItem
