import { FC } from 'react'
import styles from '@styles/components/AppLogo.module.scss'
import NeoIcon from '/public/Neo.svg'
import cn from 'classnames'
import { AppName } from '@constants/appConstants'
import AdobeLogo from '/public/adobe-logo.svg'
import DevIcon from '/public/header-badge-dev.svg'

interface Props {
  style?: React.CSSProperties
  onClick?: (e: React.MouseEvent) => void
  logo?: 'adobe' | 'projectneo'
}

const AppLogo: FC<Props> = ({ style, onClick, logo = 'projectneo' }) => {
  return (
    <div
      className={cn(styles['container'], {
        [styles['clickable']]: onClick
      })}
      style={style}
      onClick={onClick}>
      {logo === 'adobe' ? <AdobeLogo /> : <NeoIcon />}
      <span className={styles['app-name']}>
        {logo === 'adobe' ? (
          <span className={styles['adobe-name']}>Adobe</span>
        ) : null}
        <span className={styles['adobe-name']}>Adobe</span>&nbsp;{AppName}&nbsp;
      </span>
      <div className={styles['badge-wrapper']}>
        (Beta)
        {process.env.NEXT_PUBLIC_CLIENT_APP_ENVIRONMENT === 'dev' ? (
          <DevIcon />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default AppLogo
