import React, { FC, memo } from 'react'
import { ColorPicker } from 'ui'
import { SceneStateProperties } from '@hooks/useScene'
import { EngineCommitChange } from '@services/engine/types'
import ColorPickerWithTextfield from '@components/colorPickerWithTextfield/ColorPickerWithTextfield'
import { ProjectState } from '@store/slices/projectSlice'

type Props = Pick<
  SceneStateProperties,
  | 'address'
  | 'materialColor'
  | 'materialEColorLig'
  | 'materialEColorTop'
  | 'materialEColorSha'
  | 'materialIColor'
  | 'materialIEmissiveColor'
  | 'materialKeepStylesInSync'
  | 'materialType'
  | 'setPropertyState'
> & {
  sizePreference?: ProjectState['sizePreference']
  className?: string
  label?: string
  showHexField?: boolean
}

const MaterialPicker: FC<Props> = ({
  className,
  label,
  materialColor,
  setPropertyState,
  showHexField,
  sizePreference
}) => {
  const Picker = showHexField ? ColorPickerWithTextfield : ColorPicker

  return (
    <Picker
      size={sizePreference}
      label={label}
      className={className}
      color={materialColor}
      forceUpdateColorAreaHandlePosition={materialColor}
      showSwatches={false}
      onMouseDown={e =>
        setPropertyState({
          key: 'materialColor',
          value: (e.target as any).color,
          commit: EngineCommitChange.BEGIN_COMMIT
        })
      }
      onInput={e =>
        setPropertyState({
          key: 'materialColor',
          value: (e.target as any).color
        })
      }
      onChange={e =>
        setPropertyState({
          key: 'materialColor',
          value: (e.target as any).color,
          commit: EngineCommitChange.END_COMMIT
        })
      }
    />
  )
}

export default memo(MaterialPicker)
