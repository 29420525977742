import React, { FC } from 'react'
import { ActionMenu, Icon, Menu, MenuDivider, MenuItem } from 'ui'
import {
  useIsDocumentUpdatable,
  useMediumMinimumSizePreference,
  useProjectActions,
  useProjectState
} from '@hooks/useProject'
import { useSceneState, useSceneActions } from '@hooks/useScene'
import HamburgerMenuIcon from '/public/s2_icon_menu_hamburger.svg'
import { createNewProjectName, useCreateProject } from '@hooks/useCreateProject'

interface Props {
  navigateHome: () => void
}

const StudioAppBarMainMenu: FC<Props> = ({ navigateHome }) => {
  const showContextBar = useProjectState('showContextBar')
  const showOrbitControls = useProjectState('showOrbitControls')
  const showSceneNavigator = useProjectState('showSceneNavigator')
  const {
    saveProject,
    setShowContextBar,
    setShowOrbitControls,
    setShowSceneNavigator,
    setShowSettingsDialog
  } = useProjectActions()
  const size = useMediumMinimumSizePreference()
  const groupOutlineEnabled = useSceneState('groupOutlineEnabled')
  const isDocumentUpdatable = useIsDocumentUpdatable()
  const { setPropertyState } = useSceneActions()

  const [createProject] = useCreateProject({
    variables: {
      name: createNewProjectName(),
      tags: ['lifestyle', 'tech']
    },
    onCompleted(data) {
      if (!data?.createProject?.uuid) return

      window.open(
        `${process.env.NEXT_PUBLIC_CLIENT_WEB_HOST}/studio/${data.createProject.uuid}`,
        '_blank'
      )
    }
  })

  return (
    <ActionMenu aria-label="app menu" quiet size={size}>
      <Icon slot="icon">
        <HamburgerMenuIcon />
      </Icon>
      <MenuItem onClick={navigateHome}>Back to home</MenuItem>
      <MenuDivider size="s" />
      <MenuItem onClick={() => createProject()}>New</MenuItem>
      {isDocumentUpdatable && (
        <MenuItem onClick={() => saveProject()}>
          Save
          {/* TODO save to key binding */}
          {/* <kbd slot="value">⌘S</kbd> */}
        </MenuItem>
      )}
      <MenuDivider size="s" />
      <MenuItem>
        View
        <Menu slot="submenu" className="menu-non-selected-offset" size={size}>
          <MenuItem
            selected={!groupOutlineEnabled}
            onClick={() =>
              setPropertyState({
                key: 'groupOutlineEnabled',
                value: false
              })
            }>
            Default
          </MenuItem>
          <MenuItem
            selected={groupOutlineEnabled}
            onClick={() =>
              setPropertyState({
                key: 'groupOutlineEnabled',
                value: true
              })
            }>
            Outline
          </MenuItem>
          <MenuDivider size="s" />
          <MenuItem
            selected={showContextBar}
            onClick={() => setShowContextBar(!showContextBar)}>
            Contextual task bar
          </MenuItem>
          <MenuItem
            selected={showOrbitControls}
            onClick={() => setShowOrbitControls(!showOrbitControls)}>
            Orbit controls
          </MenuItem>
          <MenuItem
            selected={showSceneNavigator}
            onClick={() => setShowSceneNavigator(!showSceneNavigator)}>
            Mini layer widget
          </MenuItem>
        </Menu>
      </MenuItem>
      <MenuDivider size="s" />
      <MenuItem onClick={() => setShowSettingsDialog(true)}>
        Settings
        {/* TODO hook up key shortcut to open settings dialog */}
        {/* <kbd slot="value">⌘K</kbd> */}
      </MenuItem>
    </ActionMenu>
  )
}

export default StudioAppBarMainMenu
