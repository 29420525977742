import { RootState } from '@store/store'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { userLibrary } from '@store/slices/userLibrarySlice'

export const useUserLibrary = () => {
  const state = useSelector((state: RootState) => state.userLibrary)
  const dispatch = useDispatch()
  const boundedActions = bindActionCreators(userLibrary.actions, dispatch)

  return { ...state, ...boundedActions }
}

export type SyncStateProperties = ReturnType<typeof useUserLibrary>
