import React, { FC, ComponentProps, useMemo } from 'react'
import { Slider } from 'ui'

/* TODO: Please document magic numbers */
const DECIMAL_PLACE = 5
const NSTEPS = 100
const DECIMAL_LOG = Math.pow(10, DECIMAL_PLACE)

interface Props extends ComponentProps<typeof Slider> {
  noUpperLimit?: boolean
  noLowerLimit?: boolean
}

/* TODO: Delete this component when soft bounds are ready */
export const PropertyInputSlider: FC<Props> = ({
  label,
  min,
  max,
  step,
  ...props
}) => {
  const correctedRange = useMemo(() => {
    const flooredMin =
      min === undefined ? 0 : Math.floor(min * DECIMAL_LOG) / DECIMAL_LOG
    const ceiledMax =
      max === undefined
        ? flooredMin + 1
        : Math.ceil(max * DECIMAL_LOG) / DECIMAL_LOG
    const roundedStep =
      Math.round(
        (step === undefined ? (ceiledMax - flooredMin) / NSTEPS : step) *
          DECIMAL_LOG
      ) / DECIMAL_LOG
    return {
      min: flooredMin,
      max: ceiledMax,
      step: roundedStep
    }
  }, [min, max, step])
  /* TODO: Delete this when new property panel is ready */
  return <Slider label={label} size="s" {...props} {...correctedRange} />
}
