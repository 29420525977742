import { useCreateProjectWithDocumentMutation } from '@store/graphql/__generated__/schema'
import { useState, useEffect, useCallback } from 'react'
import { useProject } from './useProject'
import { useSceneActions, useSceneState } from './useScene'
import Context from '@store/middleware/context'
import { CANVAS_ID } from '@services/engine/engine'

export const useCopyProject = () => {
  const { name, projectUuid } = useProject()
  const engineState = useSceneState('engineState')
  const { setEngineState } = useSceneActions()

  const [createdProjectUuid, setCreatedProjectUuid] = useState<string | null>(
    null
  )

  const [duplicatingStatus, setDuplicatingStatus] = useState<
    'idle' | 'duplicating' | 'duplicated'
  >('idle')

  const [createProjectWithDocumentMutation, { loading: creatingProject }] =
    useCreateProjectWithDocumentMutation({
      update: (_, mutationResult) => {
        const uuid = mutationResult.data?.createProjectWithDocument?.uuid
        if (!uuid) return

        setCreatedProjectUuid(uuid)
        setEngineState('UNMOUNT')
      }
    })

  useEffect(() => {
    if (engineState === 'UNMOUNTED' && duplicatingStatus === 'duplicating') {
      setDuplicatingStatus('duplicated')
      // Allow client using duplicatingStatus state to first call functions before setting status to idle
      setTimeout(() => {
        setDuplicatingStatus('idle')
        setCreatedProjectUuid(null)
      }, 0)
    }
  }, [duplicatingStatus, engineState])

  const copyProject = useCallback(async () => {
    if (!projectUuid || !name) return

    const documentSnapShot = await getDocumentSnapshot()
    if (!documentSnapShot) return

    setDuplicatingStatus('duplicating')

    createProjectWithDocumentMutation({
      variables: {
        name: `${name} (duplicate)`,
        document: documentSnapShot,
        tags: ['lifestyle', 'tech']
      }
    })
  }, [projectUuid, name])

  return { status: duplicatingStatus, copyProject, createdProjectUuid }
}

function getBase64DataStringFromCanvas(canvas: HTMLCanvasElement) {
  const [_prefix, imageData] = canvas.toDataURL('image/png').split(';base64,')
  return imageData
}

export async function getDocumentSnapshot() {
  const canvas = document.getElementById(CANVAS_ID) as HTMLCanvasElement
  if (!canvas) return

  const doc = await JSON.parse(Context.Engine?.getDocument()!)

  // Add a 'snapshot' image representation of the scene
  // When creating a project from JSON, a new S3 thumbnail path will be initialized with this data
  const docWithSnapshot = JSON.stringify({
    base64Snapshot: getBase64DataStringFromCanvas(canvas),
    ...doc
  })

  return docWithSnapshot
}
