import React, { FC, memo } from 'react'
import {
  SceneStateProperties,
  useSceneActions,
  useSceneState
} from '@hooks/useScene'
import { EngineBlendType } from '@services/engine/types'
import { ActionButton, ActionGroup, Checkbox, FieldLabel } from 'ui'
import PanelAccordion from '@components/panel/PanelAccordion'
import { useProjectState, useProjectActions } from '@hooks/useProject'
import { Section } from './ObjectProperties'
import { PercentPropertySlider } from '@components/slider/PercentPropertySlider'

const Combine: FC = () => {
  const threeDCombineCollapsed = useProjectState('threeDCombineCollapsed')
  const sizePreference = useProjectState('sizePreference')
  const { setPanelAccordionItem } = useProjectActions()
  const blendType = useSceneState('blendType')
  const address = useSceneState('address')
  const drawMaterial = useSceneState('drawMaterial')
  const showUseMaterialCheckbox =
    blendType === EngineBlendType.SUBTRACT ||
    blendType === EngineBlendType.INTERSECT
  const { setPropertyState } = useSceneActions()

  return (
    <PanelAccordion
      id={Section.combine}
      label="Combine shapes"
      collapsed={threeDCombineCollapsed}
      onChange={collapsed =>
        setPanelAccordionItem({ key: 'threeDCombineCollapsed', collapsed })
      }>
      <FieldLabel>Additive</FieldLabel>

      <ActionGroup
        size={sizePreference}
        selects="single"
        selected={[blendType.toString()]}
        change={e => {
          const keys = (e as unknown as { target: { selected: string[] } })
            .target.selected
          if (!keys.length) return
          setPropertyState({
            address,
            key: 'blendType',
            value: Number(keys[0]) as SceneStateProperties['blendType']
          })
        }}>
        <ActionButton
          key={EngineBlendType.ADD.toString()}
          value={EngineBlendType.ADD.toString()}
          aria-label="Combination add">
          Add
        </ActionButton>

        <ActionButton
          key={EngineBlendType.COLOR.toString()}
          value={EngineBlendType.COLOR.toString()}
          aria-label="Combination color">
          Color
        </ActionButton>
        <ActionButton
          key={EngineBlendType.REPEL.toString()}
          value={EngineBlendType.REPEL.toString()}
          aria-label="Combination repel">
          Repel
        </ActionButton>
        <ActionButton
          key={EngineBlendType.AVOID.toString()}
          value={EngineBlendType.AVOID.toString()}
          aria-label="Combination avoid">
          Avoid
        </ActionButton>
      </ActionGroup>

      <FieldLabel>Subtractive</FieldLabel>

      <ActionGroup
        size={sizePreference}
        selects="single"
        selected={[blendType.toString()]}
        change={e => {
          const keys = (e as unknown as { target: { selected: string[] } })
            .target.selected
          if (!keys.length) return
          setPropertyState({
            address,
            key: 'blendType',
            value: Number(keys[0]) as SceneStateProperties['blendType']
          })
        }}>
        <ActionButton
          key={EngineBlendType.SUBTRACT.toString()}
          value={EngineBlendType.SUBTRACT.toString()}
          aria-label="Combination carve">
          Carve
        </ActionButton>
        <ActionButton
          key={EngineBlendType.INTERSECT.toString()}
          value={EngineBlendType.INTERSECT.toString()}
          aria-label="Combination intersect">
          Intersect
        </ActionButton>
      </ActionGroup>

      <PercentPropertySlider
        ariaLabel="Blending"
        label="Blending"
        valueKey="blendAmount"
        propertyPath="treeNode.standard.blendShape"
        enableUpperboundOverride
      />
      {showUseMaterialCheckbox && (
        <div className="flex align-center gap-xs">
          <Checkbox
            id="primitive-draw-material"
            size={sizePreference}
            checked={drawMaterial}
            change={e =>
              setPropertyState({
                address,
                key: 'drawMaterial',
                value: !drawMaterial
              })
            }></Checkbox>
          <FieldLabel for="primitive-draw-material">Use Material</FieldLabel>
        </div>
      )}
    </PanelAccordion>
  )
}

export default memo(Combine)
