import React, { FC, ReactNode, createElement } from 'react'
import cn from 'classnames'
import styles from '@styles/components/Typography.module.scss'

type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

interface Props {
  children?: ReactNode
  className?: string
  disabled?: boolean
  display?: React.CSSProperties['display']
  variant?: Variant
}

const Typography: FC<Props> = ({
  children,
  className,
  disabled,
  display,
  variant = 'h1'
}) => {
  return createElement(
    variant,
    {
      className: cn(
        styles.typography,
        styles[variant],
        { [styles.disabled]: disabled },
        className
      ),
      style: { display }
    },
    children
  )
}

export default Typography
