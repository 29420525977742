import { setPropertiesState } from '@store/slices/sceneSlice'
import { RootState } from '@store/store'
import { put, select } from 'redux-saga/effects'
import {
  BoundsVariant,
  PropertyList,
  PropertyPanelPath,
  PropertySoftBoundsHaveChangedData
} from '@services/engine/types'

export function* handlePropertySoftBoundsHaveChanged(
  data: PropertySoftBoundsHaveChangedData
) {
  const { path, min, max } = data
  yield updateSoftBound(path, min, max)
}

function* updateSoftBound(
  propertyPath: PropertyPanelPath,
  softMin: BoundsVariant,
  softMax: BoundsVariant
) {
  const propertyList: PropertyList = yield select(
    (state: RootState) => state.scene.propertyList
  )

  const property = propertyList[propertyPath]

  if (typeof property !== 'undefined') {
    yield put(
      setPropertiesState({
        propertyList: {
          ...propertyList,
          [propertyPath]: {
            ...property,
            softMin,
            softMax
          }
        }
      })
    )
  }
}
