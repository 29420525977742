import React from 'react'
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js'
import {
  useLocalVideo,
  useMeetingManager,
  useToggleLocalMute
} from 'amazon-chime-sdk-component-library-react'
import {
  addAttendee,
  createMeeting,
  getMeeting,
  removeAttendee
} from './ChimeRequests'
import { useAuth } from '@hooks/useAuth'
import { useProject } from '@hooks/useProject'
import { useChime } from '@hooks/useChime'
import VideoTile from './VideoTile'
import VideoContainer from './VideoContainer'
import VideoAvatar from './VideoAvatar'
import DeviceSelector from './DeviceSelector'
import styles from '@styles/components/LocalVideo.module.scss'
import { SpIconMovieCamera, SpIconVoiceOver } from 'workflow-icons'
import cn from 'classnames'
import { ActionButton, Button, Theme } from 'ui'

const LocalVideo = () => {
  const meetingManager = useMeetingManager()
  const { tileId, isVideoEnabled, toggleVideo } = useLocalVideo()
  const { muted, toggleMute } = useToggleLocalMute()

  const {
    sendChimeMeetingStartMessage,
    sendChimeMeetingJoinMessage,
    sendChimeMeetingLeaveMessage,
    meetingId,
    localUserAttendeeId,
    setLocalUserAttendeeId
  } = useChime()

  const { localUser } = useAuth()
  const { projectUuid } = useProject()

  const startMeeting = async () => {
    const meeting = await createMeeting(projectUuid!)
    const attendee = await addAttendee(meeting.MeetingId, localUser!.uuid!)
    const meetingSessionConfiguration = new MeetingSessionConfiguration(
      meeting,
      attendee
    )

    sendChimeMeetingStartMessage({
      meeting_id: meeting.MeetingId,
      user: localUser!
    })

    setLocalUserAttendeeId(attendee.AttendeeId)

    await meetingManager.join(meetingSessionConfiguration)
    await meetingManager.start()
  }

  const joinMeeting = async () => {
    if (!meetingId) return
    const meeting = await getMeeting(meetingId)
    const attendee = await addAttendee(meeting.MeetingId, localUser!.uuid!)
    const meetingSessionConfiguration = new MeetingSessionConfiguration(
      meeting,
      attendee
    )

    sendChimeMeetingJoinMessage(localUser!)

    setLocalUserAttendeeId(attendee.AttendeeId)

    await meetingManager.join(meetingSessionConfiguration)
    await meetingManager.start()
  }

  async function leaveMeeting() {
    await meetingManager.leave()
    await removeAttendee(meetingId!, localUserAttendeeId!)
    sendChimeMeetingLeaveMessage(localUser!)
    setLocalUserAttendeeId(null)
  }

  return (
    <>
      <VideoContainer>
        <VideoTile tileId={tileId} isVideoEnabled={isVideoEnabled} />

        {!isVideoEnabled && localUser?.adobeUserAvatarUrl && (
          <VideoAvatar src={localUser?.adobeUserAvatarUrl} />
        )}
        <div className={styles['name-tag']}>You</div>

        <Theme color="dark">
          <div className={styles['controls']}>
            <div
              style={{
                backgroundColor: tileId
                  ? 'rgba(0, 0, 0, 0.5)'
                  : 'rgba(255, 255, 255, 0.2)'
              }}
              className={styles['controls-icon-container']}>
              <ActionButton
                className={styles['action-btn']}
                style={{ paddingTop: 4 }}
                quiet
                size="s"
                onClick={toggleVideo}>
                <SpIconMovieCamera
                  className={cn({
                    [styles['icon-toggle-off']]: !isVideoEnabled
                  })}
                  size="s"
                />
              </ActionButton>
              <ActionButton
                className={styles['action-btn']}
                quiet
                size="s"
                onClick={toggleMute}>
                <SpIconVoiceOver
                  className={cn({ [styles['icon-toggle-off']]: muted })}
                  size="s"
                />
              </ActionButton>
              <DeviceSelector meetingManager={meetingManager} />
            </div>

            {!meetingId && !localUserAttendeeId && (
              <Button size="s" variant="accent" onClick={startMeeting}>
                Start
              </Button>
            )}
            {meetingId && !localUserAttendeeId && (
              <Button size="s" variant="accent" onClick={joinMeeting}>
                Join
              </Button>
            )}
            {meetingId && localUserAttendeeId && (
              <Button size="s" variant="negative" onClick={leaveMeeting}>
                Leave
              </Button>
            )}
          </div>
        </Theme>
      </VideoContainer>
    </>
  )
}

export default LocalVideo
