import React, { FC } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  ActionButton,
  Button,
  ButtonGroup,
  Icon,
  ProgressCircle,
  Toast
} from 'ui'
import { SpIconClose } from 'workflow-icons'
import cn from 'classnames'
import styles from '@styles/components/FireflyContextualMenu.module.scss'
import {
  useIsEmptyScene,
  useSceneActions,
  useSceneState
} from '@hooks/useScene'
import { useMediumMinimumSizePreference, useProject } from '@hooks/useProject'
import { useFirefly } from '@hooks/useFirefly'
import FireflyImage from '@components/fireflyPanel/FireflyImage'
import FireflyImageSkeleton from './FireflyImageSkeleton'
import { GeneratedImage } from '@store/slices/fireflySlice'
import AiGenerateIcon from '/public/s2_icon_ai_generate.svg'
import {
  LocalStorageBooleanValue,
  LocalStorageKey
} from 'constants/localStorage'
import { StudioPanel } from '@store/slices/projectSlice'
import { EngineSelectedSceneNode } from '@services/engine/types'

const FireflyContextualMenu: FC = () => {
  const flags = useFlags()

  const isEmptyScene = useIsEmptyScene()
  const selectedSceneNode = useSceneState('selectedSceneNode')
  const elements = useSceneState('elements')
  const { unselectElement } = useSceneActions()
  const size = useMediumMinimumSizePreference()
  const { showFireflyPopover, setShowFireflyPopover, setOpenedPanel } =
    useProject()

  const {
    prompt,
    setPrompt,
    generatedImages,
    imageGenerationStatus,
    stopImagGeneration,
    generateImages,
    selectGeneratedImage,
    visibleToast,
    setVisibleToast,
    showToSPopup,
    setShowToSPopup,
    setShowEmptySceneDialog
  } = useFirefly()

  const hasGeneratedAnImg = generatedImages.some(({ url }) => url)

  function handleCancelOnClick() {
    setShowFireflyPopover(false)
    setOpenedPanel(StudioPanel.Properties)
    stopImagGeneration()
  }

  function renderPreviewContent({ selected, url }: GeneratedImage) {
    const showThumbnail =
      url &&
      (imageGenerationStatus === 'idle' ||
        (imageGenerationStatus === 'generating-similar-images' && selected))

    return (
      <div
        className={cn(styles['image-wrapper'], {
          [styles['selected-image']]: selected && showThumbnail
        })}>
        {showThumbnail ? (
          <FireflyImage
            alt={`Firefly Image`}
            imageUrl={url}
            onClick={() => selectGeneratedImage({ url })}
          />
        ) : (
          <FireflyImageSkeleton />
        )}
      </div>
    )
  }

  function handleGenerateImages() {
    if (isEmptyScene) {
      setShowEmptySceneDialog(true)
      setShowFireflyPopover(false)
      setOpenedPanel(StudioPanel.Properties)
      return
    }

    if (
      selectedSceneNode === EngineSelectedSceneNode.SHAPE ||
      selectedSceneNode === EngineSelectedSceneNode.GROUP ||
      selectedSceneNode === EngineSelectedSceneNode.MULTI_SELECTED
    ) {
      for (const e of elements) {
        if (e.selected) unselectElement({ uuid: e.uuid })
      }
    }

    localStorage.getItem(LocalStorageKey.fireflyAgreedToToS) ===
    LocalStorageBooleanValue.TRUE
      ? generateImages()
      : setShowToSPopup(true)
  }

  function handleAgreeToS() {
    setShowToSPopup(false)
    localStorage.setItem(
      LocalStorageKey.fireflyAgreedToToS,
      LocalStorageBooleanValue.TRUE
    )
    generateImages()
  }

  return (
    <>
      {!flags['base-pf-ui-firefly-tos-dialog'] && (
        <div
          className={cn(styles['terms-of-service'], {
            [styles['terms-of-service-visible']]: showToSPopup
          })}>
          <img src="/firefly_cai_banner.jpg" />

          <h3>Generative AI in Adobe apps</h3>
          <div className={styles['terms-of-service-main']}>
            <p>
              By clicking “Agree,” you agree to our
              <a
                href="https://www.adobe.com/legal/licenses-terms/adobe-gen-ai-user-guidelines.html"
                target="_blank">
                {' '}
                User Guidelines
              </a>
              .
            </p>

            <ButtonGroup size={size}>
              <Button
                variant="secondary"
                treatment="outline"
                onClick={() => setShowToSPopup(false)}>
                Cancel
              </Button>
              <Button variant="cta" onClick={handleAgreeToS}>
                Agree
              </Button>
            </ButtonGroup>
          </div>
        </div>
      )}

      <div
        className={cn(styles['prompt-contextual-menu'], {
          [styles['prompt-contextual-menu-visible']]: showFireflyPopover
        })}>
        <ActionButton
          quiet
          className={styles['cancel-btn']}
          onClick={handleCancelOnClick}>
          <SpIconClose slot="icon" />
        </ActionButton>

        <div className={styles['input-wrapper']}>
          <label htmlFor="firefly-prompt">Prompt</label>
          <input
            id="firefly-prompt"
            type="text"
            className={styles['textfield']}
            readOnly={imageGenerationStatus !== 'idle'}
            value={prompt}
            onKeyDown={e => e.key === 'Enter' && generateImages()}
            onInput={e => setPrompt(e.currentTarget.value)}
            placeholder="Describe an image you want to generate"
          />
        </div>
        <div className={styles['content']}>
          <div className={styles['image-container']}>
            {(hasGeneratedAnImg || imageGenerationStatus !== 'idle') &&
              generatedImages.map((output, index) => (
                <React.Fragment key={index}>
                  {renderPreviewContent(output)}
                </React.Fragment>
              ))}
          </div>

          <div className={styles['prompt-contextual-menu-action-group']}>
            <ActionButton
              size={size}
              quiet
              className={cn(styles['generate-btn'], {
                [styles['generate-btn-disabled']]:
                  !prompt.length || showToSPopup
              })}
              onClick={handleGenerateImages}
              disabled={!prompt.length || showToSPopup}>
              {imageGenerationStatus !== 'idle' ? (
                <div className={styles['generate-btn-pending']}>
                  <ProgressCircle indeterminate size="s" />
                </div>
              ) : (
                <>
                  <Icon slot="icon">
                    <AiGenerateIcon />
                  </Icon>
                  Generate
                </>
              )}
            </ActionButton>
          </div>
        </div>
      </div>

      <Toast
        className={cn(styles['toast'], styles['denied-prompt-words-toast'], {
          [styles['toast-adjusted-firefly-popover']]: showFireflyPopover
        })}
        timeout={8000}
        open={visibleToast === 'warning'}
        close={() => {
          setVisibleToast('none')
        }}>
        <div className={styles['denied-prompt-words-toast-content']}>
          <img src="/firefly_error.webp" />
          <div className={styles['denied-prompt-words-toast-content-msg']}>
            <h4>Uh oh</h4>
            <p>
              One or more words in the prompt may not meet User Guidelines and
              were removed.
            </p>
            <Button
              size="s"
              static="white"
              variant="secondary"
              treatment="outline"
              href="https://helpx.adobe.com/firefly/kb/limitations.html"
              target="_blank">
              Learn more
            </Button>
          </div>
        </div>
      </Toast>

      <Toast
        className={cn(styles['toast'], {
          [styles['toast-adjusted-firefly-popover']]: showFireflyPopover
        })}
        timeout={2000}
        open={visibleToast === 'error'}
        close={() => {
          setVisibleToast('none')
        }}
        variant="negative">
        Uh oh, error generating images from Adobe Firefly.
      </Toast>
    </>
  )
}

export default FireflyContextualMenu
