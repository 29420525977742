import React, { FC, memo } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { EngineBackground, EngineCommitChange } from '@services/engine/types'
import {
  ActionButton,
  Divider,
  FieldLabel,
  Icon,
  MenuItem,
  OverlayTrigger,
  Picker,
  Tooltip
} from 'ui'
import SaveToIcon from '/public/s2_icon_export_to.svg'

import Floor from '@components/propertiesPanel/Floor'

import { useFlags } from 'launchdarkly-react-client-sdk'
import PanelAccordion from '@components/panel/PanelAccordion'
import {
  useProjectState,
  useProjectActions,
  useMaxMediumSizePreference
} from '@hooks/useProject'
import ColorPickerWithTextfield from '@components/colorPickerWithTextfield/ColorPickerWithTextfield'
import PanelSectionHeader from '@components/panel/PanelSectionHeader'
import Lights from './Lights'

const Background: FC = () => {
  const backgroundColorA = useSceneState('backgroundColorA')
  const backgroundColorB = useSceneState('backgroundColorB')
  const backgroundType = useSceneState('backgroundType')
  const { setPropertyState } = useSceneActions()

  const frameEnvironmentCollapsed = useProjectState('frameEnvironmentCollapsed')
  const sizePreference = useProjectState('sizePreference')
  const { setPanelAccordionItem, setShowSaveBackgroundDialog } =
    useProjectActions()

  const flags = useFlags()

  const dividerSize = useMaxMediumSizePreference()

  return (
    <PanelAccordion
      label={
        <>
          Environment
          <div className="position-relative">
            <div className="panel-accordion-label-button">
              <OverlayTrigger placement="bottom">
                <ActionButton
                  size={sizePreference}
                  quiet
                  slot="trigger"
                  onClick={e => {
                    e.stopPropagation()
                    setShowSaveBackgroundDialog(true)
                  }}>
                  <Icon slot="icon">
                    <SaveToIcon />
                  </Icon>
                </ActionButton>
                <Tooltip slot="hover-content">
                  Save background to library
                </Tooltip>
              </OverlayTrigger>
            </div>
          </div>
        </>
      }
      collapsed={frameEnvironmentCollapsed}
      onChange={collapsed =>
        setPanelAccordionItem({ key: 'frameEnvironmentCollapsed', collapsed })
      }>
      <div className="flex flex-col">
        <FieldLabel for="background-gradient">Background</FieldLabel>

        <Picker
          id="background-gradient"
          size={sizePreference}
          className="picker-min-width w-auto"
          value={backgroundType.toString()}
          onchange={e => {
            const value = parseInt(
              (e as unknown as { target: { value: string } }).target.value
            )
            setPropertyState({
              key: 'backgroundType',
              value
            })
            document.getElementById('canvas')?.focus()
          }}
          label="Selection Background gradient type">
          <MenuItem
            key={EngineBackground.SOLID.toString()}
            value={EngineBackground.SOLID.toString()}>
            Solid
          </MenuItem>
          <MenuItem
            key={EngineBackground.GRADIENT_RADIAL.toString()}
            value={EngineBackground.GRADIENT_RADIAL.toString()}>
            Radial
          </MenuItem>
          <MenuItem
            key={EngineBackground.GRADIENT_LINEAR.toString()}
            value={EngineBackground.GRADIENT_LINEAR.toString()}>
            Linear
          </MenuItem>
        </Picker>
      </div>

      {backgroundType !== EngineBackground.GRADIENT_LINEAR && (
        <>
          <ColorPickerWithTextfield
            size={sizePreference}
            label="Outer"
            color={backgroundColorA}
            onChange={e =>
              setPropertyState({
                key: 'backgroundColorA',
                value: e?.target?.color,
                commit: EngineCommitChange.END_COMMIT
              })
            }
            onInput={e =>
              setPropertyState({
                key: 'backgroundColorA',
                value: e?.target?.color
              })
            }
            onMouseDown={() =>
              setPropertyState({
                key: 'backgroundColorA',
                value: backgroundColorA,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }
          />

          {backgroundType === EngineBackground.GRADIENT_RADIAL && (
            <ColorPickerWithTextfield
              size={sizePreference}
              label="Inner"
              color={backgroundColorB}
              onChange={e =>
                setPropertyState({
                  key: 'backgroundColorB',
                  value: e?.target?.color,
                  commit: EngineCommitChange.END_COMMIT
                })
              }
              onInput={e =>
                setPropertyState({
                  key: 'backgroundColorB',
                  value: e?.target?.color
                })
              }
              onMouseDown={() =>
                setPropertyState({
                  key: 'backgroundColorB',
                  value: backgroundColorB,
                  commit: EngineCommitChange.BEGIN_COMMIT
                })
              }
            />
          )}
        </>
      )}

      {backgroundType === EngineBackground.GRADIENT_LINEAR && (
        <>
          <ColorPickerWithTextfield
            size={sizePreference}
            label="Top"
            color={backgroundColorB}
            onChange={e =>
              setPropertyState({
                key: 'backgroundColorB',
                value: e?.target?.color,
                commit: EngineCommitChange.END_COMMIT
              })
            }
            onInput={e =>
              setPropertyState({
                key: 'backgroundColorB',
                value: e?.target?.color
              })
            }
            onMouseDown={() =>
              setPropertyState({
                key: 'backgroundColorB',
                value: backgroundColorB,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }
          />

          <ColorPickerWithTextfield
            size={sizePreference}
            label="Bottom"
            color={backgroundColorA}
            onChange={e =>
              setPropertyState({
                key: 'backgroundColorA',
                value: e?.target?.color,
                commit: EngineCommitChange.END_COMMIT
              })
            }
            onInput={e =>
              setPropertyState({
                key: 'backgroundColorA',
                value: e?.target?.color
              })
            }
            onMouseDown={() =>
              setPropertyState({
                key: 'backgroundColorA',
                value: backgroundColorA,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }
          />
        </>
      )}

      {!flags['base-pf-ui-environment-floating-menu'] && (
        <>
          {flags['base-tf-fx-ground'] && (
            <>
              <Divider size={dividerSize} />
              <PanelSectionHeader sectionHeader="Floor" />
              <Floor size={sizePreference} />
            </>
          )}

          <Divider size={dividerSize} style={{ marginTop: 2 }} />
          <PanelSectionHeader sectionHeader="Light" />
          <Lights size={sizePreference} />
        </>
      )}
    </PanelAccordion>
  )
}

export default memo(Background)
