import React, { useContext, FC } from 'react'
import { Provider as SpectrumV3Provider } from '@adobe/react-spectrum'
// @ts-ignore
import SpectrumV2Provider from '@react/react-spectrum/Provider'
import { ThemeContext } from '@contexts/ThemeContext'
import Footer from '@components/footer/Footer'
import styles from '@styles/components/DefaultLayout.module.scss'
import { Theme } from 'ui'
import { useRouter } from 'next/router'
import { useAuth } from '@hooks/useAuth'
import CommunityAppBar from '@components/navigation/CommunityAppBar'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { SusiLightDialog } from '@services/auth/SusiLight/SusiLightDialog'

interface DefaultLayoutProps {
  children: React.ReactNode
}

const DefaultLayout: FC<DefaultLayoutProps> = ({ children }) => {
  const router = useRouter()
  const flags = useFlags()
  const { isDarkMode } = useContext(ThemeContext)
  const { status } = useAuth()
  const isAuthenticated = status === 'AUTHENTICATED'

  const footerDisabled = [
    '/discover',
    '/profile/[uuid]',
    '/my-projects'
  ].includes(router.pathname)

  return (
    <Theme color={isDarkMode ? 'dark' : 'light'} theme="spectrum">
      <SpectrumV2Provider
        theme={isDarkMode ? 'dark' : 'light'}
        style={{ background: 'inherit' }}>
        <SpectrumV3Provider colorScheme={isDarkMode ? 'dark' : 'light'}>
          <div className={styles['container']}>
            <header className={styles['header']}>
              <CommunityAppBar isAuthenticated={isAuthenticated} />
            </header>
            <main className={styles['main']}>{children}</main>
            {flags['base-pf-ui-login-modal'] && <SusiLightDialog />}
            {!footerDisabled && <Footer />}
          </div>
        </SpectrumV3Provider>
      </SpectrumV2Provider>
    </Theme>
  )
}

export default DefaultLayout
