import React from 'react'
import { ActionButton, Icon, Tooltip } from 'ui'
import { useUISettings } from '@services/engine/useUISettings'
import SnapMagnetIcon from '/public/temp-icon-object-snap.svg'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

const ObjectSnappingToggleButton = () => {
  const size = useMediumMinimumSizePreference()
  const { snappingActive } = useUISettings()
  const [isSnappingActive, setSnappingActive] = snappingActive()
  const snappingCurrentlyActive = isSnappingActive() ?? false

  return (
    <ActionButton
      size={size}
      selected={snappingCurrentlyActive}
      quiet
      toggles
      onclick={() => {
        setSnappingActive(!snappingCurrentlyActive)
        document.getElementById('canvas')?.focus()
      }}>
      <Icon slot="icon" className="icon-m">
        <SnapMagnetIcon />
      </Icon>
      <Tooltip selfManaged placement="bottom">
        Object snapping
      </Tooltip>
    </ActionButton>
  )
}

export default ObjectSnappingToggleButton
