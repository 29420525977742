import { FC, ReactNode, useRef } from 'react'
import Head from 'next/head'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Divider, ProgressCircle, Theme } from 'ui'

import cn from 'classnames'
import styles from '@styles/components/Studio.module.scss'

import { AppNameBeta } from '@constants/appNameBeta'
import { EngineMode } from '@services/engine/types'
import { useProjectState } from '@hooks/useProject'
import { useFirefly } from '@hooks/useFirefly'
import { AspectRatio } from '@store/slices/fireflySlice'
import { useMaxSize } from '@hooks/useMaxSize'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { useIsMobile } from '@hooks/useIsMobile'
import { useIsExportingVideo } from './useIsExportingVideo'
import { useInitializeUIPreference } from './useInitializeUIPreference'

import SyncInterface from '@components/sync/SyncInterface'
import UserFollowingIndicator from '@components/sync/UserFollowingIndicator'
import FrameDimension from '@components/propertiesPanel/FrameDimension'
import FireflyPreview from '@components/fireflyPanel/FireflyPreview'
import RightRail from '@components/rightRail/RightRail'
import LeftRail from '@components/leftRail/LeftRail'
import VideoExportDialog from '@components/share/VideoExportDialog'
import ErrorDialog from './ErrorDialog'
import RefreshDialog from './RefreshDialog'
import FireflyContextualMenu from '@components/fireflyPanel/FireflyContextualMenu'
import ContextBar from '@components/contextBar/ContextBar'
import MobileWarning from '@components/mobileWarning/mobileWarning'
import StudioAppBar from '@components/studioAppBar/StudioAppBar'
import TouchDeviceWarningDialog from './TouchDeviceWarningDialog'
import WindowsGPUConfigInstructionDialog from './WindowsGPUConfigInstructionDialog'
import SceneNavigation from '@components/SceneNavigation/SceneNavigation'
import Snapshot from '@components/snapshot/Snapshot'
import OrbitControls from '@components/propertiesPanel/OrbitControls'
import FloatingEditShapeToggle from '@components/propertiesPanel/FloatingEditShapeToggle'
import FireflyOnboardingDialog from '@components/fireflyPanel/FireflyOnboardingDialog'
import FireflyUploadImagesRightsDialog from '@components/fireflyPanel/FireflyUploadImagesRightsDialog'
import FireflyEmptySceneDialog from '@components/fireflyPanel/FireflyEmptySceneDialog'
import SettingsDialog from '@components/settingsDialog/SettingsDialog'
import FireflyFreeLimitedTimeToast from '@components/fireflyPanel/FireflyFreeLimitedTimeToast'
import SaveBackgroundDialog from '@components/library/SaveBackgroundDialog'
import SaveMaterialDialog from '@components/library/SaveMaterialDialog'
import SceneLayersPanel from '@components/SceneLayers/SceneLayersPanel'
import ChatPanel from '@components/chatPanel/ChatPanel'
import ChimePanel from '@components/chime/ChimePanel'
import CommunityPanel from '@components/communityPanel/CommunityPanel'
import FireflyPanel from '@components/fireflyPanel/FireflyPanel'
import LibraryPanel from '@components/library/LibraryPanel'
import PlayerPanel from '@components/playerPanel/PlayerPanel'
import PropertiesPanel from '@components/propertiesPanel/PropertiesPanel'
import StylesPanel from '@components/styles/StylesPanel'
import ViewsPanel from '@components/views/ViewsPanel'
import FireflyToSDialog from '@components/fireflyPanel/FireflyToSDialog'
import ProjectShareDialog from '@components/share/ProjectShareDialog'
import ProjectSharedToast from '@components/share/ProjectSharedToast'
import { PanelKeys, StudioPanel } from '@store/slices/projectSlice'
import StylesActionMenu from '@components/styles/StylesActionMenu'
import EnvironmentActionMenu from '@components/propertiesPanel/EnvironmentActionMenu'
import { CanvasContainer } from './CanvasContainer'

const AspectRatioMap: Record<AspectRatio, number> = {
  [AspectRatio.LANDSCAPE]: 4 / 3,
  [AspectRatio.PORTRAIT]: 3 / 4,
  [AspectRatio.SQUARE]: 1,
  [AspectRatio.WIDESCREEN]: 16 / 9
}

type Props = {
  uuid: string
}

const PanelMap: Record<PanelKeys, ReactNode> = {
  [StudioPanel.Properties]: <PropertiesPanel />,
  [StudioPanel.Library]: <LibraryPanel />,
  [StudioPanel.Chat]: <ChatPanel />,
  [StudioPanel.Player]: <PlayerPanel />,
  [StudioPanel.Community]: <CommunityPanel />,
  [StudioPanel.Firefly]: <FireflyPanel />,
  [StudioPanel.Meet]: <ChimePanel />,
  [StudioPanel.Views]: <ViewsPanel />,
  [StudioPanel.Layers]: <SceneLayersPanel />,
  [StudioPanel.Style]: <StylesPanel />
}

export const STUDIO_CONTAINER_ID = 'studio-container'

const Studio: FC<Props> = ({ uuid }) => {
  const flags = useFlags()

  const engineState = useSceneState('engineState')
  const canvasAnimationStartedForRecording = useSceneState(
    'canvasAnimationStartedForRecording'
  )
  const illustrativeTexturesLoaded = useSceneState('illustrativeTexturesLoaded')
  const mode = useSceneState('mode')
  const { startVideoRecording } = useSceneActions()

  const name = useProjectState('name')
  const isLoading = useProjectState('isLoading')
  const showContextBar = useProjectState('showContextBar')
  const showFireflyPopover = useProjectState('showFireflyPopover')
  const showOrbitControls = useProjectState('showOrbitControls')
  const showSceneNavigator = useProjectState('showSceneNavigator')
  const openedPanel = useProjectState('openedPanel')
  const isDraggingContextBar = useProjectState('isDraggingContextBar')
  const colorPreference = useProjectState('colorPreference')
  const sizePreference = useProjectState('sizePreference')

  const { aspectRatio, isSceneRealisticOrVectorArt } = useFirefly()

  useInitializeUIPreference()

  const isMobile = useIsMobile()

  const isDeviceCompatible = !isMobile

  const mainSectionRef = useRef<HTMLDivElement>(null)

  const mainSectionItemSize = useMaxSize(
    showFireflyPopover ? mainSectionRef.current : null,
    AspectRatioMap[aspectRatio]
  )
  const mainSectionItemStyle =
    showFireflyPopover && mainSectionItemSize ? mainSectionItemSize : undefined

  const showLoadingSpinner: boolean =
    (isLoading && engineState !== 'ERROR') ||
    (mode === EngineMode.ILLUSTRATIVE && !illustrativeTexturesLoaded)

  const isExportingVideo = useIsExportingVideo()

  const documentTitle = name ? `${name} | ${AppNameBeta}` : `${AppNameBeta}`

  if (!isDeviceCompatible) {
    return (
      <Theme color={colorPreference} theme="express">
        <MobileWarning />
      </Theme>
    )
  }
  return (
    <>
      <Head>
        <title>{documentTitle}</title>
      </Head>

      <Theme
        className={styles[`size-${sizePreference}`]}
        scale="medium"
        color={colorPreference}
        theme="spectrum">
        <RefreshDialog />
        <ErrorDialog />
        <WindowsGPUConfigInstructionDialog />
        <TouchDeviceWarningDialog />
        <VideoExportDialog />
        <FireflyOnboardingDialog />
        <FireflyUploadImagesRightsDialog />
        <FireflyEmptySceneDialog />
        <FireflyToSDialog />
        <SettingsDialog />
        <SaveBackgroundDialog />
        <ProjectShareDialog />
        <SaveMaterialDialog />
        <div
          id={STUDIO_CONTAINER_ID}
          className={cn(styles['container'], {
            [styles['minimal-layout']]: canvasAnimationStartedForRecording,
            'no-pointer-events': isDraggingContextBar
          })}
          onTransitionEnd={e => {
            if (
              e.target === e.currentTarget &&
              canvasAnimationStartedForRecording &&
              e.propertyName === 'grid-template-columns'
            ) {
              startVideoRecording()
            }
          }}>
          <StudioAppBar className={styles['app-bar']} />
          <div className={styles['left-rail']}>
            <LeftRail />
          </div>

          <div
            className={cn(styles['main'], {
              [styles['main-firefly-preview']]: showFireflyPopover
            })}>
            <FireflyFreeLimitedTimeToast />
            <ProjectSharedToast />

            {showLoadingSpinner && (
              <div className={styles['progress-circle-container']}>
                <ProgressCircle size="l" indeterminate />
              </div>
            )}

            {flags['base-pf-ui-firefly'] &&
              isSceneRealisticOrVectorArt &&
              !isExportingVideo &&
              !canvasAnimationStartedForRecording && <FireflyContextualMenu />}

            {flags['base-pf-ui-floating-edit-shape-toggle'] &&
              !isExportingVideo &&
              !showFireflyPopover &&
              !showLoadingSpinner && <FloatingEditShapeToggle />}

            <ContextBar
              show={
                !canvasAnimationStartedForRecording &&
                !isExportingVideo &&
                !showFireflyPopover &&
                showContextBar &&
                engineState === 'INITIALIZED_AND_DOCUMENT_LOADED'
              }
            />

            {!canvasAnimationStartedForRecording &&
              !isExportingVideo &&
              !showLoadingSpinner && (
                <div className={styles['floating-top-left-action-buttons']}>
                  {flags['base-pf-ui-snapshot'] && <Snapshot />}

                  {(flags['base-pf-ui-styles-floating-menu'] ||
                    flags['base-pf-ui-environment-floating-menu']) && (
                    <div className={styles['styles-environment-action-menus']}>
                      {flags['base-pf-ui-styles-floating-menu'] && (
                        <StylesActionMenu />
                      )}
                      {flags['base-pf-ui-styles-floating-menu'] &&
                        flags['base-pf-ui-environment-floating-menu'] && (
                          <Divider
                            className="vertical-divider"
                            size="m"
                            vertical
                          />
                        )}
                      {flags['base-pf-ui-environment-floating-menu'] && (
                        <EnvironmentActionMenu />
                      )}
                    </div>
                  )}
                </div>
              )}

            {!showFireflyPopover &&
              flags['base-tf-ui-scene-navigation'] &&
              !isExportingVideo &&
              showSceneNavigator && <SceneNavigation />}

            {!showFireflyPopover &&
              flags['base-tf-ui-camera-orbit'] &&
              showOrbitControls &&
              !canvasAnimationStartedForRecording &&
              engineState === 'INITIALIZED_AND_DOCUMENT_LOADED' && (
                <OrbitControls />
              )}

            <div
              className={cn({
                [styles['max-size']]: !showFireflyPopover,
                [styles['main-firefly-preview-section']]: showFireflyPopover,
                ['justify-end']: showFireflyPopover
              })}
              ref={mainSectionRef}>
              <CanvasContainer
                mainSectionItemStyle={mainSectionItemStyle!}
                uuid={uuid}>
                <UserFollowingIndicator />
                <SyncInterface />
                {flags['base-pf-ui-frame-dimension'] && !showLoadingSpinner && (
                  <FrameDimension />
                )}
              </CanvasContainer>
            </div>

            {showFireflyPopover && (
              <div className={styles['main-firefly-preview-section']}>
                <div
                  className={cn('position-relative', styles['border-radius'])}
                  style={mainSectionItemStyle}>
                  <FireflyPreview />
                </div>
              </div>
            )}
          </div>
          <div className={styles['panels']}>
            {openedPanel && (
              <div className={styles['panels-container']}>
                {PanelMap[openedPanel]}
              </div>
            )}
          </div>

          <div className={styles['right-rail']}>
            <RightRail />
          </div>
        </div>
      </Theme>
    </>
  )
}

export default Studio
