import React, { FC, memo } from 'react'
import styles from '@styles/components/StylesPanel.module.scss'
import Panel from '@components/panel/Panel'
import CursorOverlay from '@components/cursorOverlay/CursorOverlay'
import { EngineMode } from '@services/engine/types'
import { useSceneState, useSceneActions } from '@hooks/useScene'
import { useFlags } from 'launchdarkly-react-client-sdk'
import PanelHeader from '@components/panel/PanelHeader'
import PanelContent from '@components/panel/PanelContent'
import SelectionCardItem from '@components/selectionCardItem/SelectionCardItem'
import PanelAccordion from '@components/panel/PanelAccordion'
import { useProjectState, useProjectActions } from '@hooks/useProject'
import StylesInputs from './StylesInputs'
import {
  EngineModeImgMap,
  EngineModeLabelMap,
  Styles
} from './StylesActionMenu'

const StylesPanel: FC = () => {
  const styleCollapsed = useProjectState('styleCollapsed')
  const sizePreference = useProjectState('sizePreference')
  const { setPanelAccordionItem } = useProjectActions()

  const mode = useSceneState('mode')

  const { setPropertyState } = useSceneActions()

  const showAccordion =
    mode === EngineMode.PIXEL ||
    mode === EngineMode.OUTLINE ||
    mode === EngineMode.ILLUSTRATIVE

  const flags = useFlags()

  const enabledStyles = Styles.filter(style => {
    if (
      style === EngineMode.ILLUSTRATIVE &&
      !flags['base-tf-fx-illustrative-style']
    ) {
      return null
    } else {
      return style
    }
  })

  return (
    <Panel>
      <PanelHeader textAlign="center">Styles</PanelHeader>
      <PanelContent>
        <div className={styles['style-cards']}>
          {enabledStyles.map(value => (
            <SelectionCardItem
              key={value}
              label={EngineModeLabelMap[value]}
              imgFullSize
              imgSrc={EngineModeImgMap[value]}
              onClick={() =>
                setPropertyState({
                  key: 'mode',
                  value
                })
              }
              selected={value === mode}
              showCheckMark={false}
            />
          ))}
        </div>

        {showAccordion && (
          <PanelAccordion
            label={EngineModeLabelMap[mode]}
            collapsed={styleCollapsed}
            onChange={collapsed =>
              setPanelAccordionItem({
                key: 'styleCollapsed',
                collapsed
              })
            }>
            <StylesInputs size={sizePreference} />
          </PanelAccordion>
        )}
      </PanelContent>
      <CursorOverlay />
    </Panel>
  )
}

export default memo(StylesPanel)
