import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type HistoryEngineState =
  | 'UNINITIALIZED'
  | 'INITIALIZE'
  | 'INITIALIZED'
  | 'INITIALIZED_AND_DOCUMENT_LOADED'
  | 'UNMOUNT'
  | 'UNMOUNTED'
  | 'MOUNT'
  | 'ERROR'

export interface HistoryState {
  engineState: HistoryEngineState
  showHistoryDialog: {
    isOpen: boolean
  }
  historyMode: number
  historySliderPosition: number
  isSceneLoading: boolean
}

const initialState: HistoryState = {
  engineState: 'UNINITIALIZED',
  showHistoryDialog: {
    isOpen: false
  },
  historyMode: 0, // 1 is for history mode, 0 is for "normal" mode
  historySliderPosition: 1,
  isSceneLoading: true
}

export const history = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setEngineState: (
      state,
      { payload }: PayloadAction<HistoryState['engineState']>
    ) => {
      state.engineState = payload
    },
    setShowHistoryDialog: (
      state,
      { payload }: PayloadAction<HistoryState['showHistoryDialog']>
    ) => {
      state.showHistoryDialog = payload
    },
    setHistoryMode: (
      state,
      { payload }: PayloadAction<HistoryState['historyMode']>
    ) => {
      state.historyMode = payload
    },
    setHistorySliderPosition: (
      state,
      { payload }: PayloadAction<HistoryState['historySliderPosition']>
    ) => {
      state.historySliderPosition = payload
    },
    setIsLoadingScene: (
      state,
      { payload }: PayloadAction<HistoryState['isSceneLoading']>
    ) => {
      state.isSceneLoading = payload
    },
    resetState: state => {
      state.historyMode = 0
      state.historySliderPosition = 1
    }
  }
})

export const {
  setShowHistoryDialog,
  setHistoryMode,
  resetState,
  setEngineState,
  setHistorySliderPosition,
  setIsLoadingScene
} = history.actions

export default history.reducer
