import { useState, KeyboardEvent } from 'react'

export const useInput = (
  initialValue: string,
  onSubmit: (value: string) => void
) => {
  const [value, setValue] = useState(initialValue)

  function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      onSubmit(value)
    }
  }

  function onChange(value: string) {
    setValue(value)
  }

  return {
    value,
    onChange,
    onKeyDown
  }
}
