import React, { FC } from 'react'
import { useUISettings } from '@services/engine/useUISettings'
import { Switch } from 'ui'

const ObjectSnappingSwitch: FC = () => {
  const { snappingActive } = useUISettings()
  const [isSnappingActive, setSnappingActive] = snappingActive()
  const snappingCurrentlyActive = isSnappingActive() ?? false

  return (
    <Switch
      checked={snappingCurrentlyActive}
      onclick={() => {
        setSnappingActive(!snappingCurrentlyActive)
      }}>
      Object snapping
    </Switch>
  )
}

export default ObjectSnappingSwitch
