import React, { FC } from 'react'
import { useSceneActions } from '@hooks/useScene'
import styles from '@styles/components/OrbitControls.module.scss'

import { ActionButton, Tooltip } from 'ui'
import { SpIconAdd } from 'workflow-icons'
import { getCmdModifier } from '@services/engine/utils'

const ZoomIn: FC = () => {
  const { zoomSelection } = useSceneActions()

  const modifier = getCmdModifier()

  return (
    <ActionButton
      className={styles['action-btn']}
      quiet
      onClick={() => {
        zoomSelection(0.9)
        document.getElementById('canvas')?.focus()
      }}>
      <div className={styles['action-btn__icon']}>
        <SpIconAdd slot="icon" size="l" />
      </div>
      <Tooltip selfManaged placement="bottom">
        Zoom in ({modifier} +)
      </Tooltip>
    </ActionButton>
  )
}

export default ZoomIn
