import React, { memo } from 'react'
import {
  useRosterState,
  useRemoteVideoTileState
} from 'amazon-chime-sdk-component-library-react'
import { useAuth } from '@hooks/useAuth'
import { useSync } from '@hooks/useSync'
import RemoteVideo from './RemoteVideo'

const rgbColor = (r: number, g: number, b: number) => `rgb(${r}, ${g}, ${b})`

const RemoteVideos = () => {
  const { roster } = useRosterState()
  const { attendeeIdToTileId } = useRemoteVideoTileState()
  const { localUser } = useAuth()
  const { filteredRemoteUsers } = useSync()

  return (
    <>
      {Object.keys(roster).map(key => {
        const attendee = roster[key]
        if (attendee.externalUserId === localUser?.uuid) return null

        const remoteUser = filteredRemoteUsers.find(
          user => user.user.uuid === attendee.externalUserId
        )

        return (
          <RemoteVideo
            key={attendee.chimeAttendeeId}
            tileId={attendeeIdToTileId[attendee.chimeAttendeeId]}
            name={remoteUser?.user.adobe_user_display_name}
            avatar={remoteUser?.user.adobe_user_avatar_url}
            color={
              remoteUser?.user?.preferences.color &&
              rgbColor(...remoteUser?.user?.preferences.color)
            }
            attendeeId={attendee.chimeAttendeeId}
          />
        )
      })}
    </>
  )
}

export default memo(RemoteVideos)
