import React, { FC } from 'react'
import { saveAs } from 'file-saver'
import Context from '@store/middleware/context'
import ShareExportFooter from './ShareExportFooter'

function getBase64DataStringFromCanvas(canvas: HTMLCanvasElement) {
  const [_prefix, imageData] = canvas.toDataURL('image/png').split(';base64,')
  return imageData
}

const JSONExportForm: FC = () => {
  async function handleExport() {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement

    if (!canvas) return
    // Add a check there?
    const doc = await JSON.parse(Context.Engine?.getDocument()!)

    // Add a 'snapshot' image representation of the scene
    // When creating a project from JSON, a new S3 thumbnail path will be initialized with this data
    const docWithSnapshot = JSON.stringify({
      base64Snapshot: getBase64DataStringFromCanvas(canvas),
      ...doc
    })

    saveAs(
      new Blob([docWithSnapshot!], { type: 'application/json' }),
      'neo_export.json'
    )
  }

  return (
    <ShareExportFooter onExportClick={handleExport} buttonText="Download" />
  )
}

export default JSONExportForm
