import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import Background from '@components/propertiesPanel/Background'
import Frame from './Frame'
import PanelHeader from '@components/panel/PanelHeader'
import PanelContent from '@components/panel/PanelContent'
import Camera from './Camera'
import History from './History'

const SceneProperties = () => {
  const flags = useFlags()

  return (
    <>
      <PanelHeader textAlign="center">Scene</PanelHeader>
      <PanelContent>
        {!flags['base-pf-ui-history-slider'] && <History />}
        <Frame />
        <Background />
        <Camera />
      </PanelContent>
    </>
  )
}

export default SceneProperties
