import React, { FC, memo } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { FieldLabel, NumberField } from 'ui'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import PanelAccordion from '@components/panel/PanelAccordion'
import { EngineCommitChange } from '@services/engine/types'
import { ElementSize } from '@store/slices/projectSlice'
import { Section } from './ObjectProperties'

const Transform: FC = () => {
  const address = useSceneState('address')

  const threeDTransformCollapsed = useProjectState('threeDTransformCollapsed')
  const sizePreference = useProjectState('sizePreference')
  const { setPanelAccordionItem } = useProjectActions()

  return (
    <PanelAccordion
      id={Section.transform}
      label="Transform"
      collapsed={threeDTransformCollapsed}
      onChange={collapsed =>
        setPanelAccordionItem({ key: 'threeDTransformCollapsed', collapsed })
      }>
      <FieldLabel>Size</FieldLabel>
      <div className="flex justify-between gap-s">
        {(
          [
            {
              label: 'W',
              id: 'transform-scale-x',
              fieldChangeKey: 'transformScaleX'
            },
            {
              label: 'H',
              id: 'transform-scale-y',
              fieldChangeKey: 'transformScaleY'
            },
            {
              label: 'D',
              id: 'transform-scale-z',
              fieldChangeKey: 'transformScaleZ'
            }
          ] as Omit<InputProps, 'size'>[]
        ).map((props, i) => (
          <Input
            key={i}
            {...props}
            size={sizePreference}
            address={address}
            min={0}
            step={0.001}
            className="flex align-center gap-xs"
          />
        ))}
      </div>

      <FieldLabel>Position</FieldLabel>
      <div className="flex justify-between gap-s">
        {(
          [
            {
              label: 'X',
              id: 'transform-position-x',
              fieldChangeKey: 'transformPositionX'
            },
            {
              label: 'Y',
              id: 'transform-position-y',
              fieldChangeKey: 'transformPositionY'
            },
            {
              label: 'Z',
              id: 'transform-position-z',
              fieldChangeKey: 'transformPositionZ'
            }
          ] as Omit<InputProps, 'size'>[]
        ).map((props, i) => (
          <Input
            key={i}
            {...props}
            size={sizePreference}
            address={address}
            step={0.001}
            className="flex justify-between align-center gap-s"
          />
        ))}
      </div>

      <FieldLabel>Rotation</FieldLabel>
      <div className="flex justify-between gap-s">
        {(
          [
            {
              label: 'X',
              id: 'transform-rotation-x',
              fieldChangeKey: 'transformRotationX'
            },
            {
              label: 'Y',
              id: 'transform-rotation-y',
              fieldChangeKey: 'transformRotationY'
            },
            {
              label: 'Z',
              id: 'transform-rotation-z',
              fieldChangeKey: 'transformRotationZ'
            }
          ] as Omit<InputProps, 'size'>[]
        ).map((props, i) => (
          <Input
            key={i}
            {...props}
            size={sizePreference}
            address={address}
            step={0.001}
            className="flex justify-between align-center gap-s"
          />
        ))}
      </div>
    </PanelAccordion>
  )
}

export default memo(Transform)

type FieldChangeKey =
  | 'transformPositionX'
  | 'transformPositionY'
  | 'transformPositionZ'
  | 'transformRotationX'
  | 'transformRotationY'
  | 'transformRotationZ'
  | 'transformScaleZ'
  | 'transformScaleY'
  | 'transformScaleX'

interface InputProps {
  address: string
  label: string
  id: string
  step?: number
  fieldChangeKey: FieldChangeKey
  size: ElementSize
  min?: number
  className?: string
}

function Input({
  address,
  label,
  id,
  step,
  fieldChangeKey,
  size,
  min,
  className
}: InputProps) {
  const value = useSceneState(fieldChangeKey)
  const { setPropertyState } = useSceneActions()
  return (
    <div className={className}>
      <FieldLabel for={id} size={size}>
        {label}
      </FieldLabel>
      <NumberField
        style={{ width: '100%' }}
        id={id}
        aria-label={id}
        step={step}
        min={min}
        value={value}
        onChange={e => {
          if (isNaN(e.target.value)) {
            e.target.value = value
            e.stopPropagation()
          } else
            setPropertyState({
              address,
              key: fieldChangeKey,
              value: e.target.value,
              commit: EngineCommitChange.END_COMMIT
            })
        }}
        hideStepper
        size={size}
      />
    </div>
  )
}
