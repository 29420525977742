import React, { FC, ReactNode } from 'react'
import Typography from '../Typography/Typography'

interface Props {
  children?: ReactNode
  className?: string
}

const DialogFormSectionHeader: FC<Props> = ({ children, className }) => {
  return (
    <Typography className={className} variant="h4">
      {children}
    </Typography>
  )
}

export default DialogFormSectionHeader
