import React from 'react'
import { Toast } from 'ui'
import { useFirefly } from '@hooks/useFirefly'

const FireflyFreeLimitedTimeToast = () => {
  const { showLimitedFreeTimeToast, setShowLimitedFreeTimeToast } = useFirefly()

  return (
    <Toast
      open={showLimitedFreeTimeToast}
      close={() => setShowLimitedFreeTimeToast(false)}
      variant="info"
      className="absolute-align-horizontal"
      style={{
        top: 20,
        zIndex: showLimitedFreeTimeToast ? 999999 : -1
      }}>
      Scene to image is free for a limited time.
    </Toast>
  )
}

export default FireflyFreeLimitedTimeToast
