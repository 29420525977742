import React, { FC, PropsWithChildren } from 'react'
import styles from '@styles/components/Rail.module.scss'
import cn from 'classnames'
import { ClassNameProps } from 'types/reactProps'

interface Props extends PropsWithChildren, ClassNameProps {}

const Rail: FC<Props> = ({ children, className }) => {
  return <div className={cn(styles['rail'], className)}>{children}</div>
}

export default Rail
