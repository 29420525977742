import React, { FC } from 'react'
import Footer from '@components/footer/Footer'
import styles from '@styles/components/Compatibility.module.scss'
import {
  useBrowserIncompatibilityChecker,
  IncompatibilityError
} from '@hooks/useBrowserIncompatibilityChecker'
import CompatibilityWarning from '@components/compatibilityWarning/CompatibilityWarning'
import AppLogo from '@components/appLogo/AppLogo'
import { useRouter } from 'next/router'
import { AppName } from '@constants/appConstants'
import BetaLightIcon from '/public/header-badge-beta-light.svg'

export const Heading = () => (
  <h2
    style={{
      margin: 0,
      display: 'flex',
      flexWrap: 'nowrap',
      gap: '6px',
      alignItems: 'center'
    }}>
    {`${AppName}`}
    {<BetaLightIcon />}
  </h2>
)

const unsupportedBrowserWarningInfo = {
  image: '/compatibility_illustration.png',
  altText: 'Compatibility Illustration',
  heading: <Heading />,
  description: (
    <p
      style={{
        fontSize: '1rem',
        margin: 0
      }}>
      Neo is not supported on this web browser
    </p>
  )
}

const wrongAngleBackendWarningInfo = {
  image: '/compatibility_illustration.png',
  altText: 'Compatibility Illustration',
  heading: <Heading />,
  description: (
    <p
      style={{
        fontSize: '1rem',
        margin: 0
      }}>
      Chromium browser detected with an unsupported rendering Backend:
      <p>
        1. Type <b>chrome://flags</b> in the search bar
      </p>
      <p>
        2. Look for <i>Choose ANGLE graphics backend</i> setting
      </p>
      <p>
        3. Select <i>Metal</i>
      </p>
      <p>4. Restart the browser</p>
    </p>
  )
}

const webGlUnsupportedWarningInfo = {
  image: '/compatibility_illustration.png',
  altText: 'Compatibility Illustration',
  heading: <Heading />,
  description: (
    <p
      style={{
        fontSize: '1rem',
        margin: 0
      }}>
      WebGL is not supported on this web browser
    </p>
  )
}

export default function BrowserIncompatiblePage() {
  const router = useRouter()
  const error = useBrowserIncompatibilityChecker()

  if (error == IncompatibilityError.ok) {
    return (
      <div className={styles['no-error-container']}>
        <AppLogo onClick={() => router.push('/')} />
      </div>
    )
  }

  return (
    <div className={styles['container']}>
      <div className={styles['content']}>
        {error == IncompatibilityError.unsupportedBrowser && (
          <CompatibilityWarning
            image={unsupportedBrowserWarningInfo.image}
            altText={unsupportedBrowserWarningInfo.altText}
            heading={unsupportedBrowserWarningInfo.heading}
            description={unsupportedBrowserWarningInfo.description}
          />
        )}

        {error == IncompatibilityError.webGlUnsupported && (
          <CompatibilityWarning
            image={webGlUnsupportedWarningInfo.image}
            altText={webGlUnsupportedWarningInfo.altText}
            heading={webGlUnsupportedWarningInfo.heading}
            description={webGlUnsupportedWarningInfo.description}
          />
        )}

        {error == IncompatibilityError.incorrectAngleBackend && (
          <CompatibilityWarning
            image={wrongAngleBackendWarningInfo.image}
            altText={wrongAngleBackendWarningInfo.altText}
            heading={wrongAngleBackendWarningInfo.heading}
            description={wrongAngleBackendWarningInfo.description}
          />
        )}
      </div>
      <Footer />
    </div>
  )
}

BrowserIncompatiblePage.layout = 'none'
