import React, { FC } from 'react'
import { Link, Overlay, Popover } from 'ui'
import styles from '@styles/components/SharedBadge.module.scss'
import { ElementSize } from '@store/slices/projectSlice'
import Badge from '@components/badge/Badge'
import GlobeGridIcon from '/public/s2_icon_globe_grid.svg'
import ShareLink from './ShareLink'

interface Props {
  size?: ElementSize
}

// TODO update link
export const ProjectShareInfoLink = 'https://www.adobe.com'

const SharedBadge: FC<Props> = ({ size = 'm' }) => {
  return (
    <>
      <Badge
        id="project-shared-badge"
        icon={<GlobeGridIcon />}
        size={size}
        variant="positive">
        Shared
      </Badge>
      <Overlay
        placement="bottom-start"
        offset={10}
        trigger="project-shared-badge@hover">
        <Popover className={styles['popover']}>
          <p>
            Others can view this design and snapshots of its history in the
            Community gallery. To remove it from the gallery, click Share and
            choose Remove from Community. 
          </p>

          <ShareLink />
        </Popover>
      </Overlay>
    </>
  )
}

export default SharedBadge
