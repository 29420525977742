import React from 'react'
import { EngineSelectedSceneNode } from '@services/engine/types'
import { useSceneState } from '@hooks/useScene'
import ObjectProperties from './ObjectProperties'
import SceneProperties from './SceneProperties'
import CursorOverlay from '@components/cursorOverlay/CursorOverlay'
import Panel from '@components/panel/Panel'

const PropertiesPanel = () => {
  const selectedSceneNode = useSceneState('selectedSceneNode')

  const showObjectPropertiesPanel =
    selectedSceneNode === EngineSelectedSceneNode.SHAPE ||
    selectedSceneNode === EngineSelectedSceneNode.GROUP ||
    selectedSceneNode === EngineSelectedSceneNode.MULTI_SELECTED

  const showScenePropertiesPanel =
    selectedSceneNode === EngineSelectedSceneNode.PROJECT ||
    selectedSceneNode === EngineSelectedSceneNode.NONE

  return (
    <Panel>
      {showScenePropertiesPanel && <SceneProperties />}
      {showObjectPropertiesPanel && <ObjectProperties />}
      <CursorOverlay />
    </Panel>
  )
}

export default PropertiesPanel
